import { EntityMetadataMap } from 'ngrx-data';

const entityMetadata: EntityMetadataMap = {
  StarShip: {},
  Client: {},
  Article: {},
  OrderSummary: {},
  OrderModel: {},
};

const pluralNames = {};

export const entityConfig = {
  entityMetadata,
  pluralNames
};
