import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { UserType } from '../../../../auth/enums/user-type.enum';
import { UserListModel } from '../../../models/user-list.model';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends TcFormComponent<UserListModel> implements OnInit {

  private tempPass = null;

  constructor(
    public translateService: TcTranslateService,
    private dialogRef: MatDialogRef<UserDetailComponent>,
    element: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UserListModel,
  ) {

    super(translateService, element);

    this.tempPass = data.password;
    const exists = !!Object.keys(data).length;

    data.password = null;
    if(data.accessStatistique === undefined){
      data.accessStatistique = false;
    }

    this.fields = [
      {
        key: 'firstName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'lastName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        }
      },
      {
        key: 'login',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        }
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        }
      },
      {
        key: 'codeVrp',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true,
          ['vrpFormatInvalid']: this.translate.instant('validations.invalidVrpFormat'),
        },
        validators: {
          validation: [
            this.checkVrpFormat
          ]
        }
      },
      ...(exists ? this.userExistsField : this.userNotExistsField),
      {
        key: 'tabletCode',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true,
        },
      },
      {
        key: 'profil',
        type: 'select',
        templateOptions: {
          required: true,
          options: [{
            value: UserType.Administrator,
            label: this.translate.instant(`user-detail-info.${UserType.Administrator.toLowerCase()}`),
          }, {
            value: UserType.Salesman,
            label: this.translate.instant(`user-detail-info.${UserType.Salesman.toLowerCase()}`),
          }]
        }
      },
      {
        key: 'accessStatistique',
        type: 'checkbox',
        templateOptions: {
          type: 'boolean'
        }
      },
    ];
  }

  ngOnInit() {
    if (this.data) {
      this.model = Object.assign({}, this.data);
      this.model.id = this.data.id;
      this.model.firstName = this.data.firstName;
      this.model.lastName = this.data.lastName;
      this.model.email = this.data.email;
      this.model.codeVrp = this.data.codeVrp;
      this.model.password = this.data.password;
      this.model.tabletCode = this.data.tabletCode;
      this.model.login = this.data.login;
      this.model.profil = this.data.profil;
      this.model.accessStatistique = this.data.accessStatistique;
    }
    this.data.password = null;

    this.dialogRef.addPanelClass('user_detail__popup');

    super.ngOnInit();
  }

  onClose() {
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(model: any) {
    let pass: string;

    if (!model.password) {
      model.password = this.tempPass;
    } else {
      pass = model.password;
    }

    if (this.form.valid) {
      this.dialogRef.close({ ...model, password: pass || this.tempPass });
    }
  }

  private checkVrpFormat(control): ValidationErrors {
    const regex = /^[a-zA-Z][a-zA-Z0-9_$()+/-]*$/;

    const form = control.parent;
    const vrp = form.controls['codeVrp'].value;

    const isValid = regex.test(vrp);

    if (!isValid) {
      return { vrpFormatInvalid: true };
    }

    return null;
  }

  private validatePass(control): ValidationErrors {
    const form = control.parent;
    const pass = form.controls['password'].value;
    const match = form.controls['confirmPassword'].value;

    if (pass === match) {
      return null;
    }

    if (!pass && !match) {
      return null;
    }

    if (!match?.length) {
      return { confirmPasswordRequired: true };
    }

    if (pass !== match) {
      return { confirmPasswordMissmatch: true };
    }

    return null;
  }

  private validateMatch(control): ValidationErrors {
    const form = control.parent;
    const pass = form.controls['password'].value;
    const match = form.controls['confirmPassword'].value;

    if ((pass === match) || (!pass && !match)) {
      return null;
    }

    return { missmatch: true }
  }

  private checkRequired(control): ValidationErrors {
    const form = control.parent;
    const pass = form.controls['password'].value;
    const match = form.controls['confirmPassword'].value;

    if (pass?.length && !match?.length) {
      return { required: true }
    }

    return null;
  }

  private get userExistsField(): FormlyFieldConfig[] {
    return [{
      key: 'password',
      type: 'input',
      templateOptions: {
        type: 'password',
        required: false,
        change: (field) => {
          this.form.controls.confirmPassword['_fields'][0].templateOptions.required = !!field.formControl.value;
        },
      },
    }, {
      key: 'confirmPassword',
      type: 'input',
      templateOptions: {
        type: 'password',
        required: false,
        ['confirmPasswordRequired']: this.translate.instant('validations.confirm_required'),
        ['confirmPasswordMissmatch']: this.translate.instant('validations.confirm_missmatch'),
      },
      validators: {
        validation: [
          this.validatePass,
          this.validateMatch,
          this.checkRequired
        ],
      }
    }];
  }

  private get userNotExistsField(): FormlyFieldConfig[] {
    return [{
      key: 'password',
      type: 'input',
      templateOptions: {
        type: 'password',
        required: true
      },
    }, {
      key: 'confirmPassword',
      type: 'input',
      templateOptions: {
        type: 'password',
        required: true,
        ['confirmPasswordRequired']: this.translate.instant('validations.confirm_required'),
        ['confirmPasswordMissmatch']: this.translate.instant('validations.confirm_missmatch'),
      },
      validators: {
        validation: [
          this.validatePass,
          this.validateMatch
        ],
      }
    }];
  }
}
