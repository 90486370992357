import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { EntityCollectionService } from 'ngrx-data';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { SetOrdersViewType } from '../../../store/orders.actions';
import { getOrderViewType } from '../../../store/orders.selectors';
import { OrdersState } from '../../../store/orders.state';
import { OrdersViewType } from '../order-list/types';
import { OrderModel } from './../../../models/order.model';

@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.scss']
})
export class OrderFilterComponent extends TcSmartComponent implements OnInit {

  selected = { label: 'Tous', value: OrdersViewType.All };

  private data = [
    { label: 'Tous', value: OrdersViewType.All },
    { label: 'Commandes bloquées', value: OrdersViewType.Blocked },
  ];

  filters$ = new Observable<any>(subscriber => {
    subscriber.next(this.data);
  });

  service: EntityCollectionService<OrderModel>;

  constructor(
    private readonly store$: Store<OrdersState>,
  ) {
    super();
  }

  async ngOnInit() {
    const selectedValue = await this.store$.select(getOrderViewType).pipe(take(1)).toPromise();

    this.selected = this.data.find(item => item.value === selectedValue) || this.data[0];
  }

  selectFilter = (filter: { label: string, value: OrdersViewType }) => {
    this.selected = filter;

    this.store$.dispatch(new SetOrdersViewType(this.selected.value));
  }

}
