<tc-dialog (close)="onClose()">

    <div tc-dialog-title>
        <h3 mat-dialog-title></h3>
    </div>

    <div tc-dialog-content>
        <div>
            <img class="image" [src]="source" width="auto" />
        </div>
    </div>
    <mat-dialog-actions>
    </mat-dialog-actions>

</tc-dialog>