import { OrdersState, initialOrdersState } from './orders.state';
import { OrdersActions, OrdersActionTypes } from './orders.actions';
import { OrderLineModel } from '../models/order-line.model';

export function ordersReducer(
  state = initialOrdersState,
  action: OrdersActions
): OrdersState {
  switch (action.type) {

    case OrdersActionTypes.LOAD_ORDER: {
      let basket = state.basket;
      basket = action.payload;

      return {
        ...state,
        basket
      };
    }

    case OrdersActionTypes.LOAD_ORDER_SUMMARY_SUCCESS:
    case OrdersActionTypes.LOAD_ORDER_REQUEST_SUMMARY_SUCCESS: {
      let orderSummary = state.orderSummary;
      orderSummary = action.payload;

      return {
        ...state,
        orderSummary
      };
    }


    case OrdersActionTypes.ORDER_ARTICLE_SUCCESS: {

      const basket = state.basket;
      const elementIndex = basket.lines.findIndex(elem => elem.id === action.payload.orderLineId);
      if (elementIndex >= 0) {
        basket.lines[elementIndex].quantity = basket.lines[elementIndex].quantity + action.payload.article.itemsInBax;
      } else {
        const orderListRowModel: OrderLineModel = {
          id: action.payload.orderLineId,
          articleRef: action.payload.article.code,
          articleName: action.payload.article.name,
          quantity: action.payload.article.itemsInBax,
          price: action.payload.article.price,
          companyId: action.payload.article.companyId,
          articleId: action.payload.article.id,
          itemsInBax: action.payload.article.itemsInBax,
          image: action.payload.article.image,
          leftForSaleStock: action.payload.article.stock
        }

        basket.lines.push(orderListRowModel);

      }

      return {
        ...state,
        basket
      };
    }

    case OrdersActionTypes.ORDER_REMOVE_ARTICLE_SUCCESS: {

      const basket = state.basket;
      const elementIndex = basket.lines.findIndex(elem => elem.id === action.payload.orderLineId);
      if (elementIndex >= 0) {
        basket.lines[elementIndex].quantity = basket.lines[elementIndex].quantity - action.payload.article.itemsInBax;
      
        if(basket.lines[elementIndex].quantity <= 0) {        
          basket.lines.splice(elementIndex, 1);
        }
      }

      return {
        ...state,
        basket
      };
    }

    case OrdersActionTypes.INCREASE_QUANTITY_SUCCESS: {

      let newLines = [];

      const basketLines = state.basket.lines;
      const elementIndex = basketLines.findIndex(elem => elem.id === action.payload);
      if (elementIndex >= 0) {
        basketLines[elementIndex].quantity = basketLines[elementIndex].quantity + basketLines[elementIndex].itemsInBax;
      }

      newLines = newLines.concat(basketLines);

      return {
        ...state,
        basket: {
          ...state.basket,
          lines: newLines
        }
      };
    }

    case OrdersActionTypes.DECREASE_QUANTITY_SUCCESS: {

      let newLines = [];

      let basketLines = state.basket.lines;
      const elementIndex = basketLines.findIndex(elem => elem.id === action.payload);
      if (elementIndex >= 0) {
        const itemsInBax = basketLines[elementIndex].itemsInBax;
        const quantity = basketLines[elementIndex].quantity;
        if (itemsInBax === quantity) {
          basketLines = basketLines.filter(l => l.id !== action.payload);
        } else {
          basketLines[elementIndex].quantity = basketLines[elementIndex].quantity - basketLines[elementIndex].itemsInBax;
        }
      }

      newLines = newLines.concat(basketLines);

      return {
        ...state,
        basket: {
          ...state.basket,
          lines: newLines
        }
      };
    }

    case OrdersActionTypes.DELETE_FROM_BASKET_SUCCESS: {

      let newLines = [];
      let basketLines = state.basket.lines;
      basketLines = basketLines.filter(l => l.id !== action.payload);
      newLines = newLines.concat(basketLines);

      return {
        ...state,
        basket: {
          ...state.basket,
          lines: newLines
        }
      };
    }

    case OrdersActionTypes.ADD_TO_ORDERS_LIST: {
      return {
        ...state,
        orderList: [...state.orderList, ...action.payload]
      };
    }

    case OrdersActionTypes.RESET_ORDERS_LIST: {
      return {
        ...state,
        orderList: [],
      };
    }

    case OrdersActionTypes.SET_ORDER_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
