import { Injectable } from '@angular/core';
import { ArticleOrderHistory } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { ArticleOrderHistoryModel } from '../../modules/articles/models/article-order-history.model';
import { getCurrentUserVrp } from '../../modules/auth/store/auth.selectors';
import { ClientAllVrp } from '../../modules/clients/enums/client-status.enum';
import { getSelectedClientId } from '../../modules/clients/store/clients.selectors';
import { ArticleOrdersHistoryDAO } from '../dao/article-orders-history.dao';
import { GetResourcesService } from '../get-resources/get-resources.service';
import { Criteria } from '../repository/repository.interface';

@Injectable({
  providedIn: 'root'
})
export class ArticleOrderHistoryService {

  constructor(
    private readonly store$: Store<any>,
    private readonly getResourcesService: GetResourcesService,
    private articleOrdersHistoryDAO: ArticleOrdersHistoryDAO,
  ) { }

  public async getArticlesIdsFromHistory(clientId: string) {   
    const history = await await this.articleOrdersHistoryDAO.getByClientId(clientId);

    const articlesWithHistory = this.getArticlesFromHistory(history);

    return articlesWithHistory;
  }

  public async getArticlesWithHistory(articleIds: string[]) {
    const currentVrp = await this.getCurrentVrp();
    const clientId = await this.getSelectedClientId();

    const history = currentVrp.toLowerCase() === ClientAllVrp
      ? await this.getHistoryOnline(articleIds, clientId)
      : await this.getHistoryOffline(articleIds, clientId);

    const articlesWithHistory = this.getArticlesFromHistory(history);

    return articlesWithHistory;
  }

  private async getHistoryOnline(articleIds: string[], clientId: string) {
    const ids: string[] = [];

    for (const articleId of articleIds) {
      ids.push(...this.buildHistoryIds(articleId, clientId));
    }

    return this.getResourcesService.getArticleOrderHistories(ids).toPromise();
  }

  private async getHistoryOffline(articleIds: string[], clientId: string) {
    const history: ArticleOrderHistory[] = [];

    for (const articleId of articleIds) {

      const query: Criteria = {
        filter: {
          id: { $regex: new RegExp(`${articleId}_.{6}_${clientId}`, 'i') }
        }
      };

      history.push(...(await this.articleOrdersHistoryDAO.search(query)) || []);
    }

    return history;
  }

  private getArticlesFromHistory(histories: ArticleOrderHistory[]): string[] {
    const articleIds: string[] = [];
    
    //limit history to 12 months
    var moreRecentThan = moment().subtract(12, 'month').format('YYYYMM');

    for (const history of histories) {
      const [articleId, date, clientId] = history.id.split('_');
      if(date > moreRecentThan) {
        articleIds.push(articleId);
      }
    }

    return articleIds;
  }

  public async getByArticle(id: string): Promise<ArticleOrderHistoryModel[]> {
    const lastYearDates = this.getDefaultQuantity();
    const list: ArticleOrderHistoryModel[] = [];

    const history = await this.getArticleOrderHistory(id);

    for (const item of history) {
      const quantity = Number(item.quantity.toFixed(2));
      const date = this.getOrderHistoryDate(item);

      //only display history for last 12 months
      if (lastYearDates.has(date)) {
        lastYearDates.set(date, quantity);
      }
    }

    for (const [date, quantity] of lastYearDates) {
      list.push({ date, quantity });
    }

    return list;
  }

  private async getArticleOrderHistory(articleId: string) {
    const currentVrp = await this.getCurrentVrp();
    const selectedClientId = await this.getSelectedClientId();

    if (currentVrp.toLowerCase() === ClientAllVrp) {
      const ids = this.buildHistoryIds(articleId, selectedClientId);
      return this.getResourcesService.getArticleOrderHistories(ids).toPromise();
    }

    const format = `${articleId}_.{6}_${selectedClientId}`;

    const query: Criteria = {
      filter: {
        'id': { $regex: new RegExp(format, 'i') }
      }
    };

    return (await this.articleOrdersHistoryDAO.search(query)) || [];
  }

  private buildHistoryIds(code: string, clientId: string) {
    const ids: string[] = [];
    const dates = this.getDefaultQuantity();

    for (const [key] of dates) {
      const date = key.split('/').reverse().join('');
      const id = `${code}_${date}_${clientId}`;

      ids.push(id);
    }

    return ids;
  }

  private getOrderHistoryDate(item: ArticleOrderHistory): string {
    const divider = '/';
    const [, fullDate] = item.id.split('_');
    const date = `${fullDate.substr(4)}${divider}${fullDate.substr(0, 4)}`;

    return date;
  }

  private getDefaultQuantity() {
    const quntityByDate = new Map<string, number>();
    const date = moment();

    for (const _ of new Array(12)) {
      const key = date.format('MM/YYYY');
      quntityByDate.set(key, 0);

      date.subtract(1, 'month');
    }

    return quntityByDate;
  }

  private getCurrentVrp() {
    return this.store$.select(getCurrentUserVrp)
      .pipe(take(1))
      .toPromise();
  }

  private async getSelectedClientId() {
    return this.store$.select(getSelectedClientId)
      .pipe(take(1))
      .toPromise();
  }
}
