import { SyncAgentState } from '../sync-agent.state';

export function syncProgressPartitionStartReducer(
  state: SyncAgentState,
  payload: string,
): SyncAgentState {
  return {
    ...state,
    progress: [
      ...state.progress,
      {
        type: payload,
        total: 0,
        current: 0,
        finished: false,
        error: 0,
      },
    ],
  };
}

