import { Injectable } from '@angular/core';
import { ArticleOrderHistory, ArticleOrderHistoryEntityType } from '@maxel-order/shared';
import { DatabaseService } from '../database/database.service';
import { RepositoryService } from '../repository/repository.service';
import { BaseDAO } from './base.dao';
import { IdProviderService } from './id-provider.service';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleOrdersHistoryDAO extends BaseDAO<ArticleOrderHistory>  {
  constructor(
    database: DatabaseService,
    idProvider: IdProviderService,
    repositoryService: RepositoryService,
    eventsService: EventsService,
  ) {
    super(database, idProvider, repositoryService, eventsService);
    
    this.entityName = ArticleOrderHistoryEntityType.className;
    this.entityLowerName = ArticleOrderHistoryEntityType.lowerName;
  }

  public getByClientId = async (clientIdtoFind: string): Promise<ArticleOrderHistory[]> => {

    const result: ArticleOrderHistory[] = [];

    const histories = await this.getAll();

    for (const history of histories) {
      const [articleId, date, clientId] = history.id.split('_');
      if(clientId === clientIdtoFind) {
        result.push(history);
      }
    }
    return result;
  };

}
