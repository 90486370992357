import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'app-example-dialog',
  templateUrl: './example-dialog.component.html',
  styleUrls: ['./example-dialog.component.scss']
})
export class ExampleDialogComponent extends TcSmartComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ExampleDialogComponent>) {
    super();
  }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
