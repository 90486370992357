import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DatesService {

    public getFreeDays(): Date[] {
        return [
            new Date(2020, 5, 1),
            new Date(2020, 6, 14),
            new Date(2020, 7, 15),
            new Date(2020, 10, 1),
            new Date(2020, 10, 11),
            new Date(2020, 11, 25),

            new Date(2021, 0, 1),
            new Date(2021, 3, 5),
            new Date(2021, 4, 1),
            new Date(2021, 4, 8),
            new Date(2021, 4, 13),
            new Date(2021, 4, 24),

            new Date(2021, 6, 14),
            new Date(2021, 7, 15),
            new Date(2021, 10, 1),
            new Date(2021, 10, 11),
            new Date(2021, 11, 25)
        ];
    }
}
