import { StatisticsTypes } from '@maxel-order/shared';
import { StatisticsActions, StatisticsActionTypes } from './statistics.actions';
import { StatisticsState } from './statistics.interfaces';

const initialStatisticsState: StatisticsState = {
  type: StatisticsTypes.Total,
  items: [],
};

export function statisticsReducer(
  state = initialStatisticsState,
  action: StatisticsActions
): StatisticsState {

  switch (action.type) {
    case StatisticsActionTypes.SELECT_STATISTICS_TYPE:
      return selectStatisticsType(state, action.payload);
    case StatisticsActionTypes.SAVE_STATISTICS_ITEMS:
      return saveStatisticsItems(state, action.payload);

    default: {
      return state;
    }
  }
}

function selectStatisticsType(state: StatisticsState, payload: StatisticsTypes): StatisticsState {
  return {
    ...state,
    type: payload,
  };
}

function saveStatisticsItems(state: StatisticsState, payload: any[]): StatisticsState {
  return {
    ...state,
    items: payload,
  };
}
