import { DefaultPersistenceResultHandler, EntityAction } from 'ngrx-data';
import { Action } from '@ngrx/store';


export class AdditionalPropertyPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);
    return (data: any) => {
      const action = actionHandler.call(this, data);
      if (action && data && data.total !== null && data.total !== undefined) {
        (action as any).payload.total = data.total;
      }
      if (action && data && data.data) {
        (action as any).payload.data = data.data;
      }
      return action;
    };
  }
}
