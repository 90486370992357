<div class="client-list-page">
    <tc-layout
        [fixedInViewport]="true"
        [fixedTopGap]="0"
        [mode]="'side'"
        [showToolbarMenuIcon]="false"
        [opened]="true"
    >

        <div
            class="header-content"
            tc-layout-toolbar
        >
            <app-page-title></app-page-title>
            <div class="header-right">
                <app-user-info></app-user-info>
                <app-page-menu></app-page-menu>
            </div>
        </div>

        <div tc-layout-sidenav>

            <app-back-navigation></app-back-navigation>

            <div class="filter-search">

                <app-client-filter-search></app-client-filter-search>

            </div>

            <img
                src="assets/icons/line_colors.png"
                width="170px"
            >

            <app-client-filter></app-client-filter>

        </div>

        <div tc-layout-sidenav-content>

            <div class="client-list">
                <app-client-list></app-client-list>
            </div>

        </div>

    </tc-layout>
</div>
