export type ArticleProperty = | 'picture1' | 'familyId' | 'subFamily' | 'availableStock' | 'leftForSaleStock' | 'unitsPerUnderPackage' | 'arrival' | 'resuplyDate' | 'outOfStock' | 'description1' | 'description2' | 'description3' | 'unitsPerPackage' | 'catalogId' | 'id' | 'salesN1' | 'salesN' | 'color' | 'displayRackDetail' | 'vatCode' | 'salesMin' | 'new' | 'epmTax' | 'electronicTax' | 'weightKg' | 'volumeM3' | 'theme' | 'brand' | 'univers' | 'companyId' | 'deleted' ;

export const ArticleProperty = {
  picture1: 'picture1' as ArticleProperty,
  familyId: 'familyId' as ArticleProperty,
  subFamily: 'subFamily' as ArticleProperty,
  availableStock: 'availableStock' as ArticleProperty,
  leftForSaleStock: 'leftForSaleStock' as ArticleProperty,
  unitsPerUnderPackage: 'unitsPerUnderPackage' as ArticleProperty,
  arrival: 'arrival' as ArticleProperty,
  resuplyDate: 'resuplyDate' as ArticleProperty,
  outOfStock: 'outOfStock' as ArticleProperty,
  description1: 'description1' as ArticleProperty,
  description2: 'description2' as ArticleProperty,
  description3: 'description3' as ArticleProperty,
  unitsPerPackage: 'unitsPerPackage' as ArticleProperty,
  catalogId: 'catalogId' as ArticleProperty,
  id: 'id' as ArticleProperty,
  salesN1: 'salesN1' as ArticleProperty,
  salesN: 'salesN' as ArticleProperty,
  color: 'color' as ArticleProperty,
  displayRackDetail: 'displayRackDetail' as ArticleProperty,
  vatCode: 'vatCode' as ArticleProperty,
  salesMin: 'salesMin' as ArticleProperty,
  new: 'new' as ArticleProperty,
  epmTax: 'epmTax' as ArticleProperty,
  electronicTax: 'electronicTax' as ArticleProperty,
  weightKg: 'weightKg' as ArticleProperty,
  volumeM3: 'volumeM3' as ArticleProperty,
  theme: 'theme' as ArticleProperty,
  brand: 'brand' as ArticleProperty,
  univers: 'univers' as ArticleProperty,
  companyId: 'companyId' as ArticleProperty,
  deleted: 'deleted' as ArticleProperty,
}
