import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDatepickerInputEvent, MatSelectChange, Sort } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { TcListComponent, TcListFilterType, TcListSortType, TcSmartComponent, TcListDisplayColumnType } from '@tc/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter, map, skipWhile, tap } from 'rxjs/operators';

import { OrderListModel } from '../../../models/order-list.model';
import { OrdersListQueryService } from '../../../services/orders-list-query.service';
import { getOrderList, getOrderViewType } from '../../../store/orders.selectors';
import { OrderModel } from './../../../models/order.model';
import { EditOrder, SelectOrder, AddToOrdersList, ResetOrdersList } from './../../../store/orders.actions';
import { FilterData, OrdersViewType, Statuses, StatusValues } from './types';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderListComponent extends TcSmartComponent implements OnInit, OnDestroy {
  readonly OrdersViewType = OrdersViewType;

  orderList: TcListComponent;
  statuses = StatusValues;
  viewType: OrdersViewType = OrdersViewType.All;

  filter: FilterData = {
    customer: '',
    date: null,
    status: null,
  };

  private pageIndex = 0;
  private sort = 'date:desc';
  private readonly pageLimit = 50;

  private inputSubscription: Subscription = null;
  private storeSubscription: Subscription = null;
  private orderListSubscription: Subscription = null;

  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;

  @ViewChild('customerInput') customerInputRef: ElementRef;
  @ViewChild('colActionEditTemplate', { static: true }) colActionEditTemplate: TemplateRef<any>;
  @ViewChild('orderList', { static: true }) set setOrderList(values: TcListComponent) {
    this.orderList = values;
  }

  constructor(
    private readonly store$: Store<any>,
    private readonly queryService: OrdersListQueryService,
  ) {
    super();
  }

  async ngOnInit() {
    this.resetList();
    this.initTable();
    await this.queryService.init();

    this.updateRows();

    this.initListeners();
  }

  getStatusKey(status: string) {
    return `order-list.status.${(status || '').toLowerCase()}`;
  }

  filterStatus(event: MatSelectChange) {
    this.resetList();
    this.filter.status = event.value;

    this.updateRows();
  }

  filterDate(event: MatDatepickerInputEvent<any>) {
    this.resetList();
    this.filter.date = event.value;

    this.updateRows();
  }

  editOrder = (row: OrderListModel) => {
    this.store$.dispatch(new EditOrder(row));
  }

  private filterClient(value: string) {
    this.resetList();
    this.filter.customer = value;

    this.updateRows();
  }

  private initTable() {
    this.orderList.rows$ = this.store$.pipe(select(getOrderList));

    this.orderList.isFiltrable = false;
    this.orderList.filterType = TcListFilterType.Disabled;
    this.orderList.sortType = TcListSortType.Server;

    this.orderList.sortActive = 'date';
    this.orderList.sortDirection = 'desc';

    this.orderList.hasFixedHeader = true;
    this.orderList.hasAddButton = false;
    this.orderList.isPaged = false;

    this.initTableColumns();
    this.initActions();
  }

  private onSort(type: string) {
    this.resetList();
    this.sort = type;
    this.updateRows();
  }

  private initListeners() {
    this.orderList.onScrollList = (e) => {
      const triggerAt = e.target.scrollHeight - e.target.offsetHeight - 100;

      if (e.target.scrollTop > triggerAt) {
        this.loadMoreItems();
      }
    }

    this.orderList.onRowClick = (row: OrderListModel) => {
      this.store$.dispatch(new SelectOrder(row));
    };

    this.orderList.applyServerSideSort = (sort: Sort) => {
      this.onSort(`${sort.active}:${sort.direction}`);
    }

    this.storeSubscription = this.store$.select(getOrderViewType)
      .pipe(skipWhile(value => value === this.viewType))
      .subscribe(value => this.updateView(value));

    this.inputSubscription = fromEvent(this.customerInputRef.nativeElement, 'keyup')
      .pipe(
        map(event => (event as any).target.value as string),
        filter(value => !value || value.length >= 3),
        debounceTime(300),
      ).subscribe(value => this.filterClient(value))
  }

  private updateView(value: OrdersViewType) {
    this.resetList();
    this.viewType = value;

    this.filter = { customer: null, date: null, status: null };

    if (this.viewType === OrdersViewType.Blocked) {
      this.filter.status = Statuses.Blocked;
    }

    this.updateRows();
  }

  private updateRows() {
    const { items } = this.queryService.get(
      this.filter,
      this.pageIndex * this.pageLimit,
      this.pageLimit,
      this.sort,
    );

    this.store$.dispatch(new AddToOrdersList(items));
  }

  private loadMoreItems() {
    this.pageIndex++;

    this.updateRows();
  }

  private initActions() {
    this.orderList.rowActions = [{
      actionName: 'edit',
      visible: true,
      hasText: false,
      htmlTemplate: this.colActionEditTemplate
    }];
  }

  private initTableColumns() {
    this.orderList.columns = [{
      propertyName: 'date',
      visible: true
    }, {
      propertyName: 'clientName',
      visible: true
    }, {
      propertyName: 'status',
      visible: true,
      htmlTemplate: this.colStatusTemplate,
    }, {
      propertyName: 'totalExcludingTax',
      visible: true,
      displayColumnType: TcListDisplayColumnType.Currency,
      currencySymbol: '€'
    }];
  }

  private resetList() {
    const listRef = document.querySelector('#order-list > .tc-list-container');

    listRef.scrollTop = 0;
    this.pageIndex = 0;
    this.store$.dispatch(new ResetOrdersList());
  }

  ngOnDestroy() {
    this.storeSubscription?.unsubscribe();
    this.inputSubscription?.unsubscribe();
    this.orderListSubscription?.unsubscribe();
  }

}
