import { Inject, Injectable } from '@angular/core';
import { Company } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcService } from '@tc/core';
import { IMAGES_ROOT_FOLDER } from '../app.module';
import { SetLastActivity } from '../modules/auth/store/auth.actions';
import { SyncProgressPartitionStarted, SyncProgressUpdated } from '../modules/sync-agent/store/sync-agent.actions';
import { CacheService } from './cache.service';
import { ArticlesDAO, CompaniesDAO } from './dao';

export interface Img {
    url: string;
    size: number;
}

@Injectable()
export class ImageService extends TcService {

    private readonly imagePartition = 'Images';
    private readonly cacheName = 'maxelorder-images';

    constructor(
        private readonly store$: Store<any>,
        private readonly articlesDAO: ArticlesDAO,
        private readonly cacheService: CacheService,
        private readonly companiesDAO: CompaniesDAO,
        @Inject(IMAGES_ROOT_FOLDER) private imagesRootUrl: string
    ) {
        super();
    }

    public async cacheMissingImages() {
        this.store$.dispatch(new SyncProgressPartitionStarted(this.imagePartition));

        const articles = await this.articlesDAO.getAll();
        const companies = await this.companiesDAO.getAll();

        const savedRequests = await this.cacheService.getCachedRequestsByCacheName(this.cacheName);

        const imageUrls = articles
            .map(article => article.picture1 ? `${this.imagesRootUrl}${this.getCompanyNameById(companies, article.companyId)}/${article.picture1}` : null)
            .filter(url => !!url)
            .map(url => url.split(' ').join('%20'))
            .filter(url => !savedRequests.some(req => {
                const regex = new RegExp(`^((https?):\/\/)?(www.)?.*${url}`);
                return regex.test(req.url)
            }));

        await this.cacheImages(imageUrls);
    }

    private getCompanyNameById(companies: Company[], companyId: string): string {
        return companies.find(company => company.id === companyId).name;
    }

    private async cacheImages(imageUrls: string[]) {
        let processedImages = 0;
        let processedError = 0;

        if (!imageUrls.length) {
            this.store$.dispatch(new SyncProgressUpdated({
                type: this.imagePartition,
                total: 0,
                current: 1,
                error: 0
            }));
        }

        for (const url of imageUrls) {
            const response = await fetch(url, {
                headers: {
                    'sec-fetch-mode': 'cors',
                    'sec-fetch-site': 'cross-site',
                }
            });

            this.store$.dispatch(new SyncProgressUpdated({
                type: this.imagePartition,
                total: imageUrls.length,
                current: response.ok ? ++processedImages : processedImages,
                error: response.ok ? processedError : ++processedError,
            }));
            this.store$.dispatch(new SetLastActivity());
        }
    }
}
