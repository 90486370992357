import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { InjectionToken, LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { TcApiConfig, TcAppReducers, TcCoreModule, TcMetaReducers, TC_LIST_DEFAULT_NB_LINES, TcConfigService, TcLoggerService } from '@tc/core';
import { TcLayoutModule } from '@tc/layout';
import { NgImageSliderModule } from 'ng-image-slider';
import { DefaultDataServiceConfig, DefaultDataServiceFactory, EntityCollectionReducerMethodsFactory, EntityDispatcherDefaultOptions, NgrxDataModule, PersistenceResultHandler } from 'ngrx-data';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { clearState } from './clear-state.reducer';
import { entityConfig } from './entity-metadata';
import { ArticlesModule } from './modules/articles/articles.module';
import { AuthModule } from './modules/auth/auth.module';
import { ClientsModule } from './modules/clients/clients.module';
import { ExamplesModule } from './modules/examples/examples.module';
import { PhotoSelectComponent } from './modules/main/components/dumb/photo-select/photo-select.component';
import { MainModule } from './modules/main/main.module';
import { OrdersModule } from './modules/orders/orders.module';
import { SyncAgentModule } from './modules/sync-agent/sync-agent.module';
import { UsersModule } from './modules/users/users.module';
import { StatisticsModule } from './modules/statistics/statistics.module';
import { F02HomePageComponent } from './pages/f02-home-page/f02-home-page.component';
import { F03ClientListPageComponent } from './pages/f03-client-list-page/f03-client-list-page.component';
import { F04ClientDetailPageComponent } from './pages/f04-client-detail-page/f04-client-detail-page.component';
import { F07ArticleListPageComponent } from './pages/f07-article-list-page/f07-article-list-page.component';
import { F09OrderDetailPageComponent } from './pages/f09-order-detail-page/f09-order-detail-page.component';
import { F15OrderListPageComponent } from './pages/f15-order-list-page/f15-order-list-page.component';
import { FF26UserListPageComponent } from './pages/f26-user-list-page/f26-user-list-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';
import { CacheService } from './services/cache.service';
import { AdditionalEntityCollectionReducerMethodsFactory } from './services/core/additional-entity-collection-reducer-methods.factory';
import { AdditionalPropertyPersistenceResultHandler } from './services/core/additional-property-persistence';
import { QlDefaultDataServiceFactory } from './services/core/ql-default-data-service-factory';
import { ImageService } from './services/images.service';
import { StorageService } from './services/storage.service';
import { VersionService } from './services/version.service';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { Router } from '@angular/router';
import { CaptureConsole } from '@sentry/integrations';

export const APP_TIMEOUT = new InjectionToken<string>('appTimeout');
export const CHECK_APP_TIMEOUT_INTERVAL = new InjectionToken<string>('checkAppTimeoutInterval');
export const IMAGES_ROOT_FOLDER = new InjectionToken<string>('imagesRootUrl');
export const SYNC_APP_INTERVAL = new InjectionToken<string>('syncAppInterval');
export const SYNC_REQUIRED_INTERVAL = new InjectionToken<string>('syncAppInterval');
import { version } from '../../../../package.json';
import { CustomRenderer } from './services/core/custom-renderer';
import { PermissionsService } from './services/permissions/permissions.service';

Sentry.init({
  dsn: require('../assets/config.json').SENTRY_DNS,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [window.location.hostname],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new CaptureConsole({
      levels: require('../assets/config.json').SENTRY_CAPTURECONSOLE_LEVELS
    })
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: window.location.hostname,
  release: 'MaxelOrder@' + version
});

registerLocaleData(localeFr, 'fr');

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: 'http://localhost:11508', // change this with your backend url
  timeout: 3000,
  delete404OK: false
};

const entityDispatcherDefaultOptions: EntityDispatcherDefaultOptions = {
  optimisticAdd: false,
  optimisticDelete: false,
  optimisticUpdate: false,
  optimisticUpsert: false,
  optimisticSaveEntities: false
};

const apiServiceConfig: TcApiConfig = {
  // spinnerExceptions: ['api/starships/'],
  // hideToastError: false
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['authentication', 'clients', 'syncAgent', 'articles', 'orders'],
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [...TcMetaReducers, clearState, localStorageSyncReducer];

export function confirmPasswordRequiredValidator(err, field): string {
  return field.templateOptions['confirmPasswordRequired'];
}

export function confirmPasswordMissmatchValidator(err, field): string {
  return field.templateOptions['confirmPasswordMissmatch'];
}

export function vrpFormatInvalidValidator(err, field): string {
  return field.templateOptions['vrpFormatInvalid'];
}

const pages = [F02HomePageComponent, F07ArticleListPageComponent, F03ClientListPageComponent, LoginPageComponent, F04ClientDetailPageComponent, F15OrderListPageComponent, FF26UserListPageComponent];

const appModules = [MainModule, ArticlesModule, ClientsModule, AuthModule, OrdersModule, SyncAgentModule, UsersModule, StatisticsModule];

@NgModule({
  declarations: [AppComponent, F09OrderDetailPageComponent, StatisticsPageComponent, ...pages],
  imports: [
    ...appModules,
    ExamplesModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TcLayoutModule,
    TcCoreModule,
    ClientsModule,
    MatButtonModule,
    NgImageSliderModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'photo-select',
          component: PhotoSelectComponent
        }
      ],
      validationMessages: [
        { name: 'confirmPasswordRequired', message: confirmPasswordRequiredValidator },
        { name: 'confirmPasswordMissmatch', message: confirmPasswordMissmatchValidator },
        { name: 'vrpFormatInvalid', message: vrpFormatInvalidValidator }
      ]
    }),
    StoreModule.forRoot(TcAppReducers, { metaReducers }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'MaxelOrder'
        }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgrxDataModule.forRoot(entityConfig)
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: TcApiConfig, useValue: apiServiceConfig },
    CustomRenderer,
    StorageService,
    VersionService,
    ImageService,
    CacheService,
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: EntityDispatcherDefaultOptions, useValue: entityDispatcherDefaultOptions },
    { provide: DefaultDataServiceFactory, useClass: QlDefaultDataServiceFactory },
    { provide: PersistenceResultHandler, useClass: AdditionalPropertyPersistenceResultHandler },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory
    },
    { provide: TC_LIST_DEFAULT_NB_LINES, useValue: require('../assets/config.json').TC_LIST_DEFAULT_NB_LINES },
    { provide: APP_TIMEOUT, useValue: require('../assets/config.json').APP_TIMEOUT },
    { provide: CHECK_APP_TIMEOUT_INTERVAL, useValue: require('../assets/config.json').CHECK_APP_TIMEOUT_INTERVAL },
    { provide: SYNC_APP_INTERVAL, useValue: require('../assets/config.json').SYNC_APP_INTERVAL },
    { provide: SYNC_REQUIRED_INTERVAL, useValue: require('../assets/config.json').SYNC_REQUIRED_INTERVAL },
    { provide: IMAGES_ROOT_FOLDER, useValue: require('../assets/config.json').IMAGES_ROOT_FOLDER },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly permissionService: PermissionsService) {
    permissionService.init();
  }
}
