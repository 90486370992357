import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TcSmartComponent } from '@tc/core';
import { Observable } from 'rxjs';
import { UserModel } from '../../../../auth/models/user.model';
import { Store, select } from '@ngrx/store';
import { getAuthenticatedUser } from '../../../../auth/store/auth.selectors';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent extends TcSmartComponent implements OnInit {

  public authenticatedUser$: Observable<UserModel>;

  constructor(
    private readonly store: Store<any>,
  ) {
    super();
  }

  ngOnInit() {
    this.authenticatedUser$ = this.store.pipe(select(getAuthenticatedUser));
  }
}
