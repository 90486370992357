import { DecimalPipe } from '@angular/common';
import { Component, HostBinding, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { StatisticsFilter, StatisticsTypes } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcAppState, TcListComponent, TcListFilterType, TcListSortType, TcSmartComponent } from '@tc/core';
import { StatisticsFilterItem } from '../../interfaces/statistics.interface';
import { StatisticsFiltersService } from '../../services/statistics-filters.service';
import { StatisticsService } from '../../services/statistics.service';
import { SaveStatisticsItems } from '../../store/statistics.actions';
import { getCurrentStatisticsItems } from '../../store/statistics.selectors';

@Component({
  selector: 'app-statistics-component',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatisticsComponent extends TcSmartComponent implements OnInit {

  private readonly classBindings = new Map<string, string>([
    [StatisticsTypes.A, 'statistics--a'],
    [StatisticsTypes.B, 'statistics--b'],
    [StatisticsTypes.C1, 'statistics--c1'],
    [StatisticsTypes.C2, 'statistics--c2'],
    [StatisticsTypes.D, 'statistics--d'],
    [StatisticsTypes.E, 'statistics--e'],
    [StatisticsTypes.F, 'statistics--f'],
  ]);

  @HostBinding('class') get class() {
    return this.classBindings.get(this.statisticType) ?? '';
  }

  @Input() set statisticsType(type: StatisticsTypes) {
    this.statisticType = type;

    this.init();
  }

  statisticsList: TcListComponent;
  @ViewChild('statisticsList', { static: true }) set setStatisticsList(values: TcListComponent) {
    this.statisticsList = values;
  }

  public filters: StatisticsFilterItem[] = [];

  private filterValues: StatisticsFilter = {} as StatisticsFilter;
  private statisticType: StatisticsTypes;

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly translate: TranslateService,
    private readonly statisticsService: StatisticsService,
    private readonly statisticsFiltersService: StatisticsFiltersService,
    private readonly decimalPipe: DecimalPipe,
  ) {
    super();
  }

  ngOnInit() {
    this.initTable();
  }

  public applyFilters(filter) {
    if (this.filterValues[filter.key] === filter.value) {
      return;
    }

    this.filterValues[filter.key] = filter.value;

    if (!filter.value) {
      delete this.filterValues[filter.key];
    }

    this.loadItems();
  }

  private async init() {
    await this.initFilters();
    await this.initFiltersValue();

    this.loadItems();
  }

  private async initFiltersValue() {
    this.filterValues = await this.statisticsFiltersService.getDefaultFiltersValues();
  }

  private async initFilters() {
    this.filters = await this.statisticsFiltersService.getFilters(this.statisticType);
  }

  private async loadItems() {
    const items = await this.statisticsService.getItems(this.statisticType, this.filterValues);
    const prettified = this.prettify(items);

    this.store$.dispatch(new SaveStatisticsItems(prettified));
  }

  private async initTable() {
    this.statisticsList.isFiltrable = false;
    this.statisticsList.filterType = TcListFilterType.Disabled;

    this.statisticsList.sortType = TcListSortType.Disabled;

    this.statisticsList.hasFixedHeader = true;
    this.statisticsList.hasAddButton = false;
    this.statisticsList.isPaged = false;

    this.statisticsList.rows$ = this.store$.select(getCurrentStatisticsItems);

    this.initTableColumns();
    this.changeTranslations();
  }

  private initTableColumns() {
    this.statisticsList.columns = this.statisticsService.getListColumns(this.statisticType);
  }

  private async changeTranslations() {
    const currentYear = new Date().getFullYear();
    this.translate.translations.en['statistics-list'].headers = {
      ...this.translate.translations.en['statistics-list'].headers,
      variation: this.translate.instant('statistics-list.headers.variationInitial'),
      salesCurrent: this.translate.instant('statistics-list.headers.salesCurrentInitial'),
      salesYearMinusOne: this.translate.instant('statistics-list.headers.salesYearMinusOneInitial'),
      variationCurrent: this.translate.instant('statistics-list.headers.variationCurrentInitial'),
      weightYearMinusOne: this.translate.instant('statistics-list.headers.weightYearMinusOneInitial'),
    };

    if (this.statisticType === StatisticsTypes.A) {
      this.translate.translations.en['statistics-list'].headers = {
        ...this.translate.translations.en['statistics-list'].headers,
        salesCurrent: `${this.translate.instant('statistics-list.headers.sales')} ${currentYear}`,
        salesYearMinusOne: `${this.translate.instant('statistics-list.headers.sales')} ${currentYear - 1}`,
        salesYearMinusTwo: `${this.translate.instant('statistics-list.headers.sales')} ${currentYear - 2}`,

        variationCurrent: `${this.translate.instant('statistics-list.headers.variation')} ${currentYear}/${currentYear - 1}`,
        variationYearMinusOne: `${this.translate.instant('statistics-list.headers.variation')} ${currentYear - 1}/${currentYear - 2}`,
        variationYearMinusTwo: `${this.translate.instant('statistics-list.headers.variation')} ${currentYear - 2}/${currentYear - 3}`,
      };
    }

    if (this.statisticType === StatisticsTypes.C1) {
      this.translate.translations.en['statistics-list'].headers = {
        ...this.translate.translations.en['statistics-list'].headers,
        salesYearMinusOne: `CA période précédente`,
      };
    }

    if (this.statisticType === StatisticsTypes.C2) {
      this.translate.translations.en['statistics-list'].headers = {
        ...this.translate.translations.en['statistics-list'].headers,
        variation: this.translate.instant('statistics-list.headers.variationASC'),
      };
    }

    if (this.statisticType === StatisticsTypes.E) {
      this.translate.translations.en['statistics-list'].headers = {
        ...this.translate.translations.en['statistics-list'].headers,
        salesCurrent: `${this.translate.instant('statistics-list.headers.sales')} ${currentYear}`,
        salesYearMinusOne: `${this.translate.instant('statistics-list.headers.sales')} ${currentYear - 1}`,
      };
    }

    if (this.statisticType === StatisticsTypes.F) {
      this.translate.translations.en['statistics-list'].headers = {
        ...this.translate.translations.en['statistics-list'].headers,
        weightYearMinusOne: this.translate.instant('statistics-list.headers.weightYearMinusOneWithFormula'),
      };
    }
  }

  private prettify(items: any[]) {
    const variationKeys = ['variation', 'variationCurrent', 'variationYearMinusOne', 'variationYearMinusTwo', 'variationGlobal'];

    const response = items.map(item => {
      return Object.keys(item).reduce((updated, key) => {

        // Add "%" sign if any item contains variation fields
        if (variationKeys.includes(key)) {
          return { ...updated, [key]: `${this.decimalPipe.transform(item[key], '1.0', 'fr')}%` };
        }

        // Format numbers to french format
        const value = typeof item[key] === 'number' ? this.decimalPipe.transform(item[key], '1.0', 'fr') : item[key];
        return { ...updated, [key]: value };
      }, {});
    });

    return response;
  }
}
