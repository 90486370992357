import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const getAuthenticationState = createFeatureSelector<AuthState>(
  'authentication'
);

export const getAuthenticatedUser = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.user
);

export const getLastActivity = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.lastActivity
);

export const getLastUser = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.lastSignedUser
);


export const getCurrentUserVrp = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.user.codeVrp
);
