import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcListComponent, TcListDisplayColumnType, TcListSortType, TcSmartComponent } from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import { ClientModel } from '../../../models/client.model';
import { LoadClientInvoiceList } from '../../../store/clients.actions';
import { getClientInvoiceList, getSelectedClient } from '../../../store/clients.selectors';

@Component({
  selector: 'app-client-invoice-list',
  templateUrl: './client-invoice-list.component.html',
  styleUrls: ['./client-invoice-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ClientInvoiceListComponent extends TcSmartComponent implements OnInit {

  selectedClient$: Observable<ClientModel>;
  selectedClientSubscription: Subscription;
  selectedClientId: string;

  clientInvoiceList: TcListComponent;

  @ViewChild('clientInvoiceList', { static: true }) set appClientInvoiceList(values: TcListComponent) {
    this.clientInvoiceList = values;
  }

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit() {
    this.selectedClient$ = this.store.pipe(select(getSelectedClient));
    this.selectedClientSubscription = this.selectedClient$.subscribe(selectedClient => {
      if (selectedClient) {
        this.selectedClientId = selectedClient.id;
      }
    })
    this.store.dispatch(new LoadClientInvoiceList(this.selectedClientId));
    this.clientInvoiceList.rows$ = this.store.pipe(select(getClientInvoiceList));

    this.clientInvoiceList.hasActionsLabel = false;
    this.clientInvoiceList.isPaged = false;
    this.clientInvoiceList.isFiltrable = false;
    this.clientInvoiceList.sortType = TcListSortType.Disabled;
    this.clientInvoiceList.hasFixedHeader = true;

    this.clientInvoiceList.columns = [
      {
        propertyName: 'ref',
        visible: true,
        filtrable: false
      },
      {
        propertyName: 'amount',
        visible: true,
        filtrable: false,
        displayColumnType: TcListDisplayColumnType.Currency,
        currencySymbol: '€'
      },
      {
        propertyName: 'date',
        visible: true,
        filtrable: false,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'dueDate',
        visible: true,
        filtrable: false,
        displayColumnType: TcListDisplayColumnType.Date
      }
    ];
  }

}
