export type VisitProperty = | 'id' | 'date' | 'clientId' | 'comments' | 'type' | 'codeVrp' | 'exportDate' ;

export const VisitProperty = {
  id: 'id' as VisitProperty,
  date: 'date' as VisitProperty,
  clientId: 'clientId' as VisitProperty,
  comments: 'comments' as VisitProperty,
  type: 'type' as VisitProperty,
  codeVrp: 'codeVrp' as VisitProperty,
  exportDate: 'exportDate' as VisitProperty,
}
