import { ClientListRowModel } from './../models/client-list-row.model';
import { TcAction, TcListFilterModel, SHOW_SPINNER, HIDE_SPINNER, TcListSortModel } from '@tc/core';
import { ClientModel } from '../models/client.model';
import { ClientRequestModel } from '../models/client-request.model';
import { VisitModel } from '../../main/models/visit.model';
import { ClientBaseModel } from '../models/client-base.model';
import { UpdateClientModel } from '../models/update-client.model';
import { InvoiceModel } from '../models/invoice.model';
import { LoadClientsPayload } from './clients.state.interface';

export enum ClientsActionTypes {

  // RESET: this action is dispatched from home page, to reset client list
  RESET_CLIENTS = '[Clients] Reset Clients',

  // INIT CLIENTS: this action is dispatched at client list page INIT
  INIT_CLIENTS = '[Clients] Init Clients',

  LOAD_MORE_CLIENTS = '[Clients] Load More Clients',
  LOAD_MORE_CLIENTS_SUCCESS = '[Clients] Load More Clients Success',

  // LOAD CLIENTS from DB
  LOAD_CLIENTS = '[Clients] Load Clients',
  // LOAD CLIENTS SUCCESS => add clients in store
  LOAD_CLIENTS_SUCCESS = '[Clients] Load Clients Success',
  // SORT CLIENTS
  SORT_CLIENTS = '[Clients] Sort Clients',
  // FILTER CLIENTS
  FILTER_CLIENTS = '[Clients] Filter Clients',

  // SELECT CLIENT (when click a row in client list)
  SELECT_CLIENT = '[Clients] Select client',

  // LOAD CLIENT (when click on contact client form list)
  LOAD_CLIENT = '[Clients] Load Client',
  LOAD_CLIENT_SUCCESS = '[Clients] Load Client Success',

  // SAVE CLIENT in client update popup
  UPDATE_CLIENT = '[Clients] Update Client',
  UPDATE_CLIENT_SUCCESS = '[Clients] Update Client Success',

  // OPEN CLIENT DETAILS
  OPEN_CLIENT_DETAILS = '[Clients] Open Client Details',
  // EDIT CLIENT: when click on edit button in client details screen
  EDIT_CLIENT = '[Clients] Edit Client',

  // ADD NEW CLIENT REQUEST: when click on add new client button in client list screen
  ADD_NEW_CLIENT_REQUEST = '[Clients] Add New Client Request',
  // SAVE NEW CLIENT REQUEST: when click on save in new client popup
  SAVE_NEW_CLIENT_REQUEST = '[Clients] Save New Client Request',

  // ADD VISIT: Open new visit popup
  ADD_NEW_VISIT = '[Clients] Add New Visit',
  // SAVE VISIT: Save new visit
  SAVE_NEW_VISIT = '[Clients] Save New Visit',

  // navigate to client (from client list or from client detail page)
  NAVIGATE_TO_CLIENT = '[Clients] Navigate To Client',

  // INVOICE LIST FROM CLIENT FICHE
  LOAD_CLIENT_INVOICE_LIST = '[Clients] Load Client Invoice List',
  LOAD_CLIENT_INVOICE_LIST_SUCCESS = '[Clients] Load Client Invoice List Success',

  LOAD_CLIENT_BY_ID = '[Clients] Load Client By Id',
  LOAD_CLIENT_BY_ID_SUCCESS = '[Clients] Load Client By Id Success',
}

//** Reset Clients */
export class ResetClients implements TcAction {
  readonly type = ClientsActionTypes.RESET_CLIENTS;
  constructor() { }
}

//** Init Clients */
export class InitClients implements TcAction {
  readonly type = ClientsActionTypes.INIT_CLIENTS;
  constructor(public payload: { filter: TcListFilterModel }) { }
}

@SHOW_SPINNER()
export class LoadClients implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENTS;
  constructor(public payload: LoadClientsPayload) { }
}

@HIDE_SPINNER()
export class LoadClientsSuccess implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENTS_SUCCESS;
  constructor(public payload: ClientListRowModel[]) { }
}

@SHOW_SPINNER()
export class LoadMoreClients implements TcAction {
  readonly type = ClientsActionTypes.LOAD_MORE_CLIENTS;
}

@HIDE_SPINNER()
export class LoadMoreClientsSuccess implements TcAction {
  readonly type = ClientsActionTypes.LOAD_MORE_CLIENTS_SUCCESS;
  constructor(public payload: ClientListRowModel[]) { }
}

//** Sort Clients */
export class SortClients implements TcAction {
  readonly type = ClientsActionTypes.SORT_CLIENTS;
  constructor(public payload: TcListSortModel) { }
}

//** Filter Clients */
export class FilterClients implements TcAction {
  readonly type = ClientsActionTypes.FILTER_CLIENTS;
  constructor(public payload: TcListFilterModel) { }
}

// ** Select a client in client list (click on row)*/
export class SelectClient implements TcAction {
  readonly type = ClientsActionTypes.SELECT_CLIENT;
  constructor(public payload: ClientListRowModel) { }
}

// ** Load client in client details
@SHOW_SPINNER()
export class LoadClient implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENT;
  constructor(public payload: string) { }
}

@HIDE_SPINNER()
export class LoadClientSuccess implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENT_SUCCESS;
  constructor(public payload: ClientModel) { }
}

//** Open client details: when click on edit icon in client list */
export class OpenClientDetails implements TcAction {
  readonly type = ClientsActionTypes.OPEN_CLIENT_DETAILS;
  constructor(public payload: ClientListRowModel) { }
}

// ** EDIT CLIENT: when click on edit button in client details screen */
export class EditClient implements TcAction {
  readonly type = ClientsActionTypes.EDIT_CLIENT;
  constructor(public payload: ClientModel) { }
}

// ADD NEW CLIENT REQUEST: when click on add new client button in client list screen
export class AddNewClientRequest implements TcAction {
  readonly type = ClientsActionTypes.ADD_NEW_CLIENT_REQUEST;
}

// SAVE NEW CLIENT REQUEST: when click on save in new client popup
export class SaveNewClientRequest implements TcAction {
  readonly type = ClientsActionTypes.SAVE_NEW_CLIENT_REQUEST;
  constructor(public payload: { clientReq: ClientRequestModel, userId: string }) { }
}

// ADD NEW VISIT: Open new visit popup
export class AddNewVisit implements TcAction {
  readonly type = ClientsActionTypes.ADD_NEW_VISIT;
  constructor(public payload: ClientListRowModel) { }
}

// SAVE NEW VISIT: Save new visit
export class SaveNewVisit implements TcAction {
  readonly type = ClientsActionTypes.SAVE_NEW_VISIT;
  constructor(public payload: VisitModel) { }
}

// navigate to client (from client list or from client detail page)
export class NavigateToClient implements TcAction {
  readonly type = ClientsActionTypes.NAVIGATE_TO_CLIENT;
  constructor(public payload: ClientListRowModel) { }
}

// update client details from pop-up
export class UpdateClient implements TcAction {
  readonly type = ClientsActionTypes.UPDATE_CLIENT;
  constructor(public payload: UpdateClientModel) { }
}

export class UpdateClientSuccess implements TcAction {
  readonly type = ClientsActionTypes.UPDATE_CLIENT_SUCCESS;
  constructor(public payload: UpdateClientModel) { }
}

export class LoadClientInvoiceList implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENT_INVOICE_LIST;
  constructor(public payload: string) { }
}

export class LoadClientInvoiceListSuccess implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENT_INVOICE_LIST_SUCCESS;
  constructor(public payload: InvoiceModel[]) { }
}

export class LoadClientById implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENT_BY_ID;
  constructor(public payload: string) { }
}

export class LoadClientByIdSuccess implements TcAction {
  readonly type = ClientsActionTypes.LOAD_CLIENT_BY_ID_SUCCESS;
  constructor(public payload: ClientModel) { }
}


export type ClientsActions =
  | ResetClients
  | InitClients
  | LoadClients
  | LoadClientsSuccess
  | SortClients
  | FilterClients
  | SelectClient
  | LoadClient
  | LoadClientSuccess
  | OpenClientDetails
  | EditClient
  | AddNewClientRequest
  | SaveNewClientRequest
  | AddNewVisit
  | SaveNewVisit
  | NavigateToClient
  | UpdateClient
  | UpdateClientSuccess
  | UpdateClientSuccess
  | LoadClientInvoiceList
  | LoadClientInvoiceListSuccess
  | LoadClientById
  | LoadClientByIdSuccess
  | LoadMoreClients
  | LoadMoreClientsSuccess;

