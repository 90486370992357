import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatabaseResetService {

  public async reset() {
    const databases = await (window.indexedDB as any).databases();

    databases.forEach(db => window.indexedDB.deleteDatabase(db.name));

    window.localStorage.clear();
  }

}
