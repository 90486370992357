export type OrderRequestLineProperty = | 'id' | 'orderId' | 'orderNumber' | 'articleId' | 'orderQuantity' | 'unitPricePerPiece' | 'lineTotal' | 'colisageQuantity' | 'amountRemainingDelivered' ;

export const OrderRequestLineProperty = {
  id: 'id' as OrderRequestLineProperty,
  orderId: 'orderId' as OrderRequestLineProperty,
  orderNumber: 'orderNumber' as OrderRequestLineProperty,
  articleId: 'articleId' as OrderRequestLineProperty,
  orderQuantity: 'orderQuantity' as OrderRequestLineProperty,
  unitPricePerPiece: 'unitPricePerPiece' as OrderRequestLineProperty,
  lineTotal: 'lineTotal' as OrderRequestLineProperty,
  colisageQuantity: 'colisageQuantity' as OrderRequestLineProperty,
  amountRemainingDelivered: 'amountRemainingDelivered' as OrderRequestLineProperty,
}
