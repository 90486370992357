import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { TcSmartComponent, TcSimpleFilterComponent, TcListFilterModel } from '@tc/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getClientListFilter } from '../../../store/clients.selectors';
import { FilterClients } from '../../../store/clients.actions';

@Component({
  selector: 'app-client-filter-search',
  templateUrl: './client-filter-search.component.html',
  styleUrls: ['./client-filter-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientFilterSearchComponent extends TcSmartComponent implements OnInit, OnDestroy {

  filterSubscription: Subscription;
  clientListFilter: TcListFilterModel;

  tcSimpleFilter: TcSimpleFilterComponent;
  @ViewChild('tcSimpleFilter', { static: true }) set setTcSimpleFilterComponent(values: TcSimpleFilterComponent) {
    this.tcSimpleFilter = values;
    this.tcSimpleFilter.filterLabel = 'Recherche rapide ...';
  }

  constructor(
    private readonly store: Store<any>,
  ) {
    super();
  }

  ngOnInit() {
    this.filterSubscription = this.store.pipe(select(getClientListFilter))
      .subscribe(filter => {
        if (filter) {
          this.clientListFilter = filter;
          this.tcSimpleFilter.value = filter['anyFieldContains'];
        }
      });
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  onSearch = (filterValue: string) => {
    this.clientListFilter['anyFieldContains'] = filterValue;
    this.store.dispatch(new FilterClients(this.clientListFilter));
  }

}
