<div class="client-list-page">
  <tc-layout [fixedInViewport]="true" [fixedTopGap]="0" [mode]="'side'" [showToolbarMenuIcon]="false" [opened]="true">
    <div class="header-content" tc-layout-toolbar>
      <app-page-title></app-page-title>
      <div class="header-right">
        <app-user-info></app-user-info>
        <app-page-menu></app-page-menu>
      </div>
    </div>

    <div tc-layout-sidenav>
      <app-back-navigation></app-back-navigation>

      <div class="filter-search"></div>

      <img src="assets/icons/line_colors.png" width="170px" />

      <app-statistics-type-selector></app-statistics-type-selector>
    </div>

    <div tc-layout-sidenav-content>
      <app-statistics-total *ngIf="selectedType === statisticsType.Total"></app-statistics-total>

      <app-statistics-component *ngIf="selectedType === statisticsType.A" [statisticsType]="selectedType"></app-statistics-component>
      <app-statistics-component *ngIf="selectedType === statisticsType.B" [statisticsType]="selectedType"></app-statistics-component>
      <app-statistics-component *ngIf="selectedType === statisticsType.C1" [statisticsType]="selectedType"></app-statistics-component>
      <app-statistics-component *ngIf="selectedType === statisticsType.C2" [statisticsType]="selectedType"></app-statistics-component>
      <app-statistics-component *ngIf="selectedType === statisticsType.D" [statisticsType]="selectedType"></app-statistics-component>
      <app-statistics-component *ngIf="selectedType === statisticsType.F" [statisticsType]="selectedType"></app-statistics-component>
      <app-statistics-component *ngIf="selectedType === statisticsType.E" [statisticsType]="selectedType"></app-statistics-component>
    </div>
  </tc-layout>
</div>
