import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcAction, TcNotificationService, TcTranslateService } from '@tc/core';
import { Observable } from 'rxjs';
import { mergeMap, tap, withLatestFrom, take } from 'rxjs/operators';

import { APP_TIMEOUT } from '../../../app.module';
import { AuthenticationService } from '../../../services/business-services/authentication.service';
import { RepositoryService } from '../../../services/repository/repository.service';
import { InitialiseSync, HideSyncPopup } from '../../sync-agent/store/sync-agent.actions';
import { UserType } from './../enums/user-type.enum';
import {
  AuthActionTypes,
  CheckAppTimeout,
  Login,
  LoginFailure,
  LoginSuccess,
  Logout,
  SetLastActivity,
  SetLastSignedUser,
} from './auth.actions';
import { getLastActivity } from './auth.selectors';
import { getFirstSyncFinished } from '../../sync-agent/store/sync-agent.selectors';
import * as Sentry from "@sentry/angular";

@Injectable()
export class AuthEffects {

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private router: Router,
    private notificationService: TcNotificationService,
    private translateService: TcTranslateService,
    @Inject(APP_TIMEOUT) private appTimeout: number,
    private readonly repositoryService: RepositoryService,
  ) { }

  @Effect()
  login$: Observable<TcAction> = this.actions$.pipe(
    ofType<Login>(
      AuthActionTypes.LOGIN
    ),
    mergeMap((action: Login) =>
      this.authenticationService.login(action.payload.username, action.payload.password)
        .then((user) => {
          if (!user.codeVrp) {
            return new LoginFailure('login.missing-code-vrp')
          }

          this.store.dispatch(new LoginSuccess(user));

          return new SetLastSignedUser({ username: user.login, token: `Bearer ${user.access_token}` })
        })
        .catch((error) => {
          return new LoginFailure(error)
        }))
  );

  @Effect({ dispatch: false })
  loginSuccess = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap(async (action: LoginSuccess) => {
      const firstSyncFinished = await this.store
        .select(getFirstSyncFinished)
        .pipe(take(1))
        .toPromise();

      if (firstSyncFinished) {
        this.repositoryService.refresh();
      }

      if (action.payload.profil === UserType.Administrator) {
        this.router.navigateByUrl('/users');
      } else {
        this.store.dispatch(new InitialiseSync());
        this.router.navigateByUrl('/');
      }
    })
  );

  @Effect({ dispatch: false })
  loginFailed = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE),
    tap((action: LoginFailure) => {
      this.notificationService.error(this.translateService.instant(action.payload));
    })
  );

  @Effect({ dispatch: false })
  logout = this.actions$.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap((action: Logout) => {
      Sentry.configureScope(scope => scope.setUser(null)); 
      this.router.navigate(['/login']);
    })
  );

  @Effect({ dispatch: false })
  checkAppTimeout = this.actions$.pipe(
    ofType<CheckAppTimeout>(AuthActionTypes.CHECK_APP_TIMEOUT),
    withLatestFrom(this.store.pipe(select(getLastActivity))),
    tap(([action, lastActivityDate]) => {
      // if last activity date is set we will check the diff in minutes between current date and last activity date
      if (!lastActivityDate) {
        return;
      }

      const shouldLogout = this.authenticationService.shouldLogout(lastActivityDate);

      if (shouldLogout) {
        this.store.dispatch(new HideSyncPopup());
        this.store.dispatch(new Logout());
      } else {
        // if diff in minutes is lower than app timeout paramete => we will set a new lastActivity date
        // only if parameter true was sent to Check App Timeout (from AuthGuard)
        if (action.payload) {
          this.store.dispatch(new SetLastActivity());
        }
      }
    })
  );

}
