import { Injectable } from '@angular/core';
import { Family, FamilyEntityType } from '@maxel-order/shared';

import { DatabaseService } from '../database/database.service';
import { EventsService } from '../events/events.service';
import { RepositoryService } from '../repository/repository.service';
import { BaseDAO } from './base.dao';
import { IdProviderService } from './id-provider.service';

@Injectable({
  providedIn: 'root'
})
export class FamiliesDAO extends BaseDAO<Family>{
  constructor(
    database: DatabaseService,
    idProvider: IdProviderService,
    repositoryService: RepositoryService,
    eventsService: EventsService,
  ) {
    super(database, idProvider, repositoryService, eventsService);

    this.entityName = FamilyEntityType.className;
    this.entityLowerName = FamilyEntityType.lowerName;
  }
}
