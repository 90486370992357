import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {

    // TODO: AF en el archivo ngsw-config.json esta la configuración para saber que rutas esta cacheando.
    // hay algunas rutas que no se estan cacheando porque se utilizó el signo "?" en ellas,
    // las rutas que se estan cacheando son las que tienen ese signo, pero si no trae el signo no se cachea
    // por lo tanto hay que ver como corregir ese tema

    // TODO: AF falta implementar algo que limpie cache entre navegadores, es decir, si un usuario cambia un registro
    // ese cambio debería limpiar la cache del recurso que corresponde en todos los navegadores, esto se podría solucionar
    // con notificaciones push desde el backend o de alguna otra manera.

    private cacheName: string;

    constructor() {
        // TODO: AF idealmente este cache name debería estar en configuración
        this.cacheName = 'maxelorder-images';
    }

    getCacheNames = () => caches.keys();

    clearAllCache = () => {
        this.clearCacheByNameOrAll(this.cacheName, false);
    }

    clearCache = (baseUrl: string): void => {
        this.clearCacheByUrl(this.cacheName, baseUrl);
    }

    async getCachedRequestsByCacheName(cacheName: string): Promise<ReadonlyArray<Request>> {
        const currentCache = await caches.open(cacheName);

        return await currentCache.keys();
    }

    /**
     * Elimina una cache en especifico o todas si se pasa como true el parametro allKeys
     */
    private clearCacheByNameOrAll = (nameCacheParam: string, allKeys: boolean) => {
        caches.keys().then((cacheNames) => {
            return Promise.all(
                cacheNames.filter((cacheName) => {
                    if (allKeys) { return true; }
                    if (nameCacheParam === cacheName) { return true; }
                }).map((cacheName) => {
                    return caches.delete(cacheName).then((res) => this.logDelete(res, cacheName));
                })
            );
        });
    }

    /**
     * Elimina una key(si encuentra la URL) dentro de una cache
     */
    private clearCacheByUrl = (nameCache: string, url: string) => {
        caches.open(nameCache).then((c) => {
            c.keys().then((keys) => {
                keys.filter((p) => p.url.includes(url))
                    .map((keySearched) => c.delete(keySearched.url).then((res) => this.logDelete(res, keySearched)));
            });
        }
        );
    }

    private logDelete = (result: boolean, cache: any) => {
        console.log(`eliminado de cache para ${cache} =>`, (result ? 'Satisfactorio' : 'Fallido'));
    }

}
