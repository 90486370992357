import { Component, OnInit, Optional, Inject, ElementRef } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UpdateClientModel } from '../../../models/update-client.model';

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.scss']
})
export class UpdateClientComponent extends TcFormComponent<UpdateClientModel> implements OnInit {

  constructor(
    public translateService: TcTranslateService,
    private dialogRef: MatDialogRef<UpdateClientComponent>,
    element: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UpdateClientModel) {

    super(translateService, element);
    this.fields = [
      {
        key: 'address1',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'address2',
        type: 'input',
        templateOptions: {
          type: 'text'
        }
      },
      {
        key: 'address3',
        type: 'input',
        templateOptions: {
          type: 'text'
        }
      },
      {
        key: 'postalCode',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'city',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'country',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        }
      },
      {
        key: 'phone',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'contactEmail',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'storeContactName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
    ];
  }

  ngOnInit() {
    if (this.data) {
      this.model = Object.assign({}, this.data);
      this.model.address1 = this.data.address1;
      this.model.address2 = this.data.address2;
      this.model.address3 = this.data.address3;
      this.model.postalCode = this.data.postalCode;
      this.model.city = this.data.city;
      this.model.country = this.data.country;
    }
    super.ngOnInit();
  }

  onClose() {
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(model: any) {
    if (this.form.valid) {
      this.dialogRef.close(model);
    }
  }
}
