import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { TcListComponent, TcListDisplayColumnType, TcListSortType, TcSmartComponent } from '@tc/core';
import { CompaniesDAO } from 'apps/maxel-order/src/app/services/dao';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderArticle } from '../../../models/order-article.model';
import { OrderSummaryModel } from '../../../models/order-summary.model';
import { getOrderSummary } from '../../../store/orders.selectors';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent extends TcSmartComponent implements OnInit, OnDestroy {

  public date: string;
  public shop: string;
  public client: string;
  public total: number;

  private orderSummarySubscription: Subscription;

  orderSummaryList: TcListComponent;
  @ViewChild('orderSummaryList', { static: true }) set appGenericList1(values: TcListComponent) {
    this.orderSummaryList = values;
  }

  constructor(
    private dialogRef: MatDialogRef<OrderSummaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: OrderSummaryModel,
    private store: Store<any>,
    private readonly companiesDAO: CompaniesDAO,
  ) {
    super();
  }

  ngOnInit() {

    if (this.data) {
      // this.client = this.data.client;
      this.date = moment(this.data.date).format('DD/MM/YYYY')
      this.shop = this.data.client;

      const total = this.data.items.map(t => t?.amount || 0)
        .reduce((acc, value) => Number(acc) + Number(value), 0);
      this.total = Number(total.toFixed(2));
    }

    const items = new Subject<any[]>();

    this.orderSummaryList.rows$ = items
       
    this.orderSummarySubscription = this.store
      .select(getOrderSummary)
      .subscribe(async orderSummary => {
        const companyNameByIdMap = await this.companiesDAO.getCompanyNameByIdMap();
          if (orderSummary) {
            items.next(this.getParsedItems(orderSummary.items, companyNameByIdMap));
        }
      })
    this.orderSummaryList.hasActionsLabel = false;
    this.orderSummaryList.isPaged = false;
    this.orderSummaryList.isFiltrable = false;
    this.orderSummaryList.sortType = TcListSortType.Disabled;

    this.orderSummaryList.columns = [
      {
        propertyName: 'companyName',
        visible: true
      },
      {
        propertyName: 'ref',
        visible: true
      },
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'quantity',
        visible: true
      },
      {
        propertyName: 'priceUnitar',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency,
        currencySymbol: '€'
      },
      {
        propertyName: 'amount',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency,
        currencySymbol: '€'
      }
    ];
  }

  onClose() {
    this.dialogRef.close();
  }

  private getParsedItems(items: OrderArticle[], companyNameByIdMap: Map<string, string>) {
    const parsed = items.map(item => ({
      ...item,
      companyName: (companyNameByIdMap.get(item.company) || item.company || '').toUpperCase(),
    }));

    parsed.sort((a, b) => b.companyName > a.companyName ? -1 : 1)

    return parsed;
  }

  ngOnDestroy() {
    if (this.orderSummarySubscription) {
      this.orderSummarySubscription?.unsubscribe();
    }
  }

}
