<tc-dialog (close)="close()" [hasCloseButton]="true">
  <div tc-dialog-title>
    <h3 mat-dialog-title>{{'sync-finish.title' | translate}}</h3>
  </div>

  <div class="container" tc-dialog-content>
    <mat-dialog-content>
      <div *ngIf="information.duration">
        <p>{{'sync-finish.total' | translate}}</p>
        <ul>
          <li class="item" *ngFor="let partition of information.byPartition">
            {{partition.error ? partition.total - partition.error : partition.total}}
            {{getTranslationKey(partition.type) | translate}}
            <span *ngIf="partition.vrp">({{partition.vrp}})</span>
            <span *ngIf="partition.error && partition.type !== 'Images'">(<span style="color: red;">{{partition.error}} in erreur</span>)</span>
          </li>
        </ul>

        <div class="separator">
          <div>
            {{'sync-finish.duration' | translate}}
            <strong>{{information.duration}}</strong>
          </div>
          <div>
            {{'sync-finish.lastSync' | translate}}
            <strong>{{information.date}}</strong>
          </div>
        </div>
      </div>

    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="close()">
        {{'close' | translate}}
      </button>
    </mat-dialog-actions>
  </div>

</tc-dialog>
