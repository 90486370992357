export interface EventNotifier {
  subscribe(event: Events, cb: CallbackFunc);
  unsubscribe(event: Events, cb: CallbackFunc);
  publish(event: Events, data?: any);
}

export enum Events {
  RefreshRepository,
  UpdateItem,
  CreateItem,
  RemoveItem,
}

export type CallbackFunc = (data: any) => any;
