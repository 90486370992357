<div
    *ngIf="data"
    class="statistics--container"
>
    <div class="details--container">
        <ng-container *ngTemplateOutlet="statsTemplate; context:{
            value: data.activeClients,
            color: 'aquamarine',
            title: 'statistics-total.numberOfActiveClients',
            subTitle: 'statistics-total.fromLastMonth'
        }"></ng-container>

        <ng-container *ngTemplateOutlet="statsTemplate; context:{
            value: data.turnover,
            color: 'tan-hide',
            title: 'statistics-total.annualTurnover',
            subTitle: 'statistics-total.fromLastYear',
            isCurrency: true
        }"></ng-container>

        <ng-container *ngTemplateOutlet="statsTemplate; context:{
            value: data.orders,
            color: 'dodger-blue',
            title: 'statistics-total.numberOfOrders',
            subTitle: 'statistics-total.fromLastMonth'
        }"></ng-container>

        <ng-container *ngTemplateOutlet="statsTemplate; context:{
            value: data.averageOrdersAmount,
            color: 'razzmatazz',
            title: 'statistics-total.averageOrderAmount',
            subTitle: 'statistics-total.fromLastMonth',
            isCurrency: true
        }"></ng-container>
    </div>

    <div class="chart--container">
        <canvas id="statistics-total-chart"></canvas>
    </div>
</div>

<ng-template
    #statsTemplate
    let-value="value"
    let-color="color"
    let-title="title"
    let-subTitle="subTitle"
    let-isCurrency="isCurrency"
>
    <div
        class="details--item"
        [ngClass]="color"
    >
        <div
            class="details--item-header"
            [ngClass]="color"
        >
            <img
                src="assets/icons/users.svg"
                width="55px"
            >
        </div>
        <div class="details--item-container">
            <span class="details--item__title">{{title | translate}}</span>
            <span class="details--item__value">
                {{ value.total | number:'1.0':'fr' }}
                <ng-container *ngIf="isCurrency">€</ng-container>
            </span>
            <span class="details--item__variation">
                <span
                    class="positive"
                    [ngClass]="{ negative: value?.variation < 0 }"
                >
                    {{ value?.variation < 0 ? value?.variation * -1 : value?.variation }}%
                    <mat-icon *ngIf="value?.variation >= 0">arrow_upward</mat-icon>
                    <mat-icon *ngIf="value?.variation < 0">arrow_downward</mat-icon>
                </span>
                {{subTitle | translate}}
            </span>
        </div>
    </div>
</ng-template>
