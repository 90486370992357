<div class="container">
    <div class="product-icon-container">
        <div class="product-icon">
            <img
                *ngIf="!isMissing else missingRef"
                class="image"
                src='{{imagesRootSrc}}{{article.company}}/{{article.image}}'
                width="130px"
                (error)="onImageMissing($event)"
            >
        </div>

        <ng-template #missingRef>{{'article-item.missing' | translate}}</ng-template>

        <app-article-item-like
            *ngIf="article.hasHistory"
            (buttonClick)="articleHistory()"
        ></app-article-item-like>
        <app-article-item-basket
            [total]="(getNumberOfArticles$(article) | async)"
            (buttonClick)="addToBasket()"
        >
        </app-article-item-basket>
        <app-article-item-basket-remove
            [total]="(getNumberOfArticles$(article) | async)"
            (buttonClick)="removeFromBasket()"
        >
        </app-article-item-basket-remove>
        <span *ngIf="article.new">
            <img
                class="new"
                src="assets/icons/bton_new.png"
                width="40"
            >
        </span>

        <div>
            <img
                class="search"
                (click)="previewArticle()"
                src="assets/icons/search.png"
            >
        </div>
    </div>
    <div class="details-container">
        <div class="details">

            <div class="section-1">
                <div
                    matTooltipClass="artilce_tooltip__name"
                    matTooltipPosition="above"
                    matTooltip="{{article.name}}"
                    #tooltip="matTooltip"
                ></div>
                <div
                    class="name"
                    (click)="tooltip.toggle()"
                >
                    {{article.name}}
                </div>
                <div class="grey-color">{{article.code}}</div>
            </div>

            <div class="section-2">
                <div *ngIf="article.itemsInBax">
                    <span class="grey-color">{{'article-item.bax' | translate}}</span>
                    <span class="white-color">:</span>
                    <strong>{{article.itemsInBax}}</strong>
                </div>
                <div *ngIf="article.stock">
                    <span class="grey-color">{{'article-item.stock' | translate}}</span>
                    <span class="white-color">:</span>
                    <strong>{{article.stock | number:'1.0':'fr'}}</strong>
                </div>

                <div *ngIf="article.arrival && article.resuplyDate">
                    <span class="grey-color">{{'article-item.arrive' | translate}}</span>
                    <span class="white-color">:</span>
                    <strong>{{article.arrival  | number:'1.0':'fr'}}</strong>
                </div>
                <div class="columns price">
                    <strong>
                        <span
                            class="price"
                            *ngIf="article.price"
                        >
                            <span *ngIf="getRoundPrice() !== ''">
                                {{getRoundPrice() | tcCurrencyFormat}} €
                            </span>
                        </span>
                    </strong>
                    <span *ngIf="article.pvp && article.pvp !== 0">
                        {{'article-item.pvp' | translate}} {{article.pvp | tcCurrencyFormat}} €
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
