import { OrderModel } from './../../modules/orders/models/order.model';
import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TcPage, TcGenericFilterComponent } from '@tc/core';
import { Store } from '@ngrx/store';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Component({
  selector: 'app-f15-order-list-page',
  templateUrl: './f15-order-list-page.component.html',
  styleUrls: ['./f15-order-list-page.component.scss']
})
export class F15OrderListPageComponent extends TcPage implements OnInit {
  @ViewChild('orderListFilter', { static: true }) set setOrderListFilter(values: TcGenericFilterComponent<OrderModel>) {}

  constructor(private permissionService: PermissionsService, store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IAppPageState = {
      title: 'orders-page.title',
      menuItems: this.permissionService.applyPermissionOnMenuItems([
        {
          name: AppMenu.NewOrder
        },
        {
          name: AppMenu.Reports
        },
        {
          name: AppMenu.DisplaySyncPopup
        },
        {
          name: AppMenu.Logout
        }
      ])
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
