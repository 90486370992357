<tc-dialog (close)="onClose()" id="order-summary-popup">

    <div tc-dialog-title>
        <div class="header">
            <span>{{'order-summary-list.title' | translate}} {{client}} pour magasin {{shop}}</span>
            <span>{{date}}</span>
        </div>
    </div>

    <div tc-dialog-content>
        <tc-list #orderSummaryList id="order-summary-list"></tc-list>
        <div class="total"><strong>Total:&nbsp;&nbsp;</strong>{{total | tcCurrencyFormat: '€' }}</div>
    </div>


    <mat-dialog-actions>
    </mat-dialog-actions>

</tc-dialog>