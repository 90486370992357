import { SyncAgentState } from '../sync-agent.state';

export function syncProgressResetReducer(state: SyncAgentState): SyncAgentState {
  return {
    ...state,
    progress: [],
    requestedStop: false,
  };
}

