import { Injectable, PLATFORM_ID, Inject, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
// Based on https://github.com/angular/angular/issues/13818#issuecomment-372276031
// and source code of the old Renderer V1
export class CustomRenderer {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  invokeElementMethod(eleRef: ElementRef, method: string, args: any[]) {
    if (isPlatformBrowser(this.platformId)) {
      if(!eleRef?.nativeElement) return;
      const element = eleRef.nativeElement;
      element[method].apply(element, args);
    }
  }

  invokeNativeElementMethod(element: any, method: string, args: any[]) {
    if (isPlatformBrowser(this.platformId)) {
      element[method].apply(element, args);
    }
  }

  findByType(element: any, type: string): any { 
    return element?.localName === type ? element : this.findInChildrenByType(element?.children, type);
  }

  findInChildrenByType(children: any[], type: string): any {
    if(!children) return null;
    for(const child of children) {
      const found = this.findByType(child, type);
      if(found) return found;
    }
    return null;
  }
}
