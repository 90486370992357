import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { OrdersService } from '../../../services/business-services/orders.service';
import { OrderRequestsService } from '../../../services/business-services/order-requests.service';
import { VisitsService } from '../../../services/business-services/visits.service';
import { UpdateUsedCategories } from '../../articles/store/articles.actions';
import { getAuthenticatedUser } from '../../auth/store/auth.selectors';
import { LoadClientById } from '../../clients/store/clients.actions';
import { getSelectedClientId } from '../../clients/store/clients.selectors';
import { OrderSummaryComponent } from '../components/smart/order-summary/order-summary.component';
import { OrderStatus } from '../enums/order-status.enum';
import { VisitComponent } from './../../main/components/dumb/visit/visit.component';
import {
  BlockOrder,
  BlockOrderError,
  BlockOrderSuccess,
  CancelOrder,
  CancelOrderError,
  CancelOrderSuccess,
  CreateInitOrder,
  DecreaseQuantity,
  DecreaseQuantityError,
  DecreaseQuantitySuccess,
  DeleteFromBasket,
  DeleteFromBasketError,
  DeleteFromBasketSuccess,
  EditOrder,
  IncreaseQuantity,
  IncreaseQuantityError,
  IncreaseQuantitySuccess,
  LoadOrder,
  LoadOrderSummary,
  LoadOrderSummarySuccess,
  OrderArticle,
  OrderArticleError,
  OrderArticleSuccess,
  OrdersActionTypes,
  SelectOrder,
  SendDevis,
  SendDevisError,
  SendDevisSuccess,
  UpdateOrder,
  ValidateOrder,
  ValidateOrderError,
  ValidateOrderSuccess,
  ViewBasketContent,
  LoadOrderRequestSummary,
  LoadOrderRequestSummarySuccess,
  OrderRemoveArticleSuccess,
  SelectOrderIds
} from './orders.actions';
import { getOrder } from './orders.selectors';



@Injectable()
export class OrdersEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<any>,
    private dialog: MatDialog,
    private orderService: OrdersService,
    private orderRequestsService: OrderRequestsService,
    private notificationService: TcNotificationService,
    private visitService: VisitsService,
    private translateService: TcTranslateService
  ) { }

  @Effect({ dispatch: false })
  viewBasketContent = this.actions$.pipe(
    ofType<ViewBasketContent>(OrdersActionTypes.VIEW_BASKET_CONTENT),
    map((action: ViewBasketContent) => {
      this.router.navigate(['/order-detail']);
    })
  );

  @Effect({ dispatch: false })
  selectOrder = this.actions$.pipe(
    ofType<SelectOrder>(OrdersActionTypes.SELECT_ORDER),
    map((action: SelectOrder) => {

      if (action.payload.type === 'visit') {

        // const visitModel: VisitModel = {
        //   id: action.payload.id,
        //   clientName: action.payload.clientName,
        //   date: action.payload.date
        // }
        this.visitService.getVisit(action.payload.id).then(visitModel => {
          visitModel.clientName = action.payload.clientName;
          this.dialog.open(VisitComponent, {
            width: '40vw',
            height: '45vh',
            data: visitModel
          })
        })
      }

      if (action.payload.type === 'order') {
        this.store.dispatch(new LoadOrderSummary(action.payload.id));
      }

      if (action.payload.type === 'orderRequest' && action.payload.status !== 'Bloquée') {
        this.store.dispatch(new LoadOrderRequestSummary(action.payload.id));
      }

    })
  );

  @Effect({ dispatch: false })
  loadOrderSummary = this.actions$.pipe(
    ofType<LoadOrderSummary>(
      OrdersActionTypes.LOAD_ORDER_SUMMARY
    ),
    map((action) => {
      this.orderService.getOrderSummary(action.payload)
        .then(orderSummaryModel => this.loadAndOpenOrderSummaryModel(orderSummaryModel))
    })
  );

  private loadAndOpenOrderSummaryModel(orderSummaryModel) {
    const summary = {
      ...orderSummaryModel,
      items: orderSummaryModel.items.map(item => ({ ...item, amount: Number(item.amount.toFixed(2)) }))
    };
    this.store.dispatch(new LoadOrderSummarySuccess(summary));

    this.dialog.open(OrderSummaryComponent, {
      width: '90vw',
      height: '90vh',
      data: orderSummaryModel
    });
  }

  @Effect({ dispatch: false })
  selectOrderIds = this.actions$.pipe(
    ofType<SelectOrderIds>(OrdersActionTypes.SELECT_ORDER_IDS),
    map(async (action: SelectOrderIds) => {
      
      const orderSummaryModel = await this.orderService.getOrderSummary(action.payload[0]);
      for (let i = 1; i < action.payload.length; i++) {
        const orderSummaryModel2 = await this.orderService.getOrderSummary(action.payload[i]);
        orderSummaryModel.items = orderSummaryModel.items.concat(orderSummaryModel2.items);
      }
           
      this.loadAndOpenOrderSummaryModel(orderSummaryModel);
    })
  );

  @Effect({ dispatch: false })
  loadOrderRequestSummary = this.actions$.pipe(
    ofType<LoadOrderRequestSummary>(
      OrdersActionTypes.LOAD_ORDER_REQUEST_SUMMARY
    ),
    map((action) => {
      this.orderRequestsService.getOrderSummary(action.payload)
        .then(orderSummaryModel => {
          const summary = {
            ...orderSummaryModel,
            items: orderSummaryModel.items.map(item => ({ ...item, amount: Number(item.amount.toFixed(2)) }))
          };
          this.store.dispatch(new LoadOrderRequestSummarySuccess(summary));

          this.dialog.open(OrderSummaryComponent, {
            width: '90vw',
            height: '90vh',
            data: orderSummaryModel
          });
        })
    })
  );

  @Effect({ dispatch: false })
  createInitOrder = this.actions$.pipe(
    ofType<CreateInitOrder>(
      OrdersActionTypes.CREATE_INIT_ORDER
    ),
    withLatestFrom(this.store.pipe(select(getAuthenticatedUser))),
    map(([action, loggedUser]) =>
      this.orderRequestsService.createInitOrder(action.payload, loggedUser.codeVrp, loggedUser.id).then((orderModel) => {
        this.store.dispatch(new LoadOrder(orderModel));
      })
    )
  );


  @Effect({ dispatch: false })
  orderArticle = this.actions$.pipe(
    ofType<OrderArticle>(OrdersActionTypes.ORDER_ARTICLE),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {

      const article = action.payload;

      let itemsAlreadyAdded = 0;
      const items = order && order.lines && order.lines.length > 0 ? order.lines.filter(a => a.articleId === article.id) : [];
      if (items && items.length > 0) {
        itemsAlreadyAdded = items.map(a => Number(a.quantity)).reduce((accumulator, currentValue) => accumulator + currentValue);
      }
      if (article.stock <= 0) {
        this.store.dispatch(new OrderArticleError('order-article.stock-negative'));
      } else if (itemsAlreadyAdded >= article.stock) {
        this.store.dispatch(new OrderArticleError('order-article.stock-maximum'));
      } else if (!article.price) {
        this.store.dispatch(new OrderArticleError('order-article.no-price'));
      } else {

        this.orderRequestsService.orderArticle(order.id, order.number, article.id, article.itemsInBax, article.price, 1).then(
          (orderLineId) => {
            this.store.dispatch(new OrderArticleSuccess({ article, orderLineId }));
            this.store.dispatch(new UpdateUsedCategories());
          },
          (error) => {
            this.store.dispatch(new OrderArticleError(error));
          })
      }
    })
  );

  @Effect({ dispatch: false })
  orderRemoveArticle = this.actions$.pipe(
    ofType<OrderArticle>(OrdersActionTypes.ORDER_REMOVE_ARTICLE),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {

      const article = action.payload;

      this.orderRequestsService.orderArticle(order.id, order.number, article.id, article.itemsInBax, article.price, -1).then(
        (orderLineId) => {
          this.store.dispatch(new OrderRemoveArticleSuccess({ article, orderLineId }));
          this.store.dispatch(new UpdateUsedCategories());
        },
        (error) => {
          this.store.dispatch(new OrderArticleError(error));
        });    
    })
  );

  @Effect({ dispatch: false })
  orderArticleError = this.actions$.pipe(
    ofType<OrderArticleError>(
      OrdersActionTypes.ORDER_ARTICLE_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );

  @Effect({ dispatch: false })
  increaseQuantity = this.actions$.pipe(
    ofType<IncreaseQuantity>(OrdersActionTypes.INCREASE_QUANTITY),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {

      let itemsAlreadyAdded = 0;
      const orderLine = order.lines.find(a => a.id === action.payload.orderLineId);
      if (orderLine) {
        itemsAlreadyAdded = orderLine.quantity;
      }

      if (action.payload.leftForSaleStock <= 0) {
        this.store.dispatch(new IncreaseQuantityError('order-article.stock-negative'));
      } else if (itemsAlreadyAdded >= action.payload.leftForSaleStock) {
        this.store.dispatch(new IncreaseQuantityError('order-article.stock-maximum'));
      } else {

        this.orderRequestsService.increaseQuantity(action.payload.orderLineId, action.payload.itemsInBax).then(
          (success) => {
            this.store.dispatch(new IncreaseQuantitySuccess(action.payload.orderLineId));
          },
          (error) => {
            this.store.dispatch(new IncreaseQuantityError(error));
          })
      }
    })
  );


  @Effect({ dispatch: false })
  increaseQuantityError = this.actions$.pipe(
    ofType<IncreaseQuantityError>(
      OrdersActionTypes.INCREASE_QUANTITY_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );

  @Effect({ dispatch: false })
  decreaseQuantity = this.actions$.pipe(
    ofType<DecreaseQuantity>(OrdersActionTypes.DECREASE_QUANTITY),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {

      this.orderRequestsService.decreaseQuantity(action.payload.orderLineId, action.payload.itemsInBax).then(
        (success) => {
          this.store.dispatch(new DecreaseQuantitySuccess(action.payload.orderLineId));
        },
        (error) => {
          this.store.dispatch(new DecreaseQuantityError(error));
        })

    })
  );

  @Effect({ dispatch: false })
  decreaseQuantityError = this.actions$.pipe(
    ofType<DecreaseQuantityError>(
      OrdersActionTypes.DECREASE_QUANTITY_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );

  @Effect({ dispatch: false })
  deleteFromBasket = this.actions$.pipe(
    ofType<DeleteFromBasket>(OrdersActionTypes.DELETE_FROM_BASKET),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {

      this.orderRequestsService.deleteFromBasket(action.payload).then(
        (success) => {
          this.store.dispatch(new DeleteFromBasketSuccess(action.payload));
        },
        (error) => {
          this.store.dispatch(new DeleteFromBasketError(error));
        })

    })
  );

  @Effect({ dispatch: false })
  deleteFromBasketError = this.actions$.pipe(
    ofType<DeleteFromBasketError>(
      OrdersActionTypes.DELETE_FROM_BASKET_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );


  @Effect({ dispatch: false })
  cancelOrder = this.actions$.pipe(
    ofType<CancelOrder>(OrdersActionTypes.CANCEL_ORDER),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {

      this.orderRequestsService.cancelOrder(order.id).then(
        (success) => {
          this.store.dispatch(new CancelOrderSuccess());
        },
        (error) => {
          this.store.dispatch(new CancelOrderError(error));
        })

    })
  );

  @Effect({ dispatch: false })
  cancelOrderSuccess = this.actions$.pipe(
    ofType<CancelOrderSuccess>(
      OrdersActionTypes.CANCEL_ORDER_SUCCESS
    ),
    withLatestFrom(this.store.pipe(select(getSelectedClientId))),
    map(([action, selectedClientId]) => {
      this.notificationService.success(this.translateService.instant('orders-page.cancelOrder'));
      this.store.dispatch(new CreateInitOrder(selectedClientId));
      this.router.navigate(['/articles']);
    })
  );

  @Effect({ dispatch: false })
  cancelOrderError = this.actions$.pipe(
    ofType<CancelOrderError>(
      OrdersActionTypes.CANCEL_ORDER_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );


  @Effect({ dispatch: false })
  blockOrder = this.actions$.pipe(
    ofType<BlockOrder>(OrdersActionTypes.BLOCK_ORDER),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {

      this.orderRequestsService.blockOrder(order.id).then(
        () => {
          this.store.dispatch(new BlockOrderSuccess());
        },
        (error) => {
          this.store.dispatch(new BlockOrderError(error));
        })

    })
  );

  @Effect({ dispatch: false })
  blockOrderSuccess = this.actions$.pipe(
    ofType<BlockOrderSuccess>(
      OrdersActionTypes.BLOCK_ORDER_SUCCESS
    ),
    withLatestFrom(this.store.pipe(select(getSelectedClientId))),
    map(([action, selectedClientId]) => {
      this.notificationService.warning(this.translateService.instant('orders-page.blockOrder'));
      this.store.dispatch(new CreateInitOrder(selectedClientId));
      this.router.navigate(['/articles']);
    })
  );

  @Effect({ dispatch: false })
  blockOrderError = this.actions$.pipe(
    ofType<BlockOrderError>(
      OrdersActionTypes.BLOCK_ORDER_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );

  @Effect({ dispatch: false })
  validateOrder = this.actions$.pipe(
    ofType<ValidateOrder>(OrdersActionTypes.VALIDATE_ORDER),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {
      this.orderRequestsService.validateOrder(order.id, action.payload.deliveryDate, action.payload.summaryRequested, action.payload.comments)
        .then(() => this.store.dispatch(new ValidateOrderSuccess()))
        .catch((error) => this.store.dispatch(new ValidateOrderError(error)));
    })
  );

  @Effect({ dispatch: false })
  sendDevis = this.actions$.pipe(
    ofType<SendDevis>(OrdersActionTypes.SEND_DEVIS),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {
      this.orderRequestsService.devisRequested(order.id)
        .then(() => this.store.dispatch(new SendDevisSuccess()))
        .catch((error) => this.store.dispatch(new SendDevisError(error)));
    })
  );

  @Effect({ dispatch: false })
  sendDevisSuccess = this.actions$.pipe(
    ofType<SendDevisSuccess>(
      OrdersActionTypes.SEND_DEVIS_SUCCESS
    ),
    map((action) => {
      this.notificationService.success(this.translateService.instant('orders-page.sendDevis'));
    })
  );

  @Effect({ dispatch: false })
  sendDevisError = this.actions$.pipe(
    ofType<SendDevisError>(
      OrdersActionTypes.SEND_DEVIS_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );

  @Effect({ dispatch: false })
  updateOrder = this.actions$.pipe(
    ofType<UpdateOrder>(OrdersActionTypes.UPDATE_ORDER),
    withLatestFrom(this.store.pipe(select(getOrder))),
    map(([action, order]) => {
      this.orderRequestsService.updateOrder(order.id, action.payload.deliveryDate, action.payload.summaryRequested, action.payload.comments).then(
        () => { },
        (error) => { })
    })
  );

  @Effect({ dispatch: false })
  validateOrderSuccess = this.actions$.pipe(
    ofType<ValidateOrderSuccess>(
      OrdersActionTypes.VALIDATE_ORDER_SUCCESS
    ),
    withLatestFrom(this.store.pipe(select(getSelectedClientId))),
    map(([action, selectedClientId]) => {
      this.notificationService.success(this.translateService.instant('orders-page.validOrder'));
      this.store.dispatch(new CreateInitOrder(selectedClientId));
      this.router.navigate(['/articles']);
    })
  );

  @Effect({ dispatch: false })
  validateOrderError = this.actions$.pipe(
    ofType<ValidateOrderError>(
      OrdersActionTypes.VALIDATE_ORDER_ERROR
    ),
    map((action) =>
      this.notificationService.error(this.translateService.instant(action.payload))
    )
  );

  @Effect({ dispatch: false })
  editOrder = this.actions$.pipe(
    ofType<EditOrder>(
      OrdersActionTypes.EDIT_ORDER
    ),
    withLatestFrom(this.store.pipe(select(getAuthenticatedUser))),
    map(([action, loggedUser]) => {
      // this.ordersService.createInitOrder(action.payload, loggedUser.codeVrp).then((orderModel) => {
      //   this.store.dispatch(new LoadOrder(orderModel));
      // })
      this.orderRequestsService
        .getClientOrder(action.payload.clientId, OrderStatus.Blocked, action.payload.id)
        .then((order) => {
          if (order !== null) {
            this.store.dispatch(new LoadClientById(action.payload.clientId));
            this.store.dispatch(new LoadOrder(order));
            this.router.navigate(['/order-detail']);
          }
        })

    })
  );


}
