<!--The content below is only a placeholder and can be replaced.-->
<!-- <div style="text-align:center">
  <h3>
    Welcome to {{ title }} v{{ version }}!
  </h3> -->
<!-- <img width="300" alt="Angular Logo"
    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg=="> -->
<!-- </div> -->
<!-- <button mat-raised-button color="primary" (click)="run1()">Ask storage permission</button>
<button mat-raised-button color="primary" (click)="run2()">Ask notification permission</button>
<button mat-raised-button color="primary" (click)="getCacheNames()">cacheNames</button> -->
<!-- <br />
<hr> -->
<!-- <button style="margin-right: 5px;" *ngFor="let folder of folders" mat-raised-button color="primary" (click)="loadAll(folder)">Load {{ folder }} {{ folderSizes[folder] }} Gb</button>
<button style="margin-right: 20px;" mat-raised-button color="primary" (click)="slider()">Show Slider</button>
<button style="margin-right: 20px;" mat-raised-button color="primary" (click)="clearCache()">Clear Cache</button>
<br />
<hr> -->
<!-- <p>used: {{used}} Mb of {{granted}} Mb</p>
<p>persisted: {{persisted}} permissionStatus: {{permissionStatus}}</p>
<p>loaded images: {{percentImages}} % ({{countImages}} / {{ totalImages }}) (Errors: {{ countErrors }})</p>
<p>cacheNames: <span *ngFor="let name of cacheNames">[{{name}}] </span></p> -->
<!-- <p>storageEstimate usage: {{storageEstimate?.usage}}</p>
<p>storageEstimate quota: {{storageEstimate?.quota}}</p> -->
<!-- <br /> -->
<router-outlet></router-outlet>
<tc-spinner [spinnerOverlay]="'custom-overlay'" [spinnerClass]="'custom-spinner'" [customContent]="true">
    <div tc-spinner-custom-content>
        <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
        </div> -->
    </div>
</tc-spinner>