<tc-list #userList id="user-list"></tc-list>

<ng-template #colActionEditTemplate let-row="row" let-rowAction="rowAction">
  <img (click)="editUser(row)" class="image" src="assets/icons/bton_edit.png" width="40" />
  <img (click)="deleteUser(row)" class="image" src="assets/icons/bton_delete.png" width="40" />
</ng-template>

<ng-template #firstNameTemplate let-row="row">
  <tc-long-text [text]="row.firstName" [width]="'100px'">
  </tc-long-text>
</ng-template>

<ng-template #lastNameTemplate let-row="row">
  <tc-long-text [text]="row.lastName" [width]="'100px'">
  </tc-long-text>
</ng-template>

<ng-template #emailTemplate let-row="row">
  <tc-long-text [text]="row.email" [width]="'100px'">
  </tc-long-text>
</ng-template>

<ng-template #profilTemplate let-row="row">
  {{('user-detail-info.' + row.profil.toLowerCase()) | translate}}
</ng-template>


<div class="add">
  <img (click)="addUser()" src="assets/icons/bton_add.png" width="45" />
</div>
