<div class="legal-container">
    <div class="body">
        <br />
        <div class="label code"><strong>{{'client-legal-tarif.code' | translate}}
             <span class="white-color"> : </span>
            {{client.id}}</strong> </div>

        <br />
        <div class="label">
            <span class="gray">{{'client-legal-tarif.cui' | translate}} </span>
            <span class="white-color"> : </span>
            <strong *ngIf="client.siren"> {{client.siren}}</strong>
            <strong *ngIf="client.siret"> / {{client.siret}}</strong>
        </div>
        <div class="label"><span class="gray">{{'client-legal-tarif.tva' | translate}} </span>
            <span class="white-color"> : </span>
            <strong> {{client.tvaNumber}}</strong>
        </div>
        <div class="columns">
            <div>
                <div class="label">
                    <span class="gray">{{'client-legal-tarif.tarif-general' | translate}} </span>
                    <span class="white-color"> : </span>
                    <strong> {{getGeneralPrice()}}</strong>
                </div>
                <div class="label">
                    <span class="gray">{{'client-legal-tarif.tarif-particular' | translate}} </span>
                    <span class="white-color"> : </span>
                    <strong> {{getSpecialPrice()}}</strong>
                </div>
            </div>
            <div class="right">
                <div class="label">
                    <span class="gray">{{'client-legal-tarif.paymentPeriod' | translate}}</span>
                    <span class="white-color"> : </span>
                    <strong>{{'client-legal-tarif.paymentPeriodValue' | translate: {value: client.paymentPeriod} }}</strong>
                </div>
                <div class="label">
                    <span class="gray">{{'client-legal-tarif.carriage' | translate}} </span>
                    <span class="white-color"> : </span>
                    <strong *ngIf="client.carriageFree">
                      <span *ngIf="getCarriageFree() !== ''">
                        {{getCarriageFree() | tcCurrencyFormat }} €
                    </span>
                  </strong>
                </div>
            </div>
        </div>
        <br />
    </div>


</div>