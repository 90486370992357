import { AuthActions, AuthActionTypes } from './auth.actions';
import { AuthState, initialAuthState } from './auth.state';
export function authReducer(
  state = initialAuthState,
  action: AuthActions
): AuthState {
  switch (action.type) {

    case AuthActionTypes.LOGIN_SUCCESS: {

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        errorMessage: null,
        lastActivity: new Date()
      };
    }

    case AuthActionTypes.LOGOUT: {

      return {
        ...state,
        isAuthenticated: false,
        user: null,
        errorMessage: null,
        lastActivity: null
      };
    }

    case AuthActionTypes.SET_LAST_ACTIVITY: {
      return {
        ...state,
        lastActivity: new Date()
      };
    }

    case AuthActionTypes.SET_LAST_SIGNED_USER: {
      return {
        ...state,
        lastSignedUser: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
