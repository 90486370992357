import { UserDetailComponent } from './components/smart/user-detail/user-detail.component';
import { UserListComponent } from './components/smart/user-list/user-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UsersEffects } from './store/Users.effects';
import { usersReducer } from './store/Users.reducer';

const components = [
  UserListComponent,  
  UserDetailComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([UsersEffects]),
    StoreModule.forFeature('users', usersReducer)
  ],
  exports: [...components],  
})
export class UsersModule { }
