import { Injectable } from '@angular/core';
import {
    ArticleEntityType,
    ArticleOrderHistoryEntityType,
    ClientEntityType,
    ClientPriceEntityType,
    ClientRequestEntityType,
    CompanyEntityType,
    FamilyEntityType,
    InvoiceEntityType,
    OrderEntityType,
    OrderLineEntityType,
    PriceEntityType,
    SyncStatusEntityType,
    VisitEntityType,
    OrderRequestEntityType,
    OrderRequestLineEntityType,
    NotificationEntityType,
} from '@maxel-order/shared';

import { VrpProviderService } from '../business-services/vrp-provider.service';

@Injectable({
    providedIn: 'root'
})
export class PartitionsService {

    private readonly commonPartitions: string[] = [
        FamilyEntityType.lowerName,
        PriceEntityType.lowerName,
        ArticleEntityType.lowerName,
        CompanyEntityType.lowerName,
        NotificationEntityType.lowerName,
    ];
    
    private readonly clientPartitions: string[] = [
        ArticleOrderHistoryEntityType.lowerName,
        ClientEntityType.lowerName,
        ClientPriceEntityType.lowerName,
        ClientRequestEntityType.lowerName,
        InvoiceEntityType.lowerName,
        OrderEntityType.lowerName,
        OrderLineEntityType.lowerName,
        OrderRequestEntityType.lowerName,
        OrderRequestLineEntityType.lowerName,
        SyncStatusEntityType.lowerName,
        VisitEntityType.lowerName,
    ];

    private readonly pushPartitions: string[] = [
        OrderRequestEntityType.lowerName,
        OrderRequestLineEntityType.lowerName,
        NotificationEntityType.lowerName,
    ];

    constructor(
        private readonly vrpProviderService: VrpProviderService,
    ) { }

    public async all(): Promise<any[]> {
        const vrp = await this.vrpProviderService.getCurrentCodeVrp();
        if (!vrp) {
            return [];
        }

        const commonData = this.commonPartitions.map(partition => ({ lowerName: partition, dbName: partition }));
        const clientData = this.clientPartitions.map(partition => ({ lowerName: partition, dbName: `${vrp.toLowerCase()}_${partition}` }))

        return commonData.concat(clientData);
    }

    public isPush(partition){
        return this.pushPartitions.indexOf(partition) >= 0;
    }
}
