import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { AppNavigation } from '../../../store/main.actions';
import { Observable } from 'rxjs';
import { UserModel } from '../../../../auth/models/user.model';
import { getAuthenticatedUser } from '../../../../auth/store/auth.selectors';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends TcSmartComponent implements OnInit {

  authenticatedUser$: Observable<UserModel>;

  constructor( private store: Store<any>) {
    super();
   }

  ngOnInit(): void {
    this.authenticatedUser$ = this.store.pipe(select(getAuthenticatedUser));
  }

  navigation(route: string) {
    this.store.dispatch(new AppNavigation(route));
  }

}
