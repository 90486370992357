import { Injectable } from '@angular/core';
import { StatisticsFilter, StatisticsTypes } from '@maxel-order/shared';
import { UsersService } from '../../../services/business-services/users.service';
import { ClientsDAO, CompaniesDAO, FamiliesDAO, UsersDAO } from '../../../services/dao';
import { ClientAllVrp } from '../../clients/enums/client-status.enum';
import { StatisticsFilterItem } from '../interfaces/statistics.interface';

@Injectable()
export class StatisticsFiltersService {

  constructor(
    private readonly usersDAO: UsersDAO,
    private readonly clientsDAO: ClientsDAO,
    private readonly familiesDAO: FamiliesDAO,
    private readonly usersService: UsersService,
    private readonly companiesDAO: CompaniesDAO,    
  ) { }

  public async getDefaultFiltersValues(): Promise<StatisticsFilter> {
    const user = await this.usersService.getCurrentUser();

    if (!user || user.codeVrp === ClientAllVrp) {
      return {} as StatisticsFilter;
    }

    return { userId: user.id };
  }

  public async getFilters(type: StatisticsTypes): Promise<StatisticsFilterItem[]> {
    const user = await this.usersService.getCurrentUser();
    if (!user) {
      return [];
    }
    const filters = [];

    if (user.codeVrp === ClientAllVrp) {
      filters.push(await this.usersFilter())
    }

    switch (type) {
      case StatisticsTypes.A:
        filters.push(...await this.getFiltersAType());
        break;
      case StatisticsTypes.B:
        filters.push(...await this.getFiltersBType());
        break;
      case StatisticsTypes.C1:
        filters.push(...await this.getFiltersC1Type());
        break;
      case StatisticsTypes.C2:
        filters.push(...await this.getFiltersC2Type());
        break;
      case StatisticsTypes.D:
        filters.push(...await this.getFiltersDType());
        break;
      case StatisticsTypes.D:
        filters.push(...await this.getFiltersDType());
        break;
      case StatisticsTypes.E:
        filters.push(...await this.getFiltersEType());
        break;
      case StatisticsTypes.F:
        filters.push(...await this.getFiltersFType());
        break;
      default:
        filters.push(...[]);
        break;
    }

    return filters;
  }

  private async getFiltersAType(): Promise<StatisticsFilterItem[]> {
    return [await this.companyFilter()];
  }

  private async getFiltersBType(): Promise<StatisticsFilterItem[]> {
    return [this.departmentFilter(), await this.companyFilter(), await this.clientsFilter(), this.fromFilter(), this.toFilter()];
  }

  private async getFiltersC1Type(): Promise<StatisticsFilterItem[]> {
    return [await this.familiesFilter(), await this.companyFilter(), await this.clientsFilter(), this.fromFilter(), this.toFilter()];
  }

  private async getFiltersC2Type(): Promise<StatisticsFilterItem[]> {
    return [await this.familiesFilter(), await this.companyFilter(), await this.clientsFilter(), this.fromFilter(), this.toFilter()];
  }

  private async getFiltersDType(): Promise<StatisticsFilterItem[]> {
    return [this.fromFilter(), this.toFilter()];
  }

  private async getFiltersEType(): Promise<StatisticsFilterItem[]> {
    return [await this.familiesFilter(), await this.companyFilter(), await this.clientsFilter()];
  }

  private async getFiltersFType(): Promise<StatisticsFilterItem[]> {
    return [await this.familiesFilter(), await this.companyFilter(), this.fromFilter(), this.toFilter()];
  }

  private async clientsFilter() {
    const clients = await this.clientsDAO.getAll();
    return {
      key: 'clientId',
      values: clients.map(client => ({ label: client.companyName, value: client.id })),
    }
  }

  private async familiesFilter() {
    const families = await this.familiesDAO.getAll();
    return {
      key: 'familyId',
      values: families.map(family => ({ label: family.label, value: family.id })),
    }
  }

  private async usersFilter() {
    const users = await this.usersDAO.getAll();
    return {
      key: 'userId',
      values: users.filter(user => !!user.codeVrp && user.codeVrp !== ClientAllVrp).map(user => ({ label: user.login, value: user.id })),
    }
  }

  private fromFilter(): StatisticsFilterItem {
    return {
      key: 'from', values: this.periodValues()
    };
  }

  private toFilter(): StatisticsFilterItem {
    return {
      key: 'to', values: this.periodValues()
    };
  }

  private periodValues() {
    return [
      { label: 'Janvier', value: '1' },
      { label: 'Février', value: '2' },
      { label: 'Mars', value: '3' },
      { label: 'Avril', value: '4' },
      { label: 'Mai', value: '5' },
      { label: 'Juin', value: '6' },
      { label: 'Juillet', value: '7' },
      { label: 'Août', value: '8' },
      { label: 'Septembre', value: '9' },
      { label: 'Octobre', value: '10' },
      { label: 'Novembre', value: '11' },
      { label: 'Décembre', value: '12' }
    ];
  }

  private departmentFilter(): StatisticsFilterItem {
    return { key: 'department', values: new Array(99).fill(0).map((_, index) => ({ label: `${index + 1}`, value: `${index + 1}` })) };
  }

  private async companyFilter(): Promise<StatisticsFilterItem> {

    const companies = await this.companiesDAO.getAll();

    return {
      key: 'companyId',
      values: companies.map(company =>{
        return { label: company.name, value: company.id }
      })
    };
  }

}
