<tc-dialog (close)="onClose()">

    <div tc-dialog-title>
        <h3 *ngIf="model.id" mat-dialog-title>{{'user-detail-popup.edit-title' | translate}}</h3>
        <h3 *ngIf="!model.id" mat-dialog-title>{{'user-detail-popup.add-title' | translate}}</h3>
    </div>

    <div tc-dialog-content>

        <form [formGroup]="form" (ngSubmit)="submit(model)" id="user-detail-popup">

            <mat-dialog-content>
                <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
            </mat-dialog-content>

            <mat-dialog-actions>
                <button mat-raised-button color="primary" type="submit" class="save-button" tcPreventDoubleClick>{{'user-detail-popup.buttons.save' | translate}}</button>
                <button mat-raised-button (click)="cancel()" class="cancel-button" tcPreventDoubleClick>{{'user-detail-popup.buttons.cancel' | translate}}</button>
            </mat-dialog-actions>

        </form>

    </div>


    <mat-dialog-actions>
    </mat-dialog-actions>

</tc-dialog>