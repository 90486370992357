import { Injectable } from '@angular/core';
import { User } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getAuthenticatedUser } from '../../modules/auth/store/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class VrpProviderService {
  constructor(
    private readonly store$: Store<any>,
  ) { }

  public async getCurrentCodeVrp() {
    const user = await this.store$.select(getAuthenticatedUser).pipe(take(1)).toPromise() as User;
    return user?.codeVrp;
  }
}
