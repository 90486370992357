import { Injectable } from '@angular/core';
import { Article, NotificationType, OrderRequest, OrderRequestLine} from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import * as moment from 'moment';
import * as objectHash from 'object-hash';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUserVrp } from '../../modules/auth/store/auth.selectors';
import { ClientAllVrp } from '../../modules/clients/enums/client-status.enum';
import { OrderStatus } from '../../modules/orders/enums/order-status.enum';
import { OrderArticle } from '../../modules/orders/models/order-article.model';
import { OrderLineModel } from '../../modules/orders/models/order-line.model';
import { OrderSummaryModel } from '../../modules/orders/models/order-summary.model';
import { OrderModel } from '../../modules/orders/models/order.model';
import { getOrder } from '../../modules/orders/store/orders.selectors';
import { ArticlesDAO, ClientsDAO, OrderRequestsDAO, OrderRequestLinesDAO } from '../dao';
import { GetResourcesService } from '../get-resources/get-resources.service';
import { NotificationsService } from '../notifications/notifications.service';
import { Criteria } from '../repository/repository.interface';
import { VrpProviderService } from './vrp-provider.service';
import * as Sentry from "@sentry/angular";
import { Scope } from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class OrderRequestsService {

  constructor(
    private readonly ordersRequestDAO: OrderRequestsDAO,
    private readonly orderRequestLinesDAO: OrderRequestLinesDAO,
    private readonly articlesDAO: ArticlesDAO,
    private readonly clientsDAO: ClientsDAO,
    private readonly store: Store<TcAppState>,
    private readonly getResourcesService: GetResourcesService,
    private readonly notificationsService: NotificationsService,
    private readonly vrpProviderService: VrpProviderService,
  ) { }

  public async getCurrentOrder() {
    const basket = await this.getBasket();

    return await this.ordersRequestDAO.get(basket.id);
  }

  public async getBasket() {
    return this.store
      .select(getOrder)
      .pipe(take(1))
      .toPromise();
  }

  async getOrders(): Promise<OrderRequest[]> {
    return this.ordersRequestDAO.getAll();
  }

  async validateOrder(orderId: string, deliveryDate: string, summaryRequested: boolean, comments: string) {
    const order: OrderRequest = await this.ordersRequestDAO.get(orderId);
    if (!order) {
      throw new Error(`Can not find order: ${orderId}`);
    }

    order.status = OrderStatus.Valid;
    order.comment = comments;
    order.summaryRequested = summaryRequested;
    order.requestedDeliveryDate = deliveryDate;
    order.date = moment().toISOString();
    
    const orderLines = await this.orderRequestLinesDAO.search({ filter: { 'orderId': { $eq: orderId } } });
    order.hash = this.getOrderHash(orderLines ?? []);
   
    const updated = await this.ordersRequestDAO.update(order);
    const shouldSendSummary = order.summaryRequested && order.salesmanId;
    if (shouldSendSummary) {
      await this.notificationsService.send(NotificationType.OrderSummaryRequested, {
        orderId: order.id,
        vrp: await this.vrpProviderService.getCurrentCodeVrp(),
        orderRevision: updated?._rev,
      });
    }

    await this.notificationsService.send(NotificationType.OrderExportRequested, {
      orderId: order.id,
      vrp: await this.vrpProviderService.getCurrentCodeVrp(),
      orderRevision: updated?._rev,
      orderHash: order.hash,
    });

    try {
      Sentry.withScope( scope => {
        scope.setExtra("order", order);
        scope.setExtra("orderLines", orderLines);
        Sentry.captureMessage("Order validated");
      });
    } catch {}
  }

  async updateOrder(orderId: string, deliveryDate: string, summaryRequested: boolean, comments: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const order: OrderRequest = await this.ordersRequestDAO.get(orderId);
        if (order) {
          order.comment = comments;
          order.summaryRequested = summaryRequested;
          order.requestedDeliveryDate = deliveryDate;
          await this.ordersRequestDAO.update(order);
          resolve(true);
        } else {
          reject(false);
        }
      }
      catch (error) {
        reject(error);
      }
    });
  }

  async devisRequested(orderId: string) {
    const order: OrderRequest = await this.ordersRequestDAO.get(orderId);
    if (!order) {
      throw new Error(`Can not find order: ${orderId}`);
    }

    await this.notificationsService.send(NotificationType.DevisRequested, {
      orderId: order.id,
      vrp: await this.vrpProviderService.getCurrentCodeVrp(),
      orderRevision: order?._rev,
    });
  }

  async cancelOrder(orderId: string): Promise<boolean> {

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const order: OrderRequest = await this.ordersRequestDAO.get(orderId);
        if (order) {
          order.status = OrderStatus.Canceled;
          await this.ordersRequestDAO.update(order);
          resolve(true);
        } else {
          reject(false);
        }
      }
      catch (error) {
        reject(error);
      }

    });
  }

  async blockOrder(orderId: string): Promise<boolean> {

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const order: OrderRequest = await this.ordersRequestDAO.get(orderId);
        if (order) {
          order.status = OrderStatus.Blocked;
          await this.ordersRequestDAO.update(order);
          resolve(true);
        } else {
          reject(false);
        }
      }
      catch (error) {
        reject(error);
      }

    });
  }

  async getOrderSummary(orderId: string): Promise<OrderSummaryModel> {

    const query: Criteria = {
      filter: {
        'id': { $eq: orderId },
      }
    };

    const orders: OrderRequest[] = await this.ordersRequestDAO.search(query);

    if (orders.length === 0) {
      return new Promise<OrderSummaryModel>((resolve, reject) => {
        resolve(null)
      });
    }

    const orderLinesModel: OrderArticle[] = [];

    // TODO must be only one error for more
    const order = orders[0];

    const filterClient: Criteria = {
      filter: { 'id': { $eq: order.clientId } }
    };

    const clients = await this.clientsDAO.search(filterClient);

    const orderSummaryModel = {} as OrderSummaryModel;
    orderSummaryModel.date = moment.utc(order.date).toDate();
    orderSummaryModel.client = clients.length > 0 ? clients[0].companyName : '';

    const orderLinesList = await this.getOrderLinesByOrderId(order);

    for (const orderLine of orderLinesList) {
      const orderLineModel = await this.getOrderLineModel(orderLine);

      if (!orderLineModel) {
        continue;
      }

      orderLinesModel.push(orderLineModel);
    }

    // build model
    orderSummaryModel.items = orderLinesModel;

    return new Promise<OrderSummaryModel>((resolve, reject) => {
      resolve(orderSummaryModel)
    });
  }

  private async getOrderLineModel(orderLine): Promise<OrderArticle> {
    if (!orderLine) {
      return;
    }

    const article: Article = await this.articlesDAO.get(orderLine.articleId);

    let orderLineModel: OrderArticle;

    if (article) {
      orderLineModel = {
        name: article.description1,
        ref: article.id,
        id: article.id,
        company: article.companyId,
        quantity: orderLine.orderQuantity,
        priceUnitar: orderLine.unitPricePerPiece,
        amount: orderLine.unitPricePerPiece * orderLine.orderQuantity,
      }
    } else {
      orderLineModel = {
        quantity: orderLine.orderQuantity,
        priceUnitar: orderLine.unitPricePerPiece,
        amount: orderLine.unitPricePerPiece * orderLine.orderQuantity,
        name: orderLine.factureArticleDescription,
        company: orderLine.factureCompanyName,
        ref: orderLine.articleId,
        id: orderLine.articleId,
      }
    }

    return orderLineModel;
  }

  async getClientOrder(
    clientId: string,
    orderStatus: OrderStatus,
    orderId: string = null,
  ): Promise<OrderModel> {
    const query: Criteria = {
      filter: {
        'clientId': { $eq: clientId },
        'status': { $eq: orderStatus }
      }
    };

    const orders = await this.ordersRequestDAO.search(query);

    if (!orders.length) {
      return null;
    }

    const order = !!orderId ? orders.find(({ id }) => id === orderId) : orders[0];

    if (!order) {
      return null;
    }

    const orderLinesModel: OrderLineModel[] = [];

    // TODO must be only one error for more
    const orderModel = this.toOrderModel(order);

    // get order lines by orderId
    const orderLinesList = await this.getOrderLinesByOrderId(order);

    if (orderLinesList.length > 0) {

      for (let k = 0; k <= orderLinesList.length; k++) {

        const orderLineModel = {} as OrderLineModel;

        const orderLine = orderLinesList[k];
        if (orderLine) {
          orderLineModel.id = orderLine.id;
          orderLineModel.quantity = orderLine.orderQuantity;
          orderLineModel.price = orderLine.unitPricePerPiece;
          const article: Article = await this.articlesDAO.get(orderLine.articleId);

          if (article) {

            orderLineModel.articleName = article.description1;
            orderLineModel.articleRef = article.id;
            orderLineModel.articleId = article.id;
            orderLineModel.image = article.picture1;
            orderLineModel.companyId = article.companyId;
            orderLineModel.itemsInBax = article.unitsPerUnderPackage;
            orderLineModel.leftForSaleStock = article.leftForSaleStock;

            orderLinesModel.push(orderLineModel);

          }
        }
      }
    }

    // build model
    orderModel.lines = orderLinesModel;

    return new Promise<OrderModel>((resolve, reject) => {
      resolve(orderModel)
    });
  }

  public async createInitOrder(clientId: string, codeVrp: string, salesmanId: string): Promise<OrderModel> {
    const orderId = `${clientId}_${uuidv4()}`;
    const order: OrderRequest = {
      id: orderId,
      number: orderId,
      codeVrp: codeVrp,
      salesmanId: salesmanId,
      clientId: clientId,
      totalExcludingTax: 0,
      date: moment.utc().toISOString(),
      status: OrderStatus.Initial,
    };
    const orderSaved = await this.ordersRequestDAO.create(order);

    const orderModel = this.toOrderModel(orderSaved);
    orderModel.lines = [];

    return new Promise<OrderModel>((resolve, reject) => {
      resolve(orderModel)
    });
  }

  public toOrderModel(order: OrderRequest): OrderModel {
    const orderModel = {} as OrderModel;
    orderModel.id = order.id;
    orderModel.status = order.status;
    orderModel.number = order.number;
    return orderModel;
  }

  public toOrderSummaryModel(order: OrderRequest): OrderSummaryModel {
    const orderSummaryModel = {} as OrderSummaryModel;
    // orderSummaryModel.client = order.clientId;
    orderSummaryModel.date = moment.utc(order.date).toDate();
    return orderSummaryModel;
  }

  public async orderArticle(orderId: string, orderNumber: string, articleId: string, itemsInBax: number, price: number, sign: number): Promise<string> {

    return new Promise<string>(async (resolve, reject) => {

      try {
        // TODO
        const query: Criteria = {
          filter: {
            'articleId': { $eq: articleId },
            'orderId': { $eq: orderId }
          },
        };
        const orderLines: OrderRequestLine[] = await this.orderRequestLinesDAO.search(query);

        let orderLineId = `${uuidv4()}`;
        let orderLine: OrderRequestLine = null;
        if (orderLines.length > 0) {
          orderLine = orderLines[0];
          orderLine.orderQuantity = orderLine.orderQuantity + itemsInBax * sign;
          orderLineId = orderLine.id;
          if(orderLine.orderQuantity > 0) {
            await this.orderRequestLinesDAO.update(orderLine);
          } else {
            await this.orderRequestLinesDAO.delete(orderLine);
          }

        } else if (sign === 1) {

          orderLine = {
            id: orderLineId,
            articleId: articleId,
            orderId: orderId,
            orderNumber: orderNumber,
            orderQuantity: itemsInBax,
            unitPricePerPiece: price
          }
          await this.orderRequestLinesDAO.create(orderLine);
        }

        this.recalculateOrderTotal(orderLine.orderId);
        resolve(orderLineId);

      }
      catch (error) {
        reject(error);
      }

    });

  }

  public async increaseQuantity(orderLineId: string, itemsInBax: number): Promise<boolean> {

    return new Promise<boolean>(async (resolve, reject) => {

      try {
        const orderLine = await this.orderRequestLinesDAO.get(orderLineId);
        if (orderLine) {
          orderLine.orderQuantity = orderLine.orderQuantity + itemsInBax;
          await this.orderRequestLinesDAO.update(orderLine);
          this.recalculateOrderTotal(orderLine.orderId);
          resolve(true);
        } else {
          reject('');
        }

      }
      catch (error) {
        reject(error);
      }

    });

  }

  public async decreaseQuantity(orderLineId: string, itemsInBax: number): Promise<boolean> {

    return new Promise<boolean>(async (resolve, reject) => {

      try {

        const orderLine = await this.orderRequestLinesDAO.get(orderLineId);

        if (orderLine) {
          if (orderLine.orderQuantity === itemsInBax) {
            await this.orderRequestLinesDAO.delete(orderLine);
          } else {
            orderLine.orderQuantity = orderLine.orderQuantity - itemsInBax;
            await this.orderRequestLinesDAO.update(orderLine);
          }
          this.recalculateOrderTotal(orderLine.orderId);
          resolve(true);
        } else {
          reject('');
        }
      }
      catch (error) {
        reject(error);
      }

    });

  }

  public async deleteFromBasket(orderLineId: string): Promise<boolean> {

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const orderLine = await this.orderRequestLinesDAO.get(orderLineId);
        if (orderLine) {
          await this.orderRequestLinesDAO.delete(orderLine);
          this.recalculateOrderTotal(orderLine.orderId);
          resolve(true);
        } else {
          reject('');
        }
      }
      catch (error) {
        reject(error);
      }

    });
  }

  public async getLastClientOrder(clientId: string): Promise<OrderRequest> {
    const condition = {
      type: 'Order',
      sort: 'date:DESC',
      filter: {
        $and: [
          { clientId },
          {
            status: {
              $in: [
                OrderStatus.Valid,
                OrderStatus.Delivered,
              ],
            },
          },
        ]
      },
      limit: 1
    };
    const orders = await this.ordersRequestDAO.search(condition);

    return orders.length ? orders[0] : null;
  }

  private recalculateOrderTotal(orderId: string) {
    setTimeout(async () => {
      const orderLines = await this.orderRequestLinesDAO.search({ filter: { 'orderId': { $eq: orderId } } });
      const order = await this.ordersRequestDAO.get(orderId);

      const hash = this.getOrderHash(orderLines ?? []);
      const total = (orderLines || []).reduce((acc, cur) => {
        return acc + cur.orderQuantity * cur.unitPricePerPiece;
      }, 0);

      await this.ordersRequestDAO.update({
        ...order,
        hash,
        totalExcludingTax: Number(total.toFixed(2)),
      });
    })
  }

  private getOrderHash(orderLines: OrderRequestLine[]) {
    return objectHash(orderLines, {
      unorderedArrays: true,
      algorithm: 'sha1',
    });
  }

  private async getOrderLinesByOrderId(order: OrderRequest) {
    const currentVrp = await this.getCurrentVrp();

    if (currentVrp.toLowerCase() === ClientAllVrp) {
      return this.getResourcesService
        .getOrderRequestLines(order.number)
        .toPromise();
    }

    const filter: Criteria = {
      filter: { 'orderId': { $eq: order.id } }
    };

    const data = await this.orderRequestLinesDAO.search(filter);

    return data || [];
  }

  private getCurrentVrp(): Promise<string> {
    return this.store
      .select(getCurrentUserVrp)
      .pipe(take(1))
      .toPromise();
  }

}
