import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcListFilterModel, TcSmartComponent } from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import { ClientStatus } from '../../../enums/client-status.enum';
import { getClientListFilter } from '../../../store/clients.selectors';
import { FilterClients } from './../../../store/clients.actions';

@Component({
  selector: 'app-client-filter',
  templateUrl: './client-filter.component.html',
  styleUrls: ['./client-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientFilterComponent extends TcSmartComponent implements OnInit, OnDestroy {

  filterSubscription: Subscription;
  clientListFilter: TcListFilterModel;

  selectedStatus: string;

  filters$ = new Observable<any>(subscriber => {
    subscriber.next([
      { value: ClientStatus.Tous, label: `client-status.${ClientStatus.Tous}` },
      { value: ClientStatus.Actif, label: `client-status.${ClientStatus.Actif}` },
    ]);
  });

  constructor(
    private readonly store: Store<any>,
  ) {
    super();
  }

  ngOnInit() {
    this.filterSubscription = this.store.pipe(select(getClientListFilter))
      .subscribe(filter => {
        if (filter) {
          this.clientListFilter = filter;
          this.selectedStatus = this.clientListFilter['status'];
        }
      });
  }

  public selectFilter(status: string) {
    this.selectedStatus = status;
    this.clientListFilter['status'] = status;

    this.store.dispatch(new FilterClients(this.clientListFilter));
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
