import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MD5Service } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcAppState, TcService } from '@tc/core';
import { take } from 'rxjs/operators';
import { LOGIN_INAVLID_USERNAME_OR_PASSWORD } from '../../modules/auth/auth.module';
import { UserModel } from '../../modules/auth/models/user.model';
import { getLastUser } from '../../modules/auth/store/auth.selectors';
import { ConfigKeys } from '../config/config.interface';
import { ConfigService } from '../config/config.service';
import { UsersDAO } from '../dao';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends TcService {

  constructor(
    private readonly usersDAO: UsersDAO,
    private readonly config: ConfigService,
    private readonly httpClient: HttpClient,
    private readonly store: Store<TcAppState>
  ) {
    super();
  }

  async getLastSignedUser() {
    return await this.store.select(getLastUser)
      .pipe(take(1))
      .toPromise()
  }

  async login(username: string, password: string): Promise<UserModel> {
    const lastUser = await this.getLastSignedUser();
    const dbUsers = await this.usersDAO.search({ filter: { login: username.toUpperCase() } });

    if (lastUser && dbUsers.length && lastUser.username.toLowerCase() === username.toLowerCase()) {
      return await this.signInOffline(dbUsers[0], password);
    }

    if (!lastUser || !dbUsers.length || lastUser.username !== username) {
      return await this.signInOnline(username, password);
    }
  }

  async signInOnline(username: string, password: string) {
    const path = `${this.config.get(ConfigKeys.api.url)}/api/auth/login`;
    let res: any;

    try {
      res = await this.httpClient.post(
        path,
        { username, password },
      ).toPromise()
    } catch (e) {
      throw LOGIN_INAVLID_USERNAME_OR_PASSWORD;
    }

    if (!res) {
      throw LOGIN_INAVLID_USERNAME_OR_PASSWORD;
    }

    return res;
  }

  async signInOffline(dbUser, password: string): Promise<UserModel> {

    if (dbUser && new MD5Service().passwordsMatch(password, dbUser.password)) {
      const lastUser = await this.store
        .select(getLastUser)
        .pipe(take(1))
        .toPromise();

      const userModel = {
        ...dbUser,
        initiales: dbUser.login.length > 3 ? dbUser.login.substr(0, 3) : dbUser.login,
        access_token: lastUser.token.replace('Bearer ', ''),
      };

      return userModel as UserModel;
    } else {
      throw LOGIN_INAVLID_USERNAME_OR_PASSWORD;
    }
  }

  public shouldLogout(lastActivity: Date) {
    if (!lastActivity) {
      return true;
    }

    const diff = new Date().getTime() - lastActivity.getTime();
    const diffInMinutes = Math.round(diff / 60000);
    const appTimeout = this.config.get(ConfigKeys.APP_TIMEOUT);

    // if diff in minutes is greater than app timeout parameter => logout
    return diffInMinutes > appTimeout;
  }
}

