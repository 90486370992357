import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderLine, ArticleOrderHistory, OrderRequestLine } from '@maxel-order/shared';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { ConfigKeys } from '../config/config.interface';

@Injectable({
  providedIn: 'root'
})
export class GetResourcesService {

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigService,
  ) { }

  public getOrderLines(orderId: string): Observable<OrderLine[]> {
    return this.http.get<OrderLine[]>(`${this.url}/orderLines/${orderId}`);
  }

  public getOrderRequestLines(orderId: string): Observable<OrderRequestLine[]> {
    return this.http.get<OrderRequestLine[]>(`${this.url}/orderRequestLines/${orderId}`);
  }

  public getArticleOrderHistories(ids: string[]): Observable<ArticleOrderHistory[]> {
    return this.http.post<ArticleOrderHistory[]>(`${this.url}/articleOrderHistories`, { ids });
  }

  private get url(): string {
    return `${this.config.get(ConfigKeys.api.url)}/api/resources`;
  }

}
