export interface StatisticsFilter {
  userId?: string;
  from?: string;
  to?: string;
}

export interface StatisticsAFilter extends StatisticsFilter {
  department?: string;
  companyId?: string;
}

export interface StatisticsBFilter extends StatisticsFilter {
  department?: string;
  companyId?: string;
  clientId?: string;
}

export interface StatisticsCFilter extends StatisticsFilter {
  clientId?: string;
  companyId?: string;
  familyId?: string;
}

export interface StatisticsEFilter extends StatisticsFilter {
  familyId?: string;
  companyId?: string;
  clientId?: string;
}

export interface StatisticsFFilter extends StatisticsFilter {
  familyId?: string;
  companyId?: string;
}

export enum StatisticsTypes {
  Total = 'Total',
  A = 'A',
  B = 'B',
  C1 = 'C1',
  C2 = 'C2',
  D = 'D',
  E = 'E',
  F = 'F',
}
