import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TcAuthState } from '@tc/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckAppTimeout } from '../../modules/auth/store/auth.actions';
import { getAuthenticationState } from '../../modules/auth/store/auth.selectors';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<TcAuthState>,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    // Frontend authorisation
    // Ocurres at:
    //  each application start
    //  each time authState expires(timeout parametrable)
    //  authState considers last activity when a page navigation occurred
    const subsrciption = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // dispatch CheckAppTimeout action to the store with true
        // in auth effects will check if the user can to continue navigation
        this.store.dispatch(new CheckAppTimeout(true));
        subsrciption.unsubscribe();
      }
    });

    return this.store.pipe(
      select(getAuthenticationState),
      map(authenticationState => {
        const role = route.data.role;
        const currentUser = authenticationState.user;
        if (!currentUser || (role && role !== currentUser.profil)) {                      
          this.router.navigate(['/login']);
          return false;
        }
        
        Sentry.setUser({
          ID: currentUser.id,
          email: currentUser.email,
          login: currentUser.login,
          codeVrp: currentUser.codeVrp
        });
        
        return true;
      }));

  }
}
