import { TcListFilterModel, TcListPageModel } from '@tc/core';

import { ClientPricesModel } from '../../clients/models/client-prices.model';
import { ArticleOrderHistoryModel } from '../models/article-order-history.model';
import { ArticleModel } from '../models/article.model';
import { ArticleCategoryModel } from './../models/article-category.model';

export interface ArticlesState {
  clientId: string;
  filter: TcListFilterModel;
  pagination: TcListPageModel;
  articles: ArticleModel[];
  all: ArticleModel[];
  categories: ArticleCategoryModel[];
  clientPrices: ClientPricesModel;
  articleOrderHistoryList: ArticleOrderHistoryModel[];
  canLoadMore: boolean;
  usedFamilies: { [key: string]: boolean };
  clientArticleHistory: string[];
}

export const initialArticlesState: ArticlesState = {
  clientId: null,
  filter: null,
  pagination: null,
  articles: [],
  all: [],
  categories: [],
  clientPrices: null,
  articleOrderHistoryList: [],
  canLoadMore: true,
  usedFamilies: {},
  clientArticleHistory: null
};
