import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getPageInfo, TcSmartComponent } from '@tc/core';
import { Observable } from 'rxjs';
import { IAppPageState } from '../../../interfaces/app-page-state.interface';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent extends TcSmartComponent implements OnInit {

  pageInfo$: Observable<IAppPageState>;

  constructor(
    private readonly store: Store<any>,
  ) {
    super();
  }

  ngOnInit() {
    this.pageInfo$ = this.store.pipe(select(getPageInfo));
  }
}
