<div class="container">
    <span>{{'app-title' | translate}}</span>
    <div class="img">
        <img src="assets/icons/line_colors.png" width="170px">
    </div>
    <div class="form-container">
        <div class="banner">
        </div>
        <div class="form">
            <form [formGroup]="form" (ngSubmit)="submit()" id="login-form">
                <mat-form-field>
                    <input class="input" matInput required placeholder="{{'login-form.labels.username' | translate}}" formControlName="username" autocorrect="off" autocapitalize="off" spellcheck="off" floatPlaceholder="never" autocomplete="off">
                </mat-form-field>
                <mat-form-field>
                    <input matInput required type="password" placeholder="{{'login-form.labels.password' | translate}}" formControlName="password" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="off">
                </mat-form-field>
                <button mat-raised-button type="submit" class="btn btn-default" tcPreventDoubleClick>{{'login-form.button' | translate}}</button>
            </form>
        </div>
    </div>
</div>