import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MainActionTypes, AppNavigation, AppBackNavigation } from './main.actions';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';


@Injectable()
export class MainEffects {
    private targets = new Map<string, string[]>()
        .set('/orders', ['/'])
        .set('/reports', ['/'])
        .set('/clients', ['/'])
        .set('/articles', ['/clients'])
        .set('/order-detail', ['/articles']);

    constructor(
        private actions$: Actions,
        private router: Router,
        private location: Location
    ) { }

    @Effect({ dispatch: false })
    appNavigation = this.actions$.pipe(
        ofType<AppNavigation>(
            MainActionTypes.APP_NAVIGATION
        ),
        map((action: AppNavigation) => {
            this.router.navigate(['/' + action.payload]);
        })
    );

    @Effect({ dispatch: false })
    appBackNavigation = this.actions$.pipe(
        ofType<AppBackNavigation>(
            MainActionTypes.APP_BACK_NAVIGATION
        ),
        map((action: AppBackNavigation) => {
            const current = this.location.path()

            const target = this.targets.get(current);
            if (target) {
                this.router.navigate(target);
                return;
            }

            this.location.back();
        })
    );
}
