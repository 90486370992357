import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { SyncStatusService } from '../../../../../services/business-services/sync-status.service';
import { getSyncProgress } from '../../../store/sync-agent.selectors';
import { FamilyEntityType, PriceEntityType, ArticleEntityType, CompanyEntityType, ArticleOrderHistoryEntityType, ClientEntityType, ClientPriceEntityType, ClientRequestEntityType, InvoiceEntityType, OrderEntityType, OrderLineEntityType, VisitEntityType, OrderRequestEntityType, OrderRequestLineEntityType, NotificationEntityType } from '@maxel-order/shared';

@Component({
  selector: 'app-sync-finished-popup',
  templateUrl: './sync-finished-popup.component.html',
  styleUrls: ['./sync-finished-popup.component.css']
})
export class SyncFinishedPopupComponent extends TcSmartComponent implements OnInit {

  public information: SyncInformation = {
    byPartition: null,
    date: null,
    duration: null,
  };

  constructor(
    private dialogRef: MatDialogRef<SyncFinishedPopupComponent>,
    private store$: Store<any>,
    private syncStatusService: SyncStatusService,
  ) {
    super();
  }

  ngOnInit() {
    this.fetchSyncInformation();
  }

  close() {
    this.dialogRef.close();
  }

  getTranslationKey(label: string): string {
    const key = this.getEntityKey(label);

    return `entities.${key}`;
  }

  private async fetchSyncInformation() {
    const partitions = await this.store$.select(getSyncProgress).pipe(take(1)).toPromise();
    const status = await this.syncStatusService.getLast();

    this.information.byPartition = partitions.map(partition => {
      const [vrp, entity] = partition.type.indexOf('_') !== -1 ? partition.type.split('_') : [undefined, partition.type];
      return {
        vrp,
        type: entity,
        total: partition.total,
        error: partition.error
      };
    });

    this.information.duration = `${status.lastSyncSeconds}s`;
    this.information.date = moment(status.lastSyncDate).format('D/M/YYYY à HH:mm');
  }

  private getEntityKey(lowerName: string): string {

    switch (lowerName) {
      case FamilyEntityType.lowerName:
        return FamilyEntityType.className;

      case PriceEntityType.lowerName:
        return PriceEntityType.className;

      case ArticleEntityType.lowerName:
        return ArticleEntityType.className;

      case CompanyEntityType.lowerName:
        return CompanyEntityType.className;
        
      case NotificationEntityType.lowerName:
          return NotificationEntityType.className;

      case ArticleOrderHistoryEntityType.lowerName:
        return ArticleOrderHistoryEntityType.className;

      case ClientEntityType.lowerName:
        return ClientEntityType.className;

      case ClientPriceEntityType.lowerName:
        return ClientPriceEntityType.className;

      case ClientRequestEntityType.lowerName:
        return ClientRequestEntityType.className;

      case InvoiceEntityType.lowerName:
        return InvoiceEntityType.className;

      case OrderEntityType.lowerName:
        return OrderEntityType.className;

      case OrderLineEntityType.lowerName:
        return OrderLineEntityType.className;

      case OrderRequestEntityType.lowerName:
        return OrderRequestEntityType.className;

      case OrderRequestLineEntityType.lowerName:
        return OrderRequestLineEntityType.className;

      case VisitEntityType.lowerName:
        return VisitEntityType.className;

      default:
        return 'Images';
    }

  }
}

export interface SyncInformation {
  duration: string,
  date: string,
  byPartition: { type: string, total: number, vrp: string, error?: number }[];
}