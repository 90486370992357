import { OrdersModule } from './../orders/orders.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { ClientListComponent } from './components/smart/client-list/client-list.component';
import { ClientsEffects } from './store/clients.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { clientsReducer } from './store/clients.reducer';
import { ClientDetailComponent } from './components/smart/client-detail/client-detail.component';
import { ClientDetailInfoComponent } from './components/smart/client-detail-info/client-detail-info.component';
import { ClientContactComponent } from './components/dumb/client-contact/client-contact.component';
import { ClientDashboardComponent } from './components/dumb/client-dashboard/client-dashboard.component';
import { ClientLegalAndTarifComponent } from './components/dumb/client-legal-and-tarif/client-legal-and-tarif.component';
import { ClientStatusComponent } from './components/dumb/client-status/client-status.component';
import { NewClientDetailComponent } from './components/smart/new-client-detail/new-client-detail.component';
import { UpdateClientComponent } from './components/smart/update-client/update-client.component';
import { MainModule } from '../main/main.module';
import { ClientFilterComponent } from './components/smart/client-filter/client-filter.component';
import { ClientInvoiceListComponent } from './components/smart/client-invoice-list/client-invoice-list.component';
import { ClientActionListComponent } from './components/smart/client-action-list/client-action-list.component';
import { ClientFilterSearchComponent } from './components/smart/client-filter-search/client-filter-search.component';

export const PROSPECT_CLIENT = 'PROSPECT';

const components = [
  ClientListComponent,
  ClientDetailComponent,
  ClientDetailInfoComponent,
  ClientContactComponent,
  ClientDashboardComponent,
  ClientLegalAndTarifComponent,
  ClientStatusComponent,
  UpdateClientComponent,
  NewClientDetailComponent,
  ClientFilterComponent,
  ClientInvoiceListComponent,
  ClientActionListComponent,
  ClientFilterSearchComponent
]

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    MainModule,
    EffectsModule.forFeature([ClientsEffects]),
    StoreModule.forFeature('clients', clientsReducer)
  ],
  exports: [...components],
  entryComponents: [UpdateClientComponent, NewClientDetailComponent]
})
export class ClientsModule { }
