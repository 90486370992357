import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SyncAgentState } from './sync-agent.state';

const getSyncAgentState = createFeatureSelector<SyncAgentState>('syncAgent');

export const getSyncProgress = createSelector(
  getSyncAgentState,
  (state: SyncAgentState) => state.progress
);

export const getSyncRequired = createSelector(
  getSyncAgentState,
  (state: SyncAgentState) => state.syncRequired
);

export const getSyncStopRequest = createSelector(
  getSyncAgentState,
  (state: SyncAgentState) => state.requestedStop
);

export const getFirstSyncFinished = createSelector(
  getSyncAgentState,
  (state: SyncAgentState) => state.firstSyncFinished,
);

export const getLastSyncDBVersion = createSelector(
  getSyncAgentState,
  (state: SyncAgentState) => state.lastSyncDBVersion,
);