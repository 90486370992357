<div class="detail-info-container">
    <div class="header">
        <app-client-logo [logo]=""></app-client-logo>
        <app-custom-client-title></app-custom-client-title>
        <app-client-status [status]="client.status"></app-client-status>
    </div>
    <br />
    <div class="body">
        <div class="label">
            <span class="gray label">{{'client-detail-info.name' | translate}}</span>
            <span class="white-color"> : </span>
            <strong>{{user != null ? user.firstName: ""}} {{user != null ? user.lastName : ""}}</strong>
        </div>
        <div class="label">
            <span class="gray label">{{'client-detail-info.city' | translate}} </span>
            <span class="white-color"> : </span>
            <strong *ngIf="client.nationalCentral"> {{ client.nationalCentral }}</strong>
            <strong *ngIf="client.regionalCentral"> / {{ client.regionalCentral }}</strong>
        </div>
        <div class="label">
            <span class="gray label">{{'client-detail-info.type' | translate}} </span>
            <span class="white-color"> : </span>
            <strong> {{client.commercialSignType != null ?  client.commercialSignType : ''}}</strong>
        </div>
        <br />
        <div class="label address">
            <span class="gray label">{{'client-detail-info.addresse' | translate}} </span>
            <span class="white-color"> : </span>
            <strong> {{getAddress()}}</strong>
        </div>
        <br />
        <div class="label">
            <span class="gray label">{{'client-detail-info.insurance' | translate}} </span>
            <span class="white-color"> : </span>
            <strong> {{client.insurance != null ? client.insurance : ''}}</strong>
        </div>
        <div class="label">
            <span class="gray label">{{'client-detail-info.amount' | translate}} </span>
            <span class="white-color"> : </span>
            <strong *ngIf="client.insuranceOutstanding"> {{ client.insuranceOutstanding  | tcCurrencyFormat }} €</strong>
        </div>
        <br />
    </div>
    <div class="direction">
        <img (click)="directionMap(client)" src="assets/icons/bton_direction.png" width="40" />
    </div>
    <div class="edit" *ngIf="hasWriteAccess">
        <img (click)="edit()" src="assets/icons/bton_edit.png" width="40" />
    </div>
</div>