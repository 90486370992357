import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { getBasketTotalItems } from '../../../../orders/store/orders.selectors';
import { ViewBasketContent } from '../../../../orders/store/orders.actions';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketComponent extends TcSmartComponent implements OnInit {

  totalItems$: Observable<number>;

  constructor(
    private readonly store: Store<any>,
  ) {
    super();
  }

  ngOnInit() {
    this.totalItems$ = this.store.pipe(select(getBasketTotalItems));
  }

  openBakset() {
    this.store.dispatch(new ViewBasketContent());
  }

}
