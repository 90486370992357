export type ClientProperty = | 'id' | 'status' | 'commercialSignType' | 'contactEmail' | 'companyName' | 'commercialSign' | 'latitude' | 'longitude' | 'updatedBy' | 'codeVrp' | 'paymentType' | 'paymentCode' | 'paymentPeriod' | 'invoiceDate' | 'endOfMonth' | 'nationalCentral' | 'regionalCentral' | 'language' | 'carriageFree' | 'vat' | 'vatExoneration' | 'siret' | 'siren' | 'insurance' | 'insurancetype' | 'outstandingAmountAvailable' | 'outstandingAmountTotal' | 'lastOrderDate' | 'changeDate' | 'exportDate' | 'orderAmountAverage12Months' | 'totalAmountOrdered12Months' | 'devisDate' ;

export const ClientProperty = {
  id: 'id' as ClientProperty,
  status: 'status' as ClientProperty,
  commercialSignType: 'commercialSignType' as ClientProperty,
  contactEmail: 'contactEmail' as ClientProperty,
  companyName: 'companyName' as ClientProperty,
  commercialSign: 'commercialSign' as ClientProperty,
  latitude: 'latitude' as ClientProperty,
  longitude: 'longitude' as ClientProperty,
  updatedBy: 'updatedBy' as ClientProperty,
  codeVrp: 'codeVrp' as ClientProperty,
  paymentType: 'paymentType' as ClientProperty,
  paymentCode: 'paymentCode' as ClientProperty,
  paymentPeriod: 'paymentPeriod' as ClientProperty,
  invoiceDate: 'invoiceDate' as ClientProperty,
  endOfMonth: 'endOfMonth' as ClientProperty,
  nationalCentral: 'nationalCentral' as ClientProperty,
  regionalCentral: 'regionalCentral' as ClientProperty,
  language: 'language' as ClientProperty,
  carriageFree: 'carriageFree' as ClientProperty,
  vat: 'vat' as ClientProperty,
  vatExoneration: 'vatExoneration' as ClientProperty,
  siret: 'siret' as ClientProperty,
  siren: 'siren' as ClientProperty,
  insurance: 'insurance' as ClientProperty,
  insurancetype: 'insurancetype' as ClientProperty,
  outstandingAmountAvailable: 'outstandingAmountAvailable' as ClientProperty,
  outstandingAmountTotal: 'outstandingAmountTotal' as ClientProperty,
  lastOrderDate: 'lastOrderDate' as ClientProperty,
  changeDate: 'changeDate' as ClientProperty,
  exportDate: 'exportDate' as ClientProperty,
  orderAmountAverage12Months: 'orderAmountAverage12Months' as ClientProperty,
  totalAmountOrdered12Months: 'totalAmountOrdered12Months' as ClientProperty,
  devisDate: 'devisDate' as ClientProperty,
}
