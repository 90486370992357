import { initialClientsState } from './clients.state';
import { ClientsActionTypes, ClientsActions } from './clients.actions';
import { ClientsState } from './clients.state.interface';

export function clientsReducer(
  state = initialClientsState,
  action: ClientsActions
): ClientsState {
  switch (action.type) {

    case ClientsActionTypes.RESET_CLIENTS: {
      return {
        ...state,
        filter: null,
        sort: null,
        clients: [],
        selectedClient: {
          id: null,
          name: '',
          status: ''
        }
      };
    }

    case ClientsActionTypes.LOAD_CLIENT_INVOICE_LIST_SUCCESS: {
      return {
        ...state,
        clientInvoiceList: action.payload
      };
    }

    case ClientsActionTypes.SELECT_CLIENT: {
      return {
        ...state,
        selectedClient: action.payload
      };
    }

    case ClientsActionTypes.LOAD_CLIENT_SUCCESS: {
      return {
        ...state,
        selectedClient: action.payload
      };
    }

    case ClientsActionTypes.LOAD_CLIENT_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedClient: action.payload
      };
    }

    case ClientsActionTypes.EDIT_CLIENT: {
      return {
        ...state,
        selectedClient: action.payload
      };
    }

    case ClientsActionTypes.SORT_CLIENTS: {
      return {
        ...state,
        sort: action.payload
      };
    }

    case ClientsActionTypes.LOAD_CLIENTS: {
      return {
        ...state,
        filter: action.payload.filter,
        sort: action.payload.sort,
        clients: [],
      };
    }

    case ClientsActionTypes.LOAD_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: action.payload,
        pagination: {
          limit: 50,
          skip: 50,
          hasMoreItems: action.payload?.length >= 50,
        },
      };
    }

    case ClientsActionTypes.LOAD_MORE_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: [...state.clients, ...action.payload],
        pagination: {
          ...state.pagination,
          skip: state.pagination.skip + state.pagination.limit,
          hasMoreItems: action.payload?.length >= state?.pagination?.limit,
        },
      };
    }

    case ClientsActionTypes.UPDATE_CLIENT_SUCCESS: {
      const oldSelectedClient = state.selectedClient;
      const clients = state.clients;

      const clientIndex = clients.findIndex(c => c.id === state.selectedClient.id);

      if (clientIndex >= 0) {
        clients[clientIndex].storeContactName = action.payload.storeContactName;
        clients[clientIndex].city = action.payload.city;
      }

      return {
        ...state,
        selectedClient: {
          ...oldSelectedClient,
          address1: action.payload.address1,
          address2: action.payload.address2,
          address3: action.payload.address3,
          postalCode: action.payload.postalCode,
          city: action.payload.city,
          country: action.payload.country,
          phone: action.payload.phone,
          contactEmail: action.payload.contactEmail,
          storeContactName: action.payload.storeContactName
        },
        clients
      };
    }

    default: {
      return state;
    }
  }
}
