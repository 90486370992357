import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { AppBackNavigation } from '../../../store/main.actions';

@Component({
  selector: 'app-back-navigation',
  templateUrl: './back-navigation.component.html',
  styleUrls: ['./back-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackNavigationComponent extends TcSmartComponent {

  constructor(
    private readonly store: Store<any>
  ) {
    super();
  }

  onClickBack() {
    this.store.dispatch(new AppBackNavigation());
  }

}
