import { ClientsService } from './../business-services/clients.service';
import { ArticleModel } from './../../modules/articles/models/article.model';
import { DefaultDataServiceFactory, DefaultDataService, HttpUrlGenerator, DefaultDataServiceConfig } from 'ngrx-data';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TcListDataAdapter, TC_LIST_DEFAULT_NB_LINES } from '@tc/core';
import { AppEntity } from '../../modules/main/enums/app-entity.enum';
import { ArticlesService } from '../business-services/articles.service';
import { ClientModel } from '../../modules/clients/models/client.model';

@Injectable({
  providedIn: 'root'
})
export class QlDefaultDataServiceFactory extends DefaultDataServiceFactory {

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private articlesService: ArticlesService,
    @Inject(TC_LIST_DEFAULT_NB_LINES) private tcListDefaultNbLines: number,
    config?: DefaultDataServiceConfig
  ) {
    super(http, httpUrlGenerator, config);
  }

  create<T>(entityName: string): DefaultDataService<T> {

    // if (entityName === AppEntity.Article) {

    //   const adapter = new TcListDataAdapter<ArticleModel>(entityName, this.articlesService, this.http, this.httpUrlGenerator, this.tcListDefaultNbLines, this.config);
    //   return adapter as any as DefaultDataService<T>;

    // }

    return new DefaultDataService<T>(entityName, this.http, this.httpUrlGenerator, this.config);
  }
}


