<div class="client-detail-page">
    <tc-layout [fixedInViewport]="true" [fixedTopGap]="0" [mode]="'side'" [showToolbarMenuIcon]="false" [opened]="true">

        <div class="header-content" tc-layout-toolbar>
            <app-page-title>
            </app-page-title>
            <div class="header-right">
                <app-user-info></app-user-info>
                <app-page-menu></app-page-menu>
            </div>
        </div>

        <div tc-layout-sidenav>
            <app-back-navigation></app-back-navigation>
            <img class="image" src="assets/icons/line_colors.png" width="170px">
        </div>

        <div tc-layout-sidenav-content>
            <div class="scrollable">
                <app-client-detail></app-client-detail>
            </div>
        </div>

    </tc-layout>
</div>