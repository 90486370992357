import { HIDE_SPINNER, SHOW_SPINNER, TcAction } from '@tc/core';

import { UserListModel } from '../models/user-list.model';

export enum UsersActionTypes {
  LOAD_USERS = '[Users] Load Users',
  LOAD_USERS_SUCCESS = '[Users] Load Users Success',
  EDIT_USER = '[Users] Edit User',
  UPDATE_USER = '[Users] Update User',
  UPDATE_USER_SUCCESS = '[Users] Update User Success',
  INIT_USERS = '[Users] Init Users',
  ADD_NEW_USER = '[Users] Add New User',
  ADD_NEW_USER_SUCCESS = '[Users] Add New User Success',
  SAVE_NEW_USER = '[Users] Save New User',
  DELETE_USER = '[Users] Delete User',
  DEETE_USER_SUCCESS = '[Users] Delete User Success',
}

export class InitUsers implements TcAction {
  readonly type = UsersActionTypes.INIT_USERS;
  constructor(public payload: {}) { }
}

@SHOW_SPINNER()
export class LoadUsers implements TcAction {
  readonly type = UsersActionTypes.LOAD_USERS;
  constructor() { }
}

@HIDE_SPINNER()
export class LoadUsersSuccess implements TcAction {
  readonly type = UsersActionTypes.LOAD_USERS_SUCCESS;
  constructor(public payload: UserListModel[]) { }
}

export class EditUser implements TcAction {
  readonly type = UsersActionTypes.EDIT_USER;
  constructor(public payload: UserListModel) { }
}

export class UpdateUser implements TcAction {
  readonly type = UsersActionTypes.UPDATE_USER;
  constructor(public payload: UserListModel) { }
}

export class UpdateUserSuccess implements TcAction {
  readonly type = UsersActionTypes.UPDATE_USER_SUCCESS;
  constructor(public payload: UserListModel) { }
}

export class CreateUser implements TcAction {
  readonly type = UsersActionTypes.EDIT_USER;
  constructor(public payload: UserListModel) { }
}

export class AddNewUser implements TcAction {
  readonly type = UsersActionTypes.ADD_NEW_USER;
}

export class AddNewUserSuccess implements TcAction {
  readonly type = UsersActionTypes.ADD_NEW_USER_SUCCESS;
  constructor(public payload: UserListModel) { }
}

export class SaveNewUser implements TcAction {
  readonly type = UsersActionTypes.SAVE_NEW_USER;
  constructor(public payload: UserListModel) { }
}

export class DeleteUser implements TcAction {
  readonly type = UsersActionTypes.DELETE_USER;
  constructor(public payload: UserListModel) { }
}

export class DeleteUserSuccess implements TcAction {
  readonly type = UsersActionTypes.DEETE_USER_SUCCESS;
  constructor(public payload: UserListModel) { }
}

export type UsersActions =
  | InitUsers
  | LoadUsers
  | LoadUsersSuccess
  | EditUser
  | UpdateUser
  | UpdateUserSuccess
  | AddNewUser
  | SaveNewUser
  | AddNewUserSuccess
  | DeleteUser
  | DeleteUserSuccess;
