<div class="container">
    <div class="container__confirm">
        <mat-slide-toggle
            [checked]="confirmed"
            (change)="confirmed = $event.checked"
        >
            {{'client-detail.confirmation' | translate}}
        </mat-slide-toggle>
        <button
            class="darkblue button"
            mat-raised-button
            [disabled]="!confirmed"
            [class.disabled]="!confirmed"
            (click)="openCatalog()"
        >
            {{'client-detail.catalog' | translate}}
        </button>
    </div>
    <div class="first">
        <div class="left">
            <app-client-detail-info
                [client]="(selectedClient$ | async)"
                [user]="(authenticatedUser$ | async)"
            ></app-client-detail-info>
        </div>
        <div class="right">
            <app-client-legal-and-tarif [client]="(selectedClient$ | async)"></app-client-legal-and-tarif>
            <app-client-contact [client]="(selectedClient$ | async)"></app-client-contact>
        </div>
    </div>

    <div class="second">
        <app-client-dashboard [client]="(selectedClient$ | async)"></app-client-dashboard>
    </div>
    <div>
        <div class="title">
            <H1>HISTORIQUE</H1>
        </div>
        <div class="list">
            <div>
                <app-client-invoice-list></app-client-invoice-list>
            </div>
            <!-- <div>
                <app-client-action-list></app-client-action-list>
            </div> -->
        </div>
    </div>
</div>
