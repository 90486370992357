import { StatisticsTypes } from '@maxel-order/shared';
import { TcAction } from '@tc/core';

export enum StatisticsActionTypes {
  SELECT_STATISTICS_TYPE = '[Statistics] Select statistics type',
  SAVE_STATISTICS_ITEMS = '[Statistics] Save statistics items',
}

export class SelectStatisticsType implements TcAction {
  readonly type = StatisticsActionTypes.SELECT_STATISTICS_TYPE;
  constructor(public payload: StatisticsTypes) { }
}

export class SaveStatisticsItems implements TcAction {
  readonly type = StatisticsActionTypes.SAVE_STATISTICS_ITEMS;
  constructor(public payload: any[]) { }
}

export type StatisticsActions =
  SelectStatisticsType
  | SaveStatisticsItems;