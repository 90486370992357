import moment from 'moment';

export class ClientTurnoverModel {
    companyId: string;
    lastYear: number;
    lastYearSameDayAsPresent: moment.Moment
    presentDay: moment.Moment;
    lastYearTurnover: number;
    lastYearSameDayTurnover: number;
    thisYearTurnover: number;
    validGrowth: boolean;
    growth: number;
  }
