import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TcSmartComponent, TcGenericFormComponent, TcTranslateService, TcFormComponent } from '@tc/core';
import { Store } from '@ngrx/store';
import { Login } from '../../../store/auth.actions';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends TcSmartComponent {

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    private store: Store<any>) {
    super();
  }


  submit(): void {
    if (this.form.valid) {
      this.store.dispatch(new Login({ username: this.form.value.username, password: this.form.value.password }));
    }
  }

}
