import { ArticleOrderHistoryModel } from '../models/article-order-history.model';
import { ClientsActions } from './../../clients/store/clients.actions';
import { ArticlesActions, ArticlesActionTypes } from './articles.actions';
import { InitArticlesPayload, InitArticleSuccessPayload, InitCategoriesSuccessPayload, LoadArticlePayload, LoadArticleSuccessPayload } from './articles.interfaces';
import { ArticlesState, initialArticlesState } from './articles.state';

export function articlesReducer(
  state = initialArticlesState,
  action: ArticlesActions | ClientsActions
): ArticlesState {

  switch (action.type) {

    case ArticlesActionTypes.INIT_ARTICLES: {
      return initArticles(state, action.payload);
    }

    case ArticlesActionTypes.INIT_ARTICLES_SUCCESS: {
      return initArticlesSuccess(state, action.payload);
    }

    case ArticlesActionTypes.LOAD_ARTICLES: {
      return loadArticles(state, action.payload);
    }

    case ArticlesActionTypes.LOAD_ARTICLES_SUCCESS: {
      return loadArticlesSuccess(state, action.payload);
    }

    case ArticlesActionTypes.INIT_CATEGORIES_SUCCESS: {
      return initCategoriesSuccess(state, action.payload);
    }

    case ArticlesActionTypes.LOAD_ARTICLE_ORDER_HISTORY_SUCCESS: {
      return loadArticleOrderHistorySuccess(state, action.payload);
    }

    case ArticlesActionTypes.EXTEND_CATEGORY: {
      return {
        ...state,
        categories: state.categories.map(category => ({
          ...category,
          extended: action.payload === category.key ? !category.extended : category.extended
        })),
      }
    }

    case ArticlesActionTypes.UPDATE_USED_CATEGORIES_SUCCESS: {
      const body = {};

      for (const item of (action.payload || [])) {
        body[item] = true;
      }

      return {
        ...state,
        usedFamilies: { ...state.usedFamilies, ...body },
      };
    }

    case ArticlesActionTypes.RESET_USED_CATEGORIES: {
      return {
        ...state,
        usedFamilies: {},
      };
    }

    default: {
      return state;
    }
  }
}

function loadArticles(state: ArticlesState, payload: LoadArticlePayload): ArticlesState {
  return {
    ...state,
    filter: payload.filter,
    pagination: payload.pagination,
    articles: payload.loadMore ? state.articles : [],
  };
}

function loadArticlesSuccess(state: ArticlesState, payload: LoadArticleSuccessPayload): ArticlesState {
  return {
    ...state,
    articles: payload.loadMore ? [...state.articles, ...payload.articles] : payload.articles,
    canLoadMore: payload.canLoadMore,
    pagination: payload.pagination || state.pagination
  };
}

function initArticlesSuccess(state: ArticlesState, payload: InitArticleSuccessPayload): ArticlesState {
  return {
    ...state,
    all: payload.articles,
  };
}

function initArticles(state: ArticlesState, payload: InitArticlesPayload): ArticlesState {
  return {
    ...state,
    all: [],
    filter: null,
    articles: [],
    categories: [],
    clientId: payload.clientId,
    clientPrices: payload.clientPrices,
    clientArticleHistory: payload.clientArticleHistory
  };
}

function initCategoriesSuccess(state: ArticlesState, payload: InitCategoriesSuccessPayload): ArticlesState {
  return {
    ...state,
    categories: payload.categories,
  };
}

function loadArticleOrderHistorySuccess(state: ArticlesState, payload: ArticleOrderHistoryModel[]): ArticlesState {
  return {
    ...state,
    articleOrderHistoryList: payload
  };
}