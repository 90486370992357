export type InvoiceProperty = | 'id' | 'number' | 'clientId' | 'date' | 'asset' | 'status' | 'totalExcludingTax' | 'currency' | 'dueDate' ;

export const InvoiceProperty = {
  id: 'id' as InvoiceProperty,
  number: 'number' as InvoiceProperty,
  clientId: 'clientId' as InvoiceProperty,
  date: 'date' as InvoiceProperty,
  asset: 'asset' as InvoiceProperty,
  status: 'status' as InvoiceProperty,
  totalExcludingTax: 'totalExcludingTax' as InvoiceProperty,
  currency: 'currency' as InvoiceProperty,
  dueDate: 'dueDate' as InvoiceProperty,
}
