import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private storageKey = 'MO_DEVICE_ID';

  public get() {
    let id = this.fetch();
    if (!id) {
      id = this.generate();
      this.save(id);
    }

    return id;
  }

  private fetch() {
    return localStorage.getItem(this.storageKey);
  }

  private generate() {
    return window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
  }

  private save(id: string) {
    localStorage.setItem(this.storageKey, id);
  }
}
