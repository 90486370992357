import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ShowSpinner, TcSmartComponent } from '@tc/core';
import { Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { ResetUsedCategories } from '../../../../articles/store/articles.actions';
import { UserModel } from '../../../../auth/models/user.model';
import { getAuthenticatedUser } from '../../../../auth/store/auth.selectors';
import { ClientModel } from '../../../models/client.model';
import { SelectClient } from '../../../store/clients.actions';
import { getSelectedClient } from '../../../store/clients.selectors';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent extends TcSmartComponent implements OnInit {

  confirmed = false;

  authenticatedUser$: Observable<UserModel>;
  selectedClient$: Observable<ClientModel>;

  constructor(
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit(): void {
    this.authenticatedUser$ = this.store.pipe(select(getAuthenticatedUser));
    this.selectedClient$ = this.store.pipe(select(getSelectedClient));
  }

  async openCatalog() {
    const client = await this.getSelectedClient();

    this.store.dispatch(new SelectClient(client));
    this.store.dispatch(new ResetUsedCategories());
  }

  private getSelectedClient() {
    return this.selectedClient$.pipe(take(1)).toPromise();
  }
}
