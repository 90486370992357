export type ClientRequestProperty = | 'id' | 'salesmanId' | 'siren' | 'companyName' | 'storeContactName' | 'email1' | 'phone' | 'paymentType' | 'invoiceAddress' | 'deliveryAddress' | 'deliveryAddressSameAsInvoiceAddress' | 'rib' | 'requestDate' | 'emailSentDate' ;

export const ClientRequestProperty = {
  id: 'id' as ClientRequestProperty,
  salesmanId: 'salesmanId' as ClientRequestProperty,
  siren: 'siren' as ClientRequestProperty,
  companyName: 'companyName' as ClientRequestProperty,
  storeContactName: 'storeContactName' as ClientRequestProperty,
  email1: 'email1' as ClientRequestProperty,
  phone: 'phone' as ClientRequestProperty,
  paymentType: 'paymentType' as ClientRequestProperty,
  invoiceAddress: 'invoiceAddress' as ClientRequestProperty,
  deliveryAddress: 'deliveryAddress' as ClientRequestProperty,
  deliveryAddressSameAsInvoiceAddress: 'deliveryAddressSameAsInvoiceAddress' as ClientRequestProperty,
  rib: 'rib' as ClientRequestProperty,
  requestDate: 'requestDate' as ClientRequestProperty,
  emailSentDate: 'emailSentDate' as ClientRequestProperty,
}
