import { TcService } from '@tc/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService extends TcService {

  constructor() {
    super();
  }

  public getMapUrlByAddresse(city: string, address: string, postalcode: string): string {
    const baseUrl = "https://www.google.com/maps/dir/?api=1&origin=Current%20Location";
    const fullAddress = new Array(city, address, postalcode);
    const url = baseUrl + "&destination=" + fullAddress.join("+");
    return url;
  }

  public getMapUrlByLatitudeAndLongitude(latitude: number, longitude: number): string {
    const baseUrl = "https://www.google.com/maps/dir/?api=1&origin=Current%20Location";
    const fullAddress = new Array(latitude, longitude);
    const url = baseUrl + "&destination=" + fullAddress.join("+");
    return url;
  }

}
