import { Injectable } from '@angular/core';
import { Address, ClientRequest, NotificationType } from '@maxel-order/shared';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ClientRequestModel } from '../../modules/clients/models/client-request.model';
import { ClientRequestsDAO } from '../dao/client-requests.dao';
import { NotificationsService } from '../notifications/notifications.service';
import { VrpProviderService } from './vrp-provider.service';

@Injectable({
    providedIn: 'root'
})
export class ClientsRequestService {

    constructor(
        private clientRequestsDAO: ClientRequestsDAO,
        private readonly notificationsService: NotificationsService,
        private readonly vrpProviderService: VrpProviderService,
    ) { }

    public async createClientRequest(clientRequestModel: ClientRequestModel, currentUser: string) {

        if (clientRequestModel.sameAddress) {
            clientRequestModel.deliveryAddress1 = clientRequestModel.invoiceAddress1;
            clientRequestModel.deliveryAddress2 = clientRequestModel.invoiceAddress2;
            clientRequestModel.deliveryAddress3 = clientRequestModel.invoiceAddress3;
            clientRequestModel.deliveryPostalCode = clientRequestModel.invoicePostalCode;
            clientRequestModel.deliveryCity = clientRequestModel.invoiceCity;
            clientRequestModel.deliveryCountry = clientRequestModel.deliveryCountry;
        }
        const invoiceAddressObject: Address = {
            id: null,
            companyName: clientRequestModel.companyName,
            address1: clientRequestModel.invoiceAddress1,
            address2: clientRequestModel.invoiceAddress2,
            address3: clientRequestModel.invoiceAddress3,
            zipCode: clientRequestModel.invoicePostalCode,
            city: clientRequestModel.invoiceCity,
            country: clientRequestModel.invoiceCountry,
        };
        const deliveryAddressObject: Address = {
            id: null,
            companyName: clientRequestModel.companyName,
            address1: clientRequestModel.deliveryAddress1,
            address2: clientRequestModel.deliveryAddress2,
            address3: clientRequestModel.deliveryAddress3,
            zipCode: clientRequestModel.deliveryPostalCode,
            city: clientRequestModel.deliveryCity,
            country: clientRequestModel.deliveryCountry,
        };

        const clientRequest: ClientRequest = {
            id: uuidv4(),
            salesmanId: currentUser,
            siren: clientRequestModel.siren,
            companyName: clientRequestModel.companyName,
            storeContactName: clientRequestModel.companyName,
            email1: clientRequestModel.email,
            phone: clientRequestModel.phone,
            paymentType: clientRequestModel.paymentType,
            invoiceAddress: invoiceAddressObject,
            deliveryAddress: deliveryAddressObject,
            deliveryAddressSameAsInvoiceAddress: clientRequestModel.sameAddress,
            rib: clientRequestModel.rib,
            requestDate: moment.utc().toISOString(),
            emailSentDate: null,
        };

        await this.clientRequestsDAO.create(clientRequest);

        await this.notificationsService.send(NotificationType.ClientCreated, {
            clientId: clientRequest.id,
            vrp: await this.vrpProviderService.getCurrentCodeVrp(),
        });
    }

}
