export type OrderRequestProperty = | 'id' | 'number' | 'codeVrp' | 'clientId' | 'date' | 'status' | 'totalExcludingTax' | 'requestedDeliveryDate' | 'comment' | 'salesmanId' | 'exportDate' | 'devisDate' | 'summaryRequested' | 'devisRequested' | 'internalOrder' | 'hash' | 'isRemoved' ;

export const OrderRequestProperty = {
  id: 'id' as OrderRequestProperty,
  number: 'number' as OrderRequestProperty,
  codeVrp: 'codeVrp' as OrderRequestProperty,
  clientId: 'clientId' as OrderRequestProperty,
  date: 'date' as OrderRequestProperty,
  status: 'status' as OrderRequestProperty,
  totalExcludingTax: 'totalExcludingTax' as OrderRequestProperty,
  requestedDeliveryDate: 'requestedDeliveryDate' as OrderRequestProperty,
  comment: 'comment' as OrderRequestProperty,
  salesmanId: 'salesmanId' as OrderRequestProperty,
  exportDate: 'exportDate' as OrderRequestProperty,
  devisDate: 'devisDate' as OrderRequestProperty,
  summaryRequested: 'summaryRequested' as OrderRequestProperty,
  devisRequested: 'devisRequested' as OrderRequestProperty,
  internalOrder: 'internalOrder' as OrderRequestProperty,
  hash: 'hash' as OrderRequestProperty,
  isRemoved: 'isRemoved' as OrderRequestProperty,
}
