import { Injectable } from '@angular/core';
import { MD5Service, User, UserEntityType } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { take } from 'rxjs/operators';
import { getAuthenticatedUser } from '../../modules/auth/store/auth.selectors';
import { UserListModel } from '../../modules/users/models/user-list.model';
import { UsersDAO } from '../dao';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private readonly usersDAO: UsersDAO,
    private readonly store$: Store<TcAppState>,
  ) { }

  public async getUsers(): Promise<UserListModel[]> {
    const users = await this.usersDAO.getAll();
    return users.map(user => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      tabletCode: user['tabletCode'],
      password: user.password,
      email: user.email,
      codeVrp: user.codeVrp,
      login: user.login,
      profil: user.profil,
      accessStatistique: user.accessStatistique
    }));
  };

  async updateUser(userListModel: UserListModel): Promise<UserListModel> {
    let user: any = {};
    if (userListModel.id) {
      //case update existed user
      user = await this.usersDAO.get(userListModel.id);
      if (user) {
        user.firstName = userListModel.firstName;
        user.lastName = userListModel.lastName;
        user.tabletCode = userListModel.tabletCode;
        if (user.password !== userListModel.password) {
          user.password = new MD5Service().encodePassword(userListModel.password);
        }
        user.codeVrp = userListModel.codeVrp;
        user.email = userListModel.email;
        user.login = userListModel.login.toUpperCase();
        user.profil = userListModel.profil;
        user.accessStatistique = userListModel.accessStatistique;
        userListModel.password = user.password;
        userListModel.profil = user.profil;

        await this.usersDAO.update(user);
      }
    } else {
      //case create new user
      user.id = Math.random().toString(36).substring(4);
      user._id = UserEntityType.className + ':' + user.id;
      user.firstName = userListModel.firstName;
      user.lastName = userListModel.lastName;
      user.tabletCode = userListModel.tabletCode;
      user.password = new MD5Service().encodePassword(userListModel.password);
      user.email = userListModel.email;
      user.codeVrp = userListModel.codeVrp;
      user.login = userListModel.login.toUpperCase();
      user.profil = userListModel.profil;
      user.accessStatistique = userListModel.accessStatistique;
      userListModel.id = user.id;
      userListModel.password = user.password;

      await this.usersDAO.create(user);
    }

    return userListModel;
  }

  async deleteUser(id: string) {
    const user = await this.usersDAO.get(id);
    if (!user) {
      return;
    }

    await this.usersDAO.delete(user);
  }

  public async getCurrentUser(): Promise<User> {
    return await this.store$.select(getAuthenticatedUser).pipe(take(1)).toPromise() as User;
  }

  public async getCurrentUserId(): Promise<string> {
    const user = await this.getCurrentUser();

    return user?.id;
  }
}
