<div class="article-list-page">
    <tc-layout [fixedInViewport]="true" [fixedTopGap]="0" [mode]="'side'" [showToolbarMenuIcon]="false" [opened]="true">

        <div class="header-content" tc-layout-toolbar>
            <div class="header-left">
                <app-client-logo [logo]="(selectedClient$ | async)?.logo"></app-client-logo>
                <app-custom-client-title></app-custom-client-title>
            </div>
            <div class="header-right">
                <app-total-price-basket></app-total-price-basket>
                <app-basket></app-basket>
                <app-user-info></app-user-info>
                <app-page-menu></app-page-menu>
            </div>
        </div>

        <div tc-layout-sidenav>

            <app-back-navigation></app-back-navigation>

            <div class="filter-search">
                <!-- <tc-generic-filter #articleListFilter>
                </tc-generic-filter> -->
                <app-article-filter-search></app-article-filter-search>
            </div>

            <img src="assets/icons/line_colors.png" width="170px">

            <app-article-filter (selectNewFilter)="scrollToTop()"></app-article-filter>

        </div>

        <div tc-layout-sidenav-content>

            <app-article-list></app-article-list>
        </div>

    </tc-layout>
</div>