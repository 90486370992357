export type OrderProperty = | 'id' | 'number' | 'codeVrp' | 'clientId' | 'date' | 'status' | 'totalExcludingTax' | 'requestedDeliveryDate' | 'comment' ;

export const OrderProperty = {
  id: 'id' as OrderProperty,
  number: 'number' as OrderProperty,
  codeVrp: 'codeVrp' as OrderProperty,
  clientId: 'clientId' as OrderProperty,
  date: 'date' as OrderProperty,
  status: 'status' as OrderProperty,
  totalExcludingTax: 'totalExcludingTax' as OrderProperty,
  requestedDeliveryDate: 'requestedDeliveryDate' as OrderProperty,
  comment: 'comment' as OrderProperty,
}
