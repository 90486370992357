export class UpdateClientModel {
  id: string;
  address1?: string;
  address2?: string;
  address3?: string;
  postalCode?: string;
  city: string;
  country?: string;
  phone: string;
  contactEmail?: string;
  storeContactName?: string;
}
