import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';

import { OrderDetailComponent } from './components/smart/order-detail/order-detail.component';
import { OrderFilterComponent } from './components/smart/order-filter/order-filter.component';
import { OrderListComponent } from './components/smart/order-list/order-list.component';
import { OrderSummaryComponent } from './components/smart/order-summary/order-summary.component';
import { OrdersListQueryService } from './services/orders-list-query.service';
import { OrdersListService } from './services/orders-list.service';
import { OrdersEffects } from './store/orders.effects';
import { ordersReducer } from './store/orders.reducer';

const components = [
  OrderListComponent,
  OrderFilterComponent,
  OrderSummaryComponent,
  OrderDetailComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([OrdersEffects]),
    StoreModule.forFeature('orders', ordersReducer)
  ],
  providers: [
    OrdersListService,
    OrdersListQueryService,
  ],
  exports: [...components],
  entryComponents: [OrderSummaryComponent]
})
export class OrdersModule { }
