<div class="filter_container scroll--hidden">
    <div
        *ngFor="let category of (filters$ | async);trackBy: trackByCategory"
        class="filter_category"
    >
        <img
            class="filter_category__company"
            [src]="'assets/icons/'+category.key+'.jpg'"
            (click)="extend(category.key)"
        >

        <ng-container *ngIf="category.extended">
            <div
                *ngFor="let family of category.items; trackBy: trackBy"
                class="filter_category__item"
                [class.selected]="family.id === selectedFamilyId"
                (click)="selectFilter(family)"
            >
                <img
                    *ngIf="!usedFamilies[family.id]"
                    src="assets/icons/help.png"
                >
                {{family.label}}
            </div>

        </ng-container>
    </div>
</div>
