import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ArticlesState } from './articles.state';

export const getArticlesState = createFeatureSelector<ArticlesState>(
  'articles'
);

export const getVisibleArticles = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.articles
);

export const getAllArticles = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.all
);

export const getArticleOrderHistoryList = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.articleOrderHistoryList
);

export const getArticleListFilter = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.filter
);

export const getArticleListPagination = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.pagination
);

export const getArticleCategories = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.categories
);

export const getClientPrices = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.clientPrices
);

export const getClientArticleHistory = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.clientArticleHistory
);

export const getUsedFamilies = createSelector(
  getArticlesState,
  (state: ArticlesState) => state.usedFamilies
);

