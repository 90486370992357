import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { StatisticsFiltersComponent } from './components/statistics-filters/statistics-filters.component';
import { StatisticsTotalComponent } from './components/statistics-total/statistics-total.component';
import { StatisticsTypeSelectorComponent } from './components/statistics-type-selector/statistics-type-selector.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { StatisticsFiltersService } from './services/statistics-filters.service';
import { StatisticsService } from './services/statistics.service';
import { statisticsReducer } from './store/statistics.reducer';

const components = [
  StatisticsFiltersComponent,
  StatisticsTypeSelectorComponent,
  StatisticsComponent,
  StatisticsTotalComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    StoreModule.forFeature('statistics', statisticsReducer),
  ],
  providers: [
    StatisticsService,
    StatisticsFiltersService,
    DecimalPipe,
  ],
  exports: [...components],
})
export class StatisticsModule { }
