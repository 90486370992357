import { Injectable } from '@angular/core';
import { ArticleEntityType, CompanyEntityType, FamilyEntityType, IdProvider, PriceEntityType, UserEntityType } from '@maxel-order/shared';
import { VrpProviderService } from '../business-services/vrp-provider.service';

@Injectable({
  providedIn: 'root'
})
export class IdProviderService implements IdProvider {

  constructor(
    private readonly vrpProviderService: VrpProviderService,
  ) { }

  private readonly commonPartitions = [
    ArticleEntityType.className,
    CompanyEntityType.className,
    FamilyEntityType.className,
    PriceEntityType.className,
    UserEntityType.className,
  ];

  public async get(entity: string, id: string) {
    const partition = await this.getPartition(entity);

    return `${partition}:${id}`;
  }

  private async getPartition(entity: string) {
    if (this.commonPartitions.indexOf(entity) !== -1) {
      return entity;
    }

    const userVrp = await this.vrpProviderService.getCurrentCodeVrp();
    return `${userVrp}_${entity}`;
  }
}
