import { Injectable } from '@angular/core';
import { Article, Price } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { getCurrentUserVrp } from '../../modules/auth/store/auth.selectors';
import { ClientAllVrp } from '../../modules/clients/enums/client-status.enum';
import { getSelectedClient } from '../../modules/clients/store/clients.selectors';
import { CompaniesDAO } from '../dao';
import { ArticlesDAO } from '../dao/articles.dao';
import { FamiliesDAO } from '../dao/families.dao';
import { PricesDAO } from '../dao/prices.dao';
import { ArticleCategoryModel } from './../../modules/articles/models/article-category.model';
import { ArticleModel } from './../../modules/articles/models/article.model';
import { ArticleOrderHistoryService } from './article-order-history.service';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  constructor(
    private readonly store$: Store<any>,
    private readonly companiesDAO: CompaniesDAO,
    private readonly articlesDAO: ArticlesDAO,
    private readonly familiesDAO: FamiliesDAO,
    private readonly pricesDAO: PricesDAO,
    private readonly articleOrderHistoryService: ArticleOrderHistoryService,
  ) { }

  public getById(id: string) {
    return this.articlesDAO.get(id);
  }

  public async parseArticles() {
    const clientPrices = await this.getClientPrices();
    if (!clientPrices.length) {
      return { articles: [] };
    }

    const allArticles = await this.articlesDAO.search({});
    const prices = await this.getPrices(clientPrices);

    const priceByArticleId = new Map<string, Price>();
    for (const price of prices) {
      priceByArticleId.set(price.articleId, price);
    }

    const companyNameByIdMap = await this.companiesDAO.getCompanyNameByIdMap();

    const validArticles = allArticles
      .filter(article => priceByArticleId.has(article.id))
      .map(article => this.toArticleModel(article, priceByArticleId.get(article.id), companyNameByIdMap))
      .sort((a, b) => {
        // Sort by catalogId + name
        return a.catalogId !== b.catalogId
          ? a.catalogId > b.catalogId ? 1 : -1
          : a.name > b.name ? 1 : -1
      });

    return { articles: validArticles };
  }

  private async getClientPrices() {
    const client = await this.store$.select(getSelectedClient).pipe(take(1)).toPromise();

    if (client.id === 'PROSPECT') { // PROSPECT client has special price code
      const result = (await this.companiesDAO.getAll()).map(company=> {
        return {companyId : company.id, code: company.defaultPriceCode}
      });
      console.log(result);
      return result;
    }

    const clientPrices = [...client['generalPrice'], ...client['specialPrice']];
    return clientPrices;
  }


  private async getPrices(clientPrices: any[]) {
    const prices = await this.pricesDAO.search({
      filter: {
        '$or': clientPrices.map(clientPrice => ({ code: `${clientPrice.companyId}_${clientPrice.code}` })),
      }
    });

    const now = moment.utc();
    const actual = prices.filter(price => {
      const { startDate, endDate } = price;

      if (!startDate && !endDate) { // no dates
        return true;
      }

      const start = moment.utc(price.startDate);
      const end = moment.utc(price.endDate);

      if (startDate && !endDate) { // no end date
        return now > start;
      }

      if (!startDate && endDate) { // no start date
        return now < end;
      }

      // has start & end date
      return start < now && now < end;
    });

    return actual;
  }

  public async parseCategories(articles: ArticleModel[]): Promise<ArticleCategoryModel[]> {
    const categories: ArticleCategoryModel[] = [];

    const companies = await this.companiesDAO.getAll();
    const familiesByCompany = await this.getFamiliesByCompanyId();
    const familiesFromArticles = this.getFamiliesFromArticles(articles);

    for (const [companyId, families] of familiesByCompany) {
      const company = companies.find(({ id }) => id === companyId);

      const category = {
        extended: true,
        key: (company?.name || '').toLowerCase(),
        items: families
          .filter(family => familiesFromArticles.has(family.id))
          .sort((a, b) => (a.label > b.label) ? 1 : -1),
      };
      categories.unshift(category);
    }

    return categories.filter(category => !!category.key && category.items.length);
  }

  public async filterArticles(articles: ArticleModel[], clientArticleHistory: string[], payload) {
    let response = [...articles];
    const { filter, pagination } = payload;

    if (filter && filter.familyId) {
      response = response.filter(article => article.familyId === filter.familyId);
    }

    if (filter && filter.anyFieldContains) {
      const searchKeys = ['description1', 'name', 'id'];
      response = response.filter(article => {
        return searchKeys.some(key => article[key] && article[key].match(new RegExp(filter.anyFieldContains, 'i')));
      });
    }

    if (pagination.after) {
      response = response.slice(pagination.after);
    }

    if (pagination.first) {
      response = response.slice(0, pagination.first);
    }

    const currentVrp = await this.store$.select(getCurrentUserVrp).pipe(take(1)).toPromise();
    if(currentVrp.toLowerCase() === ClientAllVrp)
    {    
      clientArticleHistory = await this.articleOrderHistoryService.getArticlesWithHistory(response.map(a => a.id));
    }
    response = response.map(article => {
      return clientArticleHistory.includes(article.id)
        ? { ...article, hasHistory: true }
        : article;
    });

    return response;
  }

  private async getFamiliesByCompanyId() {
    const familiesByCompany = new Map<string, any[]>();

    const dbFamilies = await this.familiesDAO.getAll();

    for (const family of dbFamilies) {
      if (!familiesByCompany.has(family.companyId)) {
        familiesByCompany.set(family.companyId, []);
      }
      familiesByCompany.set(family.companyId, [...familiesByCompany.get(family.companyId), family]);
    }

    return familiesByCompany;
  }

  private getFamiliesFromArticles(articles: ArticleModel[]) {
    const familiesFromArticles = new Set<string>();

    for (const article of articles) {
      familiesFromArticles.add(article.familyId);
    }

    return familiesFromArticles;
  }


  private toArticleModel(article: Article, price: Price, companyNameByIdMap: Map<string, string>): ArticleModel {
    return {
      id: article.id,
      name: article.description1,
      itemsInBax: article.unitsPerUnderPackage,
      stock: article.leftForSaleStock,
      code: article.id,
      image: article.picture1,
      companyId: article.companyId,
      resuplyDate: moment.utc(article.resuplyDate).toDate(),
      arrival: article.arrival,
      new: article.new,
      company: companyNameByIdMap.get(article.companyId),
      familyId: article.familyId,
      catalogId: article.catalogId,
      description1: article.description1,
      price: price.unitPrice,
      pvp: price.rrp,
    } as ArticleModel;
  }
}
