import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcConfirmDialogComponent, TcNotificationService } from '@tc/core';
import { map, mergeMap } from 'rxjs/operators';

import { UsersService } from '../../../services/business-services/users.service';
import { UserDetailComponent } from '../components/smart/user-detail/user-detail.component';
import {
  AddNewUser,
  AddNewUserSuccess,
  DeleteUser,
  DeleteUserSuccess,
  EditUser,
  LoadUsers,
  LoadUsersSuccess,
  SaveNewUser,
  UpdateUser,
  UpdateUserSuccess,
  UsersActionTypes,
} from './users.actions';


@Injectable()
export class UsersEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<any>,
    private dialog: MatDialog,
    private usersService: UsersService,
    private notificationService: TcNotificationService,
    private translate: TranslateService,
  ) { }

  @Effect({ dispatch: false })
  loadUsers = this.actions$.pipe(
    ofType<LoadUsers>(
      UsersActionTypes.LOAD_USERS
    ),
    map((action) => {
      this.usersService.getUsers().then((users) => {
        this.store.dispatch(new LoadUsersSuccess(users));
      })
    })
  );

  @Effect({ dispatch: false })
  editUser = this.actions$.pipe(
    ofType<EditUser>(UsersActionTypes.EDIT_USER),
    map((action: EditUser) => {
      const dialog = this.dialog.open(UserDetailComponent, {
        width: '860px',
        data: action.payload
      });
      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.store.dispatch(new UpdateUser(data));
        }
      });
    })
  );

  @Effect()
  saveUser$ = this.actions$.pipe(
    ofType<UpdateUser>(UsersActionTypes.UPDATE_USER),
    mergeMap((action: UpdateUser) =>
      this.usersService.updateUser(action.payload).then((user) => {
        return new UpdateUserSuccess(user)
      })
    )
  );

  @Effect({ dispatch: false })
  addUser = this.actions$.pipe(
    ofType<AddNewUser>(UsersActionTypes.ADD_NEW_USER),
    map((action: AddNewUser) => {
      const dialog = this.dialog.open(UserDetailComponent, {
        width: '860px',
        data: {}
      });
      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.store.dispatch(new SaveNewUser(data));
        }
      });
    })
  );

  @Effect()
  createUser$ = this.actions$.pipe(
    ofType<SaveNewUser>(UsersActionTypes.SAVE_NEW_USER),
    mergeMap((action: SaveNewUser) =>
      this.usersService.updateUser(action.payload).then((user) => {
        return new AddNewUserSuccess(user)
      })
    )
  );

  @Effect({ dispatch: false })
  deleteUser = this.actions$.pipe(
    ofType<DeleteUser>(UsersActionTypes.DELETE_USER),
    map((action: DeleteUser) => {
      const submessage = this.translate.instant('user-list.dialog.delete.submessage');
      const message = this.translate.instant(
        'user-list.dialog.delete.message',
        { firstName: action.payload.firstName, lastName: action.payload.lastName },
      );


      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          message: `${message}. ${submessage}`,
          noText: 'user-list.dialog.delete.no',
          yesText: 'user-list.dialog.delete.yes'
        },
        panelClass: 'user-list-dialog-panel'
      });

      dialog.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.usersService.deleteUser(action.payload.id).then(() => {
            this.store.dispatch(new DeleteUserSuccess(action.payload));
          })
        }
      });
    })
  );

  @Effect({ dispatch: false })
  deleteUserSuccess = this.actions$.pipe(
    ofType<DeleteUserSuccess>(UsersActionTypes.DEETE_USER_SUCCESS),
    map((action: DeleteUserSuccess) => {
      const message = this.translate.instant(
        'user-list.deleted.success',
        { firstName: action.payload.firstName, lastName: action.payload.lastName }
      );

      this.notificationService.success(message);
    }),
  );

}
