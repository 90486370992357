import { Injectable } from '@angular/core';
import { Article, NotificationType, Order, OrderLine } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import * as moment from 'moment';
import * as objectHash from 'object-hash';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUserVrp } from '../../modules/auth/store/auth.selectors';
import { ClientAllVrp } from '../../modules/clients/enums/client-status.enum';
import { OrderStatus } from '../../modules/orders/enums/order-status.enum';
import { OrderArticle } from '../../modules/orders/models/order-article.model';
import { OrderLineModel } from '../../modules/orders/models/order-line.model';
import { OrderSummaryModel } from '../../modules/orders/models/order-summary.model';
import { OrderModel } from '../../modules/orders/models/order.model';
import { getOrder } from '../../modules/orders/store/orders.selectors';
import { ArticlesDAO, ClientsDAO, CompaniesDAO, OrdersDAO } from '../dao';
import { OrderLinesDAO } from '../dao/order-lines.dao';
import { GetResourcesService } from '../get-resources/get-resources.service';
import { NotificationsService } from '../notifications/notifications.service';
import { Criteria } from '../repository/repository.interface';
import { VrpProviderService } from './vrp-provider.service';
import { ClientTurnoverModel } from '../../modules/orders/models/client-turnover.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private readonly ordersDAO: OrdersDAO,
    private readonly orderLinesDAO: OrderLinesDAO,
    private readonly articlesDAO: ArticlesDAO,
    private readonly clientsDAO: ClientsDAO,
    private readonly companiesDAO: CompaniesDAO,
    private readonly store: Store<TcAppState>,
    private readonly getResourcesService: GetResourcesService,
    private readonly notificationsService: NotificationsService,
    private readonly vrpProviderService: VrpProviderService,
  ) { }

  async getOrders(): Promise<Order[]> {
    return this.ordersDAO.getAll();
  }

  async getOrderSummary(orderId: string): Promise<OrderSummaryModel> {

    const query: Criteria = {
      filter: {
        'id': { $eq: orderId },
      }
    };

    const orders: Order[] = await this.ordersDAO.search(query);

    if (orders.length === 0) {
      return new Promise<OrderSummaryModel>((resolve, reject) => {
        resolve(null)
      });
    }

    const orderLinesModel: OrderArticle[] = [];

    // TODO must be only one error for more
    const order = orders[0];

    const filterClient: Criteria = {
      filter: { 'id': { $eq: order.clientId } }
    };

    const clients = await this.clientsDAO.search(filterClient);

    const orderSummaryModel = {} as OrderSummaryModel;
    orderSummaryModel.date = moment.utc(order.date).toDate();
    orderSummaryModel.client = clients.length > 0 ? clients[0].companyName : '';

    const orderLinesList = await this.getOrderLinesByOrderId(order);

    for (const orderLine of orderLinesList) {
      const orderLineModel = await this.getOrderLineModel(orderLine);

      if (!orderLineModel) {
        continue;
      }

      orderLinesModel.push(orderLineModel);
    }

    // build model
    orderSummaryModel.items = orderLinesModel;

    return new Promise<OrderSummaryModel>((resolve, reject) => {
      resolve(orderSummaryModel)
    });
  }

  private async getOrderLineModel(orderLine): Promise<OrderArticle> {
    if (!orderLine) {
      return;
    }

    const article: Article = await this.articlesDAO.get(orderLine.articleId);

    let orderLineModel: OrderArticle;

    if (article) {
      orderLineModel = {
        name: article.description1,
        ref: article.id,
        id: article.id,
        company: article.companyId,
        quantity: orderLine.orderQuantity,
        priceUnitar: orderLine.unitPricePerPiece,
        amount: orderLine.unitPricePerPiece * orderLine.orderQuantity,
      }
    } else {
      orderLineModel = {
        quantity: orderLine.orderQuantity,
        priceUnitar: orderLine.unitPricePerPiece,
        amount: orderLine.unitPricePerPiece * orderLine.orderQuantity,
        name: orderLine.factureArticleDescription,
        company: orderLine.factureCompanyName,
        ref: orderLine.articleId,
        id: orderLine.articleId,
      }
    }

    return orderLineModel;
  }

  public toOrderSummaryModel(order: Order): OrderSummaryModel {
    const orderSummaryModel = {} as OrderSummaryModel;
    // orderSummaryModel.client = order.clientId;
    orderSummaryModel.date = moment.utc(order.date).toDate();
    return orderSummaryModel;
  }

  public async getLastClientOrder(clientId: string): Promise<Order> {
    const condition = {
      type: 'Order',
      sort: 'date:DESC',
      filter: {
        $and: [
          { clientId },
          {
            status: {
              $in: [
                OrderStatus.Valid,
                OrderStatus.Delivered,
              ],
            },
          },
        ]
      },
      limit: 1
    };
    const orders = await this.ordersDAO.search(condition);

    return orders.length ? orders[0] : null;
  }

  public async getClientOrderIdsByDate(clientId: string, date: string): Promise<string[]> {
    const condition = {
      type: 'Order',
      filter: {
        $and: [
          { clientId },
          { date },
          {
            status: {
              $in: [
                OrderStatus.Valid,
                OrderStatus.Delivered,
              ],
            },
          },
        ]
      },
    };
    const orders = await this.ordersDAO.search(condition);
    return orders.length ? orders.map(o=>o.id) : null;
  }

  public async getClientTurnOver(clientId: string): Promise<ClientTurnoverModel[]> {
    const condition = {
      type: 'Order',
      filter: {
        $and: [
          { clientId },
          {
            status: {
              $in: [
                OrderStatus.Valid,
                OrderStatus.Delivered,
              ],
            },
          },
        ]
      },
    };
    const orders = await this.ordersDAO.search(condition);
    console.warn('orders', orders);
    
    const presentDay = moment().hours(0).minutes(0).seconds(0).milliseconds(0).utcOffset(0, true);
    const currentYear = presentDay.year();
    const lastYear = presentDay.year() - 1;
    const lastYearSameDayAsPresent = presentDay.clone().subtract(1, 'year');
    const lastYearOrders = orders.filter(o=>o.date.startsWith('' + lastYear));
    const lastYearSameDayOrders = lastYearOrders.filter(o=>o.date <= lastYearSameDayAsPresent.toISOString());  
    const thisYearOrders = orders.filter(o=>o.date.startsWith('' + currentYear));

    var companies = (await this.companiesDAO.getAll()).reverse();
    const result = companies.map(company => {

      const model: ClientTurnoverModel = new ClientTurnoverModel();
      model.companyId = company.id;
      model.presentDay = presentDay;
      model.lastYear = lastYear
      model.lastYearSameDayAsPresent = lastYearSameDayAsPresent;  
      
      model.lastYearTurnover = this.getTurnover(lastYearOrders.filter(o=>o.id.endsWith(company.id))) ?? 0;
      model.thisYearTurnover = this.getTurnover(thisYearOrders.filter(o=>o.id.endsWith(company.id))) ?? 0; 
      model.lastYearSameDayTurnover = this.getTurnover(lastYearSameDayOrders.filter(o=>o.id.endsWith(company.id))) ?? 0;

      //calculate this year growth in same period over last year same period in percent
      model.growth = model.thisYearTurnover / model.lastYearSameDayTurnover * 100 - 100;
      model.validGrowth = model.lastYearSameDayTurnover != 0;

      return model;
    });

    return result;
  }

  private getTurnover(orders: Order[]): number {
    return orders.length ? orders.map(o=>o.totalExcludingTax).reduce((a,b)=>a+b) : null;
  }

  private async getOrderLinesByOrderId(order: Order) {
    const currentVrp = await this.getCurrentVrp();

    if (currentVrp.toLowerCase() === ClientAllVrp) {
      return this.getResourcesService
        .getOrderLines(order.number)
        .toPromise();
    }

    const filter: Criteria = {
      filter: { 'orderId': { $eq: order.id } }
    };

    const data = await this.orderLinesDAO.search(filter);

    return data || [];
  }

  private getCurrentVrp(): Promise<string> {
    return this.store
      .select(getCurrentUserVrp)
      .pipe(take(1))
      .toPromise();
  }

}
