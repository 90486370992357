import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { ExampleDialogComponent } from './components/smart/example-dialog/example-dialog.component';


const dialogs = [
  ExampleDialogComponent
];

@NgModule({
  declarations: [
    ...dialogs
  ],
  imports: [
    CommonModule,
    TcCoreModule
  ],
  exports: [
    ...dialogs
  ],
  entryComponents: [
    ...dialogs
  ]
})
export class ExamplesModule { }
