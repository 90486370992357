import { Injectable } from '@angular/core';
import { PartitionsService } from '../partitions/partitions.service';
import { UserEntityType } from '@maxel-order/shared';

@Injectable({
  providedIn: 'root'
})
export class DocsProviderService {
  private readonly limit = 50000;

  constructor(
    private readonly partitionsService: PartitionsService,
  ) { }

  public async all(): Promise<any[]> {
    const partitions = await this.partitionsService.all();

    const hasUserInfo = await this.hasUsersInfo();
    if (hasUserInfo) {
      partitions.push({ lowerName: UserEntityType.lowerName, dbName: UserEntityType.lowerName });
    }

    const responses = [];

    for (const partition of partitions) {
      const items = await this.get(partition.dbName);

      while (items.length > 50 * 1000) {
        responses.push(...items.splice(0, 50 * 1000));
      }

      responses.push(...items);
    }

    return responses;
  }

  private async get(partition: string): Promise<any[]> {
    const connection = await this.getConnection(partition);
    const [revisions, docs] = await Promise.all([this.getRevisions(connection), this.getStoredDocs(connection)]);
    connection.close();

    const docsByKey = new Map();
    docs.forEach(doc => {
      docsByKey.set(doc._doc_id_rev, doc);
    });

    const response = revisions.map(rev => {
      const id = rev.id + '::' + rev.winningRev;
      const doc = docsByKey.get(id);
      delete doc._doc_id_rev;

      return {
        ...doc,
        _id: rev.id,
        _rev: rev.winningRev,
      };
    }).filter(doc => !doc._deleted);

    return response;
  }

  private async hasUsersInfo() {
    const databases = await (window as any).indexedDB.databases();
    return databases.find(db => db.name === `_pouch_${UserEntityType.lowerName}`);
  }

  private getConnection(database: string): Promise<IDBDatabase> {
    return new Promise((resolve) => {
      const request = indexedDB.open(`_pouch_${database}`);
      request.onsuccess = function (event) {
        resolve(request.result);
      };
    });
  }

  private getRevisions(db): Promise<any[]> {
    return new Promise((resolve) => {
      const transaction = this.getTransaction(db, 'document-store');
      if (!transaction) {
        return resolve([]);
      }

      const obj = transaction.objectStore('document-store');

      resolve(this.loadBatchItems(obj, this.getDocsInRange, (r) => r.length ? r[r.length - 1].id : 0));
    });
  }

  private getStoredDocs(db): Promise<any[]> {
    return new Promise((resolve) => {
      const transaction = this.getTransaction(db, 'by-sequence');
      if (!transaction) {
        return resolve([]);
      }

      const docIndex = transaction.objectStore('by-sequence').index('_doc_id_rev');

      resolve(this.loadBatchItems(docIndex, this.getIndexInRange, (r) => r.length ? r[r.length - 1]._doc_id_rev : 0));
    });
  }

  private getTransaction(db, table: string) {
    try {
      return db.transaction([table], 'readonly');
    } catch (e) {
      return;
    }
  }

  private getDocsInRange(obj, startKey, limit: number) {
    return new Promise((resolve) => {
      const getRevisionsRequest = obj.getAll(IDBKeyRange.lowerBound(startKey, true), limit);

      getRevisionsRequest.onsuccess = () => {
        resolve(getRevisionsRequest.result.map(rev => JSON.parse(rev.data)));
      };
    });
  }

  private getIndexInRange(index, startKey, limit: number) {
    return new Promise((resolve) => {
      const getDocsRequest = index.getAll(IDBKeyRange.lowerBound(startKey, true), limit);

      getDocsRequest.onsuccess = (e) => {
        resolve(e.target.result);
      };
    });
  }

  private async loadBatchItems(obj, method, next) {
    const docs = [];
    let startKey = 0;
    let result = [];

    do {
      result = await method(obj, startKey, this.limit);
      docs.push(...result);
      startKey = next(result);
    } while (result.length >= this.limit);

    return docs;
  }

}
