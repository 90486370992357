import { getSelectedClient } from './../../../../clients/store/clients.selectors';
import { Component, OnInit } from '@angular/core';
import { TcSmartComponent } from '@tc/core';
import { Observable } from 'rxjs';
import { ClientModel } from '../../../../clients/models/client.model';
import { Store, select } from '@ngrx/store';
import { ClientListRowModel } from '../../../../clients/models/client-list-row.model';

@Component({
  selector: 'app-custom-client-title',
  templateUrl: './custom-client-title.component.html',
  styleUrls: ['./custom-client-title.component.scss']
})
export class CustomClientTitleComponent extends TcSmartComponent implements OnInit {

  selectedClient$: Observable<ClientListRowModel>;

  constructor(
    private store: Store<any>
  ) {
    super();
   }

  ngOnInit(): void {
    this.selectedClient$ = this.store.pipe(select(getSelectedClient));
  }

}
