export type AddressProperty = | 'clientId' | 'internalCode' | 'companyName' | 'address1' | 'address2' | 'address3' | 'zipCode' | 'city' | 'country' | 'managerName' | 'storeContactName' | 'email1' | 'email2' | 'phone' | 'companyname' ;

export const AddressProperty = {
  clientId: 'clientId' as AddressProperty,
  internalCode: 'internalCode' as AddressProperty,
  companyName: 'companyName' as AddressProperty,
  address1: 'address1' as AddressProperty,
  address2: 'address2' as AddressProperty,
  address3: 'address3' as AddressProperty,
  zipCode: 'zipCode' as AddressProperty,
  city: 'city' as AddressProperty,
  country: 'country' as AddressProperty,
  managerName: 'managerName' as AddressProperty,
  storeContactName: 'storeContactName' as AddressProperty,
  email1: 'email1' as AddressProperty,
  email2: 'email2' as AddressProperty,
  phone: 'phone' as AddressProperty,
  companyname: 'companyname' as AddressProperty,
}
