import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { IMAGES_ROOT_FOLDER } from '../../../../../app.module';
import { OrderArticle, OrderRemoveArticle } from '../../../../orders/store/orders.actions';
import { getNumberOfArticlesInBasket } from '../../../../orders/store/orders.selectors';
import { ArticleModel } from '../../../models/article.model';
import { PreviewArticle, ShowArticleHistory } from '../../../store/articles.actions';

@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleItemComponent extends TcSmartComponent implements OnInit {

  isMissing = false;

  imagesRootSrc: string;
  @Input()
  article: ArticleModel;

  constructor(
    private readonly store: Store<any>,
    @Inject(IMAGES_ROOT_FOLDER) private imagesRootUrl: string
  ) {
    super();
  }

  ngOnInit() {
    this.imagesRootSrc = this.imagesRootUrl;
  }

  onImageMissing(e: Event) {
    this.isMissing = e.type === 'error';
  }

  previewArticle() {
    this.store.dispatch(new PreviewArticle(this.article));
  }

  articleHistory() {
    this.store.dispatch(new ShowArticleHistory(this.article));
  }

  addToBasket() {
    this.store.dispatch(new OrderArticle(this.article));
  }

  removeFromBasket() {
    this.store.dispatch(new OrderRemoveArticle(this.article));
  }

  getNumberOfArticles$ = (article: ArticleModel) => {
    return this.store.pipe(select(getNumberOfArticlesInBasket(article)));
  }

  getRoundPrice = (): string => {
    if (this.article.price) {
      return Number(this.article.price).toFixed(2);
    }
    return '';
  }

}
