import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TcPage, TcGenericFilterComponent } from '@tc/core';
import { Store } from '@ngrx/store';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { LoadUsers } from '../../modules/users/store/users.actions';

@Component({
  selector: 'app-f26-user-list-page',
  templateUrl: './f26-user-list-page.component.html',
  styleUrls: ['./f26-user-list-page.component.scss']
})

export class FF26UserListPageComponent extends TcPage implements OnInit {

  //orderListFilter: TcGenericFilterComponent<OrderModel>;
  // @ViewChild('orderListFilter', { static: true }) set setOrderListFilter(values: TcGenericFilterComponent<OrderModel>) {
  //   this.orderListFilter = values;
  //   this.orderListFilter.entityName = 'OrderModel';
  //   this.orderListFilter.filterLabel = 'Recherche rapide ...'
  // }

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {

    this.store.dispatch(new LoadUsers());

    const pageInfo: IAppPageState = {
      title: 'users-page.title',
      menuItems: [
        {
          name: AppMenu.Logout
        }
      ]
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

  }

}

