import { Component, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcListFilterModel, TcSmartComponent } from '@tc/core';
import { Observable, Subscription } from 'rxjs';

import { ArticleCategoryModel } from '../../../models/article-category.model';
import { ExtendCategory, FilterArticles, UpdateUsedCategories } from '../../../store/articles.actions';
import { getArticleCategories, getArticleListFilter, getUsedFamilies } from '../../../store/articles.selectors';
import { Family } from '@maxel-order/shared';

@Component({
  selector: 'app-article-filter',
  templateUrl: './article-filter.component.html',
  styleUrls: ['./article-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleFilterComponent extends TcSmartComponent implements OnInit, OnDestroy {

  selected: ArticleCategoryModel;

  usedFamilies: { [key: string]: boolean } = {};

  selectedFamilyId = null;
  articleListFilter: TcListFilterModel;

  filters$: Observable<ArticleCategoryModel[]>;

  filterSubscription: Subscription;
  usedFamiliesSubscription: Subscription;

  @Output() public selectNewFilter: EventEmitter<void> = new EventEmitter();

  constructor(
    private readonly store$: Store<any>,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  async ngOnInit() {
    this.store$.dispatch(new UpdateUsedCategories());

    this.filters$ = this.store$.pipe(select(getArticleCategories));

    this.filterSubscription = this.store$.pipe(select(getArticleListFilter)).subscribe(filter => {
        this.articleListFilter = filter;
        this.selectedFamilyId = filter?.familyId;
    });

    this.usedFamiliesSubscription = this.store$
      .select(getUsedFamilies)
      .subscribe(categories => {
        this.usedFamilies = categories;
        this.cdr.detectChanges();
      });
  }

  trackBy(_: number, item: Family): string {
    return item.id;
  }

  extend(key: string) {
    this.store$.dispatch(new ExtendCategory(key));
  }

  selectFilter = (family: Family) => {
    if (!this.articleListFilter) {
      this.articleListFilter = {};
    }

    const isSame = this.selectedFamilyId && this.selectedFamilyId === family.id;

    // MO-306 Éviter le double-clic sur Famille qui recharge le catalogue
    if(isSame) {
      return;
    }
    
    this.selectNewFilter.emit();
    this.selectedFamilyId = family.id;

    this.articleListFilter.familyId = this.selectedFamilyId;

    this.store$.dispatch(new FilterArticles(this.articleListFilter));
  }

  public trackByCategory(_, item) {
    return item.key;
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.usedFamiliesSubscription) {
      this.usedFamiliesSubscription.unsubscribe();
    }
  }

}
