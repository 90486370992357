import { LastUserModel, UserModel } from '../models/user.model';

export interface AuthState {
  isAuthenticated: boolean;
  user: UserModel;
  lastActivity: Date;
  errorMessage: string | null;
  lastSignedUser: LastUserModel;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  lastActivity: null,
  lastSignedUser: null,
};
