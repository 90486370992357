import { ClientListRowModel } from './../../modules/clients/models/client-list-row.model';
import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { select, Store } from '@ngrx/store';
import { getSelectedClient } from '../../modules/clients/store/clients.selectors';
import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { Observable } from 'rxjs';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Component({
  selector: 'app-f09-order-detail-page',
  templateUrl: './f09-order-detail-page.component.html',
  styleUrls: ['./f09-order-detail-page.component.scss']
})
export class F09OrderDetailPageComponent extends TcPage implements OnInit {
  clientSelected$: Observable<ClientListRowModel>;

  constructor(private permissionService: PermissionsService, store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.clientSelected$ = this.store.pipe(select(getSelectedClient));

    const pageInfo: IAppPageState = {
      title: '',
      menuItems: this.permissionService.applyPermissionOnMenuItems([
        {
          name: AppMenu.Orders
        },
        {
          name: AppMenu.Reports
        },
        {
          name: AppMenu.DisplaySyncPopup
        },
        {
          name: AppMenu.Logout
        }
      ])
    };

    this.pageInfo = pageInfo;
    super.ngOnInit();
  }
}
