import { ClientBaseModel } from './client-base.model';
import { PriceCodeModel } from './price-code.model';

export class ClientModel extends ClientBaseModel {

  // detail info
  nationalCentral?: string;
  regionalCentral?: string;
  commercialSignType?: string;
  address2?: string;
  address3?: string;
  country?: string;
  insurance?: string;
  insuranceOutstanding?: string;

  // tarif and legal
  siren?: string;
  siret?: string;
  tvaNumber?: string;
  generalPrice?: PriceCodeModel[];
  specialPrice?: PriceCodeModel[];
  paymentPeriod?: string;
  carriageFree?: string;

  //contact
  language?: string;
  contactEmail?: string;
  phone?: string;

  //dashboard
  lastOrderDate?: Date;
  orderAmountAverage12Months?: number;
  totalAmountOrdered12Months?: number;
}
