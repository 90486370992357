<tc-dialog (close)="onClose()">

    <div tc-dialog-title>
        <h3 mat-dialog-title>{{'new-client-popup.title' | translate}}</h3>
    </div>

    <div tc-dialog-content>

        <form [formGroup]="form" (ngSubmit)="submit(model)" id="new-client-popup">

            <mat-dialog-content>
                <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
            </mat-dialog-content>

            <mat-dialog-actions>
                <button mat-raised-button type="submit" class="save-button" tcPreventDoubleClick>{{'new-client-popup.buttons.save' | translate}}</button>
                <button mat-raised-button (click)="cancel()" class="cancel-button" tcPreventDoubleClick>{{'new-client-popup.buttons.cancel' | translate}}</button>
            </mat-dialog-actions>

        </form>

    </div>

</tc-dialog>