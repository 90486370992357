import { Injectable } from '@angular/core';
import { NotificationEntityType, UserEntityType } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';
import rel from 'relational-pouch';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { getAuthenticatedUser, getLastUser } from '../../modules/auth/store/auth.selectors';
import { ConfigKeys } from '../config/config.interface';
import { ConfigService } from '../config/config.service';
import { PartitionsService } from '../partitions/partitions.service';
import { SyncService } from '../sync/sync.service';
import { VersionService } from '../version.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private connectionsByPartition: Record<string, PouchDB.Database<any>> = {};

  private lastSignedUser$: Subscription;
  private lastSignedUserName: string;

  constructor(
    private readonly config: ConfigService,
    private readonly syncService: SyncService,
    private readonly store$: Store<TcAppState>,
    private readonly versionService: VersionService,
    private readonly partitionsService: PartitionsService,
  ) {
    this.init();
  }

  public get(partition: string): PouchDB.Database<any> {
    return this.connectionsByPartition[partition];
  }

  public getRemoteConnection(remote: string, token: string) {
    const databaseVersion = this.versionService.getDBVersion();

    return new PouchDB(
      remote, {
      fetch: function (url: string, opts: any) {
        opts.headers.set('authorization', token);
        opts.headers.set('X-DB-VERSION', databaseVersion);
        return PouchDB.fetch(url, opts);
      }
    });
  }

  private init() {
    PouchDB
      .plugin(PouchFind)
      .plugin(rel);

    this.subscribeVrp();
  }

  private subscribeVrp() {
    this.store$.select(getAuthenticatedUser)
      .pipe(skipWhile(value => !value), take(1))
      .subscribe(() => this.subscribeLastUser());
  }

  private subscribeLastUser() {
    this.lastSignedUser$?.unsubscribe();

    this.lastSignedUser$ = this.store$.select(getLastUser)
      .subscribe(async (lastUser) => {
        if (lastUser && this.lastSignedUserName !== lastUser.username) {
          this.lastSignedUserName = lastUser.username;

          await this.createConnections(lastUser.token);
        }
      });
  }

  private async createConnections(token: string) {
    const partitions = [
      { lowerName: UserEntityType.lowerName, dbName: UserEntityType.lowerName },
      ...await this.partitionsService.all(),
    ];

    for (const partition of partitions) {
      if (!this.connectionsByPartition[partition.dbName]) {
        const connection = new PouchDB(partition.dbName);
        // duplicate database to get it by lowerName in databaseService.get
        this.connectionsByPartition[partition.dbName] = connection;
        this.connectionsByPartition[partition.lowerName] = connection;
      }
    }

    for (const partition of partitions) {
      if (this.connectionsByPartition[partition.dbName]) {
        const remoteUrl = `${this.config.get(ConfigKeys.database.url)}/${partition.dbName}`;
        this.syncService.start({
          partition: partition.lowerName,
          remote: this.getRemoteConnection(remoteUrl, token),
          connection: this.connectionsByPartition[partition.dbName],
        });
      }
    }
  }

}
