export type OrderLineProperty = | 'id' | 'orderId' | 'orderNumber' | 'articleId' | 'orderQuantity' | 'unitPricePerPiece' | 'lineTotal' | 'colisageQuantity' | 'amountRemainingDelivered' ;

export const OrderLineProperty = {
  id: 'id' as OrderLineProperty,
  orderId: 'orderId' as OrderLineProperty,
  orderNumber: 'orderNumber' as OrderLineProperty,
  articleId: 'articleId' as OrderLineProperty,
  orderQuantity: 'orderQuantity' as OrderLineProperty,
  unitPricePerPiece: 'unitPricePerPiece' as OrderLineProperty,
  lineTotal: 'lineTotal' as OrderLineProperty,
  colisageQuantity: 'colisageQuantity' as OrderLineProperty,
  amountRemainingDelivered: 'amountRemainingDelivered' as OrderLineProperty,
}
