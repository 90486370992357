import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Sort } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { TcListComponent, TcListFilterType, TcListSortModel, TcListSortType, TcSmartComponent } from '@tc/core';
import { Subscription } from 'rxjs';
import { PermissionsService } from '../../../../../services/permissions/permissions.service';
import { ResetUsedCategories } from '../../../../articles/store/articles.actions';
import { PROSPECT_CLIENT } from '../../../clients.module';
import { ClientStatus } from '../../../enums/client-status.enum';
import { LoadMoreClients, SelectClient } from '../../../store/clients.actions';
import { getClientListPagination, getClients } from '../../../store/clients.selectors';
import { ClientListRowModel } from './../../../models/client-list-row.model';
import {
  AddNewClientRequest,
  AddNewVisit,
  NavigateToClient,
  OpenClientDetails,
  SortClients
} from './../../../store/clients.actions';
import { getClientListSort } from './../../../store/clients.selectors';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientListComponent extends TcSmartComponent implements OnInit, OnDestroy {

  sortSubscription: Subscription;
  hasMoreItemsSubscription: Subscription;

  @ViewChild('colLocationTemplate', { static: true }) colLocationTemplate: TemplateRef<any>;

  @ViewChild('colClientDetailsTemplate', { static: true }) colClientDetailsTemplate: TemplateRef<any>;
  @ViewChild('colVisitTemplate', { static: true }) colVisitTemplate: TemplateRef<any>;
  @ViewChild('colDirectionTemplate', { static: true }) colDirectionTemplate: TemplateRef<any>;
  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;

  clientList: TcListComponent;
  @ViewChild('clientList', { static: true }) set setClientList(values: TcListComponent) {
    this.clientList = values;
  }

  public hasWriteAccess = true;
  private hasMoreClients = true;

  constructor(
    private store: Store<any>,
    private readonly permissionsService: PermissionsService,
  ) {
    super();
  }

  ngOnInit() {
    this.setPermissions();

    // get client list sort from state and set sort info to client list
    this.sortSubscription = this.store.pipe(select(getClientListSort))
      .subscribe(sort => {
        if (!sort) {
          return;
        }

        this.clientList.sortActive = sort.key;
        this.clientList.sortDirection = sort.order;
      });

    this.hasMoreItemsSubscription = this.store.pipe(select(getClientListPagination))
      .subscribe(pagination => this.hasMoreClients = pagination.hasMoreItems);

    this.clientList.rows$ = this.store.pipe(select(getClients));

    this.clientList.isFiltrable = false;
    this.clientList.filterType = TcListFilterType.Disabled;
    this.clientList.sortType = TcListSortType.Server;
    this.clientList.isPaged = false;
    this.clientList.hasFixedHeader = true;
    this.clientList.hasAddButton = false;

    // on sort => dispatch new action to store with sort expression
    this.clientList.applyServerSideSort = (sort: Sort) => {
      const sortModel: TcListSortModel = {
        key: sort.active,
        order: sort.direction
      }
      this.store.dispatch(new SortClients(sortModel));
    }

    // on row click => dispatch action to store with selected client
    this.clientList.onRowClick = (row: ClientListRowModel) => {
      if (row.status !== ClientStatus.Interdit) {
        if (row.id === 'PROSPECT') {
          this.store.dispatch(new SelectClient(row));
          this.store.dispatch(new ResetUsedCategories());
        } else {
          this.openClientDetails(row);
        }
      }
    }

    this.clientList.onScrollDown = () => {
      if (!this.hasMoreClients) {
        return;
      }
      this.store.dispatch(new LoadMoreClients());
    };

    this.clientList.columns = [
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'storeContactName',
        visible: true
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate
      },
      {
        propertyName: 'city',
        visible: true,
      },
      {
        propertyName: 'distance',
        visible: true,
        htmlTemplate: this.colLocationTemplate
      }
    ];

    this.clientList.rowActions = [
      {
        actionName: 'contact',
        visible: true,
        hasText: false,
        htmlTemplate: this.colClientDetailsTemplate
      },
      {
        actionName: 'visit',
        visible: true,
        hasText: false,
        htmlTemplate: this.colVisitTemplate
      },
      {
        actionName: 'direction',
        visible: true,
        hasText: false,
        htmlTemplate: this.colDirectionTemplate
      },
    ];
  }

  openClientDetails = (row: ClientListRowModel) => {
    this.store.dispatch(new OpenClientDetails(row));
  }

  addClient() {
    this.store.dispatch(new AddNewClientRequest());
  }

  visiteClient(row: ClientListRowModel) {
    this.store.dispatch(new AddNewVisit(row));
  }

  directionMap(client: ClientListRowModel) {
    this.store.dispatch(new NavigateToClient(client));
  }

  private async setPermissions() {
    this.hasWriteAccess = await this.permissionsService.hasAccess();

    if (!this.hasWriteAccess) {
      this.clientList.rowActions = this.clientList.rowActions.filter(item => item.actionName !== 'visit')
    }
  }

  ngOnDestroy(): void {
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }
    if (this.hasMoreItemsSubscription) {
      this.hasMoreItemsSubscription.unsubscribe();
    }
  }
}
