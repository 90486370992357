import { Component, OnInit, ElementRef, Optional, Inject } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UpdateClientComponent } from '../update-client/update-client.component';

@Component({
  selector: 'app-new-client-detail',
  templateUrl: './new-client-detail.component.html',
  styleUrls: ['./new-client-detail.component.scss']
})
export class NewClientDetailComponent extends TcFormComponent<any> implements OnInit {

  constructor(
    public translateService: TcTranslateService,
    private dialogRef: MatDialogRef<UpdateClientComponent>,
    element: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    super(translateService, element);
    this.fields = [
      {
        fieldGroupClassName: 'display-grid',
        fieldGroup: [
          {
            key: 'siren',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            },
          },
          // social raison
          {
            key: 'companyName',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            },
          },

          {
            key: 'storeContactName',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            },
          },
          {
            key: 'email',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            },
          },
          {
            key: 'phone',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            },
          },
          {
            key: 'paymentType',
            type: 'select',
            templateOptions: {
              options: [
                { value: 1, label: this.translate.instant('new-client-popup.paymentTypeOptions.1') },
                { value: 2, label: this.translate.instant('new-client-popup.paymentTypeOptions.2') },
              ]
            },
          },
          //rib
          {
            key: 'rib',
            type: 'photo-select',
            templateOptions: {
              label: this.translate.instant('new-client-popup.labels.rib'),
            }
          }
        ]
      },
      {
        key: 'invoiceAddressLabel',
        className: 'section-label',
        template: '<h3>Adresse de facturation</h3>',
      },
      {
        fieldGroupClassName: 'display-grid',
        fieldGroup: [
          {
            key: 'invoiceAddress1',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            }
          },
          {
            key: 'invoiceAddress2',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'invoiceAddress3',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'invoicePostalCode',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            }
          },
          {
            key: 'invoiceCity',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            }
          },
          {
            key: 'invoiceCountry',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            }
          }
        ]
      },
      {
        fieldGroupClassName: 'single-line',
        fieldGroup: [
          {
            key: 'sameAddress',
            type: 'checkbox',
            templateOptions: {
              type: 'boolean'
            }
          }
        ]
      },
      {
        key: 'deliveryAddressLabel',
        className: 'section-label',
        template: '<h3>Adresse de livraison</h3>',
        hideExpression: 'model.sameAddress'
      },
      {
        fieldGroupClassName: 'display-grid',
        hideExpression: 'model.sameAddress',
        fieldGroup: [
          {
            key: 'deliveryAddress1',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'deliveryAddress2',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'deliveryAddress3',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'deliveryPostalCode',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'deliveryCity',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'deliveryCountry',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          }
        ]
      }
    ];
  }

  ngOnInit() {

    if (this.data) {
      this.model = Object.assign({}, this.data);
      if (this.data.sameAddress) {
        this.model.deliveryAddress1 = this.data.invoiceAddress1;
        this.model.deliveryAddress2 = this.data.invoiceAddress2;
        this.model.deliveryAddress3 = this.data.invoiceAddress3;
        this.model.deliveryPostalCode = this.data.invoicePostalCode
        this.model.deliveryCity = this.data.deliveryCity
        this.model.deliveryCountry = this.data.deliveryCountry;
      }
    }
    super.ngOnInit();
  }

  onClose() {
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(model: any) {
    if (this.form.valid) {
      this.dialogRef.close(model);
    }
  }
}
