import { Component, OnInit, Optional, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TcSmartComponent } from '@tc/core';
import { ArticleOrderHistoryModel } from '../../../models/article-order-history.model';
import { Store, select } from '@ngrx/store';
import { LoadArticleOrderHistory } from '../../../store/articles.actions';
import { Observable } from 'rxjs';
import { getArticleOrderHistoryList } from '../../../store/articles.selectors';
import { ArticleModel } from '../../../models/article.model';

@Component({
  selector: 'app-article-history',
  templateUrl: './article-history.component.html',
  styleUrls: ['./article-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleHistoryComponent extends TcSmartComponent implements OnInit {

  orderHistoryList$: Observable<ArticleOrderHistoryModel[]>;
  constructor(
    private dialogRef: MatDialogRef<ArticleHistoryComponent>,
    private store: Store<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ArticleModel,
  ) {
    super();
  }

  ngOnInit() {

    this.store.dispatch(new LoadArticleOrderHistory(this.data));

    this.orderHistoryList$ = this.store.pipe(select(getArticleOrderHistoryList));

    this.dialogRef.addPanelClass('article__history_popup');

  }

  onClose() {
    this.dialogRef.close();
  }
}
export interface Month {
  id: string;
  name: string;
}

export interface OrderHistory {
  date: string;
  number: number;
}
