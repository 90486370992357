import { Injectable } from '@angular/core';
import { TcService } from '@tc/core';
import { buildDate, version, databaseVersion } from '../../../../../package.json';

@Injectable()
export class VersionService extends TcService {

  constructor() {
    super();
  }

  public getVersion(): string {
    return version;
  }

  public getDBVersion(): string {
    return databaseVersion;
  }

  public getBuildDate(): string {
    return buildDate;
  }

}
