import { SyncProgress } from '../models/sync-progress.model';

export interface SyncAgentState {
    requestedStop: boolean;
    progress: SyncProgress[];
    syncRequired: boolean;
    firstSyncFinished: boolean;
    lastSyncDBVersion: string;
}

export const initialSyncAgentState: SyncAgentState = {
    requestedStop: false,
    progress: [],
    syncRequired: null,
    firstSyncFinished: false,
    lastSyncDBVersion: null,
};
