import { TcCoreModule } from '@tc/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/smart/login/login.component';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth.reducer';

export const LOGIN_INAVLID_USERNAME_OR_PASSWORD = 'login.inavlid-username-or-password';

const smartComponents = [
  LoginComponent
];

@NgModule({
  declarations: [
    ...smartComponents
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    FormsModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature('authentication', authReducer)
  ],
  exports: [
    ...smartComponents
  ]
})
export class AuthModule { }
