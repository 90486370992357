import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcPage } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { Observable, Subscription } from 'rxjs';
import { getSelectedClient } from '../../modules/clients/store/clients.selectors';
import { ClientListRowModel } from '../../modules/clients/models/client-list-row.model';
import { InitArticlesPage } from '../../modules/articles/store/articles.actions';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Component({
  selector: 'app-f07-article-list-page',
  templateUrl: './f07-article-list-page.component.html',
  styleUrls: ['./f07-article-list-page.component.scss']
})
export class F07ArticleListPageComponent extends TcPage implements OnInit, OnDestroy {
  selectedClient$: Observable<ClientListRowModel>;

  selectedClientSubscription: Subscription;
  spinnerSubscription: Subscription;

  constructor(private permissionService: PermissionsService, store: Store<any>) {
    super(store);
  }

  ngOnDestroy() {
    if (this.selectedClientSubscription) {
      this.selectedClientSubscription.unsubscribe();
    }

    if (this.spinnerSubscription) {
      this.spinnerSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.selectedClient$ = this.store.pipe(select(getSelectedClient));

    this.store.dispatch(new InitArticlesPage());

    const pageInfo: IAppPageState = {
      title: 'articles-page.title',
      menuItems: this.permissionService.applyPermissionOnMenuItems([
        {
          name: AppMenu.Orders
        },
        {
          name: AppMenu.Reports
        },
        {
          name: AppMenu.DisplaySyncPopup
        },
        {
          name: AppMenu.Logout
        }
      ])
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

  public scrollToTop() {
    const scrollComponent = document.getElementsByClassName('tc-list-container')[0];

    scrollComponent.scrollTo(0, 0);
  }
}
