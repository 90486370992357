
import { Injectable } from '@angular/core';
import { Company, CompanyEntityType } from '@maxel-order/shared';
import { DatabaseService } from '../database/database.service';
import { EventsService } from '../events/events.service';
import { RepositoryService } from '../repository/repository.service';
import { BaseDAO } from './base.dao';
import { IdProviderService } from './id-provider.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesDAO extends BaseDAO<Company>{
  constructor(
    database: DatabaseService,
    idProvider: IdProviderService,
    repositoryService: RepositoryService,
    eventsService: EventsService,
  ) {
    super(database, idProvider, repositoryService, eventsService);

    this.entityName = CompanyEntityType.className;
    this.entityLowerName = CompanyEntityType.lowerName;
  };

  public async getCompanyNameByIdMap(){
    let result = new Map<string, string>();
    const companies = await this.getAll();
    companies.forEach(company => result = result.set(company.id, company.name));
    return result;
  }
}
