import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcAction, TcNotificationService, TcTranslateService } from '@tc/core';
import { Observable } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ClientsRequestService } from '../../../services/business-services/clients-request.service';
import { InvoicesService } from '../../../services/business-services/invoices.service';
import { OrderRequestsService } from '../../../services/business-services/order-requests.service';
import { VisitsService } from '../../../services/business-services/visits.service';
import { getAuthenticatedUser } from '../../auth/store/auth.selectors';
import { VisitComponent } from '../../main/components/dumb/visit/visit.component';
import { VisitModel } from '../../main/models/visit.model';
import { OrderStatus } from '../../orders/enums/order-status.enum';
import { CreateInitOrder, LoadOrder } from '../../orders/store/orders.actions';
import { NewClientDetailComponent } from '../components/smart/new-client-detail/new-client-detail.component';
import { UpdateClientComponent } from '../components/smart/update-client/update-client.component';
import { ClientsService } from './../../../services/business-services/clients.service';
import { MapService } from './../../../services/map.service';
import {
  AddNewClientRequest,
  AddNewVisit,
  ClientsActionTypes,
  EditClient,
  FilterClients,
  InitClients,
  LoadClient,
  LoadClientById,
  LoadClientByIdSuccess,
  LoadClientInvoiceList,
  LoadClientInvoiceListSuccess,
  LoadClients,
  LoadClientsSuccess,
  LoadClientSuccess,








  LoadMoreClients,
  LoadMoreClientsSuccess, NavigateToClient,
  OpenClientDetails,
  SaveNewClientRequest,
  SaveNewVisit,
  SelectClient,
  SortClients,
  UpdateClient,
  UpdateClientSuccess
} from './clients.actions';
import { getClientListFilter, getClientListLoadMoreOptions, getClientListSort, getClients } from './clients.selectors';


@Injectable()
export class ClientsEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private dialog: MatDialog,
    private store: Store<any>,
    private mapService: MapService,
    private clientsRequestService: ClientsRequestService,
    private clientsService: ClientsService,
    private visitsService: VisitsService,
    private orderRequestsService: OrderRequestsService,
    private notificationService: TcNotificationService,
    private translateService: TcTranslateService,
    private invoicesService: InvoicesService,
  ) { }

  // INIT CLIENTS: this action is dispatched at client list page INIT
  @Effect({ dispatch: false })
  initClients$ = this.actions$.pipe(
    ofType<InitClients>(ClientsActionTypes.INIT_CLIENTS),
    withLatestFrom(this.store.pipe(select(getClients))),
    map(([action, clients]) => {
      // if clients is null in store or clients is empty => dispatch an action to load clients
      if (!clients || !clients.length) {
        this.store.dispatch(new LoadClients({
          filter: action.payload.filter,
          limit: 50,
          skip: 0,
        }));
      }
    })
  );

  @Effect({ dispatch: false })
  loadClients$ = this.actions$.pipe(
    ofType<LoadClients>(ClientsActionTypes.LOAD_CLIENTS),
    map(async ({ payload }) => {
      const clients = await this.clientsService.getClients({
        filter: payload.filter,
        limit: payload.limit,
        skip: payload.skip,
        sort: payload.sort,
      });

      this.store.dispatch(new LoadClientsSuccess(clients));
    })
  );

  @Effect({ dispatch: false })
  loadMoreClients$ = this.actions$.pipe(
    ofType<LoadMoreClients>(ClientsActionTypes.LOAD_MORE_CLIENTS),
    withLatestFrom(this.store.pipe(select(getClientListLoadMoreOptions))),
    map(async ([action, options]) => {
      const clients = await this.clientsService.getClients({
        filter: options.filter,
        limit: options?.pagination?.limit,
        skip: options?.pagination?.skip,
        sort: options.sort,
      });

      this.store.dispatch(new LoadMoreClientsSuccess(clients));
    })
  );

  @Effect({ dispatch: false })
  sortClients$ = this.actions$.pipe(
    ofType<SortClients>(ClientsActionTypes.SORT_CLIENTS),
    withLatestFrom(this.store.pipe(select(getClientListFilter))),
    map(([action, filter]) => {
      this.store.dispatch(new LoadClients({ filter, sort: action.payload, limit: 50, skip: 0 }));
    })
  );

  @Effect({ dispatch: false })
  filterClients$ = this.actions$.pipe(
    ofType<FilterClients>(ClientsActionTypes.FILTER_CLIENTS),
    withLatestFrom(this.store.pipe(select(getClientListSort))),
    map(([action, sort]) => {
      this.store.dispatch(new LoadClients({ filter: action.payload, sort, limit: 50, skip: 0 }));
    })
  );

  @Effect({ dispatch: false })
  selectClient$ = this.actions$.pipe(
    ofType<SelectClient>(ClientsActionTypes.SELECT_CLIENT),
    map((action: SelectClient) => {
      // TODO
      // this.ordersService.getInitOrder(action.payload.id).then((order) => {
      this.orderRequestsService.getClientOrder(action.payload.id, OrderStatus.Initial).then((order) => {
        if (order !== null) {
          this.store.dispatch(new LoadOrder(order));
        } else {
          this.store.dispatch(new CreateInitOrder(action.payload.id));
        }
      })
      this.router.navigate(['/articles']);
    })
  );

  @Effect({ dispatch: false })
  openClientDetails$ = this.actions$.pipe(
    ofType<OpenClientDetails>(ClientsActionTypes.OPEN_CLIENT_DETAILS),
    map((action: OpenClientDetails) => {
      this.store.dispatch(new LoadClient(action.payload.id));
    })
  );

  @Effect({ dispatch: false })
  loadClient$ = this.actions$.pipe(
    ofType<LoadClient>(ClientsActionTypes.LOAD_CLIENT),
    map((action: LoadClient) =>
      this.clientsService.getClient(action.payload).then((client) => {
        this.store.dispatch(new LoadClientSuccess(client));
      })
    )
  );

  @Effect({ dispatch: false })
  loadClientSucess$ = this.actions$.pipe(
    ofType<LoadClientSuccess>(ClientsActionTypes.LOAD_CLIENT_SUCCESS),
    map((action: LoadClientSuccess) => {
      this.router.navigate(['/client-details']);
    })
  );

  @Effect({ dispatch: false })
  editClient$ = this.actions$.pipe(
    ofType<EditClient>(ClientsActionTypes.EDIT_CLIENT),
    map((action: EditClient) => {
      const dialog = this.dialog.open(UpdateClientComponent, {
        width: '860px',
        data: action.payload
      });
      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.store.dispatch(new UpdateClient(data));
        }
      });
    })
  );

  @Effect({ dispatch: false })
  addNewClientRequest$ = this.actions$.pipe(
    ofType<AddNewClientRequest>(ClientsActionTypes.ADD_NEW_CLIENT_REQUEST),
    withLatestFrom(this.store.pipe(select(getAuthenticatedUser))),
    map(([action, user]) => {
      const dialog = this.dialog.open(NewClientDetailComponent, {
        width: '800px',
        autoFocus: false
      });
      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.store.dispatch(new SaveNewClientRequest({ clientReq: data, userId: user.id }));
        }
      });
    })
  );

  @Effect({ dispatch: false })
  saveNewClientRequest = this.actions$.pipe(
    ofType<SaveNewClientRequest>(ClientsActionTypes.SAVE_NEW_CLIENT_REQUEST),
    map((action: SaveNewClientRequest) => {
      this.clientsRequestService.createClientRequest(action.payload.clientReq, action.payload.userId)
        .then(() => {
          this.notificationService.success(this.translateService.instant('client-request.save-alert'));
        });
    })
  );

  @Effect({ dispatch: false })
  visitClient = this.actions$.pipe(
    ofType<AddNewVisit>(ClientsActionTypes.ADD_NEW_VISIT),
    withLatestFrom(this.store.pipe(select(getAuthenticatedUser))),
    map(([action, user]) => {
      const visitModel: VisitModel = {
        id: null,
        clientId: action.payload.id,
        clientName: action.payload.name,
        codeVrp: (user as any).codeVrp
      }
      const dialog = this.dialog.open(VisitComponent, {
        width: '60vw',
        height: '45vh',
        data: visitModel
      });
      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.store.dispatch(new SaveNewVisit(data));
        }
      });
    })
  );

  @Effect({ dispatch: false })
  saveNewVisit = this.actions$.pipe(
    ofType<SaveNewVisit>(ClientsActionTypes.SAVE_NEW_VISIT),
    map((action: SaveNewVisit) => {
      this.visitsService.createVisit(action.payload);
      this.notificationService.success(this.translateService.instant('visit-client-popup.message'));
    })
  );

  @Effect({ dispatch: false })
  navigateToClient = this.actions$.pipe(
    ofType<NavigateToClient>(ClientsActionTypes.NAVIGATE_TO_CLIENT),
    map((action: NavigateToClient) => {
      const data = action.payload;
      if (data.latitude && data.longitude) {
        window.open(this.mapService.getMapUrlByLatitudeAndLongitude(data.latitude, data.longitude));
      } else {
        window.open(this.mapService.getMapUrlByAddresse(data.city, data.address1, data.postalCode));
      }

    })
  );

  @Effect()
  saveClient$ = this.actions$.pipe(
    ofType<UpdateClient>(ClientsActionTypes.UPDATE_CLIENT),
    mergeMap((action: UpdateClient) =>
      this.clientsService.updateClient(action.payload)
        .then((client) => {
          this.notificationService.success(this.translateService.instant('client-request.update-success'));
          return new UpdateClientSuccess(client)
        })
    )
  );

  @Effect()
  loadClientInvoiceList$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadClientInvoiceList>(ClientsActionTypes.LOAD_CLIENT_INVOICE_LIST),
    mergeMap((action: LoadClientInvoiceList) =>
      this.invoicesService.getUnpaidInvoices(action.payload)
        .then((invoices) => {
          return new LoadClientInvoiceListSuccess(invoices)
        })
    )
  );

  @Effect()
  loadClientById$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadClientById>(
      ClientsActionTypes.LOAD_CLIENT_BY_ID
    ),
    mergeMap((action: LoadClientById) =>
      this.clientsService.getClient(action.payload).then((client) => {
        return new LoadClientByIdSuccess(client)
      })
    )
  );

}
