export const ConfigKeys = {
  database: {
    url: "database.url",
    db: "database.db",
  },
  translate: {
    language: "translate.language",
  },
  logger: {
    level: "logger.level",
    serverLogLevel: "logger.serverLogLevel",
    serverLoggingUrl: "logger.serverLoggingUrl",
  },
  notifications: {
    closeButton: "notifications.closeButton",
    timeOut: "notifications.timeOut",
  },
  APP_TIMEOUT: "APP_TIMEOUT",
  CHECK_APP_TIMEOUT_INTERVAL: "CHECK_APP_TIMEOUT_INTERVAL",
  SYNC_APP_INTERVAL: "SYNC_APP_INTERVAL",
  SYNC_REQUIRED_INTERVAL: "SYNC_REQUIRED_INTERVAL",
  IMAGES_ROOT_FOLDER: "IMAGES_ROOT_FOLDER",
  api: {
    url: "api.url",
  },
};
