import { Injectable } from '@angular/core';
import { Address, Client, ClientPrice, NotificationType } from '@maxel-order/shared';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { GetClientOptions } from '../../interfaces/clients.interface';
import { PROSPECT_CLIENT } from '../../modules/clients/clients.module';
import { ClientStatus } from '../../modules/clients/enums/client-status.enum';
import { ClientPricesModel } from '../../modules/clients/models/client-prices.model';
import { ClientModel } from '../../modules/clients/models/client.model';
import { PriceCodeModel } from '../../modules/clients/models/price-code.model';
import { UpdateClientModel } from '../../modules/clients/models/update-client.model';
import { ClientPricesDAO, ClientsDAO, CompaniesDAO } from '../dao';
import { NotificationsService } from '../notifications/notifications.service';
import { ClientListRowModel } from './../../modules/clients/models/client-list-row.model';
import { UsersService } from './users.service';
import { VrpProviderService } from './vrp-provider.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  
  constructor(
    private clientsDAO: ClientsDAO,
    private companiesDAO: CompaniesDAO,
    private clientPricesDAO: ClientPricesDAO,
    private readonly usersService: UsersService,
    private readonly vrpProviderService: VrpProviderService,
    private readonly notificationsService: NotificationsService,
  ) { }

  private async getDeliveryAddressKey(){
    const companyForClients = await (await this.companiesDAO.getAll()).find(company => company.isCompanyForClients);

    return `${companyForClients.name}DeliveryAddress`;
  } 

  public async getClientPrices(clientId: string): Promise<ClientPricesModel> {
    const generalPrices: PriceCodeModel[] = [];
    const specialPrices: PriceCodeModel[] = [];

    const clientPrices = await this.clientPricesDAO.getAll();
    const companies = await this.companiesDAO.getAll();

    const clientPricesById = new Map<string, ClientPrice>();
    for (const clientPrice of clientPrices) {
      clientPricesById.set(clientPrice.id, clientPrice);
    }

    for (const company of companies) {
      const clientPrice = clientPricesById.get(`${clientId}_${company.id}`);
      if (!clientPrice) {
        continue;
      }

      if (clientPrice.generalPriceCode && clientPrice.generalPriceCode !== '') {
        generalPrices.push({ companyId: company.id, code: clientPrice.generalPriceCode });
      }
      if (clientPrice.specialPriceCode && clientPrice.specialPriceCode !== '') {
        specialPrices.push({ companyId: company.id, code: clientPrice.specialPriceCode });
      }
    }

    return {
      generalPrice: generalPrices,
      specialPrice: specialPrices,
    };
  }

  public async getClient(clientId: string): Promise<ClientModel> {
    let clientModel: ClientModel;

    try {
      const clientPrice = await this.getClientPrices(clientId);
      const deliveryAddressKey = await this.getDeliveryAddressKey(); 
      const client = await this.clientsDAO.get(clientId);
      clientModel = this.toClientModel(client, deliveryAddressKey);

      clientModel.generalPrice = clientPrice.generalPrice
      clientModel.specialPrice = clientPrice.specialPrice;

    } catch (err) {
      console.error('[ClientsService:getClient]', err);
    }

    return clientModel;
  }

  public async getClientById(clientId: string): Promise<ClientListRowModel> {
    let clientModel: ClientListRowModel;

    try {
      const deliveryAddressKey = await this.getDeliveryAddressKey();      
      const client = await this.clientsDAO.get(clientId);
      clientModel = this.toClientListRowModel(client, deliveryAddressKey);

    } catch (err) {
      console.error('[ClientsService:getClientById]', err);
    }

    return clientModel;
  }

  public async updateClient(updateClient: UpdateClientModel): Promise<UpdateClientModel> {

    const client = await this.clientsDAO.get(updateClient.id);
    const oldClient = cloneDeep(client);

    client.contactEmail = updateClient.contactEmail;

    const deliveryAddressKey = await this.getDeliveryAddressKey();

    if (client[deliveryAddressKey]) {
      client[deliveryAddressKey].address1 = updateClient.address1;
      client[deliveryAddressKey].address2 = updateClient.address2;
      client[deliveryAddressKey].address3 = updateClient.address3;
      client[deliveryAddressKey].zipCode = updateClient.postalCode;
      client[deliveryAddressKey].city = updateClient.city;
      client[deliveryAddressKey].country = updateClient.country;
      client[deliveryAddressKey].phone = updateClient.phone;
      client[deliveryAddressKey].storeContactName = updateClient.storeContactName;
    }

    const addressChanged = this.addressChanged(oldClient[deliveryAddressKey], client[deliveryAddressKey]);

    if (addressChanged) {
      client.latitude = null;
      client.longitude = null;
    }

    client.changeDate = moment.utc().toISOString();
    client.updatedBy = await this.usersService.getCurrentUserId();

    await this.clientsDAO.update(client);

    await this.notificationsService.send(NotificationType.ClientUpdated, {
      clientId: client.id,
      vrp: await this.vrpProviderService.getCurrentCodeVrp(),
    });

    return updateClient;
  }

  public async getClients(options: GetClientOptions): Promise<ClientListRowModel[]> {
    const { filter, sort, limit, skip } = options;

    const result = await this.clientsDAO.getAll();

    const deliveryAddressKey = await this.getDeliveryAddressKey();

    let clients = result.map(client => this.toClientListRowModel(client, deliveryAddressKey));

    let prospectClient = null;
    const prospectIndex = clients.findIndex(c => c.id === PROSPECT_CLIENT);
    if (prospectIndex >= 0) {
      prospectClient = clients[prospectIndex];
      clients = clients.filter(c => c.id !== PROSPECT_CLIENT);
    }

    if (filter?.status === ClientStatus.Actif) {
      clients = clients.filter(c => c.status === ClientStatus.Actif);
    }

    if (filter?.anyFieldContains) {
      clients = clients.filter(o => {
        return Object.keys(o)
          .some(k => {
            const value = o[k] && (o[k] + '');
            if (!value) {
              return false;
            }
            return value.toLowerCase().includes(
              filter.anyFieldContains.toLowerCase()
            );
          });
      });
    }

    clients = clients.sort((a, b) => (a.name > b.name) ? 1 : -1);

    if (sort && sort.order !== '') {
      if (sort.order === 'asc') {
        clients = clients.sort((a, b) => (a[sort.key].toUpperCase() > b[sort.key].toUpperCase()) ? 1 : -1);
      } else {
        clients = clients.sort((a, b) => (a[sort.key].toUpperCase() < b[sort.key].toUpperCase()) ? 1 : -1);
      }
    }

    if (prospectClient !== null) {
      clients.unshift(prospectClient);
    }

    clients = clients.slice(skip, skip + limit);

    clients = clients.map(client => ({
      ...client,
      visibleStatus: `client-status.${client.status}`,
      hasActionsVisible: client.name !== PROSPECT_CLIENT && client.status !== ClientStatus.Interdit
    }))

    return clients;
  }

  private prospectClient(): ClientListRowModel {
    return {
      id: PROSPECT_CLIENT,
      name: PROSPECT_CLIENT,
      status: ClientStatus.Actif,
      city: PROSPECT_CLIENT,
      storeContactName: PROSPECT_CLIENT,
      address1: '',
      postalCode: ''
    }
  }

  private toClientListRowModel(client: Client, deliveryAddressKey: string): ClientListRowModel {
    if (client.id === PROSPECT_CLIENT || client.companyName.toUpperCase() === PROSPECT_CLIENT) {
      return this.prospectClient();
    }

    const clientModel = {
      id: client.id,
      name: client.companyName,
      status: client.status,
      latitude: client.latitude,
      longitude: client.longitude,
    } as ClientListRowModel;   

    if (client[deliveryAddressKey]) {
      clientModel.city = client[deliveryAddressKey].city;
      clientModel.address1 = client[deliveryAddressKey].address1;
      clientModel.postalCode = client[deliveryAddressKey].zipCode;
      clientModel.storeContactName = client[deliveryAddressKey].storeContactName;
    }

    return clientModel;
  }

  private toClientModel(client: Client, deliveryAddressKey: string): ClientModel {
    const clientModel = {
      id: client.id,
      name: client.companyName,
      status: client.status,
      commercialSignType: client.commercialSignType,
      nationalCentral: client.nationalCentral,
      regionalCentral: client.regionalCentral,
      insurance: client.insurance,
      insuranceOutstanding: client.outstandingAmountAvailable,
      contactEmail: client.contactEmail,
      siren: client.siren,
      siret: client.siret,
      tvaNumber: client.vat,
      carriageFree: client.carriageFree,
      language: client.language,
      lastOrderDate: moment.utc(client.lastOrderDate).toDate(),
      orderAmountAverage12Months: client.orderAmountAverage12Months,
      totalAmountOrdered12Months: client.totalAmountOrdered12Months,
      paymentPeriod: client.paymentPeriod,
    } as ClientModel;

    if (client[deliveryAddressKey]) {
      clientModel.city = client[deliveryAddressKey].city;
      clientModel.country = client[deliveryAddressKey].country;
      clientModel.address1 = client[deliveryAddressKey].address1;
      clientModel.address2 = client[deliveryAddressKey].address2;
      clientModel.address3 = client[deliveryAddressKey].address3;
      clientModel.postalCode = client[deliveryAddressKey].zipCode;
      clientModel.storeContactName = client[deliveryAddressKey].storeContactName;
      clientModel.phone = client[deliveryAddressKey].phone;
    }

    return clientModel;
  }

  private calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const RADIANS: number = 180 / 3.14159265;
    const R = 6371e3; // metres
    const φ1 = lat1 / RADIANS;
    const φ2 = lat2 / RADIANS;
    const Δφ = (lat2 - lat1) / RADIANS;
    const Δλ = (lon2 - lon1) / RADIANS;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c;

    return d / 1000;
  }

  private addressChanged(old: Address, updated: Address): boolean {
    return old.address1 !== updated.address1
      || old.address2 !== updated.address2
      || old.address3 !== updated.address3;
  }

}
