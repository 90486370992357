import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState } from './users.state';

const getUsersState = createFeatureSelector<UsersState>(
  'users'
);

export const getUsers = createSelector(
  getUsersState,
  (state: UsersState) => state.users
);
