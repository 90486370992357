import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcListComponent, TcListFilterType, TcListSortType, TcSmartComponent } from '@tc/core';

import { UserListModel } from '../../../models/user-list.model';
import { AddNewUser, DeleteUser, EditUser } from '../../../store/users.actions';
import { getUsers } from '../../../store/users.selectors';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent extends TcSmartComponent implements OnInit {

  @ViewChild('colActionEditTemplate', { static: true }) colActionEditTemplate: TemplateRef<any>;

  @ViewChild('passwordTemplate', { static: true }) passwordTemplate: TemplateRef<any>;
  @ViewChild('emailTemplate', { static: true }) emailTemplate: TemplateRef<any>;
  @ViewChild('firstNameTemplate', { static: true }) firstNameTemplate: TemplateRef<any>;
  @ViewChild('lastNameTemplate', { static: true }) lastNameTemplate: TemplateRef<any>;
  @ViewChild('profilTemplate', { static: true }) profilTemplate: TemplateRef<any>;

  userList: TcListComponent;

  @ViewChild('userList', { static: true }) set setuserList(values: TcListComponent) {
    this.userList = values;
  }

  constructor(
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit() {
    this.userList.rows$ = this.store.pipe(select(getUsers));
    this.userList.isFiltrable = false;
    this.userList.filterType = TcListFilterType.Disabled;
    this.userList.sortType = TcListSortType.Client;
    this.userList.isPaged = false;
    this.userList.hasFixedHeader = true;
    this.userList.hasAddButton = true;

    this.userList.onRowClick = (row: UserListModel) => {
    }

    this.userList.columns = [
      {
        propertyName: 'firstName',
        visible: true,
        htmlTemplate: this.firstNameTemplate
      },
      {
        propertyName: 'lastName',
        visible: true,
        htmlTemplate: this.lastNameTemplate
      },
      {
        propertyName: 'login',
        visible: true
      },
      {
        propertyName: 'email',
        visible: true,
        htmlTemplate: this.emailTemplate
      },
      {
        propertyName: 'codeVrp',
        visible: true
      },
      {
        propertyName: 'tabletCode',
        visible: true
      },
      {
        propertyName: 'profil',
        visible: true,
        htmlTemplate: this.profilTemplate
      }
    ];

    this.userList.rowActions = [
      {
        actionName: 'edit',
        visible: true,
        hasText: false,
        htmlTemplate: this.colActionEditTemplate
      },
      {
        actionName: 'delete',
        visible: true,
        hasText: false,
        //htmlTemplate: this.colActionEditTemplate
      },
    ];
  }

  editUser = (row: UserListModel) => {
    this.store.dispatch(new EditUser(row));
  }

  addUser = () => {
    this.store.dispatch(new AddNewUser());
  }

  deleteUser = (row: UserListModel) => {
    this.store.dispatch(new DeleteUser(row));
  }
}
