import { TcCoreModule } from '@tc/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { mainReducer } from './store/main.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MainEffects } from './store/main.effects';
import { HomeComponent } from './components/smart/home/home.component';
import { BackNavigationComponent } from './components/smart/back-navigation/back-navigation.component';
import { PageMenuComponent } from './components/smart/page-menu/page-menu.component';
import { PageTitleComponent } from './components/smart/page-title/page-title.component';
import { UserInfoComponent } from './components/smart/user-info/user-info.component';
import { VisitComponent } from './components/dumb/visit/visit.component';
import { CustomClientTitleComponent } from './components/smart/custom-client-title/custom-client-title.component';
import { ClientLogoComponent } from './components/dumb/client-logo/client-logo.component';
import { PhotoSelectComponent } from './components/dumb/photo-select/photo-select.component';

const smartComponents = [
  HomeComponent,
  BackNavigationComponent,
  PageMenuComponent,
  PageTitleComponent,
  UserInfoComponent,
  CustomClientTitleComponent
];
const dumbComponents = [
  VisitComponent,
  ClientLogoComponent,
  PhotoSelectComponent
];

@NgModule({
  declarations: [
    ...smartComponents,
    ...dumbComponents,
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    StoreModule.forFeature('main', mainReducer),
    EffectsModule.forFeature([
      MainEffects,
    ]),
  ],
  exports: [
    ...smartComponents,
    ...dumbComponents
  ]
})
export class MainModule { }
