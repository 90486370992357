import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientsState } from './clients.state.interface';

const getClientsState = createFeatureSelector<ClientsState>(
  'clients'
);

export const getClients = createSelector(
  getClientsState,
  (state: ClientsState) => state.clients
);

export const getSelectedClient = createSelector(
  getClientsState,
  (state: ClientsState) => state.selectedClient
);

export const getSelectedClientId = createSelector(
  getClientsState,
  (state: ClientsState) => state.selectedClient.id
);

export const getClientActionList = createSelector(
  getClientsState,
  (state: ClientsState) => state.clientActionList
);

export const getClientInvoiceList = createSelector(
  getClientsState,
  (state: ClientsState) => state.clientInvoiceList
);

export const getClientListFilter = createSelector(
  getClientsState,
  (state: ClientsState) => state.filter
);

export const getClientListSort = createSelector(
  getClientsState,
  (state: ClientsState) => state.sort
);

export const getClientListPagination = createSelector(
  getClientsState,
  (state: ClientsState) => state.pagination
);

export const getClientListLoadMoreOptions = createSelector(
  getClientListFilter,
  getClientListSort,
  getClientListPagination,
  (filter, sort, pagination) => ({ filter, sort, pagination }),
);
