import { cancelSyncReducer } from './reducers/cancel-sync.reducer';
import { syncProgressPartitionStartReducer } from './reducers/sync-progress-partition-start.reducer';
import { syncProgressResetReducer } from './reducers/sync-progress-reset.reducer';
import { syncProgressUpdatedReducer } from './reducers/sync-progress-updated.reducer';
import { SyncAgentActions, SyncAgentActionTypes } from './sync-agent.actions';
import { initialSyncAgentState, SyncAgentState } from './sync-agent.state';

export function syncAgentReducer(
  state = initialSyncAgentState,
  action: SyncAgentActions
): SyncAgentState {

  switch (action.type) {

    case SyncAgentActionTypes.SYNC_PROGRESS_UPDATED:
      return syncProgressUpdatedReducer(state, action.payload);

    case SyncAgentActionTypes.SYNC_PROGRESS_RESET:
      return syncProgressResetReducer(state);

    case SyncAgentActionTypes.SYNC_PROGRESS_PARTITION_STARTED:
      return syncProgressPartitionStartReducer(state, action.payload);

    case SyncAgentActionTypes.CANCEL_SYNC:
      return cancelSyncReducer(state);

    case SyncAgentActionTypes.SYNC_REQUIRED: {
      return {
        ...state,
        syncRequired: true
      };
    }

    case SyncAgentActionTypes.FIRST_SYNC_FINISHED: {
      return {
        ...state,
        firstSyncFinished: true,
      };
    }

    case SyncAgentActionTypes.SET_LAST_SYNC_DB_VERSION: {
      return {
        ...state,
        lastSyncDBVersion: action.payload,
      };
    }

    default:
      return state;

  }
}
