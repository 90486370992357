import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { Subscription } from 'rxjs';
import { SyncProgressService, SyncTimerStats } from '../../../../../services/business-services/sync-progress.service';
import { CancelSync, StartSync } from '../../../store/sync-agent.actions';
import { getSyncProgress } from '../../../store/sync-agent.selectors';
import { VrpProviderService } from '../../../../../services/business-services/vrp-provider.service';
import { ClientAllVrp } from '../../../../clients/enums/client-status.enum';

@Component({
  selector: 'app-sync-agent-popup',
  templateUrl: './sync-agent-popup.component.html',
  styleUrls: ['./sync-agent-popup.component.css']
})
export class SyncAgentPopupComponent extends TcSmartComponent implements OnInit, OnDestroy {

  progressSubscription: Subscription;

  displayProgressBar = false;

  timer;
  timerStats = {} as SyncTimerStats;

  hasCloseButton = true;
  public totalPartitions = 16;

  constructor(
    private dialogRef: MatDialogRef<SyncAgentPopupComponent>,
    private store: Store<any>,
    private syncProgressService: SyncProgressService,
    private vrpProviderService: VrpProviderService,
    @Optional() @Inject(MAT_DIALOG_DATA) public disableClose: boolean,
  ) {
    super();
  }

  async ngOnInit() {
    this.hasCloseButton = !this.disableClose;
    
    const vrp = await this.vrpProviderService.getCurrentCodeVrp()
    this.totalPartitions = (vrp.toLowerCase() === ClientAllVrp) ? 13 : 16;

    this.progressSubscription = this.store.pipe(select(getSyncProgress))
      .subscribe(progress => {
        if (!progress || !progress.length) {
          return;
        }

        const { current, total, finished, error } = progress[progress.length - 1];
        if (total === (current + error) && progress.length === this.totalPartitions && finished) {
          this.onClose();
        }
      });
  }

  onClose() {
    this.dialogRef.close();
    clearInterval(this.timer);
  }

  startSync() {
    if (this.displayProgressBar) {
      return;
    }

    this.startTimer();
    this.store.dispatch(new StartSync());

    this.displayProgressBar = true;
  }

  cancel() {
    this.dialogRef.close();
    this.store.dispatch(new CancelSync());
  }

  private async startTimer() {
    await this.syncProgressService.start();

    this.timer = setInterval(async () => {
      this.timerStats = await this.syncProgressService.get();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
  }

}
