export class ClientBaseModel {
  id: string;
  name: string;
  status: string;
  storeContactName?: string;
  city?: string;
  address1?: string;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
}
