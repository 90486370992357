import { SyncProgress } from '../../models/sync-progress.model';
import { SyncAgentState } from '../sync-agent.state';

export function syncProgressUpdatedReducer(
  state: SyncAgentState,
  payload: SyncProgress,
): SyncAgentState {

  return {
    ...state,
    progress: [
      ...state.progress.slice(0, -1),
      {
        ...payload,
        finished: payload.current + payload.error >= payload.total,
      },
    ],
  };
}

