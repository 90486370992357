export type UserProperty = | 'id' | 'codeVrp' | 'login' | 'password' | 'lastName' | 'firstName' | 'email' | 'phone' | 'profil' | 'deviceId' ;

export const UserProperty = {
  id: 'id' as UserProperty,
  codeVrp: 'codeVrp' as UserProperty,
  login: 'login' as UserProperty,
  password: 'password' as UserProperty,
  lastName: 'lastName' as UserProperty,
  firstName: 'firstName' as UserProperty,
  email: 'email' as UserProperty,
  phone: 'phone' as UserProperty,
  profil: 'profil' as UserProperty,
  deviceId: 'deviceId' as UserProperty,
}
