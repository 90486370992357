import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatisticsFilter, StatisticsTypes } from '@maxel-order/shared';
import { TranslateService } from '@ngx-translate/core';
import { TcGenericListColumn, TcLoggerService } from '@tc/core';
import { ConfigKeys } from '../../../services/config/config.interface';
import { ConfigService } from '../../../services/config/config.service';

@Injectable()
export class StatisticsService {
  private baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigService,
    private readonly logger: TcLoggerService,
    private readonly translate: TranslateService,
  ) {
    this.baseUrl = `${this.config.get(ConfigKeys.api.url)}/api/resources/statistics`;
  }

  public async getItems(type: StatisticsTypes, filters: StatisticsFilter): Promise<any[]> {
    try {
      return await this.http.post(`${this.baseUrl}/${type}`, filters).toPromise() as any[];
    } catch (error) {
      this.logger.error(error);

      return [];
    }
  }

  public getListColumns(statisticType: StatisticsTypes) {
    switch (statisticType) {
      case StatisticsTypes.A:
        return this.getListColumnsAType();
      case StatisticsTypes.B:
        return this.getListColumnsBType();
      case StatisticsTypes.C1:
        return this.getListColumnsC1Type();
      case StatisticsTypes.C2:
        return this.getListColumnsC2Type();
      case StatisticsTypes.D:
        return this.getListColumnsDType();
      case StatisticsTypes.E:
        return this.getListColumnsEType();
      case StatisticsTypes.F:
        return this.getListColumnsFType();
      default:
        return []
    }
  }

  private getListColumnsAType(): TcGenericListColumn[] {
    return [
      { propertyName: 'department', visible: true },
      { propertyName: 'salesYearMinusTwo', visible: true },
      { propertyName: 'variationYearMinusTwo', visible: true },
      { propertyName: 'salesYearMinusOne', visible: true },
      { propertyName: 'variationYearMinusOne', visible: true },
      { propertyName: 'salesCurrent', visible: true },
      { propertyName: 'variationCurrent', visible: true },
      { propertyName: 'variationGlobal', visible: true },
    ];
  }

  private getListColumnsBType(): TcGenericListColumn[] {
    return [
      { propertyName: 'department', visible: true },
      { propertyName: 'client', visible: true },
      { propertyName: 'salesYearMinusOne', visible: true },
      { propertyName: 'salesCurrent', visible: true },
      { propertyName: 'variation', visible: true }
    ];
  }
  private getListColumnsC1Type() {
    return [
      { propertyName: 'id', visible: true },
      { propertyName: 'family', visible: true },
      { propertyName: 'name', visible: true },
      { propertyName: 'salesYearMinusOne', visible: true },
      { propertyName: 'salesCurrent', visible: true },
      { propertyName: 'variation', visible: true },
    ];
  }

  private getListColumnsC2Type() {
    return [
      { propertyName: 'id', visible: true },
      { propertyName: 'family', visible: true },
      { propertyName: 'name', visible: true },
      { propertyName: 'salesYearMinusOne', visible: true },
      { propertyName: 'salesCurrent', visible: true },
      { propertyName: 'variation', visible: true },
    ];
  }

  private getListColumnsDType() {
    return [
      { propertyName: 'name', visible: true },
      { propertyName: 'weightYearMinusOne', visible: true },
      { propertyName: 'salesYearMinusOne', visible: true },
      { propertyName: 'weightCurrent', visible: true },
      { propertyName: 'salesCurrent', visible: true },
      { propertyName: 'variation', visible: true },
    ];
  }

  private getListColumnsEType() {
    return [
      { propertyName: 'id', visible: true },
      { propertyName: 'family', visible: true },
      { propertyName: 'name', visible: true },
      { propertyName: 'salesYearMinusOne', visible: true },
      { propertyName: 'salesCurrent', visible: true },
      { propertyName: 'variation', visible: true },
    ];
  }

  private getListColumnsFType() {
    return [
      { propertyName: 'company', visible: true },
      { propertyName: 'family', visible: true },
      { propertyName: 'weightYearMinusOne', visible: true },
      { propertyName: 'salesYearMinusOne', visible: true },
      { propertyName: 'weightCurrent', visible: true },
      { propertyName: 'salesCurrent', visible: true },
      { propertyName: 'variation', visible: true },
    ];
  }

}
