<tc-dialog (close)="onClose()">

    <div tc-dialog-title>
        <h3 mat-dialog-title>
            <span *ngIf="data && !data.id">{{'visit-client-popup.new-title' | translate}}</span>
            <span *ngIf="data && data.id">{{'visit-client-popup.view-title' | translate}}</span>
        </h3>
    </div>

    <div tc-dialog-content>
        <div class="date">
            {{ data && data.clientName }} -
            <span *ngIf="data && !data.id">{{ data && data.date }}</span>
            <span *ngIf="data && data.id">{{ data && data.date | date: 'dd/MM/yyyy' }}</span>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit(model)" id="visit-client-popup">

            <mat-dialog-content>
                <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
            </mat-dialog-content>

            <mat-dialog-actions *ngIf="data && !data.id">
                <button mat-raised-button color="primary" type="submit" class="save-button" tcPreventDoubleClick>{{'visit-client-popup.buttons.save' | translate}}</button>
            </mat-dialog-actions>

        </form>
    </div>

    <mat-dialog-actions>
    </mat-dialog-actions>

</tc-dialog>
