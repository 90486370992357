import { Injectable } from '@angular/core';
import { ArticleOrderHistoryEntityType, OrderLineEntityType, SyncStatusEntityType, OrderRequestLineEntityType } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { SetLastActivity } from '../../modules/auth/store/auth.actions';
import { getLastUser } from '../../modules/auth/store/auth.selectors';
import { ClientAllVrp } from '../../modules/clients/enums/client-status.enum';
import {
  DisplaySyncFinishPopup,
  FirstSyncFinished,
  HideSyncPopup,
  SetLastSyncDBVersion,
  SyncProgressPartitionStarted,
  SyncProgressReset,
  SyncProgressUpdated
} from '../../modules/sync-agent/store/sync-agent.actions';
import { getSyncStopRequest } from '../../modules/sync-agent/store/sync-agent.selectors';
import { ConfigKeys } from '../config/config.interface';
import { ConfigService } from '../config/config.service';
import { DatabaseService } from '../database/database.service';
import { ImageService } from '../images.service';
import { PartitionsService } from '../partitions/partitions.service';
import { RepositoryService } from '../repository/repository.service';
import { VersionService } from '../version.service';
import { SyncStatusService } from './sync-status.service';
import { VrpProviderService } from './vrp-provider.service';

@Injectable({
  providedIn: 'root'
})
export class SyncAgentService {

  constructor(
    private readonly store$: Store<any>,
    private readonly config: ConfigService,
    private readonly database: DatabaseService,
    private readonly imageService: ImageService,
    private readonly versionService: VersionService,
    private readonly syncStatusService: SyncStatusService,
    private readonly repositoryService: RepositoryService,
    private readonly partitionsService: PartitionsService,
    private readonly vrpProviderService: VrpProviderService,
  ) { }

  public async start() {
    this.store$.dispatch(new SyncProgressReset());

    const start = +new Date();
    let partitions = await this.partitionsService.all();

    const vrp = await this.vrpProviderService.getCurrentCodeVrp();

    partitions = partitions.filter(({ lowerName }) => lowerName !== SyncStatusEntityType.lowerName);
    if (vrp.toLowerCase() === ClientAllVrp) {
      partitions = partitions.filter(
        ({ lowerName }) => lowerName !== ArticleOrderHistoryEntityType.lowerName && lowerName !== OrderLineEntityType.lowerName && lowerName !== OrderRequestLineEntityType.lowerName
      );
    }

    await this.syncData(partitions);
    this.repositoryService.refresh();
    
    await this.imageService.cacheMissingImages();
    const finish = +new Date();

    await this.syncStatusService.track(finish - start);
    this.store$.dispatch(new FirstSyncFinished());
    this.store$.dispatch(new HideSyncPopup());
    this.store$.dispatch(new DisplaySyncFinishPopup());

    const currentDBVersion = this.versionService.getDBVersion();
    this.store$.dispatch(new SetLastSyncDBVersion(currentDBVersion));
  }

  private async syncData(partitions: any[]) {
    for (const partition of partitions) {
      await this.syncPartition(partition);
    }
  }

  private async syncPartition(partition: any) {
    const dbName = partition.dbName;
    const entityLowerName = partition.lowerName;

    this.store$.dispatch(new SyncProgressPartitionStarted(dbName));

    if (await this.isSyncStopped()) {
      this.store$.dispatch(new SyncProgressUpdated({
        type: dbName,
        total: 0,
        current: 1,
        error: 0
      }));
      return;
    }

    return new Promise(async (resolve, reject) => {
      const url = `${this.config.get(ConfigKeys.database.url)}/${dbName}`;

      const lastUser = await this.store$.select(getLastUser).pipe(take(1)).toPromise();
      const connection = this.database.get(dbName);

      const isPush = this.partitionsService.isPush(entityLowerName);

      const syncMethod = isPush ? connection.replicate.to : connection.replicate.from;
      
      const onChange = (changes) => {
        const docCount = isPush ? changes.docs_written : changes.docs_read;
        this.store$.dispatch(new SyncProgressUpdated({
          type: dbName,
          total: (changes['pending'] ?? 0) + docCount,
          current: docCount, 
          error: 0
        }));

        this.store$.dispatch(new SetLastActivity());
      }

      syncMethod(
        this.database.getRemoteConnection(url, lastUser.token), {
        retry: true,
        batch_size: 5000,
      })
        .on('change', onChange)
        .on('complete', (changes) =>{
          onChange(changes);
          resolve(changes);
        })
        .on('error', (error) =>{
          console.error(error);
          reject(error);
        })
    });
  }

  private isSyncStopped() {
    return this.store$.select(getSyncStopRequest).pipe(take(1)).toPromise();
  }
}
