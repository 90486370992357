import { EditClient } from './../../../store/clients.actions';
import { NavigateToClient } from '../../../store/clients.actions';
import { Component, OnInit, Input } from '@angular/core';
import { ClientModel } from '../../../models/client.model';
import { UserModel } from '../../../../auth/models/user.model';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { PermissionsService } from '../../../../../services/permissions/permissions.service';

@Component({
  selector: 'app-client-detail-info',
  templateUrl: './client-detail-info.component.html',
  styleUrls: ['./client-detail-info.component.scss']
})
export class ClientDetailInfoComponent extends TcSmartComponent implements OnInit {

  @Input()
  client: ClientModel;

  @Input()
  user: UserModel;

  public hasWriteAccess = true;

  constructor(
    private store: Store<any>,
    private readonly permissionsService: PermissionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.permissionsService.hasAccess().then((hasAccess) => this.hasWriteAccess = hasAccess);
  }

  getAddress(): string {
    return [
      this.client.address1,
      this.client.address2,
      this.client.address3,
      this.client.postalCode,
      this.client.city,
      this.client.country
    ]
      .filter(r => r !== undefined && r !== null && r !== '')
      .join(', ');
  }

  edit() {
    this.store.dispatch(new EditClient(this.client));
  }

  directionMap(client: ClientModel) {
    this.store.dispatch(new NavigateToClient(client));
  }
}
