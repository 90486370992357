import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { FilterData } from '../components/smart/order-list/types';
import { OrderListModel } from '../models/order-list.model';
import { OrdersListService } from './orders-list.service';

@Injectable()
export class OrdersListQueryService {

  constructor(
    private readonly ordersListService: OrdersListService,
  ) { }

  async init() {
    await this.ordersListService.init();
  }

  get(
    filter: FilterData,
    skip: number,
    limit: number,
    sort: string,
  ): {
    items: OrderListModel[], totalItems: number,
  } {

    let items = this.ordersListService.get();

    if (filter.customer) {
      items = items.filter(item =>
        ((item.clientName || '').toLowerCase()).indexOf(filter.customer.toLowerCase()) !== -1
      );
    }

    if (filter.date) {
      items = items.filter(item => item.date === this.format(filter.date))
    }

    if (filter.status) {
      items = items.filter(item => item.status === filter.status);
    }

    if (sort) {
      const [key, order] = sort.split(':');

      const sortFn = (a: OrderListModel, b: OrderListModel): number => {
        return order === 'asc'
          ? a[key] > b[key] ? 1 : -1
          : a[key] < b[key] ? 1 : -1;
      }

      const sortByDateFn = (a: OrderListModel, b: OrderListModel): number => {
        const date1 = a.date.split('/').reverse().join('');
        const date2 = b.date.split('/').reverse().join('');

        return order === 'asc'
          ? date1 > date2 ? 1 : -1
          : date1 < date2 ? 1 : -1;
      }


      key === 'date' ? items.sort(sortByDateFn) : items.sort(sortFn);
    }

    const length = items.length;

    return {
      items: items.slice(skip, skip + limit),
      totalItems: length,
    };
  }

  private format(date: string | Date, format = 'DD/MM/YYYY'): string {
    return moment(date).format(format);
  }

}
