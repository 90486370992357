<tc-dialog
    (close)="onClose()"
    [hasCloseButton]="hasCloseButton"
>

    <div tc-dialog-title>
        <h3 mat-dialog-title>
            {{'sync-agent.labels.title' | translate}}
        </h3>
    </div>

    <div
        class="container"
        tc-dialog-content
    >
        <mat-dialog-content>
            <div class="message text">
                <strong *ngIf="hasCloseButton">
                    {{'sync-agent.labels.message' | translate}}
                </strong>
                <strong *ngIf="!hasCloseButton">
                    {{'sync-agent.labels.messageFirstTime' | translate}}
                </strong>
            </div>
            <ng-container *ngIf="displayProgressBar">
                <div>
                    <div class="progress text">
                        <strong>
                            {{'sync-agent.labels.progress' | translate}}
                        </strong>
                        <span>
                            {{timerStats?.progress | number:'1.0-2'}}%
                        </span>
                    </div>
                    <mat-progress-bar
                        mode="determinate"
                        [value]="timerStats?.progress"
                    ></mat-progress-bar>
                </div>
                <div
                    class="text"
                    *ngIf="timerStats?.total"
                >
                    <div>
                        {{'sync-agent.labels.elapsedTime' | translate}}{{timerStats?.elapsed}}
                    </div>
                    <div>
                        {{'sync-agent.labels.totalTime' | translate}}{{timerStats?.total}}
                    </div>
                    <div>
                        {{'sync-agent.labels.remainingTime' | translate}}{{timerStats?.remaining}}
                    </div>
                </div>
            </ng-container>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button
                *ngIf="!displayProgressBar"
                mat-raised-button
                color="primary"
                (click)="startSync()"
            >
                {{'sync-agent.buttons.start' | translate}}
            </button>
            <button
                mat-raised-button
                color="primary"
                *ngIf="hasCloseButton"
                (click)="cancel()"
            >
                {{'sync-agent.buttons.cancel' | translate}}
            </button>
        </mat-dialog-actions>
    </div>

</tc-dialog>
