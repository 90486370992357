import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'app-article-item-basket',
  templateUrl: './article-item-basket.component.html',
  styleUrls: ['./article-item-basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleItemBasketComponent extends TcDumbComponent {

  @Input()
  total: number;

  @Output()
  buttonClick: EventEmitter<any> = new EventEmitter();

  onButtonClick() {
    this.buttonClick.emit();
  }

}
