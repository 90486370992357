import { AuthActionTypes } from './modules/auth/store/auth.actions';

export function clearState(reducer) {
  return (state, action) => {

    if (action.type === AuthActionTypes.LOGOUT) {
      for (const key in state) {
        const temp = state[key];

        if (key === 'syncAgent') {
          state[key] = {
            firstSyncFinished: temp.firstSyncFinished,
            lastSyncDBVersion: temp.lastSyncDBVersion,
          };
        } else if (key === 'authentication') {
          state[key] = {
            lastSignedUser: temp.lastSignedUser
          }
        } else if (key !== 'settings') {
          state[key] = undefined;
        }
      }
    }
    return reducer(state, action);
  };

}
