import { Component, OnInit } from '@angular/core';
import { StatisticsTypes } from '@maxel-order/shared';
import { TranslateService } from '@ngx-translate/core';
import { TcSmartComponent } from '@tc/core';
import Chart from 'chart.js';
import { timer } from 'rxjs';
import { StatisticsService } from '../../services/statistics.service';

@Component({
  selector: 'app-statistics-total',
  templateUrl: './statistics-total.component.html',
  styleUrls: ['./statistics-total.component.scss'],
})
export class StatisticsTotalComponent extends TcSmartComponent implements OnInit {

  public data;

  constructor(
    private readonly statisticsService: StatisticsService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  async ngOnInit() {
    this.data = await this.statisticsService.getItems(StatisticsTypes.Total, {});

    await timer(100).toPromise();

    this.initChart();
  }

  private initChart() {
    const canvas: HTMLCanvasElement = document.getElementById('statistics-total-chart') as HTMLCanvasElement;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

    const data = {
      type: 'bar',
      data: {
        labels: this.data.byWeek.map(week => week.label),
        datasets: [{
          data: this.data.byWeek.map(week => week.value),
          backgroundColor: '#68e0cf',
          borderColor: 'transparent'
        }],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
          padding: {
            top: 30,
            bottom: 30,
          },
        },
        animation: {
          duration: 1000
        },
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false
        },
        title: {
          text: this.translate.instant(`statistics-total.salesTurnoverPerWeek`),
          display: true,
          fontColor: '#000000'
        },
        scales: {
          yAxes: [{
            gridLines: {
              tickMarkLength: 0,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
      }
    };

    this.createChart(ctx, data);
  }

  private createChart(ctx, data) {
    return new Chart(ctx, data);
  }
}
