import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';

import { ClientStatus } from '../../modules/clients/enums/client-status.enum';
import { InitClients } from '../../modules/clients/store/clients.actions';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Component({
  selector: 'app-f03-client-list-page',
  templateUrl: './f03-client-list-page.component.html',
  styleUrls: ['./f03-client-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class F03ClientListPageComponent extends TcPage implements OnInit {
  constructor(private permissionService: PermissionsService, protected readonly store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(new InitClients({ filter: { status: ClientStatus.Actif } }));

    this.pageInfo = {
      title: 'clients-page.title',
      menuItems: this.permissionService.applyPermissionOnMenuItems([
        {
          name: AppMenu.Orders
        },
        {
          name: AppMenu.Reports
        },
        {
          name: AppMenu.DisplaySyncPopup
        },
        {
          name: AppMenu.Logout
        }
      ])
    } as IAppPageState;

    super.ngOnInit();
  }
}
