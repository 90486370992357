import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-client-logo',
  templateUrl: './client-logo.component.html',
  styleUrls: ['./client-logo.component.scss']
})
export class ClientLogoComponent implements OnInit {

  @Input()
  logo: string;
  
  constructor() { }

  ngOnInit(){
  }

}
