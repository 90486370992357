<div class="contact-container">
    <div class="body">
        <br />
        <div>
            <span class="contact"><strong>{{'client-contact.contact' | translate}}</strong></span>
            <span class="gray label">&nbsp;&nbsp;&nbsp;&nbsp;{{'client-contact.language' | translate}}</span><span class="white-color"> : </span><strong> {{client.language}}</strong>
        </div>
        <br />
        <div class="columns">
            <div class="left">
                <div class="label">
                    <strong>{{client.storeContactName}}</strong>
                </div>
                <div class="label" *ngIf="client.contactEmail">
                    <span class="gray">{{'client-contact.email' | translate}}</span>
                    <span class="white-color"> : </span>
                    <strong> {{client.contactEmail}}</strong>
                </div>
            </div>
            <div class="label right" *ngIf="client.phone">
                <span class="gray">{{'client-contact.phone' | translate}}</span>
                <span class="white-color"> : </span>
                <strong> {{client.phone}}</strong>
            </div>
        </div>
        <br />
    </div>
    <div class="image" *ngIf="hasWriteAccess">
        <img (click)="edit()" src="assets/icons/bton_edit.png" width="40" />
    </div>
</div>