import { Injectable } from '@angular/core';
import { CallbackFunc, EventNotifier, Events } from './events.interface';

@Injectable({
  providedIn: 'root'
})
export class EventsService implements EventNotifier {
  private events: Map<Events, CallbackFunc[]> = new Map();

  public subscribe(eventName: Events, func: CallbackFunc) {
    if (!this.isEvent(eventName)) {
      return;
    }

    const callbacks = this.events.get(eventName) || [];
    callbacks.push(func);

    this.events.set(eventName, callbacks);
  }

  public unsubscribe(eventName: Events, func: CallbackFunc) {
    if (!this.isEvent(eventName)) {
      return;
    }

    const callbacks = this.events.get(eventName);
    const index = callbacks.indexOf(func);

    if (index !== -1) {
      callbacks.splice(index, 1);
      this.events.set(eventName, callbacks);
    }
  }

  public publish(eventName: Events, data?: any) {
    if (!this.isEvent(eventName)) {
      return;
    }

    const cb = this.events.get(eventName);

    if (cb && cb.length) {
      cb.forEach((fn) => {
        try { fn(data); } catch (e) {
          console.error(`[EventsService.publish] ${eventName}`, e);
        }
      });
    }
  }

  private isEvent(event: Events) {
    return !!(event in Events);
  }
}
