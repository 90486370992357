import { Component, ViewChild, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FieldType } from '@ngx-formly/core';
import { PreviewArticle } from '../../../../articles/store/articles.actions';

@Component({
  selector: 'app-photo-select',
  templateUrl: './photo-select.component.html',
  styleUrls: ['./photo-select.component.scss']
})
export class PhotoSelectComponent extends FieldType implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput;

  public base64 = null;
  public label: string;

  constructor(
    private readonly store$: Store<any>,
  ) {
    super();
  }

  ngOnInit() {
    this.label = this?.field?.templateOptions?.label ?? '';
  }

  onScanFile() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  public async onScannedFileSelected() {
    const file = this.fileInput.nativeElement.files[0];
    this.base64 = await this.toBase64(file);
  }

  public zoom(event: MouseEvent) {
    this.store$.dispatch(new PreviewArticle(this.base64));

    event.stopPropagation();
    return false;
  }

  private toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}
