import { TcService, TcTranslateService } from '@tc/core';
import { Injectable } from '@angular/core';

@Injectable()
export class StorageService extends TcService {

    constructor(
        // TODO
        // private translateService: TcTranslateService
    ) {
        super();
    }

    /**
     * Check if storage is persisted already.
     * Promise resolved with true if current origin is
     * using persistent storage, false if not, and undefined if the API is not
     * present.
     */
    isStoragePersisted = async (): Promise<boolean> => {
        return await navigator.storage && navigator.storage.persisted ?
            navigator.storage.persisted() :
            undefined;
    }

    /** Tries to convert to persisted storage.
     *  Promise resolved with true if successfully
     * persisted the storage, false if not, and undefined if the API is not present.
     */
    persist = async (): Promise<boolean> => {
        return await navigator.storage && navigator.storage.persist ?
            navigator.storage.persist() :
            undefined;
    }

    /** Queries available disk quota.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/StorageEstimate
     * Promise resolved with
     * {quota: number, usage: number} or undefined.
     */
    showEstimatedQuota = async (): Promise<StorageEstimate> => {
        return await navigator.storage && navigator.storage.estimate ?
            navigator.storage.estimate() :
            undefined;
    }

    /** Tries to persist storage without ever prompting user.
     *       "never" In case persisting is not ever possible. Caller don't bother
     *       asking user for permission.
     *       "prompt" In case persisting would be possible if prompting user first.
     *      "persisted" In case this call successfully silently persisted the storage,
     *       or if it was already persisted.
     */
    tryPersistWithoutPromtingUser = async (): Promise<string> => {
        if (!navigator.storage || !navigator.storage.persisted) {
            return 'never';
        }
        let persisted = await navigator.storage.persisted();
        if (persisted) {
            return 'persisted';
        }
        if (!navigator.permissions || !navigator.permissions.query) {
            return 'prompt'; // It MAY be successful to prompt. Don't know.
        }
        const permission = await navigator.permissions.query({
            name: 'persistent-storage'
        });
        if (permission.state === 'granted') {
            persisted = await navigator.storage.persist();
            if (persisted) {
                return 'persisted';
            } else {
                throw new Error('Failed to persist');
            }
        }
        if (permission.state === 'prompt') {
            return 'prompt';
        }
        return 'never';
    }


    assurePersistance = async (): Promise<boolean> => {
        let state = await this.tryPersistWithoutPromtingUser();
        if (state === 'prompt') {
            // TODO
            alert("Cette application nécessite l'approbation de la notification push. Veuillez l'accepter dans la prochaine fenêtre contextuelle.");
            await Notification.requestPermission();
            await navigator.storage.persist();
            state = await this.tryPersistWithoutPromtingUser();
        }
        return state === 'persisted';
    }
}
