<tc-dialog (close)="onClose()">

    <div tc-dialog-title>
        <h3 mat-dialog-title>{{'article-history.title' | translate}}</h3>

    </div>

    <div class="container" tc-dialog-content>
        <div class="article">
            <div>{{data.name}}</div>
            <div>{{data.articleType}}</div>
            <div>{{data.code}}</div>
        </div>
        <div class="tabel">
            <mat-dialog-content>
                <div class="cell-tabel" *ngFor="let history of (orderHistoryList$ | async).reverse()">
                    <div class="date">{{history.date}}</div>
                    <div class="number">{{history.quantity}}</div>
                </div>
            </mat-dialog-content>
        </div>


    </div>


    <mat-dialog-actions>
    </mat-dialog-actions>

</tc-dialog>
