<div class="filters--container">
  <ng-container *ngFor="let filter of filters;trackBy: trackByKey">
    <mat-form-field>
      <mat-label>
        {{ 'statistics-filters.filters.' + filter.key | translate }}
      </mat-label>
      <mat-select (selectionChange)="onFilter(filter.key, $event)">
        <mat-option [value]="''">{{ 'statistics-filters.value.all' | translate }}</mat-option>
        <mat-option *ngFor="let item of filter.values;trackBy: trackByValue" [value]="item.value">
          {{ item.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>
