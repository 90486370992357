import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcSmartComponent, TcCurrencyFormat } from '@tc/core';
import { IMAGES_ROOT_FOLDER } from '../../../../../app.module';
import { DatesService } from '../../../../../services/business-services/dates.service';
import { OrderRequestsService } from '../../../../../services/business-services/order-requests.service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

import { OrderModel } from '../../../models/order.model';
import {
  BlockOrder,
  CancelOrder,
  DecreaseQuantity,
  DeleteFromBasket,
  IncreaseQuantity,
  SendDevis,
  UpdateOrder,
  ValidateOrder,
} from '../../../store/orders.actions';
import { getOrder } from '../../../store/orders.selectors';
import { getSelectedClientId } from './../../../../clients/store/clients.selectors';
import { CompaniesDAO } from 'apps/maxel-order/src/app/services/dao';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent extends TcSmartComponent implements OnInit, OnDestroy {

  minDate: Date;
  selectedDate: Date = null;

  displayedColumns = ['article', 'ref', 'quantity', 'pu', 'totalPrice', 'action'];
  elements: any[] = [];
  result: any[] = [];

  displayImages = false;
  summaryRequested = false;
  multipleCompanies = false;
  comments = '';

  order$: Observable<OrderModel>;
  orderSubscription: Subscription;
  order: OrderModel;
  imagesRootSrc: string;

  orderLinesSubscription: Subscription;

  selectedClientId$: Observable<string>;

  constructor(
    private readonly store: Store<any>,
    private readonly datePipe: DatePipe,
    private readonly datesService: DatesService,
    private readonly ordersRequestService: OrderRequestsService,
    private readonly translateService: TranslateService,
    private readonly tcCurrencyFormat: TcCurrencyFormat,
    private readonly companiesDAO: CompaniesDAO,
    @Inject(IMAGES_ROOT_FOLDER) private imagesRootUrl: string
  ) {
    super();
  }

  public get shippingTax(): string {
    if (this.multipleCompanies) {
      return this.shippingTaxText(500);
    }

    return this.shippingTaxText(350);
  }

  private shippingTaxText(totalTax: number): string {
    const left = totalTax - this.getTotalCost();

    return left > 0
      ? this.translateService.instant('order-detail-list.franco.leftTax', { left: this.tcCurrencyFormat.transform(left), total: this.tcCurrencyFormat.transform(totalTax) })
      : this.translateService.instant('order-detail-list.franco.fullTax', { total: this.tcCurrencyFormat.transform(totalTax) });
  }

  filterDates = (d: Date | null): boolean => {
    const freeDays = this.datesService.getFreeDays();
    const isFreeDay = freeDays.find(day => this.datePipe.transform(day, 'dd/MM/YYYY') === this.datePipe.transform(d, 'dd/MM/YYYY'));

    if (d !== null) {
      const selectedDay = d.getDay();
      return selectedDay !== 0 && selectedDay !== 6 && !isFreeDay;
    } else {
      const currentDate = new Date();
      const day = currentDate.getDay();
      return day !== 0 && day !== 6 && !isFreeDay;
    }
  }

  ngOnInit() {
    this.initMinDate();

    this.selectedClientId$ = this.store.pipe(select(getSelectedClientId));

    this.getOrderDetails();

    this.imagesRootSrc = this.imagesRootUrl;
    this.order$ = this.store.pipe(select(getOrder));    

    this.orderSubscription = this.order$.subscribe(async order => {

      const companyNameByIdMap = await this.companiesDAO.getCompanyNameByIdMap();

      this.order = order;

      this.elements = [];
      this.result = [];
      
      for (let i = 0; i <= this.order.lines.length; i++) {
        const orderElem = this.order.lines[i];
        if (orderElem) {
          const company = companyNameByIdMap.get(orderElem.companyId);
          const total: number = orderElem.quantity * orderElem.price;
          const elem = {
            data: {
              id: orderElem.id,
              image: orderElem.image,
              article: orderElem.articleName,
              ref: orderElem.articleRef,
              quantity: orderElem.quantity,
              pu: orderElem.price,
              totalPrice: total,
              company,
              leftForSaleStock: orderElem.leftForSaleStock,
              itemsInBax: orderElem.itemsInBax
            },
            isTotal: false,
          };
          this.elements.push(elem);
        }
      }

      const result = this.elements.reduce(function (r, a) {
        r[a.data.company] = r[a.data.company] || [];
        r[a.data.company].push(a);
        return r;
      }, Object.create(null));

      const companies = Object.keys(result);

      this.multipleCompanies = companies.length >= 2 ? true : false;

      for (let i = 0; i < companies.length; i++) {
        const company = companies[i];
        let partialResult = [];
        partialResult = result[company];
        let totalForCompany = 0;
        if (partialResult) {
          partialResult.forEach(elem => {
            if (elem) {
              totalForCompany = totalForCompany + elem.data.totalPrice;
            }
          })
          this.result = this.result.concat(result[company]);
          this.result = this.result.concat({
            data: {
              image: '',
              article: '',
              ref: '',
              quantity: 0,
              pu: 0,
              totalPrice: 0,
              company: ''
            },
            isTotal: true,
            companyName: company,
            companyTotal: totalForCompany
          });
        }
      }
    });
  }

  showOrHideImage() {
    this.displayImages = !this.displayImages;

    if (!this.displayImages) {
      this.displayedColumns.shift();
    } else {
      this.displayedColumns.unshift('image');
    }
  }

  changeSummaryRequested() {
    this.summaryRequested = !this.summaryRequested;

    this.updateOrder();
  }

  getTotalCost() {
    return this.result.map(t => (t && t.data) ? t.data.totalPrice : 0)
      .reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  blockOrder() {
    this.store.dispatch(new BlockOrder());
  }

  cancelOrder() {
    this.store.dispatch(new CancelOrder());
  }

  validateOrder() {
    this.store.dispatch(new ValidateOrder({
      deliveryDate: moment.utc(this.selectedDate).format(),
      summaryRequested: this.summaryRequested,
      comments: this.comments,
    }));
  }

  updateOrder() {
    this.store.dispatch(new UpdateOrder({
      deliveryDate: moment.utc(this.selectedDate).format(),
      summaryRequested: this.summaryRequested,
      comments: this.comments,
    }));
  }

  sendDevis() {
    this.store.dispatch(new SendDevis());
  }

  increaseQuantity(orderLineId: string, itemsInBax: number, leftForSaleStock: number) {
    this.store.dispatch(new IncreaseQuantity({ orderLineId, itemsInBax, leftForSaleStock }));

  }

  decreaseQuantity(orderLineId: string, itemsInBax: number, leftForSaleStock: number) {
    this.store.dispatch(new DecreaseQuantity({ orderLineId, itemsInBax, leftForSaleStock }));
  }

  deleteFromBasket(orderLineId: string) {
    this.store.dispatch(new DeleteFromBasket(orderLineId));
  }

  updateDate(value: Date) {
    this.selectedDate = value ? moment.utc(moment(value).format('L')).toDate() : null;
    this.updateOrder();
  }

  private initSelectedDate() {
    this.selectedDate = new Date(this.minDate);

    this.updateOrder();
  }

  private async getOrderDetails() {
    const order = await this.ordersRequestService.getCurrentOrder();

    this.comments = order.comment;
    this.summaryRequested = order.summaryRequested;

    if (order.requestedDeliveryDate && moment(order.requestedDeliveryDate).isValid()) {
      this.selectedDate = moment.utc(order.requestedDeliveryDate).toDate();
    } else {
      this.initSelectedDate();
    }

  }

  private initMinDate() {
    const target = moment().add(4, 'days');
    const weekendCount = this.getWeekendCount(target);

    target.add(weekendCount, 'days');

    const finalTarget = this.getFinalMinDate(target);

    this.minDate = finalTarget;
  }

  private getFinalMinDate(target: moment.Moment): Date {

    while (!this.filterDates(target.toDate())) {
      target.add(1, 'day');
    }

    return target.toDate();
  }

  private getWeekendCount(target: moment.Moment): number {
    let weekends = 0;
    const current = moment();

    while (!current.isSame(target, 'days')) {
      const isValid = this.filterDates(current.toDate());

      if (!isValid) { weekends++; }

      current.add(1, 'day');
    }

    return weekends;
  }

  ngOnDestroy() {
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }

    if (this.orderLinesSubscription) {
      this.orderLinesSubscription.unsubscribe();
    }
  }

}
