import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getSelectedClient } from '../../modules/clients/store/clients.selectors';
import { ClientListRowModel } from '../../modules/clients/models/client-list-row.model';
import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Component({
  selector: 'app-f04-client-detail-page',
  templateUrl: './f04-client-detail-page.component.html',
  styleUrls: ['./f04-client-detail-page.component.scss']
})
export class F04ClientDetailPageComponent extends TcPage implements OnInit {
  clientSelected$: Observable<ClientListRowModel>;
  constructor(private permissionService: PermissionsService, store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IAppPageState = {
      title: 'client-detail.title',
      menuItems: this.permissionService.applyPermissionOnMenuItems([
        {
          name: AppMenu.Orders
        },
        {
          name: AppMenu.Reports
        },
        {
          name: AppMenu.DisplaySyncPopup
        },
        {
          name: AppMenu.Logout
        }
      ])
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
    this.clientSelected$ = this.store.pipe(select(getSelectedClient));
  }
}
