import { HIDE_SPINNER, SHOW_SPINNER, TcAction } from '@tc/core';
import { LastUserModel, UserModel } from '../models/user.model';

export enum AuthActionTypes {
  LOGIN = "[Authentication] Login",
  LOGIN_SUCCESS = "[Authentication] Login Success",
  LOGIN_FAILURE = "[Authentication] Login Failure",
  LOGOUT = "[Authentication] Logout",
  SET_LAST_ACTIVITY = "[Authentication] Set last activity",
  CHECK_APP_TIMEOUT = "[Authentication] Check app timeout",
  SET_LAST_SIGNED_USER = "[Authentication] Set last signed user"
}

@SHOW_SPINNER()
export class Login implements TcAction {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: { username: string, password: string }) { }
}

@HIDE_SPINNER()
export class LoginSuccess implements TcAction {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: UserModel) { }
}

@HIDE_SPINNER()
export class LoginFailure implements TcAction {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: string) { }
}

export class Logout implements TcAction {
  readonly type = AuthActionTypes.LOGOUT;
  constructor() { }
}

export class SetLastActivity implements TcAction {
  readonly type = AuthActionTypes.SET_LAST_ACTIVITY;
}

export class CheckAppTimeout implements TcAction {
  readonly type = AuthActionTypes.CHECK_APP_TIMEOUT;
  constructor(public payload?: boolean) { }
}

export class SetLastSignedUser implements TcAction {
  readonly type = AuthActionTypes.SET_LAST_SIGNED_USER;
  constructor(public payload: LastUserModel) { }
}

export type AuthActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | SetLastActivity
  | CheckAppTimeout
  | SetLastSignedUser;
