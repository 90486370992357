import { Component, OnInit, Input } from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import { ClientModel } from '../../../models/client.model';

@Component({
  selector: 'app-client-legal-and-tarif',
  templateUrl: './client-legal-and-tarif.component.html',
  styleUrls: ['./client-legal-and-tarif.component.scss']
})
export class ClientLegalAndTarifComponent extends TcDumbComponent implements OnInit {

  @Input()
  client: ClientModel;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  getCarriageFree = (): string => {
    if (this.client.carriageFree) {
      return Number(this.client.carriageFree).toFixed(2);
    }
    return '';
  }

  getGeneralPrice = (): string => {
    if (this.client.generalPrice && this.client.generalPrice.length > 0) {
      return this.client.generalPrice.map(pc => pc.companyId + ': ' + pc.code).join(', ');
    }
    return '';
  }

  getSpecialPrice = (): string => {
    if (this.client.specialPrice && this.client.specialPrice.length > 0) {
      return this.client.specialPrice.map(pc => pc.companyId + ': ' + pc.code).join(', ');
    }
    return '';
  }


}
