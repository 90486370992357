
import { Entity, PouchdbCrudDAO } from '@maxel-order/shared';
import { DatabaseService } from '../database/database.service';
import { Events } from '../events/events.interface';
import { EventsService } from '../events/events.service';
import { Criteria } from '../repository/repository.interface';
import { RepositoryService } from '../repository/repository.service';
import { IdProviderService } from './id-provider.service';

export class BaseDAO<T extends Entity> extends PouchdbCrudDAO<T> {

  constructor(
    database: DatabaseService,
    idProvider: IdProviderService,
    private readonly repository: RepositoryService,
    private readonly eventsService: EventsService,
  ) {
    super(database, idProvider);
  }

  public get(id: string): Promise<T> {
    return this.repository.getById<T>(id, this.entityName);
  }

  public getAll(): Promise<T[]> {
    return this.repository.get<T>(this.entityName);
  }

  public async getCount(): Promise<number> {
    const { length } = await this.getAll();

    return length;
  }

  public search(request: Criteria): Promise<T[]> {
    return this.repository.search<T>({
      ...request,
      type: this.entityName,
    });
  }

  async create(obj: T): Promise<T> {
    const response = await super.create(obj);

    this.eventsService.publish(Events.CreateItem, response);

    return response;
  }

  public async update(obj: T): Promise<T> {
    const existing = await this.get(obj.id);

    if (existing) {
      obj['_rev'] = existing._rev;
    }

    const response = await super.update(obj);

    this.eventsService.publish(Events.UpdateItem, response);

    return response;
  }

  public async delete(obj: T): Promise<any> {
    const existing = await this.get(obj.id);
    if (!existing) {
      return;
    }

    const response = await super.delete({
      ...obj,
      _id: existing['_id'],
      _rev: existing['_rev'],
    });

    this.eventsService.publish(Events.RemoveItem, obj);

    return response;
  }
}
