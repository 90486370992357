import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatisticsTypes } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcAppState, TcPage } from '@tc/core';
import { Subscription } from 'rxjs';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { getSelectedStatisticsType } from '../../modules/statistics/store/statistics.selectors';

@Component({
  selector: 'app-statistics-page',
  templateUrl: './statistics-page.component.html',
  styleUrls: ['./statistics-page.component.scss'],
})
export class StatisticsPageComponent extends TcPage implements OnInit, OnDestroy {

  public readonly statisticsType = StatisticsTypes;
  public selectedType = StatisticsTypes.Total;
  public selectedTypeSubscription: Subscription;

  constructor(
    public readonly store$: Store<TcAppState>,
  ) {
    super(store$);
  }

  ngOnInit() {
    const pageInfo: IAppPageState = {
      title: 'statistics-page.title',
      menuItems: [
        { name: AppMenu.NewOrder },
        { name: AppMenu.Orders },
        { name: AppMenu.DisplaySyncPopup },
        { name: AppMenu.Logout }
      ]
    };

    this.pageInfo = pageInfo;

    this.selectedTypeSubscription = this.store$.select(getSelectedStatisticsType)
      .subscribe(type => this.selectedType = type);

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.selectedTypeSubscription.unsubscribe();
  }

}
