import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';
import { NotificationsDAO } from '../dao/notifications.dao';
import { NotificationType } from '@maxel-order/shared';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(
    private readonly notificationsDAO: NotificationsDAO,
  ) { }

  public async send(type: NotificationType, context?: any) {
    await this.notificationsDAO.create({
      id: uuid(),
      context,
      category: type,
      processed: false,
      processedDate: null,
      date: moment().toISOString(),
    });
  }
}
