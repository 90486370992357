import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StatisticsTypes } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcAppState, TcSmartComponent } from '@tc/core';
import { take } from 'rxjs/operators';
import { SaveStatisticsItems, SelectStatisticsType } from '../../store/statistics.actions';
import { getSelectedStatisticsType } from '../../store/statistics.selectors';

@Component({
  selector: 'app-statistics-type-selector',
  templateUrl: './statistics-type-selector.component.html',
  styleUrls: ['./statistics-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsTypeSelectorComponent extends TcSmartComponent implements OnInit {
  public selected = { label: 'Total', value: StatisticsTypes.Total };

  public filters: StatisticsFilter[] = [];

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly translate: TranslateService,
  ) {
    super();

    this.filters = [
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.Total}`), value: StatisticsTypes.Total },
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.A}`), value: StatisticsTypes.A },
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.D}`), value: StatisticsTypes.D },
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.B}`), value: StatisticsTypes.B },
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.F}`), value: StatisticsTypes.F },
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.E}`), value: StatisticsTypes.E },
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.C1}`), value: StatisticsTypes.C1 },
      { label: this.translate.instant(`statistics-type.${StatisticsTypes.C2}`), value: StatisticsTypes.C2 }
    ];
  }

  ngOnInit() {
    this.store$.select(getSelectedStatisticsType)
      .pipe(take(1))
      .subscribe(type => this.selected = this.filters.find(filter => filter.value === type));
  }

  public selectFilter(filter: StatisticsFilter) {
    if (this.selected.value === filter.value) {
      return;
    }

    this.selected = filter;
    this.store$.dispatch(new SelectStatisticsType(filter.value));
    this.store$.dispatch(new SaveStatisticsItems([]));
  }
}

interface StatisticsFilter {
  label: string;
  value: StatisticsTypes;
}
