import { Component, OnInit, ViewChild } from '@angular/core';
import { TcSmartComponent, TcListComponent, TcListSortType } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { getClientInvoiceList, getClientActionList } from '../../../store/clients.selectors';

@Component({
  selector: 'app-client-action-list',
  templateUrl: './client-action-list.component.html',
  styleUrls: ['./client-action-list.component.scss']
})
export class ClientActionListComponent extends TcSmartComponent implements OnInit {

  clientActionList: TcListComponent;
  @ViewChild('clientActionList', { static: true }) set appClientActionList(values: TcListComponent) {
    this.clientActionList = values;
  }

  constructor(private store: Store<any>) {
    super();
   }

  ngOnInit() {
    this.clientActionList.rows$ = this.store.pipe(select(getClientActionList));

    this.clientActionList.hasActionsLabel = false;
    this.clientActionList.isPaged = false;
    this.clientActionList.isFiltrable = false;
    this.clientActionList.sortType = TcListSortType.Disabled;

    this.clientActionList.columns = [
      {
        propertyName: 'action',
        visible: true,
        filtrable: false
      },
      {
        propertyName: 'ref',
        visible: true,
        filtrable: false
      },
      {
        propertyName: 'date',
        visible: true,
        filtrable: false
      }
    ];
  }

}
