
import { Injectable } from '@angular/core';
import { User, UserEntityType } from '@maxel-order/shared';
import { DatabaseService } from '../database/database.service';
import { EventsService } from '../events/events.service';
import { RepositoryService } from '../repository/repository.service';
import { BaseDAO } from './base.dao';
import { IdProviderService } from './id-provider.service';

@Injectable({
  providedIn: 'root'
})
export class UsersDAO extends BaseDAO<User> {

  constructor(
    database: DatabaseService,
    idProvider: IdProviderService,
    repositoryService: RepositoryService,
    eventsService: EventsService,
  ) {
    super(database, idProvider, repositoryService, eventsService);

    this.entityName = UserEntityType.className;
    this.entityLowerName = UserEntityType.lowerName;
  }
}
