import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SyncAgentEffects } from './store/sync-agent.effects';
import { syncAgentReducer } from './store/sync-agent.reducer';
import { SyncAgentPopupComponent } from './components/dumb/sync-agent-popup/sync-agent-popup.component';
import { SyncFinishedPopupComponent } from './components/dumb/sync-finished-popup/sync-finished-popup.component';
import { MatProgressBarModule } from '@angular/material';
// import { DeviceDetectorModule } from 'ngx-device-detector';


@NgModule({
  declarations: [
    SyncAgentPopupComponent,
    SyncFinishedPopupComponent,
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    MatProgressBarModule,
    EffectsModule.forFeature([SyncAgentEffects]),
    StoreModule.forFeature('syncAgent', syncAgentReducer),
    // DeviceDetectorModule.forRoot()
  ],
  exports: [
    SyncAgentPopupComponent,
    SyncFinishedPopupComponent,
  ],
  entryComponents: [
    SyncAgentPopupComponent,
    SyncFinishedPopupComponent,
  ]
})
export class SyncAgentModule { }
