<div class="filter-list order-filter">
    <div
        class="filter-list-item"
        *ngFor="let filter of filters; last as isLast"
        (click)="selectFilter(filter)"
        [class.selected]="selected.value === filter.value"
        [class.last]="isLast"
    >
        {{filter.label}}
    </div>
</div>
