import { Md5 } from 'ts-md5/dist/md5';

export class MD5Service {

  private md5WithSalt(salt: string, password: string): string {
    return salt + Md5.hashStr(salt + password);
  }

  private generateRandomString(length) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  public encodePassword(password: string): string {
    const salt = this.generateRandomString(10);
    return this.md5WithSalt(salt, password);
  }

  public passwordsMatch(password: string, passwordFromDB: string): boolean {
    const salt = passwordFromDB.substring(0, 10);
    return passwordFromDB === this.md5WithSalt(salt, password);
  }

}
