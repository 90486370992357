import { Injectable } from '@angular/core';
import { Visit } from '@maxel-order/shared';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { VisitModel } from '../../modules/main/models/visit.model';
import { VisitsDAO } from '../dao/visits.dao';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {

  constructor(private visitsDAO: VisitsDAO) { }

  getVisits(): Promise<Visit[]> {
    return this.visitsDAO.getAll();
  }

  createVisit(visitModel: VisitModel) {
    const newVisit: Visit = {
      id: uuidv4(),
      date: moment().toISOString(),
      clientId: visitModel.clientId,
      comments: visitModel.comments,
      codeVrp: visitModel.codeVrp,
    }
    this.visitsDAO.create(newVisit);
  }

  async getVisit(visitId: string): Promise<VisitModel> {

    // const query: Criteria = {
    //   filter: {
    //     'id': { $eq: visitId }
    //   }
    // };

    const visit: Visit = await this.visitsDAO.get(visitId);
    // const visit = visits[0];
    const visitModel: any = {};
    visitModel.id = visit.id
    visitModel.comments = visit.comments;
    visitModel.date = moment.utc(visit.date).toDate();
    return new Promise<VisitModel>((resolve, reject) => {
      resolve(visitModel)
    });
  }
}
