import { MainActions } from './main.actions';
import { initialMainState, MainState } from './main.state';


export function mainReducer(
    state = initialMainState,
    action: MainActions
): MainState {
    switch (action.type) {
        default: {
            return state;
          }
    }
}