<div class="filter__fields">
  <mat-form-field>
    <mat-label>{{'order-list.headers.status' | translate}}</mat-label>
    <mat-select [disabled]="viewType === OrdersViewType.Blocked" [value]="filter.status"
      (selectionChange)="filterStatus($event)">
      <mat-option [value]="null">{{'order-list.status.all' | translate}}</mat-option>
      <mat-option *ngFor="let status of statuses" [value]="status.value">
        {{status.label | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'order-list.headers.date' | translate}}</mat-label>
    <input matInput [matDatepicker]="picker" [value]="filter.date" (dateChange)="filterDate($event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="client__input">
    <mat-label>{{'order-list.headers.clientName' | translate}}</mat-label>
    <input #customerInput matInput [ngModel]="filter.customer">
  </mat-form-field>
</div>

<tc-list #orderList id="order-list"></tc-list>

<ng-template #colStatusTemplate let-row="row" let-rowAction="rowAction">
  {{getStatusKey(row.status) | translate}}
</ng-template>

<ng-template #colActionEditTemplate let-row="row" let-rowAction="rowAction">
  <img *ngIf="row.status == 'BLOCKED'" (click)="editOrder(row)" class="image" src="assets/icons/bton_edit.png"
    width="40" />
</ng-template>
