<div class="user-list-page">

    <tc-layout [fixedInViewport]="true" [fixedTopGap]="0" [mode]="'side'" [showToolbarMenuIcon]="false" [opened]="true">

        <div class="header-content" tc-layout-toolbar>
            <app-page-title>
            </app-page-title>
            <div class="header-right">
                <app-user-info></app-user-info>
                <app-page-menu></app-page-menu>
            </div>
        </div>

        <div tc-layout-sidenav>

            <app-back-navigation></app-back-navigation>

            <img src="assets/icons/line_colors.png" width="170px" class="image">

        </div>

        <div tc-layout-sidenav-content>
            <div class="user-list">
                <app-user-list></app-user-list>
            </div>

        </div>

    </tc-layout>
</div>