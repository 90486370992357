import { Injectable } from '@angular/core';
import { Invoice } from '@maxel-order/shared';
import { InvoiceModel } from '../../modules/clients/models/invoice.model';
import { InvoicesDAO } from '../dao/invoices.dao';
import { Criteria } from '../repository/repository.interface';

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {

    constructor(
        private readonly invoicesDAO: InvoicesDAO
    ) { }

    public async getUnpaidInvoices(clientId: string): Promise<InvoiceModel[]> {
        const query: Criteria = {
            filter: {
                $and: [
                    { 'clientId': { $eq: clientId } },
                    { 'status': { $ne: '0.00' } },
                ],
            },
        };
        const invoices: Invoice[] = await this.invoicesDAO.search(query);

        const invoiceModelList: InvoiceModel[] = invoices.map(invoice => ({
            id: invoice.id,
            ref: invoice.number,
            amount: invoice.totalExcludingTax,
            date: invoice.date,
            dueDate: invoice.dueDate,
        }));

        return invoiceModelList;
    }

}