<tc-dialog (close)="onClose()">

    <div tc-dialog-title>
        <h3 mat-dialog-title>Example dialog title</h3>
    </div>

    <div tc-dialog-content>

        <mat-dialog-content>
            Place dialog content here
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-raised-button color="primary" class="btn btn-default" tcPreventDoubleClick>Dialog
                button</button>
        </mat-dialog-actions>


    </div>

</tc-dialog>