import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { TcLayoutModule } from '@tc/layout';

import { ArticleItemBasketComponent } from './components/dumb/article-item-basket/article-item-basket.component';
import { ArticleItemBasketRemoveComponent } from './components/dumb/article-item-basket-remove/article-item-basket-remove.component';
import { ArticleItemLikeComponent } from './components/dumb/article-item-like/article-item-like.component';
import { ArticlePreviewComponent } from './components/dumb/article-preview/article-preview.component';
import { ArticleFilterSearchComponent } from './components/smart/article-filter-search/article-filter-search.component';
import { ArticleFilterComponent } from './components/smart/article-filter/article-filter.component';
import { ArticleHistoryComponent } from './components/smart/article-history/article-history.component';
import { ArticleItemComponent } from './components/smart/article-item/article-item.component';
import { ArticleListComponent } from './components/smart/article-list/article-list.component';
import { BasketComponent } from './components/smart/basket/basket.component';
import { TotalPriceBasketComponent } from './components/smart/total-price-basket/total-price-basket.component';
import { ArticlesEffects } from './store/articles.effects';
import { articlesReducer } from './store/articles.reducer';

const smartComponents = [
  ArticleListComponent,
  BasketComponent,
  TotalPriceBasketComponent,
  ArticleItemComponent,
  ArticleFilterComponent,
  ArticleFilterSearchComponent
];

const dumbComponents = [
  ArticleItemBasketComponent,
  ArticleItemBasketRemoveComponent,
  ArticleHistoryComponent,
  ArticlePreviewComponent,
  ArticleItemLikeComponent
];

@NgModule({
  declarations: [...smartComponents, ...dumbComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    TcLayoutModule,
    MatTooltipModule,
    EffectsModule.forFeature([ArticlesEffects]),
    StoreModule.forFeature('articles', articlesReducer),
  ],
  exports: [...smartComponents, ...dumbComponents],
  entryComponents: [
    ArticleHistoryComponent,
    ArticlePreviewComponent
  ]
})
export class ArticlesModule { }
