import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcMenuItem } from '@tc/core';
import { take } from 'rxjs/operators';
import { UserModel } from '../../modules/auth/models/user.model';
import { getAuthenticatedUser } from '../../modules/auth/store/auth.selectors';
import { ClientAllVrp } from '../../modules/clients/enums/client-status.enum';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { VrpProviderService } from '../business-services/vrp-provider.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public currentUser: UserModel;

  constructor(private readonly vrpProviderService: VrpProviderService, private readonly store$: Store<any>) {}

  public init() {
    console.warn('permission service init');
    this.store$.select(getAuthenticatedUser).subscribe(user => {
      this.currentUser = user;
      console.log('current user:', user);
    });
  }

  public async hasAccess() {
    const vrp = await this.vrpProviderService.getCurrentCodeVrp();
    if (!vrp) {
      return false;
    }

    return vrp.toLowerCase() !== ClientAllVrp.toLowerCase();
  }

  public applyPermissionOnMenuItems(items: TcMenuItem[]): TcMenuItem[] {
    const result = this.currentUser.accessStatistique ? items : items.filter(i => i.name !== AppMenu.Reports);
    return result;
  }
}
