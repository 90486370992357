import { UsersState, initialUsersState } from './users.state';
import { UsersActions, UsersActionTypes } from './users.actions';

export function usersReducer(state = initialUsersState, action: UsersActions): UsersState {
  switch (action.type) {
    case UsersActionTypes.LOAD_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload
      };
    }

    case UsersActionTypes.UPDATE_USER_SUCCESS: {
      const updatedItem = state.users.find(u => u.id === action.payload.id);
      if (updatedItem) {
        updatedItem.firstName = action.payload.firstName;
        updatedItem.lastName = action.payload.lastName;
        updatedItem.email = action.payload.email;
        updatedItem.password = action.payload.password;
        updatedItem.tabletCode = action.payload.tabletCode;
        updatedItem.codeVrp = action.payload.codeVrp;
        updatedItem.login = action.payload.login;
        updatedItem.profil = action.payload.profil;
        updatedItem.accessStatistique = action.payload.accessStatistique;
      }

      return {
        ...state
      };
    }

    case UsersActionTypes.ADD_NEW_USER_SUCCESS: {
      const updatedUsers = state.users.concat({
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        password: action.payload.password,
        tabletCode: action.payload.tabletCode,
        codeVrp: action.payload.codeVrp,
        login: action.payload.login,
        profil: action.payload.profil,
        accessStatistique: action.payload.accessStatistique
      });
      return {
        ...state,
        users: updatedUsers
      };
    }

    case UsersActionTypes.DEETE_USER_SUCCESS: {
      return {
        ...state,
        users: state.users.filter(u => u.id !== action.payload.id)
      };
    }

    default: {
      return state;
    }
  }
}
