import { Injectable } from '@angular/core';
import { ClientEntityType, ClientRequestEntityType, OrderRequestEntityType, OrderRequestLineEntityType, SyncStatusEntityType, UserEntityType, VisitEntityType, NotificationEntityType } from '@maxel-order/shared';
import { TcLoggerService, TcNotificationService } from '@tc/core';
import { Events } from '../events/events.interface';
import { EventsService } from '../events/events.service';
import { ConnectionOptions, SyncOptions } from './types';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private readonly defaultSyncOptions: SyncOptions = {
    live: true,
    retry: true,
  };
  private readonly liveSyncDatabases: Record<string, ConnectionOptions> = {
    [UserEntityType.lowerName]: { pull: this.defaultSyncOptions, push: this.defaultSyncOptions },
    [ClientEntityType.lowerName]: { push: this.defaultSyncOptions },
    [ClientRequestEntityType.lowerName]: { push: this.defaultSyncOptions },

// Uncommennt to activate automatic sync of OrderRequests OrderRequestLines and Notifications
//     [OrderRequestEntityType.lowerName]: { push: this.defaultSyncOptions },
//     [OrderRequestLineEntityType.lowerName]: { push: this.defaultSyncOptions },

    [SyncStatusEntityType.lowerName]: { push: this.defaultSyncOptions },
    [VisitEntityType.lowerName]: { push: this.defaultSyncOptions },
//    [NotificationEntityType.lowerName]: { push: this.defaultSyncOptions },
  };

  constructor(
    private readonly logger: TcLoggerService,
    private readonly eventsService: EventsService,
    private readonly notificationService: TcNotificationService,
  ) { }

  public start(data) {
    const { partition, connection, remote } = data;

    if (!this.liveSyncDatabases[partition]) {
      return;
    }

    this.sync(connection, remote, this.liveSyncDatabases[partition]);
  }

  private sync(connection: PouchDB.Database<any>, remote: PouchDB.Database<any>, options: ConnectionOptions) {
    const replicate = this.getReplicate(connection, remote, options);

    replicate
      .on('change', (changes) => {
        console.log('SYNC: changed: ', changes);
        if (changes && options.pull) {
          this.eventsService.publish(Events.RefreshRepository);
        }
      })
      .on('error', (error) => {
        this.logger.error('SYNC: error:', error);
        console.error('SYNC: error:', error);
        this.notificationService.error(error as any);
      })
  }

  private getReplicate(
    connection: PouchDB.Database<any>,
    remote: PouchDB.Database<any>,
    options: ConnectionOptions,
  ): any {
    if (options.push && options.pull) {
      return connection.sync(remote, options);
    }

    return options.push
      ? connection.replicate.to(remote, options.push)
      : connection.replicate.from(remote, options.pull);
  }
}
