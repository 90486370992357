import { Component, EventEmitter, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'app-article-item-like',
  templateUrl: './article-item-like.component.html',
  styleUrls: ['./article-item-like.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleItemLikeComponent extends TcDumbComponent {

  @Output()
  buttonClick: EventEmitter<any> = new EventEmitter();

  public onButtonClick() {
    this.buttonClick.emit();
  }
}
