import { Injectable } from '@angular/core';
import { OrderRequest, Visit, Order } from '@maxel-order/shared';
import * as moment from 'moment';

import { ClientsService } from '../../../services/business-services/clients.service';
import { OrdersService } from '../../../services/business-services/orders.service';
import { OrderRequestsService } from '../../../services/business-services/order-requests.service';
import { VisitsService } from '../../../services/business-services/visits.service';
import { ClientStatus } from '../../clients/enums/client-status.enum';
import { ClientListRowModel } from '../../clients/models/client-list-row.model';
import { OrderListModel } from '../models/order-list.model';

@Injectable()
export class OrdersListService {

  private readonly clientsMap = new Map<string, ClientListRowModel>();

  private ordersAndVisits: OrderListModel[] = [];

  constructor(
    private readonly ordersService: OrdersService,
    private readonly orderRequestsService: OrderRequestsService,
    private readonly visitsService: VisitsService,
    private readonly clientsService: ClientsService,
  ) { }

  async init() {
    await this.initClients();

    const orders = (await this.initOrders())
      .map(item => this.mapOrder(item));

    const orderRequests = (await this.initOrderRequests())
      .filter(item => item.isRemoved !== true && item.status !== 'INITIAL') // Hide soft deleted orders
      .map(item => this.mapOrderRequest(item));

    const visits = (await this.initVisits())
      .map(item => this.mapVisit(item));

    this.ordersAndVisits = orders.concat(orderRequests).concat(visits);
  }

  get(): OrderListModel[] {
    return this.ordersAndVisits;
  }

  private async initClients() {
    const clients = await this.clientsService.getClients({
      filter: { status: ClientStatus.Tous },
      limit: 9999,
      skip: 0,
    });
    this.addClientsToMap(clients);
  }

  private addClientsToMap(clients: ClientListRowModel[]) {
    clients.forEach(client =>
      this.clientsMap.set(client.id, client)
    );
  }

  private initOrders(): Promise<Order[]> {
    return this.ordersService.getOrders();
  }

  private initOrderRequests(): Promise<OrderRequest[]> {
    return this.orderRequestsService.getOrders();
  }

  private async initVisits(): Promise<Visit[]> {
    return this.visitsService.getVisits();
  }

  private mapOrder(order: Order): OrderListModel {
    return {
      id: order.id,
      clientId: order.clientId,
      date: this.format(order.date),
      clientName: this.clientsMap.get(order.clientId)?.name,
      status: order.status,
      totalExcludingTax: order.totalExcludingTax,
      type: 'order',
    };
  }

  private mapOrderRequest(order: OrderRequest): OrderListModel {
    return {
      id: order.id,
      clientId: order.clientId,
      date: this.format(order.date),
      clientName: this.clientsMap.get(order.clientId)?.name,
      status: order.status,
      totalExcludingTax: order.totalExcludingTax,
      type: 'orderRequest',
    };
  }

  private mapVisit(visit: Visit): OrderListModel {
    return {
      id: visit.id,
      clientId: visit.clientId,
      clientName: this.clientsMap.get(visit.clientId)?.name,
      date: this.format(visit.date),
      status: 'VISITED',
      totalExcludingTax: 0,
      type: 'visit',
    };
  }

  private format(date: string | Date, format = 'DD/MM/YYYY'): string {
    return moment(date).format(format);
  }

}
