import { TcAction, SHOW_SPINNER, HIDE_SPINNER } from '@tc/core';
import { ArticleModel } from '../../articles/models/article.model';
import { OrdersViewType } from '../components/smart/order-list/types';
import { OrderListModel } from '../models/order-list.model';
import { OrderModel } from '../models/order.model';
import { OrderSummaryModel } from '../models/order-summary.model';

export enum OrdersActionTypes {

  SELECT_ORDER = '[Orders] Select order',
  SELECT_ORDER_IDS = '[Orders] Select orders by ids',

  CANCEL_ORDER = '[Orders] Cancel Order',
  CANCEL_ORDER_SUCCESS = '[Orders] Cancel Order Success',
  CANCEL_ORDER_ERROR = '[Orders] Cancel Order Error',

  BLOCK_ORDER = '[Orders] Block Order',
  BLOCK_ORDER_SUCCESS = '[Orders] Block Order Success',
  BLOCK_ORDER_ERROR = '[Orders] Block Order Error',

  UPDATE_ORDER = '[Orders] Update Order',

  VALIDATE_ORDER = '[Orders] Validate Order',
  VALIDATE_ORDER_SUCCESS = '[Orders] Validate Order Success',
  VALIDATE_ORDER_ERROR = '[Orders] Validate Order Error',

  SEND_DEVIS = '[Order] Send Devis',
  SEND_DEVIS_SUCCESS = '[Orders] Send Devis Success',
  SEND_DEVIS_ERROR = '[Orders] Send Devis Error',

  VIEW_BASKET_CONTENT = '[Order] View basket content',

  VIEW_ORDER_SUMMARY = '[Order-list] View Order Summary',
  EDIT_ORDER = '[Order-list] Edit Order',

  CREATE_INIT_ORDER = '[Orders] Create Init Order',
  LOAD_ORDER = '[Orders] Load Order',

  LOAD_ORDER_SUMMARY = '[Orders] Load Order Summary',
  LOAD_ORDER_SUMMARY_SUCCESS = '[Orders] Load Order Summary Success',
  
  LOAD_ORDER_REQUEST_SUMMARY = '[Orders] Load OrderRequest Summary',
  LOAD_ORDER_REQUEST_SUMMARY_SUCCESS = '[Orders] Load OrderRequest Summary Success',

  INCREASE_QUANTITY = '[Orders] Increase quantity',
  INCREASE_QUANTITY_SUCCESS = '[Orders] Increase quantity Success',
  INCREASE_QUANTITY_ERROR = '[Orders] Increase quantity Error',

  DECREASE_QUANTITY = '[Orders] Decrease quantity',
  DECREASE_QUANTITY_SUCCESS = '[Orders] Decrease quantity Success',
  DECREASE_QUANTITY_ERROR = '[Orders] Decrease quantity Error',

  DELETE_FROM_BASKET = '[Orders] Delete from basket',
  DELETE_FROM_BASKET_SUCCESS = '[Orders] Delete from basket Success',
  DELETE_FROM_BASKET_ERROR = '[Orders] Delete from basket Error',

  ORDER_ARTICLE = '[Orders] Order Article',
  ORDER_ARTICLE_SUCCESS = '[Orders] Order Article Success',

  ORDER_REMOVE_ARTICLE = '[Orders] Order Remove Article',
  ORDER_REMOVE_ARTICLE_SUCCESS = '[Orders] Order Remove Article Success',

  ORDER_ARTICLE_ERROR = '[Orders] Order Article Error',

  ADD_TO_ORDERS_LIST = '[Orders] Add to orders list',
  RESET_ORDERS_LIST = '[Orders] Reset orders list',
  SET_ORDER_VIEW_TYPE = '[Orders] Set orders view type',
}


@SHOW_SPINNER()
export class IncreaseQuantity implements TcAction {
  readonly type = OrdersActionTypes.INCREASE_QUANTITY;
  constructor(public payload: { orderLineId: string, itemsInBax: number, leftForSaleStock: number }) { }
}

@HIDE_SPINNER()
export class IncreaseQuantitySuccess implements TcAction {
  readonly type = OrdersActionTypes.INCREASE_QUANTITY_SUCCESS;
  constructor(public payload: string) { }
}

@HIDE_SPINNER()
export class IncreaseQuantityError implements TcAction {
  readonly type = OrdersActionTypes.INCREASE_QUANTITY_ERROR;
  constructor(public payload: string) { }
}

@SHOW_SPINNER()
export class DecreaseQuantity implements TcAction {
  readonly type = OrdersActionTypes.DECREASE_QUANTITY;
  constructor(public payload: { orderLineId: string, itemsInBax: number, leftForSaleStock: number }) { }
}

@HIDE_SPINNER()
export class DecreaseQuantitySuccess implements TcAction {
  readonly type = OrdersActionTypes.DECREASE_QUANTITY_SUCCESS;
  constructor(public payload: string) { }
}

@HIDE_SPINNER()
export class DecreaseQuantityError implements TcAction {
  readonly type = OrdersActionTypes.DECREASE_QUANTITY_ERROR;
  constructor(public payload: string) { }
}

@SHOW_SPINNER()
export class DeleteFromBasket implements TcAction {
  readonly type = OrdersActionTypes.DELETE_FROM_BASKET;
  constructor(public payload: string) { }
}

@HIDE_SPINNER()
export class DeleteFromBasketSuccess implements TcAction {
  readonly type = OrdersActionTypes.DELETE_FROM_BASKET_SUCCESS;
  constructor(public payload: string) { }
}

@HIDE_SPINNER()
export class DeleteFromBasketError implements TcAction {
  readonly type = OrdersActionTypes.DELETE_FROM_BASKET_ERROR;
  constructor(public payload: string) { }
}

@SHOW_SPINNER()
export class OrderArticle implements TcAction {
  readonly type = OrdersActionTypes.ORDER_ARTICLE;
  constructor(public payload: ArticleModel) { }
}

@HIDE_SPINNER()
export class OrderArticleSuccess implements TcAction {
  readonly type = OrdersActionTypes.ORDER_ARTICLE_SUCCESS;
  constructor(
    public payload: { article: ArticleModel, orderLineId: string }
  ) { }
}

@SHOW_SPINNER()
export class OrderRemoveArticle implements TcAction {
  readonly type = OrdersActionTypes.ORDER_REMOVE_ARTICLE;
  constructor(public payload: ArticleModel) { }
}

@HIDE_SPINNER()
export class OrderRemoveArticleSuccess implements TcAction {
  readonly type = OrdersActionTypes.ORDER_REMOVE_ARTICLE_SUCCESS;
  constructor(
    public payload: { article: ArticleModel, orderLineId: string }
  ) { }
}

@HIDE_SPINNER()
export class OrderArticleError implements TcAction {
  readonly type = OrdersActionTypes.ORDER_ARTICLE_ERROR;
  constructor(public payload: string) { }
}

export class ViewBasketContent implements TcAction {
  readonly type = OrdersActionTypes.VIEW_BASKET_CONTENT;
  constructor() { }
}

export class SelectOrder implements TcAction {
  readonly type = OrdersActionTypes.SELECT_ORDER;
  constructor(public payload: OrderListModel) { }
}

export class SelectOrderIds implements TcAction {
  readonly type = OrdersActionTypes.SELECT_ORDER_IDS;
  constructor(public payload: string[]) { }
}

export class LoadOrder implements TcAction {
  readonly type = OrdersActionTypes.LOAD_ORDER;
  constructor(public payload: OrderModel) { }
}

export class CreateInitOrder implements TcAction {
  readonly type = OrdersActionTypes.CREATE_INIT_ORDER;
  constructor(public payload: string) { }
}

export class ViewOrderSummary implements TcAction {
  readonly type = OrdersActionTypes.VIEW_ORDER_SUMMARY;
  constructor(public payload: OrderSummaryModel) { }
}

export class EditOrder implements TcAction {
  readonly type = OrdersActionTypes.EDIT_ORDER;
  constructor(public payload: OrderListModel) { }
}

@SHOW_SPINNER()
export class CancelOrder implements TcAction {
  readonly type = OrdersActionTypes.CANCEL_ORDER;
}

@HIDE_SPINNER()
export class CancelOrderSuccess implements TcAction {
  readonly type = OrdersActionTypes.CANCEL_ORDER_SUCCESS;
}

@HIDE_SPINNER()
export class CancelOrderError implements TcAction {
  readonly type = OrdersActionTypes.CANCEL_ORDER_ERROR;
  constructor(public payload: string) { }
}

@SHOW_SPINNER()
export class BlockOrder implements TcAction {
  readonly type = OrdersActionTypes.BLOCK_ORDER;
}

@HIDE_SPINNER()
export class BlockOrderSuccess implements TcAction {
  readonly type = OrdersActionTypes.BLOCK_ORDER_SUCCESS;
}

@HIDE_SPINNER()
export class BlockOrderError implements TcAction {
  readonly type = OrdersActionTypes.BLOCK_ORDER_ERROR;
  constructor(public payload: string) { }
}

@SHOW_SPINNER()
export class ValidateOrder implements TcAction {
  readonly type = OrdersActionTypes.VALIDATE_ORDER;
  constructor(public payload: { deliveryDate: string, summaryRequested: boolean, comments: string }) { }
}

export class UpdateOrder implements TcAction {
  readonly type = OrdersActionTypes.UPDATE_ORDER;
  constructor(public payload: { deliveryDate: string, summaryRequested: boolean, comments: string }) { }
}

@HIDE_SPINNER()
export class ValidateOrderSuccess implements TcAction {
  readonly type = OrdersActionTypes.VALIDATE_ORDER_SUCCESS;
}

@HIDE_SPINNER()
export class ValidateOrderError implements TcAction {
  readonly type = OrdersActionTypes.VALIDATE_ORDER_ERROR;
  constructor(public payload: string) { }
}

@SHOW_SPINNER()
export class SendDevis implements TcAction {
  readonly type = OrdersActionTypes.SEND_DEVIS;
}

@HIDE_SPINNER()
export class SendDevisSuccess implements TcAction {
  readonly type = OrdersActionTypes.SEND_DEVIS_SUCCESS;
}

@HIDE_SPINNER()
export class SendDevisError implements TcAction {
  readonly type = OrdersActionTypes.SEND_DEVIS_ERROR;
  constructor(public payload: string) { }
}

export class AddToOrdersList implements TcAction {
  readonly type = OrdersActionTypes.ADD_TO_ORDERS_LIST;

  constructor(
    public payload: OrderListModel[],
  ) { }
}

export class ResetOrdersList implements TcAction {
  readonly type = OrdersActionTypes.RESET_ORDERS_LIST;
}

export class SetOrdersViewType implements TcAction {
  readonly type = OrdersActionTypes.SET_ORDER_VIEW_TYPE;

  constructor(
    public payload: OrdersViewType
  ) { }
}

export class LoadOrderSummary implements TcAction {
  readonly type = OrdersActionTypes.LOAD_ORDER_SUMMARY;
  constructor(public payload: string) { }
}

export class LoadOrderSummarySuccess implements TcAction {
  readonly type = OrdersActionTypes.LOAD_ORDER_SUMMARY_SUCCESS;
  constructor(public payload: OrderSummaryModel) { }
}

export class LoadOrderRequestSummary implements TcAction {
  readonly type = OrdersActionTypes.LOAD_ORDER_REQUEST_SUMMARY;
  constructor(public payload: string) { }
}

export class LoadOrderRequestSummarySuccess implements TcAction {
  readonly type = OrdersActionTypes.LOAD_ORDER_REQUEST_SUMMARY_SUCCESS;
  constructor(public payload: OrderSummaryModel) { }
}


export type OrdersActions =
  | OrderArticle
  | OrderArticleSuccess
  | OrderRemoveArticle
  | OrderRemoveArticleSuccess
  | OrderArticleError
  | IncreaseQuantity
  | IncreaseQuantitySuccess
  | IncreaseQuantityError
  | DecreaseQuantity
  | DecreaseQuantitySuccess
  | DecreaseQuantityError
  | DeleteFromBasket
  | DeleteFromBasketSuccess
  | DeleteFromBasketError
  | ViewBasketContent
  | CancelOrder
  | CancelOrderSuccess
  | CancelOrderError
  | ValidateOrder
  | ValidateOrderSuccess
  | ValidateOrderError
  | BlockOrder
  | BlockOrderSuccess
  | BlockOrderError
  | SendDevis
  | SendDevisSuccess
  | SendDevisError
  | LoadOrder
  | LoadOrderSummary
  | LoadOrderSummarySuccess
  | LoadOrderRequestSummary
  | LoadOrderRequestSummarySuccess
  | CreateInitOrder
  | ViewOrderSummary
  | EditOrder
  | AddToOrdersList
  | ResetOrdersList
  | SetOrdersViewType;
