export class VisitModel {
  id: string;
  date?: string;
  clientName?: string;
  clientId?: string;
  comments?: string;
  type?: string;
  codeVrp?: string;
  exportDate?: Date;
}
