import { TcAction } from '@tc/core';
import { SyncProgress } from '../models/sync-progress.model';

export enum SyncAgentActionTypes {
  INITIALISE_SYNC = '[Sync Agent] Initialise Sync',

  DISPLAY_SYNC_POPUP = '[Sync Agent] Display Sync Popup',
  HIDE_SYNC_POPUP = '[Sync Agent] Hide Sync Popup',

  DISPLAY_SYNC_FINISH_POPUP = '[Sync Agent] Display sync finish popup',

  CANCEL_SYNC = '[Sync Agent] Cancel Sync',
  START_SYNC = '[Sync Agent] Start Sync',

  SYNC_PROGRESS_RESET = '[Sync Agent] Sync progress reset',
  SYNC_PROGRESS_UPDATED = '[Sync Agent] Sync progress updated',
  SYNC_PROGRESS_PARTITION_STARTED = '[Sync Agent] Sync progress partition started',

  SYNC_REQUIRED = '[Sync Agent] Sync Required',

  FIRST_SYNC_FINISHED = '[Sync Agend] First Sync Finished',

  SET_LAST_SYNC_DB_VERSION = '[Sync Agend] Set last sync app version',
}

export class InitialiseSync implements TcAction {
  readonly type = SyncAgentActionTypes.INITIALISE_SYNC;
}

export class DisplaySyncPopup implements TcAction {
  readonly type = SyncAgentActionTypes.DISPLAY_SYNC_POPUP;
  constructor(public payload?: { disableClose: boolean }) { }
}

export class DisplaySyncFinishPopup implements TcAction {
  readonly type = SyncAgentActionTypes.DISPLAY_SYNC_FINISH_POPUP;
}

export class CancelSync implements TcAction {
  readonly type = SyncAgentActionTypes.CANCEL_SYNC;
}

export class StartSync implements TcAction {
  readonly type = SyncAgentActionTypes.START_SYNC;
}

export class SyncProgressUpdated implements TcAction {
  readonly type = SyncAgentActionTypes.SYNC_PROGRESS_UPDATED;

  constructor(public payload: SyncProgress) { }
}

export class SyncProgressReset implements TcAction {
  readonly type = SyncAgentActionTypes.SYNC_PROGRESS_RESET;
}

export class SyncProgressPartitionStarted implements TcAction {
  readonly type = SyncAgentActionTypes.SYNC_PROGRESS_PARTITION_STARTED;

  constructor(public payload: string) { }
}

export class SyncRequired implements TcAction {
  readonly type = SyncAgentActionTypes.SYNC_REQUIRED;
}

export class FirstSyncFinished implements TcAction {
  readonly type = SyncAgentActionTypes.FIRST_SYNC_FINISHED;
}

export class SetLastSyncDBVersion implements TcAction {
  readonly type = SyncAgentActionTypes.SET_LAST_SYNC_DB_VERSION;

  constructor(public payload: string) { }
}

export class HideSyncPopup implements TcAction {
  readonly type = SyncAgentActionTypes.HIDE_SYNC_POPUP;
}

export type SyncAgentActions =
  InitialiseSync
  | SyncRequired
  | CancelSync
  | StartSync
  | SyncProgressUpdated
  | SyncProgressReset
  | SyncProgressPartitionStarted
  | DisplaySyncPopup
  | DisplaySyncFinishPopup
  | FirstSyncFinished
  | HideSyncPopup
  | SetLastSyncDBVersion;
