import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrdersState } from './orders.state';
import { ArticleModel } from '../../articles/models/article.model';

const getOrdersState = createFeatureSelector<OrdersState>(
  'orders'
);

/**
 * get number of items in basket
 */
export const getBasketTotalItems = createSelector(
  getOrdersState,
  (state: OrdersState) => state.basket && state.basket.lines && state.basket.lines.length > 0 ? state.basket.lines.map(a => Number(a.quantity)).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
);

/**
 * get total sum of basket
 */
export const getBasketTotalSum = createSelector(
  getOrdersState,
  (state: OrdersState) => state.basket && state.basket.lines && state.basket.lines.length > 0 ? state.basket.lines.map(a => Number(a.quantity) * Number(a.price ? a.price : 0)).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
);

/**
 * get number of articles of type, added in basket
 * @param article article model
 */
export const getNumberOfArticlesInBasket = (article: ArticleModel) => createSelector(
  getOrdersState,
  (state: OrdersState) => {
    const items = state.basket && state.basket.lines && state.basket.lines.length > 0 ? state.basket.lines.filter(a => a.articleId === article.id) : [];
    if (items && items.length > 0) {
      return items.map(a => Number(a.quantity)).reduce((accumulator, currentValue) => accumulator + currentValue);
    }
    return 0;
  }
);

// todo: change this selector
export const getOrderSummary = createSelector(
  getOrdersState,
  (state: OrdersState) => state.orderSummary
);

export const getOrderList = createSelector(
  getOrdersState,
  (state: OrdersState) => state.orderList
);

export const getOrderId = createSelector(
  getOrdersState,
  (state: OrdersState) => state.basket && state.basket.id
);

export const getOrder = createSelector(
  getOrdersState,
  (state: OrdersState) => state.basket
);

export const getBasketLines = createSelector(
  getOrdersState,
  (state: OrdersState) => state.basket.lines
);

export const getOrderViewType = createSelector(
  getOrdersState,
  (state: OrdersState) => state.viewType,
);