<div class="client-list-page">
    <tc-layout [fixedInViewport]="true" [fixedTopGap]="0" [mode]="'side'" [showToolbarMenuIcon]="false" [opened]="true">

        <div class="header-content" tc-layout-toolbar>
            <div class="header-left">
                <app-client-logo [logo]="(clientSelected$ | async)?.logo"></app-client-logo>
                <app-custom-client-title></app-custom-client-title>
            </div>
            <div class="header-right">
                <app-user-info></app-user-info>
                <app-page-menu></app-page-menu>
            </div>
        </div>
        <div tc-layout-sidenav>

            <app-back-navigation></app-back-navigation>

            <!-- <div class="filter-search">
                <tc-generic-filter #clientListFilter>
                </tc-generic-filter>
            </div> -->

            <img class="image" src="assets/icons/line_colors.png" width="170px">

            <!-- <app-client-filter></app-client-filter> -->

        </div>

        <div tc-layout-sidenav-content>
            <div>
                <app-order-detail></app-order-detail>
            </div>

        </div>

    </tc-layout>
</div>