import { TcAction } from '@tc/core';

export enum MainActionTypes {

  // NEW_ORDER = '[Main] New order',
  // MY_ORDERS = '[Main] My orders',
  // MY_STATISTICS = '[Main] My statistics',
  APP_NAVIGATION = '[Main] App Navigation',
  APP_BACK_NAVIGATION = '[Main] App Back Navigation'
}


// export class NewOrder implements TcAction {
//   readonly type = MainActionTypes.NEW_ORDER;
//   constructor() { }
// }

// export class MyOrders implements TcAction {
//   readonly type = MainActionTypes.MY_ORDERS;
//   constructor() { }
// }


// export class MyStatistics implements TcAction {
//   readonly type = MainActionTypes.MY_STATISTICS;
//   constructor() { }
// }

export class AppNavigation implements TcAction {
  readonly type = MainActionTypes.APP_NAVIGATION;
  constructor(public payload: string) { }
}

export class AppBackNavigation implements TcAction {
  readonly type = MainActionTypes.APP_BACK_NAVIGATION;
}


export type MainActions =
  AppBackNavigation
  | AppNavigation;
