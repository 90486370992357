import { Component, OnInit, ElementRef, Optional, Inject } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VisitModel } from '../../../models/visit.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss']
})
export class VisitComponent extends TcFormComponent<any> implements OnInit {

  constructor(
    public translateService: TcTranslateService,
    private dialogRef: MatDialogRef<VisitComponent>,
    element: ElementRef,
    private datePipe: DatePipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: VisitModel) {

    super(translateService, element);

    this.fields = [
      {
        key: 'comments',
        type: 'textarea',
        templateOptions: {
          required: true,
          autosize: true,
          autosizeMinRows: 4,
          autosizeMaxRows: 8,
          readonly: this.data && this.data.id !== null
        }
      }
    ];

  }

  ngOnInit() {
    if (this.data && !this.data.id) {
      this.data.date = this.datePipe.transform(new Date(), "dd/MM/yyyy");
    }

    this.model = Object.assign({}, this.data);

    super.ngOnInit();

    this.dialogRef.addPanelClass('visits__create_popup');
  }

  onClose() {
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(model: any) {
    if (this.form.valid) {
      this.dialogRef.close(model);
    }
  }
}

