import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatisticsState } from './statistics.interfaces';

const getStatisticsState = createFeatureSelector<StatisticsState>(
  'statistics'
);

export const getSelectedStatisticsType = createSelector(
  getStatisticsState,
  (state: StatisticsState) => state.type,
);

export const getCurrentStatisticsItems = createSelector(
  getStatisticsState,
  (state: StatisticsState) => state.items,
);
