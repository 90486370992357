import { Component, Inject, OnInit, Optional, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TcDumbComponent } from '@tc/core';
import { IMAGES_ROOT_FOLDER } from '../../../../../app.module';

@Component({
  selector: 'app-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePreviewComponent extends TcDumbComponent implements OnInit {

  public source: string;

  constructor(
    private dialogRef: MatDialogRef<ArticlePreviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(IMAGES_ROOT_FOLDER) private imagesRootUrl: string
  ) {
    super();
  }

  ngOnInit() {
    this.source = typeof this.data === 'string' && this.data.indexOf('data:') === 0
      ? this.data
      : `${this.imagesRootUrl}${this.data.company}/${this.data.image}`;
  }

  onClose() {
    this.dialogRef.close();
  }
}
