<div class="container">
  <div class="user">{{ 'home-page.title' | translate }}, {{ (authenticatedUser$ | async)?.firstName }} {{ (authenticatedUser$ | async)?.lastName }}</div>
  <div class="img">
    <img src="assets/icons/line_colors.png" width="170px" />
  </div>
  <div class="options">
    <div class="user-menu">
      <div class="card" (click)="navigation('clients')">
        <div class="card-content">
          <img src="assets/icons/new-order.png" width="150" />
        </div>
        <div class="card-name">{{ 'app-menu.clients' | translate }}</div>
      </div>

      <div class="card" (click)="navigation('orders')">
        <div class="card-content"><img src="assets/icons/my-orders.png" width="150" /></div>
        <div class="card-name">{{ 'app-menu.orders' | translate }}</div>
      </div>

      <div class="card" *ngIf="(authenticatedUser$ | async)?.accessStatistique" (click)="navigation('reports')">
        <div class="card-content"><img src="assets/icons/my-statistics.png" width="150" /></div>
        <div class="card-name">{{ 'app-menu.reports' | translate }}</div>
      </div>
    </div>
  </div>
</div>
