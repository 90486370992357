import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'app-total-price-basket',
  templateUrl: './total-price-basket.component.html',
  styleUrls: ['./total-price-basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalPriceBasketComponent extends TcSmartComponent implements OnInit {

  // public totalSum$: Observable<number>;

  constructor(
    private readonly store: Store<any>
  ) {
    super();
  }

  ngOnInit() {
    // this.totalSum$ = this.store.pipe(select(getBasketTotalSum));
  }
}
