<div class="filter-list client-filter">
    <div
        class="filter-list-item"
        *ngFor="let filter of (filters$ | async); last as isLast"
        (click)="selectFilter(filter.value)"
        [class.selected]="selectedStatus === filter?.value"
        [class.last]="isLast"
    >
        {{filter.label | translate}}
    </div>
</div>
