import { ClientPriceEntityType } from '@maxel-order/shared';
import {
  ArticleEntityType,
  PriceEntityType,
  AddressEntityType,
  ClientEntityType,
  OrderEntityType,
  OrderLineEntityType,
  InvoiceEntityType,
  InvoiceLineEntityType,
  UserEntityType,
  ClientRequestEntityType,
  ArticleOrderHistoryEntityType,
  OrderProperty,
  CompanyEntityType,
  OrderLineProperty,
  FamilyEntityType,
  SyncStatusEntityType,
  AddressProperty
} from './entities/generated';
import { VisitEntityType } from './entities/generated/visit-entity-type';

const schema = [
  {
    singular: CompanyEntityType.lowerName,
    plural: CompanyEntityType.pluralName
  },
  {
    singular: FamilyEntityType.lowerName,
    plural: FamilyEntityType.pluralName
  },
  {
    singular: ArticleEntityType.lowerName,
    plural: ArticleEntityType.pluralName,
    relations: {
      familyId: { belongsTo: { type: FamilyEntityType.lowerName, options: { async: true } } }
    }
  },
  {
    singular: PriceEntityType.lowerName,
    plural: PriceEntityType.pluralName,
    relations: {
      articleId: { belongsTo: { type: ArticleEntityType.lowerName, options: { async: true } } }
    }
  },
  {
    singular: AddressEntityType.lowerName,
    plural: AddressEntityType.pluralName,
    relations: { clientId: { belongsTo: { type: ClientEntityType.lowerName, options: { async: true } } } }
  },
  {
    singular: ClientEntityType.lowerName,
    plural: ClientEntityType.pluralName,
    relations: {
      addresses: {
        hasMany: { type: AddressEntityType.lowerName, options: { queryInverse: AddressProperty.clientId, async: true } }
      },
      orders: {
        hasMany: { type: OrderEntityType.lowerName, options: { queryInverse: OrderProperty.clientId, async: true } }
      },
      invoices: {
        hasMany: { type: InvoiceEntityType.lowerName, options: { queryInverse: 'invoiceId', async: true } }
      },
      visits: {
        hasMany: { type: VisitEntityType.lowerName, options: { queryInverse: 'visitId', async: true } }
      },
    }
  },
  {
    singular: OrderEntityType.lowerName,
    plural: OrderEntityType.pluralName,
    relations: {
      clientId: { belongsTo: { type: ClientEntityType.lowerName, options: { async: true } } },
      salesmanId: { belongsTo: { type: UserEntityType.lowerName, options: { async: true } } },
      orderLines: {
        hasMany: { type: OrderLineEntityType.lowerName, options: { queryInverse: OrderLineProperty.orderId, async: true } }
      },
    }
  },
  {
    singular: OrderLineEntityType.lowerName,
    plural: OrderLineEntityType.pluralName,
    relations: {
      orderId: { belongsTo: { type: OrderEntityType.lowerName, options: { async: true } } }
    }
  },
  {
    singular: InvoiceEntityType.lowerName,
    plural: InvoiceEntityType.pluralName,
    relations: {
      clientId: { belongsTo: { type: ClientEntityType.lowerName, options: { async: true } } },
      orderLines: {
        hasMany: { type: InvoiceLineEntityType.lowerName, options: { queryInverse: 'invoiceId', async: true } }
      },
    }
  },
  {
    singular: InvoiceLineEntityType.lowerName,
    plural: InvoiceLineEntityType.pluralName,
    relations: {
      invoiceId: { belongsTo: { type: InvoiceEntityType.lowerName, options: { async: true } } }
    }
  },
  {
    singular: VisitEntityType.lowerName,
    plural: VisitEntityType.pluralName,
    relations: {
      clientId: { belongsTo: { type: ClientEntityType.lowerName, options: { async: true } } },
    }
  },
  {
    singular: UserEntityType.lowerName,
    plural: UserEntityType.pluralName,
  },
  {
    singular: ClientRequestEntityType.lowerName,
    plural: ClientRequestEntityType.pluralName,
    relations: {
      addresses: {
        hasMany: { type: AddressEntityType.lowerName, options: { queryInverse: AddressProperty.clientId, async: true } }
      }
    }
  },
  {
    singular: ArticleOrderHistoryEntityType.lowerName,
    plural: ArticleOrderHistoryEntityType.pluralName
  },
  {
    singular: ClientPriceEntityType.lowerName,
    plural: ClientPriceEntityType.pluralName
  },
  {
    singular: SyncStatusEntityType.lowerName,
    plural: SyncStatusEntityType.pluralName
  }
];

export { schema };
