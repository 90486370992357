<tc-list
    #clientList
    id="client-list"
></tc-list>

<ng-template
    #colLocationTemplate
    let-row="row"
    let-rowAction="rowAction"
>
    <div
        *ngIf="row.distance"
        class="distance"
    >
        <span>{{row.distance.toFixed(0)}} kms</span>
        <img
            src="assets/icons/icon_location.png"
            width="20"
        >
    </div>
</ng-template>

<ng-template
    #colClientDetailsTemplate
    let-row="row"
    let-rowAction="rowAction"
>
    <img
        *ngIf="row?.hasActionsVisible"
        (click)="openClientDetails(row)"
        class="image"
        src="assets/icons/bton_client.png"
        width="40"
    >
</ng-template>

<ng-template
    #colDirectionTemplate
    let-row="row"
    let-rowAction="rowAction"
>
    <img
        *ngIf="row?.hasActionsVisible"
        (click)="directionMap(row)"
        class="image"
        src="assets/icons/bton_direction.png"
        width="40"
    >
</ng-template>

<ng-template
    #colVisitTemplate
    let-row="row"
    let-rowAction="rowAction"
>
    <img
        *ngIf="row?.hasActionsVisible"
        (click)="visiteClient(row)"
        class="image"
        src="assets/icons/bton_visit.png"
        width="40"
    >
</ng-template>

<ng-template
    #colStatusTemplate
    let-row="row"
    let-rowAction="rowAction"
>
    {{row?.visibleStatus | translate}}
</ng-template>

<div
    class="add"
    *ngIf="hasWriteAccess"
>
    <img
        (click)="addClient()"
        src="assets/icons/bton_add.png"
        width="45"
    >
</div>
