export type DunningProperty = | 'code' | 'clientCode' | 'number' | 'numberInvoice' | 'date' | 'comments' ;

export const DunningProperty = {
  code: 'code' as DunningProperty,
  clientCode: 'clientCode' as DunningProperty,
  number: 'number' as DunningProperty,
  numberInvoice: 'numberInvoice' as DunningProperty,
  date: 'date' as DunningProperty,
  comments: 'comments' as DunningProperty,
}
