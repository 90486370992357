export interface FilterData {
  status: Statuses,
  customer: string;
  date: Date;
}

export enum Statuses {
  Visited = 'VISITED',
  Initial = 'INITIAL',
  Blocked = 'BLOCKED',
  Valid = 'VALID',
  Delivered = 'DELIVERED',
  Canceled = 'CANCELED',
}

export enum OrdersViewType {
  All,
  Blocked,
}

export const StatusValues = [{
  label: 'order-list.status.visited',
  value: Statuses.Visited
}, {
  label: 'order-list.status.blocked',
  value: Statuses.Blocked
}, {
  label: 'order-list.status.valid',
  value: Statuses.Valid
}, {
  label: 'order-list.status.delivered',
  value: Statuses.Delivered
}, {
  label: 'order-list.status.canceled',
  value: Statuses.Canceled
}];
