import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcListComponent, TcListFilterType, TcListSortType, TcSmartComponent } from '@tc/core';
import { LoadMoreArticles } from '../../../store/articles.actions';
import { getVisibleArticles } from './../../../store/articles.selectors';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleListComponent extends TcSmartComponent implements OnInit {

  articleList: TcListComponent;
  @ViewChild('articleList', { static: true }) set setArticleList(values: TcListComponent) {
    this.articleList = values;
  }

  @ViewChild('colArticleItemTemplate', { static: true }) colArticleItemTemplate: TemplateRef<any>;

  constructor(
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit() {
    this.articleList.rows$ = this.store.pipe(select(getVisibleArticles));

    this.articleList.isFiltrable = false;
    this.articleList.filterType = TcListFilterType.Disabled;
    this.articleList.sortType = TcListSortType.Disabled;
    this.articleList.isPaged = false;
    this.articleList.hasFixedHeader = true;
    this.articleList.hasAddButton = false;

    this.articleList.onScrollDown = () => {
      this.store.dispatch(new LoadMoreArticles());
    };

    this.articleList.columns = [
      {
        propertyName: 'name',
        visible: true,
        htmlTemplate: this.colArticleItemTemplate
      }
    ];
  }

}
