import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TC_LIST_DEFAULT_NB_LINES, TcAction, HideSpinner, ShowSpinner } from '@tc/core';
import { Observable } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ArticlesService } from '../../../services/business-services/articles.service';
import { ClientsService } from '../../../services/business-services/clients.service';
import { OrderRequestsService } from '../../../services/business-services/order-requests.service';
import { ArticlePreviewComponent } from '../components/dumb/article-preview/article-preview.component';
import { ArticleHistoryComponent } from '../components/smart/article-history/article-history.component';
import { getSelectedClientId } from './../../clients/store/clients.selectors';
import {
  ArticlesActionTypes,
  FilterArticles,
  InitArticlesPage,
  LoadArticleOrderHistory,
  LoadArticleOrderHistorySuccess,
  LoadArticles,
  LoadMoreArticles,
  PreviewArticle,
  ShowArticleHistory,
  UpdateUsedCategories,
  UpdateUsedCategoriesSuccess,
  InitArticles,
  InitArticlesSuccess,
  InitCategoriesSuccess,
  LoadArticlesSuccess
} from './articles.actions';
import { getArticlesState, getAllArticles, getClientArticleHistory } from './articles.selectors';
import { ArticleOrderHistoryService } from '../../../services/business-services/article-order-history.service';

@Injectable()
export class ArticlesEffects {
  constructor(
    private readonly store: Store<any>,
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly articlesService: ArticlesService,
    private readonly articleOrderHistoryService: ArticleOrderHistoryService,
    private readonly clientsService: ClientsService,
    private readonly ordersRequestsService: OrderRequestsService,
    @Inject(TC_LIST_DEFAULT_NB_LINES) private tcListDefaultNbLines: number
  ) {
    this.tcListDefaultNbLines = 99999;
  }

  @Effect({ dispatch: false })
  initArticlesPageEffect$ = this.actions$.pipe(
    ofType<InitArticlesPage>(ArticlesActionTypes.INIT_ARTICLES_PAGE),
    withLatestFrom(this.store.pipe(select(getArticlesState))),
    withLatestFrom(this.store.pipe(select(getSelectedClientId))),
    map(async ([[action, articlesState], selectedClientId]) => {
      if (articlesState.clientId === selectedClientId) {
        if (articlesState.filter) {
          return this.store.dispatch(new FilterArticles(articlesState.filter));
        }
        return;
      }

      this.store.dispatch(new ShowSpinner());

      //workaround to allow spinner to be displayed
      await new Promise(resolve => setTimeout(resolve, 0));

      const clientPrices = await this.clientsService.getClientPrices(selectedClientId);

      const clientArticleHistory = await this.articleOrderHistoryService.getArticlesIdsFromHistory(selectedClientId);

      this.store.dispatch(new HideSpinner());

      this.store.dispatch(
        new InitArticles({
          clientPrices,
          clientId: selectedClientId,
          clientArticleHistory
        })
      );
    })
  );

  @Effect({ dispatch: false })
  loadArticlesEffect$ = this.actions$.pipe(
    ofType<LoadArticles>(ArticlesActionTypes.LOAD_ARTICLES),
    withLatestFrom(this.store.pipe(select(getAllArticles))),
    withLatestFrom(this.store.pipe(select(getClientArticleHistory))),
    map(([[action, articles], clientArticleHistory]) => {
      this.articlesService.filterArticles(articles, clientArticleHistory, action.payload).then(filtered => {
        this.store.dispatch(
          new LoadArticlesSuccess({
            articles: filtered,
            loadMore: !!action.payload.loadMore,
            canLoadMore: !(filtered.length < this.tcListDefaultNbLines),
            pagination: {
              after: action.payload.pagination ? Number(action.payload.pagination.after) + this.tcListDefaultNbLines : 50,
              first: this.tcListDefaultNbLines
            }
          })
        );
      });
    })
  );

  @Effect({ dispatch: false })
  initArticlesEffect$ = this.actions$.pipe(
    ofType<InitArticles>(ArticlesActionTypes.INIT_ARTICLES),
    map(async action => {
      const { articles } = await this.articlesService.parseArticles();
      this.store.dispatch(new InitArticlesSuccess({ articles }));

      const categories = await this.articlesService.parseCategories(articles);
      this.store.dispatch(new InitCategoriesSuccess({ categories }));
    })
  );

  @Effect({ dispatch: false })
  filterArticlesEffect$ = this.actions$.pipe(
    ofType<FilterArticles>(ArticlesActionTypes.FILTER_ARTICLES),
    map(action => {
      this.store.dispatch(
        new LoadArticles({
          filter: action.payload,
          pagination: { first: this.tcListDefaultNbLines, after: 0 }
        })
      );
    })
  );

  @Effect({ dispatch: false })
  loadMoreArticlesEffect$ = this.actions$.pipe(
    ofType<LoadMoreArticles>(ArticlesActionTypes.LOAD_MORE_ARTICLES),
    withLatestFrom(this.store.pipe(select(getArticlesState))),
    map(([action, articlesState]) => {
      if (!articlesState.canLoadMore) {
        return;
      }

      this.store.dispatch(
        new LoadArticles({
          filter: articlesState.filter,
          pagination: {
            first: this.tcListDefaultNbLines,
            after: Number(articlesState.pagination.after)
          },
          loadMore: true
        })
      );
    })
  );

  @Effect({ dispatch: false })
  previewArticleEffect$ = this.actions$.pipe(
    ofType<PreviewArticle>(ArticlesActionTypes.PREVIEW_ARTICLE),
    map((action: PreviewArticle) => {
      this.dialog.open(ArticlePreviewComponent, {
        width: '95vw',
        height: '95vh',
        maxWidth: '95vw',
        maxHeight: '95vh',
        data: action.payload
      });
    })
  );

  @Effect({ dispatch: false })
  articleHistoryEffect$ = this.actions$.pipe(
    ofType<ShowArticleHistory>(ArticlesActionTypes.SHOW_ARTICLE_HISTORY),
    map((action: ShowArticleHistory) => {
      this.dialog.open(ArticleHistoryComponent, {
        width: '920px',
        data: action.payload
      });
    })
  );

  @Effect()
  loadArticleOrderHistoryEffect$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadArticleOrderHistory>(ArticlesActionTypes.LOAD_ARTICLE_ORDER_HISTORY),
    mergeMap((action: LoadArticleOrderHistory) =>
      this.articleOrderHistoryService.getByArticle(action.payload.id).then(orderHistory => {
        return new LoadArticleOrderHistorySuccess(orderHistory);
      })
    )
  );

  @Effect()
  updateUsedCategoriesEffect$: Observable<TcAction> = this.actions$.pipe(
    ofType<UpdateUsedCategories>(ArticlesActionTypes.UPDATE_USED_CATEGORIES),
    mergeMap(async (action: UpdateUsedCategories) => {
      const families = new Set<string>();
      const basket = await this.ordersRequestsService.getBasket();

      for (const line of basket.lines || []) {
        if (!line.familyId) {
          const article = await this.articlesService.getById(line.articleId);
          line.familyId = article.familyId;
        }
        families.add(line.familyId);
      }

      return new UpdateUsedCategoriesSuccess(Array.from(families));
    })
  );
}
