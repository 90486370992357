import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { SyncProgress } from '../../modules/sync-agent/models/sync-progress.model';
import { getSyncProgress } from '../../modules/sync-agent/store/sync-agent.selectors';
import { VrpProviderService } from './vrp-provider.service';
import { ClientAllVrp } from '../../modules/clients/enums/client-status.enum';

@Injectable({
  providedIn: 'root'
})
export class SyncProgressService {
  private startTime = 0;
  private totalPartitions = 16;

  constructor(
    private readonly store$: Store<any>,
    private vrpProviderService: VrpProviderService,
  ) { }

  public async start() {
    this.startTime = moment().unix();
    const vrp = await this.vrpProviderService.getCurrentCodeVrp()
    this.totalPartitions = (vrp.toLowerCase() === ClientAllVrp) ? 13 : 16;
  }

  public async get(): Promise<SyncTimerStats> {
    const progress = await this.getProgress();
    const elapsed = moment().diff(moment.unix(this.startTime), 'seconds');

    const done = this.getDoneProgress(progress);
    const total = await this.getTotal(elapsed, done);

    return {
      elapsed: this.pretty(elapsed),
      total: this.pretty(total),
      remaining: this.pretty(total - elapsed),
      progress: done,
    }
  }

  private async getTotal(elapsed: number, done: number) {
    return elapsed * 100 / done;
  }

  private getProgress() {
    return this.store$.select(getSyncProgress).pipe(take(1)).toPromise();
  }

  private getDoneProgress(progress: SyncProgress[]) {
    if (!progress || !progress.length) {
      return 0;
    }

    let percentage = (progress.length - 1) / this.totalPartitions;

    const last = progress[progress.length - 1];
    percentage += last.total ? (last.current / last.total) * (1 / this.totalPartitions) : 0;

    return Math.floor(percentage * 100);
  }

  private pretty(seconds: number) {
    return moment().startOf('day')
      .seconds(seconds)
      .format('HH:mm:ss');
  }
}

export interface SyncTimerStats {
  elapsed: string;
  total: string;
  remaining: string;
  progress: number;
}