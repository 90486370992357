import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { environment } from '../../../environments/environment';

const jsonConfig = require('../../../assets/config.json');

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  get<T>(key: string): T {
    return get(environment, key, undefined) || get(jsonConfig, key, undefined);
  }
}