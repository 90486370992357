export type NotificationProperty = | 'id' | 'category' | 'date' | 'processed' | 'processedDate' | 'context' ;

export const NotificationProperty = {
  id: 'id' as NotificationProperty,
  category: 'category' as NotificationProperty,
  date: 'date' as NotificationProperty,
  processed: 'processed' as NotificationProperty,
  processedDate: 'processedDate' as NotificationProperty,
  context: 'context' as NotificationProperty,
}
