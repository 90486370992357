import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TcSmartComponent } from '@tc/core';
import { StatisticsFilterItem } from '../../interfaces/statistics.interface';

@Component({
  selector: 'app-statistics-filters',
  templateUrl: './statistics-filters.component.html',
  styleUrls: ['./statistics-filters.component.scss'],
})
export class StatisticsFiltersComponent extends TcSmartComponent {

  @Input() filters: StatisticsFilterItem[] = [];

  @Output() applyFilters = new EventEmitter<{ key: string; value: string; }>();

  public onFilter(key: string, event) {
    this.applyFilters.emit({ key, value: event.value });
  }

  public trackByKey(_, item) {
    return item?.key;
  }

  public trackByValue(_, item) {
    return item?.value;
  }
}
