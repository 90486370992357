import { Component, OnInit, Input } from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import { Store } from '@ngrx/store';
import { EditClient } from '../../../store/clients.actions';
import { ClientModel } from '../../../models/client.model';
import { PermissionsService } from '../../../../../services/permissions/permissions.service';

@Component({
  selector: 'app-client-contact',
  templateUrl: './client-contact.component.html',
  styleUrls: ['./client-contact.component.scss']
})
export class ClientContactComponent extends TcDumbComponent implements OnInit {

  @Input()
  client: ClientModel;

  public hasWriteAccess = true;

  constructor(
    private store: Store<any>,
    private readonly permissionsService: PermissionsService,
  ) {
    super();
  }

  ngOnInit() {
    this.permissionsService.hasAccess().then((hasAccess) => this.hasWriteAccess = hasAccess);
  }

  edit() {
    this.store.dispatch(new EditClient(this.client));
  }

}
