import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HideSpinner, ShowSpinner } from '@tc/core';
import itiriri, { IterableQuery } from 'itiriri';
import { Events } from '../events/events.interface';
import { EventsService } from '../events/events.service';
import { DocsProviderService } from './docs-provider.service';
import { Criteria } from './repository.interface';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  public collection: Promise<any[]>;
  private isLoading = false;

  constructor(
    private readonly eventsService: EventsService,
    private readonly docsProvider: DocsProviderService,
    private readonly store$: Store<any>,
  ) {
    this.initListeners();
  }

  public async getById<T>(id: string, type: string): Promise<T> {
    return (await this.getSource())
      .filter(doc => doc.type === type)
      .filter(doc => doc.id === id)
      .first();
  }

  public async get<T>(type: string): Promise<T[]> {
    return (await this.getSource())
      .filter(doc => doc.type === type)
      .toArray();
  }

  public async search<T>(criteria: Criteria): Promise<T[]> {
    return this.build(await this.getSource(), criteria);
  }

  public refresh() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.store$.dispatch(new ShowSpinner());

    this.collection = this.docsProvider
      .all()
      .then(docs => {
        this.isLoading = false;
        this.store$.dispatch(new HideSpinner());

        return docs;
      });
  }

  private build(source: IterableQuery<any>, criteria: Criteria) {
    source = source.filter(doc => doc.type === criteria.type);

    if (criteria.filter) {
      source = this.buildFilter(source, criteria.filter);
    }

    if (criteria.sort) {
      const predicate = this.getSort(criteria.sort);
      source = source.sort(predicate);
    }

    if (criteria.skip) {
      source = source.skip(criteria.skip);
    }

    if (criteria.limit) {
      source = source.take(criteria.limit);
    }

    return source.toArray();
  }

  private buildFilter(source: IterableQuery<any>, filter: any) {
    const operators = ['$and', '$or'];
    const operator = Object.keys(filter)[0];

    const filters = operators.indexOf(operator) === -1
      ? { $and: Object.keys(filter).map(key => ({ [key]: filter[key] })) }
      : filter;

    const predicate = this.predicate(filters);
    source = source.filter(predicate);

    return source;
  }

  private predicate(filters: any) {
    const key = Object.keys(filters)[0];

    switch (key) {
      case '$and':
        return (doc) => filters[key].every((match) => this.getFilter(match)(doc));

      case '$or':
        return (doc) => filters[key].some((match) => this.getFilter(match)(doc));
    }
  }

  private getFilter(match) {
    const key = Object.keys(match)[0];
    const value = match[key];

    const operators = {
      "$regex": doc => doc[key] && doc[key].match(value['$regex']),
      "$ne": doc => doc[key] !== value['$ne'],
      "$eq": doc => doc[key] === value['$eq'],
      "$gt": doc => doc[key] > value['$gt'],
      "$gte": doc => doc[key] >= value['$gte'],
      "$lt": doc => doc[key] < value['$lt'],
      "$lte": doc => doc[key] <= value['$lte'],
      "$in": doc => value['$in'].indexOf(doc[key]) !== -1,
    };

    if (typeof value === 'object') {
      return operators[Object.keys(value)[0]];
    }

    return doc => doc[key] === value;
  }

  private getSort(sort: string) {
    const [key, order] = sort.split(':');

    const strategies = {
      'ASC': (a, b) => a[key] > b[key] ? 1 : -1,
      'DESC': (a, b) => a[key] < b[key] ? 1 : -1,
    }

    return strategies[order] || strategies['ASC'];
  }

  private async getSource() {
    if (!this.collection) {
      this.refresh();
    }

    return itiriri(await this.collection);
  }

  private initListeners() {
    this.eventsService.subscribe(Events.RefreshRepository, () => this.refresh());
    this.eventsService.subscribe(Events.CreateItem, (item) => this.add(item));
    this.eventsService.subscribe(Events.RemoveItem, (item) => this.remove(item));
    this.eventsService.subscribe(Events.UpdateItem, (item) => this.update(item));
  }

  private async add(item) {
    this.collection = Promise.resolve([...(await this.collection), item]);
  }

  private async remove(item) {
    this.collection = Promise.resolve((await this.collection).filter(i => i._id !== item._id));
  }

  private async update(item) {
    await this.remove(item);
    await this.add(item);
  }
}

