import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcListFilterModel, TcSimpleFilterComponent, TcSmartComponent } from '@tc/core';
import { Subscription } from 'rxjs';
import { FilterArticles } from '../../../store/articles.actions';
import { getArticleListFilter } from '../../../store/articles.selectors';
import { CustomRenderer } from '../../../../../services/core/custom-renderer';

@Component({
  selector: 'app-article-filter-search',
  templateUrl: './article-filter-search.component.html',
  styleUrls: ['./article-filter-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleFilterSearchComponent extends TcSmartComponent implements OnInit, OnDestroy, AfterViewInit {  

  @ViewChild('tcSimpleFilter', { static: true }) set setTcSimpleFilterComponent(values: TcSimpleFilterComponent) {
    this.tcSimpleFilter = values;
    this.tcSimpleFilter.filterLabel = 'Recherche rapide ...'
    this.tryFocusAndSelectSearchText();
  }

  constructor(
    private readonly store: Store<any>,
    private renderer: CustomRenderer,
    private elementRef: ElementRef,
  ) {
    super();
  }

  filterSubscription: Subscription;

  articleListFilter: TcListFilterModel;

  tcSimpleFilter: TcSimpleFilterComponent;

  private timer: any;

  tryFocusAndSelectSearchText() {

    // focus disabled because functionality is not used
    return;

    const searchInput = this.renderer.findByType(this.elementRef.nativeElement, 'input');
    if(!searchInput) return;
    setTimeout(() => {
      searchInput.focus();
      searchInput.select();
    });
  }   

  ngOnInit(): void {
    this.filterSubscription = this.store.pipe(select(getArticleListFilter)).subscribe(filter => {
      if (filter) {        
        this.articleListFilter = filter;
        this.tcSimpleFilter.value = filter['anyFieldContains'];
        this.tryFocusAndSelectSearchText();
      }
    });
  }

  ngAfterViewInit(): void {
    this.tryFocusAndSelectSearchText();
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  onSearch = (filterValue: string) => {

    // apply filter only if empty or has at least 3 characters
    if(filterValue && filterValue.length > 0 && filterValue.length < 3) {
      return;
    }

    this.articleListFilter['anyFieldContains'] = filterValue;
    
    // // if there is already a timer running... then stop it
    // if (this.timer){
    //   clearTimeout(this.timer);
    // }
    
    // // trigger the search action after 400 millis
    // this.timer = setTimeout(this.doSearch, 400);

    this.doSearch();
  }

  doSearch = () => {
    this.store.dispatch(new FilterArticles(this.articleListFilter));
  }

}
