import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { SyncAgentService } from '../../../services/business-services/sync-agent.service';
import { SyncStatusService } from '../../../services/business-services/sync-status.service';
import { CheckAppTimeout } from '../../auth/store/auth.actions';
import { SyncAgentPopupComponent } from '../components/dumb/sync-agent-popup/sync-agent-popup.component';
import { SyncFinishedPopupComponent } from '../components/dumb/sync-finished-popup/sync-finished-popup.component';
import {
  DisplaySyncFinishPopup,
  DisplaySyncPopup,
  HideSyncPopup,
  InitialiseSync,
  StartSync,
  SyncAgentActionTypes,
  SyncProgressReset
} from './sync-agent.actions';

@Injectable()
export class SyncAgentEffects {

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private syncService: SyncAgentService,
    private syncStatusService: SyncStatusService,
    private store$: Store<any>,
  ) { }

  @Effect({ dispatch: false })
  initialise = this.actions$.pipe(
    ofType<InitialiseSync>(SyncAgentActionTypes.INITIALISE_SYNC),
    map(async () => {

      this.store$.dispatch(new SyncProgressReset());

      const { show, firstTime } = await this.syncStatusService.shouldShowSync();

      if (show) {
        this.store$.dispatch(new DisplaySyncPopup({ disableClose: firstTime }));
      }
    })
  );

  @Effect({ dispatch: false })
  displaySyncPopup = this.actions$.pipe(
    ofType<DisplaySyncPopup>(SyncAgentActionTypes.DISPLAY_SYNC_POPUP),
    map((action: DisplaySyncPopup) => {
      this.store$.dispatch(new SyncProgressReset());

      const disableClose = action.payload && action.payload.disableClose;

      this.dialog.open(SyncAgentPopupComponent, {
        width: '500px',
        data: disableClose,
        disableClose: true
      });
    })
  );

  @Effect({ dispatch: false })
  displaySyncFinishPopup = this.actions$.pipe(
    ofType<DisplaySyncFinishPopup>(SyncAgentActionTypes.DISPLAY_SYNC_FINISH_POPUP),
    map(() => {
      this.dialog.open(SyncFinishedPopupComponent, {
        width: '500px',
      });
    })
  );

  @Effect({ dispatch: false })
  startSync = this.actions$.pipe(
    ofType<StartSync>(SyncAgentActionTypes.START_SYNC),
    map((action: StartSync) => {
      this.syncService.start();
    })
  );

  @Effect({ dispatch: false })
  hideSyncPopup = this.actions$.pipe(
    ofType<HideSyncPopup>(SyncAgentActionTypes.HIDE_SYNC_POPUP),
    map((action: HideSyncPopup) => {
      this.dialog.closeAll();
    })
  );
}
