<div class="order-detail-container">
  <div class="header">
    <div>
      <button class="order-amount darkblue"
        mat-raised-button>{{'order-detail-list.buttons.orderTicket' | translate}}</button>
    </div>
    <div class="header-right">
      <div class="date">
        <span class="white-color">{{'order-detail-list.delivery' | translate}}</span>&nbsp;&nbsp;
        <mat-form-field>
          <input [ngModel]="selectedDate" [min]="minDate" matInput [matDatepickerFilter]="filterDates"
            [matDatepicker]="picker" (ngModelChange)="updateDate($event)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-slide-toggle (change)="showOrHideImage()" [checked]="displayImages">
        <span class="white-color">{{'order-detail-list.displayImage' | translate}}</span>
      </mat-slide-toggle>
    </div>
  </div>
  <div class="table">
    <table mat-table [dataSource]="result" id="order-detail-list">

      <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
        <th mat-header-cell *matHeaderCellDef>{{'order-detail-list.headers.' + col | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element && element.isTotal">
            <ng-container *ngIf="col==='pu'">
              <div class="text-bold company-total">Total {{element.companyName}} HT</div>
            </ng-container>
            <ng-container *ngIf="col==='totalPrice'">
              <div class="text-bold">{{element.companyTotal | tcCurrencyFormat: '€' }}</div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="element && !element.isTotal">
            <ng-container *ngIf="col==='image'" class="image">
              <img src='{{imagesRootSrc}}{{element.data.company}}/{{element.data.image}}' width="50" />
            </ng-container>
            <ng-container *ngIf="col==='action'">
              <mat-icon class="button" (click)="deleteFromBasket(element.data.id)">delete</mat-icon>
            </ng-container>
            <ng-container *ngIf="col==='pu'">
              <div class="text-bold">{{element.data[col] | tcCurrencyFormat: '€'}}</div>
            </ng-container>
            <ng-container *ngIf="col==='totalPrice'">
              <div class="text-bold">{{element.data[col] | tcCurrencyFormat: '€' }}</div>
            </ng-container>
            <ng-container *ngIf="col==='quantity'">
              <div class="icons">
                <button class="quantity__button">
                  <mat-icon class="button"
                    (click)="decreaseQuantity(element.data.id, element.data.itemsInBax, element.data.leftForSaleStock)">
                    remove_circle
                  </mat-icon>
                </button>

                {{element.data[col] | number:'1.0':'fr'}}

                <button class="quantity__button">
                  <mat-icon class="button"
                    (click)="increaseQuantity(element.data.id, element.data.itemsInBax, element.data.leftForSaleStock)">
                    add_circle
                  </mat-icon>
                </button>

              </div>
            </ng-container>
            <ng-container class="icons"
              *ngIf="col !== 'quantity' && col !== 'action' && col !== 'image' && col !== 'pu' && col !== 'totalPrice'">
              {{element.data[col]}}
            </ng-container>
          </ng-container>
        </td>

        <td class="footer-td" mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="col==='pu'">
            <div class="franco">{{shippingTax}}</div>
            <div class="total-txt">{{'order-detail-list.total' | translate}}</div>
          </ng-container>
          <ng-container *ngIf="col==='totalPrice'">
            <div class="franco">{{'order-detail-list.francoOffert' | translate}}</div>
            <div>{{getTotalCost() | tcCurrencyFormat: '€' }}</div>
          </ng-container>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
  </div>
  <div class="footer" *ngIf="(selectedClientId$ | async) !== 'PROSPECT'">

    <div class="footer-left">

      <!-- <form class="example-form"> -->
      <!-- <mat-form-field class="example-full-width"> -->
      <span *ngIf="multipleCompanies"><strong>{{'order-detail-list.linkOrder' | translate}}</strong></span>
      <!-- <input matInput>
              </mat-form-field> -->

      <mat-form-field class="comment--container">
        <mat-label>{{'order-detail-list.comm' | translate}}</mat-label>
        <textarea rows="3" matInput [(ngModel)]="comments" (ngModelChange)="updateOrder()"></textarea>
      </mat-form-field>
      <!-- </form> -->
      <div class="footer-buttons">
        <button class="darkblue button" mat-raised-button
          (click)="blockOrder()">{{'order-detail-list.buttons.block' | translate}}</button>
        <button class="darkblue button" mat-raised-button
          (click)="cancelOrder()">{{'order-detail-list.buttons.cancel' | translate}}</button>
      </div>

    </div>
    <div class="footer-right">
      <div class="checkbox">
        <mat-slide-toggle (change)="changeSummaryRequested()" [checked]="summaryRequested">
          <span class="white-color">{{'order-detail-list.buttons.sendSummary' | translate}}</span>
        </mat-slide-toggle>
      </div>
      <div class="footer-buttons">
        <button class="blue button" mat-raised-button
          (click)="validateOrder()">{{'order-detail-list.buttons.validate' | translate}}</button>
        <button class="blue button" mat-raised-button
          (click)="sendDevis()">{{'order-detail-list.buttons.sendDevis' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="footer prospect" *ngIf="(selectedClientId$ | async) === 'PROSPECT'">
    <div class="footer-right">
      <div class="footer-buttons">
        <button class="blue button" mat-raised-button
          (click)="sendDevis()">{{'order-detail-list.buttons.sendDevis' | translate}}</button>
      </div>
    </div>
  </div>

</div>
