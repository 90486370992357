<input
    type="file"
    #fileInput
    style="display: none"
    (change)="onScannedFileSelected()"
    accept="image/*"
    capture="camera"
>

<div
    class="photo-select"
    (click)="onScanFile()"
>
    <input
        matInput
        [hidden]="true"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [(ngModel)]="base64"
    >
    <span class="label">{{label}}</span>
    <img
        *ngIf="base64"
        class="image"
        [src]="base64"
        width="50px"
        (click)="zoom($event)"
    >
    <span>
        <mat-icon>camera_enhance</mat-icon>
    </span>
</div>
