export type PriceProperty = | 'id' | 'index' | 'articleId' | 'priceClientCode' | 'clientDescription1' | 'clientDescription2' | 'unitPrice' | 'rrp' | 'startDate' | 'endDate' | 'currency' | 'rrpInCurrency' | 'companyId' | 'gencod' ;

export const PriceProperty = {
  id: 'id' as PriceProperty,
  index: 'index' as PriceProperty,
  articleId: 'articleId' as PriceProperty,
  priceClientCode: 'priceClientCode' as PriceProperty,
  clientDescription1: 'clientDescription1' as PriceProperty,
  clientDescription2: 'clientDescription2' as PriceProperty,
  unitPrice: 'unitPrice' as PriceProperty,
  rrp: 'rrp' as PriceProperty,
  startDate: 'startDate' as PriceProperty,
  endDate: 'endDate' as PriceProperty,
  currency: 'currency' as PriceProperty,
  rrpInCurrency: 'rrpInCurrency' as PriceProperty,
  companyId: 'companyId' as PriceProperty,
  gencod: 'gencod' as PriceProperty,
}
