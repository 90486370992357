import { ClientsState } from './clients.state.interface';

export const initialClientsState: ClientsState = {
  filter: null,
  sort: null,
  clients: [],
  pagination: {
    limit: 50,
    skip: 0,
    hasMoreItems: true,
  },
  selectedClient: {
    id: null,
    name: '',
    status: ''
  },
  clientInvoiceList: [],
  clientActionList: [
    { id: 1, ref: 'FA20190501', date: '21/05/2019', action: 'Règlement' },
    { id: 1, ref: 'FA20190501', date: '21/05/2019', action: 'Relance 1' },
    { id: 1, ref: 'FA20190501', date: '21/05/2019', action: 'Relance2' },
  ]
};