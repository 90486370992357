import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserType } from './modules/auth/enums/user-type.enum';
import { F02HomePageComponent } from './pages/f02-home-page/f02-home-page.component';
import { F03ClientListPageComponent } from './pages/f03-client-list-page/f03-client-list-page.component';
import { F04ClientDetailPageComponent } from './pages/f04-client-detail-page/f04-client-detail-page.component';
import { F07ArticleListPageComponent } from './pages/f07-article-list-page/f07-article-list-page.component';
import { F09OrderDetailPageComponent } from './pages/f09-order-detail-page/f09-order-detail-page.component';
import { F15OrderListPageComponent } from './pages/f15-order-list-page/f15-order-list-page.component';
import { FF26UserListPageComponent } from './pages/f26-user-list-page/f26-user-list-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';
import { AuthGuard } from './services/guards/auth.guard';
import { SyncGuard } from './services/guards/sync.guard';

const routes: Routes = [
  {
    path: '',
    component: F02HomePageComponent,
    canActivate: [SyncGuard, AuthGuard],
    data: {
      role: UserType.Salesman
    }
  },
  {
    path: 'clients',
    component: F03ClientListPageComponent,
    canActivate: [SyncGuard, AuthGuard],
    data: {
      role: UserType.Salesman
    }
  },
  {
    path: 'orders',
    component: F15OrderListPageComponent,
    canActivate: [SyncGuard, AuthGuard],
    data: {
      role: UserType.Salesman
    }
  },
  {
    path: 'reports',
    component: StatisticsPageComponent,
    canActivate: [SyncGuard, AuthGuard],
    data: {
      role: UserType.Salesman
    }
  },
  {
    path: 'order-detail',
    component: F09OrderDetailPageComponent,
    canActivate: [SyncGuard, AuthGuard],
    data: {
      role: UserType.Salesman
    }
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [SyncGuard],
  },
  {
    path: 'articles',
    component: F07ArticleListPageComponent,
    canActivate: [SyncGuard, AuthGuard]
  },
  {
    path: 'client-details',
    component: F04ClientDetailPageComponent,
    canActivate: [SyncGuard, AuthGuard],
    data: {
      role: UserType.Salesman
    }
  },
  {
    path: 'users',
    component: FF26UserListPageComponent,
    canActivate: [SyncGuard, AuthGuard],
    data: {
      role: UserType.Administrator
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
