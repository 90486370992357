import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TcAppComponent, TcAuthenticationService, TcConfigService } from '@tc/core';
import Bottleneck from 'bottleneck';
import { combineLatest, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { CHECK_APP_TIMEOUT_INTERVAL } from './app.module';
import { UserType } from './modules/auth/enums/user-type.enum';
import { CheckAppTimeout } from './modules/auth/store/auth.actions';
import { getAuthenticatedUser } from './modules/auth/store/auth.selectors';
import { InitialiseSync } from './modules/sync-agent/store/sync-agent.actions';
import { VrpProviderService } from './services/business-services/vrp-provider.service';
import { Events } from './services/events/events.interface';
import { EventsService } from './services/events/events.service';
import { ImageService } from './services/images.service';
import { VersionService } from './services/version.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TcAppComponent implements OnInit, OnDestroy {

  constructor(
    store: Store<any>,
    configService: TcConfigService,
    authService: TcAuthenticationService,
    router: Router,
    private versionService: VersionService,
    private imageService: ImageService,
    private vrpProviderService: VrpProviderService,
    private eventsService: EventsService,
    @Inject(CHECK_APP_TIMEOUT_INTERVAL) private appTimeoutInterval: number
  ) {
    super(store, configService, authService, router);

    // this.swUpdate.setup();
  }
  title = 'MaxelOrder';
  prop = 'test';

  used: number;
  granted: number;
  persisted: boolean;
  permissionStatus: string;
  storageEstimate: StorageEstimate;
  version: string;

  countImages = 0;
  countErrors = 0;
  totalImages = 0;
  percentImages = 0;

  folders: Array<string> = [];
  folderSizes: { [folder: string]: number } = {};

  timerSubscription: Subscription;

  async ngOnInit() {
    this.version = this.versionService.getVersion();
    await this.refresh();

    // create a timer to check app timeout
    // in auth effects will check if the user can to continue navigation
    this.timerSubscription = combineLatest([timer(1000, this.appTimeoutInterval * 60000), this.store.pipe(select(getAuthenticatedUser))]).subscribe(
      ([time, authenticatedUser]) => {
        console.log('timer: ', time); // TODO: log this
        if (authenticatedUser && time !== 0) {
          this.store.dispatch(new CheckAppTimeout());
        }
      });

    this.checkForSync();
    setTimeout(() => {
      this.eventsService.publish(Events.RefreshRepository);
    });
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  async checkQuota() {
    const promise = async () => {
      return new Promise((resolve, reject) => {
        const nav: any = navigator;
        nav.webkitTemporaryStorage.queryUsageAndQuota(
          (usedBytes, grantedBytes) => {
            // console.log('we are using ', usedBytes, ' of ', grantedBytes, 'bytes');
            resolve({
              usedBytes: usedBytes / 1024 / 1024,
              granted: grantedBytes / 1024 / 1024,
            });
          },
          (e) => { console.log('Error', e); reject(e); }
        );
      });
    };

    const result: any = await promise();

    this.used = Math.round(result.usedBytes * 100) / 100;
    this.granted = Math.round(result.granted * 100) / 100;
  }

  async refresh() {

    await this.checkQuota();

    this.persisted = await navigator.storage.persisted();
    const permissionStatus = await navigator.permissions.query({ name: 'persistent-storage' });
    this.permissionStatus = permissionStatus.state;

    // this.storageEstimate = await navigator.storage.estimate();
    // console.log(this.storageEstimate);

    setTimeout(() => this.refresh(), 3000);
  }

  async run1() {
    await navigator.storage.persist();
  }

  async run2() {
    await Notification.requestPermission();
  }

  slider() {
    this.router.navigate(['slider']);
  }
  
  private async checkForSync() {
    const userVrp = await this.vrpProviderService.getCurrentCodeVrp();
    const currentUser = await this.store
      .select(getAuthenticatedUser)
      .pipe(take(1))
      .toPromise();

    if (!userVrp) {
      return;
    }

    if (currentUser.profil !== UserType.Administrator) {
      this.store.dispatch(new InitialiseSync());
    }
  }
}
