import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,

    RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { TcAuthState } from '@tc/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLastSyncDBVersion } from '../../modules/sync-agent/store/sync-agent.selectors';
import { DatabaseResetService } from '../database/database-reset.service';
import { VersionService } from '../version.service';

@Injectable({
  providedIn: 'root'
})
export class SyncGuard implements CanActivate {

  constructor(
    private readonly store: Store<TcAuthState>,
    private readonly versionService: VersionService,
    private readonly databaseResetService: DatabaseResetService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    return this.store.select(getLastSyncDBVersion)
      .pipe(
        map(lastSyncDbVersion => {
          const path = route.routeConfig.path;
          const currentDBVersion = this.versionService.getDBVersion();

          const shouldResetDB = lastSyncDbVersion && currentDBVersion !== lastSyncDbVersion;

          if (!shouldResetDB) {
            return true;
          }

          this.databaseResetService.reset().then(() => {
            window.location.reload();
          });

          return path === 'login';
        })
      )

  }
}
