export type EntityType = 'user' | 'salesman' | 'article' | 'client' | 'facture' | 'commande' | 'order';

export const EntityType = {
    User: 'user' as EntityType,
    Salesman: 'salesman' as EntityType,
    Article: 'article' as EntityType,
    Client: 'client' as EntityType,
    Facture: 'facture' as EntityType,
    Commande: 'commande' as EntityType,
    Order: 'order' as EntityType,
};