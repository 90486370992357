import { Injectable } from '@angular/core';
import { SyncStatus } from '@maxel-order/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { getLastActivity } from '../../modules/auth/store/auth.selectors';
import { ConfigKeys } from '../config/config.interface';
import { ConfigService } from '../config/config.service';
import { SyncStatusDAO } from '../dao/sync-status.dao';
import { DeviceService } from '../device/device.service';
import { Criteria } from '../repository/repository.interface';
import { AuthenticationService } from './authentication.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class SyncStatusService {

  constructor(
    private readonly config: ConfigService,
    private readonly store$: Store<TcAppState>,
    private readonly usersService: UsersService,
    private readonly deviceService: DeviceService,
    private readonly syncStatusDAO: SyncStatusDAO,
    private readonly authService: AuthenticationService,
  ) { }

  async shouldShowSync(): Promise<{ show: boolean, firstTime: boolean }> {
    const lastActivity = await this.store$.select(getLastActivity).pipe(take(1)).toPromise();
    const shouldLogout = this.authService.shouldLogout(lastActivity);
    if (shouldLogout) {
      return { show: false, firstTime: false };
    }

    const status = await this.getLast();
    if (!status) {
      return { show: true, firstTime: true };
    }

    const last = moment.utc(status.lastSyncDate);
    const now = moment.utc();

    return {
      show: now.diff(last, 'hours') > this.config.get(ConfigKeys.SYNC_REQUIRED_INTERVAL),
      firstTime: false,
    };
  }

  public async track(time: number) {
    const deviceId = this.deviceService.get();
    const status = (await this.getLast()) || await this.createDefault(deviceId);

    const updated = {
      ...status,
      lastSyncSeconds: Math.floor(time / 1000),
      lastSyncDate: moment.utc().toISOString(),
      syncCount: status.syncCount + 1,
    };

    await this.syncStatusDAO.update(updated);
  }

  public async getLast() {
    const userId = await this.usersService.getCurrentUserId();
    const filterStatuses: Criteria = {
      filter: { 'userId': { $eq: userId } }
    };
    const statuses = await this.syncStatusDAO.search(filterStatuses);

    return statuses?.length ? statuses[statuses.length - 1] : null;
  }

  private async createDefault(deviceId: string): Promise<SyncStatus> {
    const userId = await this.usersService.getCurrentUserId();
    const status = {
      userId,
      deviceId,
      id: uuid(),
      syncCount: 0,
    };

    const created = await this.syncStatusDAO.create(status);
    return created;
  }

}
