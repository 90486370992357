import { Component, OnInit, Input } from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import { ClientStatus } from '../../../enums/client-status.enum';

@Component({
  selector: 'app-client-status',
  templateUrl: './client-status.component.html',
  styleUrls: ['./client-status.component.scss']
})
export class ClientStatusComponent extends TcDumbComponent implements OnInit {
  @Input()
  status: string;

  constructor() { 
    super();
  }
  
  getClientStatus(status: string){
    switch(status) { 
      case ClientStatus.Interdit: { 
        return 'icon_interdit.png'; 
        break; 
      } 
      case ClientStatus.Perdu: { 
        return 'icon_perdu.png'; 
         break; 
      } 
      case ClientStatus.Actif: { 
        return 'icon_valide.png'; 
         break; 
      } 
      default: { 
        return 'icon_interdit.png'; 
         break; 
      } 
    }
  }

  ngOnInit(): void {
  }

}
