<div class="dashboard-container">
    <div class="body">
        <br>
        <div class="title">
            <strong>
                {{'client-dashboard.title' | translate}}
            </strong>
        </div>
        <br>
        <div class="columns">
            <div>
                <div class="label">
                    <span class="gray">
                        {{'client-dashboard.last-order' | translate}}
                    </span>
                    <span class="white-color">:</span>
                    <span *ngIf="client.lastOrderDate">
                        <span *ngIf="monthsFromLastOrder && monthsFromLastOrder !== 0">
                            {{'client-dashboard.last-order-value' | translate: {value: monthsFromLastOrder, date: (client.lastOrderDate | date: 'dd/MM/yyyy') } }}
                        </span>
                        <span *ngIf="monthsFromLastOrder == 0">
                            {{client.lastOrderDate | date: 'dd/MM/yyyy'}}
                        </span>
                    </span>
                    <img class="image" (click)="showDetails()" src="assets/icons/bton_search.png" width="40">
                </div>               
                <div
                    class="label"
                    *ngIf="client.orderAmountAverage12Months"
                >
                    <span class="gray">
                        {{'client-dashboard.average-amount' | translate}}
                    </span>
                    <span class="white-color">:</span>
                    <span *ngIf="client.orderAmountAverage12Months">
                        {{client.orderAmountAverage12Months.toFixed(2)  | tcCurrencyFormat }} €
                    </span>
                </div>                
            </div>
            <div class="right">
                <div
                    class="label"
                    *ngIf="client.totalAmountOrdered12Months"
                >
                    <span class="gray">
                        {{'client-dashboard.total-orders' | translate}}
                    </span>
                    <span class="white-color">:</span>
                    <span *ngIf="client.totalAmountOrdered12Months">
                        {{client.totalAmountOrdered12Months.toFixed(2) | tcCurrencyFormat}} €
                    </span>
                </div>
            </div>
        </div>
        <div class="columns turnover">
            <div *ngFor="let turnover of turnovers" class="company-turnover">
                <div class="label">
                    <span>
                        {{ 'companies.' + turnover.companyId | translate}}
                    </span>
                </div>
                <div class="label">
                    <span class="gray">
                        {{'client-dashboard.turnover-last-year-label' | translate: { value: turnover.lastYear } }}
                    </span>
                    <span class="white-color">:</span>
                    <span>
                        {{'client-dashboard.turnover-value' | translate: { value: turnover.lastYearTurnover.toFixed(2) | tcCurrencyFormat } }}
                    </span>
                </div>
                <div class="label">
                    <span class="gray">
                        {{'client-dashboard.turnover-until-day-label' | translate: { value: (turnover.lastYearSameDayAsPresent | date: 'dd/MM/yyyy') } }}
                    </span>
                    <span class="white-color">:</span>
                    <span>
                        {{'client-dashboard.turnover-value' | translate: { value: turnover.lastYearSameDayTurnover.toFixed(2) | tcCurrencyFormat } }}
                    </span>
                </div>
                <div class="label">
                    <span class="gray">
                        {{'client-dashboard.turnover-until-day-label' | translate: { value: (turnover.presentDay | date: 'dd/MM/yyyy') } }}
                    </span>
                    <span class="white-color">:</span>
                    <span>
                        {{'client-dashboard.turnover-value' | translate: { value: turnover.thisYearTurnover.toFixed(2) | tcCurrencyFormat } }}
                    </span>
                    <span *ngIf="turnover.validGrowth">
                        {{'client-dashboard.turnover-growth-value' | translate: { value: turnover.growth.toFixed(2) | tcCurrencyFormat } }}
                    </span>
                </div>
            </div>            
        </div>
        <br>

    </div>   
</div>
