import { OrderArticle } from '../models/order-article.model';
import { OrderListModel } from '../models/order-list.model';
import { OrderModel } from './../models/order.model';
import { OrdersViewType } from '../components/smart/order-list/types';
import { OrderSummaryModel } from '../models/order-summary.model';

export interface OrdersState {
  orderList: OrderListModel[],
  basket: OrderModel,
  orderSummary: OrderSummaryModel;
  viewType: OrdersViewType;
}

export const initialOrdersState: OrdersState = {
  basket: { id: null, lines: [] },
  orderList: [],
  viewType: OrdersViewType.All,
  orderSummary: null
    // { ref: '52PCOVERS', name: 'BROSSE TUNNEL MULTI-USAGE PICOTS PERLES - 2 MODELES ASSORTIS', quantity: 45, priceUnitar: 22.5, amount: 1012.5 },
    // { ref: '52PCOVERS', name: 'POUDRE SOLIEL2', quantity: 20, priceUnitar: 42.5, amount: 850 },
    // { ref: '52PCOVERS', name: 'POUDRE SOLIEL3', quantity: 16, priceUnitar: 82.5, amount: 1320 },
    // { ref: '52PCOVERS', name: 'POUDRE SOLIEL4', quantity: 11, priceUnitar: 62.5, amount: 687.5 }
};
