import 'pouchdb';
import { Entity } from '../../core';
import { ArticleEntityType, CompanyEntityType, FamilyEntityType, PriceEntityType, UserEntityType } from '../../entities/generated';
import { IdProvider } from './id-provider.interface';

export class PouchdbCrudDAO<T extends Entity> {
  public entityName: string;
  public entityLowerName: string;

  private currentDB: PouchDB.Database<T>;

  constructor(
    protected readonly databaseService: any,
    protected readonly idProvider?: IdProvider,
  ) { }

  public get database() {
    if (!this.currentDB) {
      this.currentDB = this.databaseService.get(this.entityLowerName) as any;
    }
    return this.currentDB;
  }

  public async create(obj: T): Promise<T> {
    const _id = obj['_id'] || await this.idProvider.get(this.entityName, obj.id);

    const toCreate = {
      ...obj,
      ...this.getDefaultFilter(),
      _id,
    };

    const { rev } = await this.database.put(toCreate);

    return {
      ...toCreate,
      _rev: rev,
    };
  };

  public async getAll(): Promise<any[]> {
    console.log('crud-dao getAll:', this.entityName);

    const newRequest: PouchDB.Find.FindRequest<{}> = {
      selector: {
        "$and": [
          this.getDefaultFilter(),
          ...this.getIdFilter(),
        ]
      }
    };

    const { docs } = await this.database.find(newRequest);
    return docs;
  };

  public async get(id: string): Promise<any> {
    try {
      const { docs } = await this.database.find({
        selector: {
          "$and": [
            this.getDefaultFilter(),
            ...this.getIdFilter(),
            { id },
          ]
        }
      });
      return docs?.[0];
    } catch (e) {
      return null;
    }
  };

  public async update(obj: T): Promise<T> {
    const { rev } = await this.database.put(obj);

    return {
      ...obj,
      _rev: rev,
    };
  };

  public async delete(obj: T): Promise<any> {
    await this.database.put({
      ...obj,
      _deleted: true,
    });
  };

  public async find(request?: PouchDB.Find.FindRequest<{}>): Promise<any[]> {

    console.log('crud-dao find: ' + this.entityName, request);

    const newRequest: PouchDB.Find.FindRequest<{}> = {
      selector: {
        "$and": [
          this.getDefaultFilter(),
          ...this.getIdFilter()
        ]
      }
    };

    if (request.selector.$and) {
      newRequest.selector.$and.push(...request.selector.$and);
    } else {
      newRequest.selector.$and.push(request.selector);
    }

    console.log('crud-dao find new request: ' + this.entityName, newRequest);

    if (request.sort) {
      newRequest.sort = request.sort;
    }

    if (request.limit) {
      newRequest.limit = request.limit;
    }

    if (request.skip) {
      newRequest.skip = request.skip;
    }

    const { docs } = await this.database.find(newRequest);

    return docs;
  };

  protected getDefaultFilter() {
    return {
      type: this.entityName,
    };
  }

  protected getIdFilter() {
    // todo
    const prefix = this.entityName === ArticleEntityType.className
      || this.entityName === FamilyEntityType.className
      || this.entityName === UserEntityType.className
      || this.entityName === CompanyEntityType.className
      || this.entityName === PriceEntityType.className
      ? `${this.entityName}:` : `JCD_${this.entityName}:`;

    return [
      {
        "_id": {
          "$gte": prefix
        }
      },
      {
        "_id": {
          "$lte": prefix + `￿`
        }
      }
    ]
  }
}
