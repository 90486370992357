import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getPageInfo, TcMenuComponent, TcMenuItem, TcSmartComponent } from '@tc/core';
import { VersionService } from '../../../../../services/version.service';
import { fromEvent, Subscription, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Logout } from '../../../../auth/store/auth.actions';
import { AppMenu } from '../../../../main/enums/app-menu.enum';
import { IAppPageState } from '../../../../main/interfaces/app-page-state.interface';
import { AppNavigation } from '../../../../main/store/main.actions';
import { DisplaySyncPopup, SyncRequired } from '../../../../sync-agent/store/sync-agent.actions';
import { getSyncProgress } from '../../../../sync-agent/store/sync-agent.selectors';
import { SYNC_APP_INTERVAL, SYNC_REQUIRED_INTERVAL } from './../../../../../app.module';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageMenuComponent extends TcSmartComponent implements OnInit, AfterViewInit, OnDestroy {

  pageInfo: IAppPageState;
  pageInfoSubscription: Subscription;

  syncProgressSubscription: Subscription;
  syncProgress: any;

  isSyncRequired = false;

  pageMenu: TcMenuComponent;
  @ViewChild('pageMenu', { static: true }) set appPageMenu(value: TcMenuComponent) {
    this.pageMenu = value;
  }

  private subscription: Subscription;

  constructor(
    private store: Store<any>,
    private readonly versionService: VersionService,
    @Inject(SYNC_APP_INTERVAL) private syncAppInterval: number,
    @Inject(SYNC_REQUIRED_INTERVAL) private syncRequiredInterval: number
  ) {
    super();
  }

  ngOnInit() {
    this.pageInfoSubscription = this.store.pipe(select(getPageInfo))
      .subscribe((pageInfo: IAppPageState) => {
        if (pageInfo && pageInfo.menuItems) {
          this.pageMenu.items = pageInfo.menuItems;

          this.pageMenu.items.push({
            name: 'version',
            disabled: true,
          });
        }
      });

    this.syncProgressSubscription = this.store.pipe(select(getSyncProgress))
      .subscribe((syncProgress) => {
        if (syncProgress) {
          this.syncProgress = syncProgress;
        }
      });

    timer(1000, this.syncAppInterval * 60000).subscribe(t => {
      this.isSyncRequired = this.syncRequired(this.syncProgress);
    })

    this.pageMenu.onMenuItemClick = (item: TcMenuItem) => {
      switch (item.name) {
        case AppMenu.Logout:
          this.store.dispatch(new Logout());
          break;
        case AppMenu.DisplaySyncPopup:
          this.store.dispatch(new DisplaySyncPopup());
          break;
        default:
          this.store.dispatch(new AppNavigation(item.name));
      }
    }
  }

  async ngAfterViewInit() {
    await timer(200).toPromise();

    const el = document.querySelector('app-page-menu .mat-menu-trigger');

    const version = this.versionService.getVersion();
    const buildDate = this.versionService.getBuildDate();

    if (el) {
      this.subscription = fromEvent(el, 'click')
        .pipe(
          map(() => document.querySelector('.tc-menu .mat-menu-content div > span:last-of-type')),
          filter(element => element.querySelector('button').disabled),
        )
        .subscribe(e =>
          e.querySelector('span button span').innerHTML = `v${version} - ${buildDate}`
        );
    }

  }

  private syncRequired(syncProgress: any): boolean {
    if (syncProgress) {
      const time = Math.abs(syncProgress.newTime - syncProgress.start) / 1000;
      if (time >= this.syncRequiredInterval) {
        this.store.dispatch(new SyncRequired());
        return true;
      }
    }

    return false;
  }

  ngOnDestroy() {
    if (this.pageInfoSubscription) {
      this.pageInfoSubscription.unsubscribe();
    }

    if (this.syncProgressSubscription) {
      this.syncProgressSubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
