import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppPageState } from '../../modules/main/interfaces/app-page-state.interface';
import { AppMenu } from '../../modules/main/enums/app-menu.enum';
import { TcPage } from '@tc/core';
import { getSyncRequired } from '../../modules/sync-agent/store/sync-agent.selectors';
import { Observable, Subscription } from 'rxjs';
import { SyncRequired, DisplaySyncPopup } from '../../modules/sync-agent/store/sync-agent.actions';
import { ResetClients } from '../../modules/clients/store/clients.actions';

@Component({
  selector: 'app-f02-home-page',
  templateUrl: './f02-home-page.component.html',
  styleUrls: ['./f02-home-page.component.scss']
})
export class F02HomePageComponent extends TcPage implements OnInit {

  syncRequiredSubscription: Subscription;
  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {

    this.store.dispatch(new ResetClients());

    this.syncRequiredSubscription = this.store.pipe(select(getSyncRequired)).subscribe(syncRequired => {
      if (syncRequired === true) {
        this.store.dispatch(new DisplaySyncPopup())
      }
    });

    const pageInfo: IAppPageState = {
      title: 'home-page.title',
      menuItems: [
        {
          name: AppMenu.DisplaySyncPopup
        },
        {
          name: AppMenu.Logout
        }
      ]
    };
    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
