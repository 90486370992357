import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectOrderIds } from '../../../../orders/store/orders.actions';
import { ClientModel } from '../../../models/client.model';
import { OrdersService } from '../../../../../services/business-services/orders.service';
import { TcNotificationService } from '@tc/core';
import { ClientTurnoverModel } from '../../../../orders/models/client-turnover.model';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {

  @Input()
  client: ClientModel;

  public monthsFromLastOrder: number;
  public lastOrderIds: string[];
  public turnovers: ClientTurnoverModel[]

  constructor(
    private readonly store$: Store<any>,
    private readonly ordersService: OrdersService,
    private notificationService: TcNotificationService,
  ) { }

  ngOnInit(): void {
    this.monthsFromLastOrder = null;
    if (!this.client) {
      return;
    }

    const lastOrderDate = this.client.lastOrderDate;
    if (lastOrderDate) {
      this.monthsFromLastOrder = this.monthsDiff(lastOrderDate, new Date());
    }
    this.loadLastOrder(this.client.id);
    this.loadTurnOver(this.client.id);
  }

  yearsDiff(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const yearsDiff = date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
  }

  monthsDiff(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const years = this.yearsDiff(d1, d2);
    const months = (years * 12) + (date2.getMonth() - date1.getMonth());
    return months;
  }

  public showDetails() {
    if (!this.lastOrderIds) {
      this.notificationService.error('Pas de commande récente');
      return;
    }

    this.store$.dispatch(new SelectOrderIds(this.lastOrderIds));
  }

  private async loadLastOrder(clientId: string) {
    var date = this.client.lastOrderDate?.toISOString();
    this.lastOrderIds = await this.ordersService.getClientOrderIdsByDate(clientId, date);
  }

  private async loadTurnOver(clientId: string) {
    this.turnovers = await this.ordersService.getClientTurnOver(clientId);
  }
}
