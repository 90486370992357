import { HIDE_SPINNER, SHOW_SPINNER, TcAction, TcListFilterModel } from '@tc/core';
import { ArticleOrderHistoryModel } from '../models/article-order-history.model';
import { ArticleModel } from '../models/article.model';
import { InitArticlesPayload, InitArticleSuccessPayload, InitCategoriesSuccessPayload, LoadArticlePayload, LoadArticleSuccessPayload } from './articles.interfaces';

export enum ArticlesActionTypes {

  INIT_ARTICLES_PAGE = '[Articles] Init Articles Page',

  INIT_ARTICLES = '[Articles] Init Articles',
  INIT_ARTICLES_SUCCESS = '[Articles] Init Articles Success',
  INIT_CATEGORIES_SUCCESS = '[Articles] Init Categories Success',

  LOAD_ARTICLES = '[Articles] Load Articles',
  LOAD_ARTICLES_SUCCESS = '[Articles] Load Articles Success',
  FILTER_ARTICLES = '[Articles] Filter Articles',
  LOAD_MORE_ARTICLES = '[Articles] Load More Articles',

  PREVIEW_ARTICLE = '[Articles] Preview Article',
  ADD_TO_BASKET = '[Articles] Add To Basket',

  SHOW_ARTICLE_HISTORY = '[Articles] Article History',
  LOAD_ARTICLE_ORDER_HISTORY = '[Articles] Load Article Order History',
  LOAD_ARTICLE_ORDER_HISTORY_SUCCESS = '[Articles] Load Article Order History Success',

  EXTEND_CATEGORY = '[Articles] Extend category',
  UPDATE_USED_CATEGORIES = '[Articles] Update used categories',
  UPDATE_USED_CATEGORIES_SUCCESS = '[Articles] Update used categories success',
  RESET_USED_CATEGORIES = '[Articles] Reset used categories',
}

export class InitArticlesPage implements TcAction {
  readonly type = ArticlesActionTypes.INIT_ARTICLES_PAGE;
}

export class LoadArticles implements TcAction {
  readonly type = ArticlesActionTypes.LOAD_ARTICLES;
  constructor(public payload: LoadArticlePayload) { }
}

@SHOW_SPINNER()
export class InitArticles implements TcAction {
  readonly type = ArticlesActionTypes.INIT_ARTICLES;
  constructor(public payload: InitArticlesPayload) { }
}

@HIDE_SPINNER()
export class InitArticlesSuccess implements TcAction {
  readonly type = ArticlesActionTypes.INIT_ARTICLES_SUCCESS;
  constructor(public payload: InitArticleSuccessPayload) { }
}

export class InitCategoriesSuccess implements TcAction {
  readonly type = ArticlesActionTypes.INIT_CATEGORIES_SUCCESS;
  constructor(public payload: InitCategoriesSuccessPayload) { }
}

export class LoadArticlesSuccess implements TcAction {
  readonly type = ArticlesActionTypes.LOAD_ARTICLES_SUCCESS;
  constructor(public payload: LoadArticleSuccessPayload) { }
}

export class FilterArticles implements TcAction {
  readonly type = ArticlesActionTypes.FILTER_ARTICLES;
  constructor(public payload: TcListFilterModel) { }
}

export class LoadMoreArticles implements TcAction {
  readonly type = ArticlesActionTypes.LOAD_MORE_ARTICLES;
}

export class PreviewArticle implements TcAction {
  readonly type = ArticlesActionTypes.PREVIEW_ARTICLE;
  constructor(public payload: ArticleModel) { }
}

export class ShowArticleHistory implements TcAction {
  readonly type = ArticlesActionTypes.SHOW_ARTICLE_HISTORY;
  constructor(public payload: ArticleModel) { }
}

export class LoadArticleOrderHistory implements TcAction {
  readonly type = ArticlesActionTypes.LOAD_ARTICLE_ORDER_HISTORY;
  constructor(public payload: ArticleModel) { }
}

export class LoadArticleOrderHistorySuccess implements TcAction {
  readonly type = ArticlesActionTypes.LOAD_ARTICLE_ORDER_HISTORY_SUCCESS;
  constructor(public payload: ArticleOrderHistoryModel[]) { }
}

export class ExtendCategory implements TcAction {
  readonly type = ArticlesActionTypes.EXTEND_CATEGORY;
  constructor(public payload: string) { }
}

export class UpdateUsedCategories implements TcAction {
  readonly type = ArticlesActionTypes.UPDATE_USED_CATEGORIES;
}

export class UpdateUsedCategoriesSuccess implements TcAction {
  readonly type = ArticlesActionTypes.UPDATE_USED_CATEGORIES_SUCCESS;
  constructor(public payload: string[]) { }
}

export class ResetUsedCategories implements TcAction {
  readonly type = ArticlesActionTypes.RESET_USED_CATEGORIES;
}

export type ArticlesActions =
  | InitArticlesPage
  | LoadArticles
  | LoadArticlesSuccess
  | FilterArticles
  | LoadMoreArticles
  | PreviewArticle
  | ShowArticleHistory
  | LoadArticleOrderHistory
  | LoadArticleOrderHistorySuccess
  | ExtendCategory
  | UpdateUsedCategories
  | UpdateUsedCategoriesSuccess
  | ResetUsedCategories
  | InitArticles
  | InitArticlesSuccess
  | InitCategoriesSuccess;
