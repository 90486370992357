<tc-list
    #articleList
    id="article-list"
>
</tc-list>

<ng-template
    #colArticleItemTemplate
    let-row="row"
    let-rowAction="rowAction"
>
    <app-article-item [article]="row"></app-article-item>
</ng-template>
