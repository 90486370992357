import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SwService } from './app/services/serviceWorker';
import { StorageService } from './app/services/storage.service';
// import { Workbox } from 'workbox-window';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => new StorageService().assurePersistance()
    .then((restartRequired) => {
      SwService.init(restartRequired);
    })
  )
  .catch(err => console.error(err));

